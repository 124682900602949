import { MdInsertPhoto } from "react-icons/md";
import cx from './index.module.scss';
import { compareDates } from "../../../constants/Constants";

export default function UserItem({item, selectedChat, handleItemClick,}:any) {
    const bookingId = (item?.type !== 'admin' || item?.chatType !== 'generalUser') ? (item?.bookingData?.booking_Id || item?.bookingData?.businessBookingId) : ''
    const selectedBookingId = (selectedChat?.bookingData?.booking_Id || selectedChat?.bookingData?.businessBookingId)
    let timeStamp: any = item?.updateMessage
    let timeDate: any;

    if (timeStamp) {
        timeDate = new Date(timeStamp?.seconds * 1000 + timeStamp?.nanoseconds / 1000000)
    }

    const options: any = {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);

    const formattedDate = timeDate ? formatter.format(timeDate) : ""
    const date = timeDate ? formattedDate?.split(",")?.[0] : ""
    const time = timeDate ? formattedDate?.split(",")?.[1] : "";
    const checkIfBookingToday = !formattedDate ? false : compareDates(date, new Date()) === 0;

    const dynamicDate = !formattedDate ? "" : checkIfBookingToday ? time : date
    const client = item?.Client
    const firstNameLetter = client?.firstName?.charAt(0).toUpperCase();
    const firstLastNameLetter = client?.lastName?.charAt(0).toUpperCase();
    const services = item?.chatType === 'generalUser'
        ? '(General Chat)' : item?.type === 'admin'
            ? '' : item?.bookingData?.services?.length > 0
                ? `(${item?.bookingData?.services?.reduce((acc: any, val: any) => acc?.serviceId?._id !== val?.serviceId?._id ? [...acc, val] : acc, [])?.map((item: any) => item?.serviceId?.serviceId?.serviceName).join(",")})`
                : ''
    
    return (
        <li>
            <button
                className={`${cx.listMenu} ${((item?.chatType === "generalUser" && selectedChat?.chatType === 'generalUser' && item?.clientId === selectedChat?.clientId) || (item?.chatType !== "generalUser" && selectedChat?.chatType !== 'generalUser' && bookingId === selectedBookingId)) && selectedChat && selectedChat?.chatType !== 'admin'   ? cx.active : ''}`}
                onClick={() => handleItemClick(item)}>
                {item?.Client?.image ?
                    <img src={item?.Client?.image} className={`${cx.profileImg}`} alt={item?.Client?.firstName} />
                    : (item?.profileImage || item?.image?.[0]) ? (
                        <img src={item?.profileImage || item?.image?.[0]} className={`${cx.profileImg}`} alt={item?.businessName} />
                    ) : (
                        <div className={cx.businessIconBox}>
                            <span>{`${firstNameLetter || 'A'}${firstLastNameLetter || ''}`}</span>
                        </div>
                    )}
                <div className={`${cx.chatListBody_mainBox}`}>
                    <div className={`${cx.chatListBody}`}>
                        <h5 title={`${item?.Client?.firstName || ""} ${item?.Client?.lastName || ""} ${services}`}>
                            {client?.firstName || ''} {client?.lastName || ""} {services}
                        </h5>
                        <span>{dynamicDate}</span>
                        {/* <p>{item?.allMessage?.length !== 0 && item?.lastMsg === "" ? <MdInsertPhoto /> : item?.lastMsg}</p> */}

                    </div>
                    {bookingId && <p><span>{`booking ID : #${bookingId}`}</span></p>}
                    <p>
                        {item?.lastMsg && (item?.type === 'img' || item?.type === 'doc' || item?.imgName)
                            ? <>
                                <MdInsertPhoto /> {item?.imgformat === 'doc' ? 'Doc' : 'Image'}
                            </>
                            : item?.lastMsg}
                    </p>
                </div>
            </button>
        </li>
    )
}