import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";

import { deleteIcon, editButton, iconLogoGrey, plus, teamPerson1, teamWorking } from "../../../assets/images";
import DataGridTable from "./DataGrid";
import { ExportBtn, ImportBtn } from "../../../components/Business/Buttons";
import { useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";
import HoverEyeContent from "../../../components/Business/HoverEyeContent/HoverEyeContent";
import { Row, Col, Dropdown } from "react-bootstrap";
import { BiGridAlt } from "react-icons/bi";
import { FiList } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";
import { Status } from "../../../components/Business";
import { IoSearchSharp } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import PromotionCard from "./PromotionCard";
import BreadCrumbs from "../../../components/Business/BreadCrumbs/BreadCrumbs";
import Loader from "../../../components/Business/Loader/Loader";

const Promotion = (props: any) => {
  const [promotionList, setPromotionList] = useState<any>([]);
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  const { mainModuleRoles } = useSelector((state: any) => state.BranchReducer);
  const [promotionStatus, setPromotionStatus] = useState('all')
  const [branchFilter, setBranchFilter] = useState('')
  const [query, setQuery] = useState('')
  const [view, setView] = useState<string>('grid');
  const [typeFilter, setTypeFilter] = useState<any>('all')
  const [branchList, setBranchList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (businessData) {
      getPromotion();
      getBranches()
    }
  }, [businessData]);

  const getBranches = async () => {
    let res = await httpRequest(`getBranch?businessId=${businessData._id}`, "get", null, 'json');
    if (res.status === true) {
      setBranchList(res.data);
    }
  }

  const getPromotion = async () => {
    if (businessData) {
      setLoading(true)
      let res = await httpRequest(`getPromotion?businessId=${businessData?._id}&type=list`, "get", null, "json");
      if (res.status) {
        setPromotionList(res?.data);
      }
      setLoading(false)
    }
  };

  const canCreatePromo = mainModuleRoles?.Promotion?.Create;
  const handleNavigate = (type: string) => {
    if (type === 'wellnezza') {
      navigate('WellnezzaAddPromotion/wellnezza-info')
    } else {
      navigate('addPromotion/info')
    }
  }
  const filterPromotion = promotionList?.filter((promotion: any) => {
    const activeStatusPrmotion = +new Date(`${promotion?.dateTo}T${promotion?.endTimeTo}`) >= +new Date();
    const activeCheck = promotionStatus === 'all' ? true : activeStatusPrmotion
    const manageCheck = typeFilter === 'all' ? true : typeFilter?.includes(promotion?.promotionIdentity);
    const typeCheck = typeFilter === 'all' ? true : typeFilter?.includes(promotion?.promotionType)
    const branchCheck = !branchFilter ? true : promotion?.branchArr?.includes(branchFilter)
    const queryCheck = !query ? true : promotion?.promotionName?.toLocaleLowerCase()?.replaceAll(' ', '')?.includes(query?.toLocaleLowerCase()?.replaceAll(' ', ''))
    return manageCheck && branchCheck && queryCheck && typeCheck && activeCheck
  });
  const wellnezzaPromotionList: any = ["Regular", "Platform", "Off Peak Hours", "Last minute offer",]
  // 'wellnezza Regular'
  let promotionCounter: any = {}
  promotionList?.forEach((item: any) => {
    let identity = `${item?.promotionIdentity} ${item?.promotionType}`;
    if (promotionCounter.hasOwnProperty(identity)) {
      promotionCounter[identity] += 1
    } else {
      promotionCounter[identity] = 1
    }
  })
  return (
    <>
      <section className={`${st.pageWrapper} ${view === 'grid' ? st.maxWidth_pageWrapper : ''}`}>
        {/* <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>Promotion List</h5>
          </div>
          <div className={`${st.rightInfo}`}></div>
        </div> */}

        <div className={`${st.newRedesign_main}`}>
          {/* <BreadCrumbs /> */}
          <Row>
            <Col md={12}>
              <div className={`${st.head_paraBox} ${st.pt50}`}>
                <h2>Promotions</h2>
                <p>The Wellnezza platform allows you to offer a variety of promotions to your customers, which will also be featured on our website.</p>
              </div>
            </Col>
            <Col md={12}>
              <div className={`${st.gridList_view} justify-content-end mb-4`}>
                <button className={`btn grid ${view === 'grid' ? 'active' : ''}`} onClick={() => setView("grid")}>
                  <BiGridAlt />
                </button>
                <button className={`btn listView ${view === 'list' ? 'active' : ''}`} onClick={() => setView("list")}>
                  <FiList />
                </button>
              </div>
            </Col>
          </Row>
          {view === 'grid' ? (
            <>
              {canCreatePromo && <Row>
                <Col md={12}>
                  <div className="d-flex justify-content-end gap-3 mb-3">
                    <button className={`btn ${st.addBlue}`} onClick={() => handleNavigate('direct')}><FaPlus /> Create own Promotion</button>
                    <button className={`btn ${st.addBlue}`} onClick={() => handleNavigate('wellnezza')}><FaPlus /> SignUp for Wellnezza Promotion</button>
                  </div>
                </Col>
              </Row>}

              <Row>
                <Col md={4}>
                  <Col lg={11}>
                    <div className={`${st.services_leftMain_shadow}`}>
                      {/* <h3 className="mb-2">Promotion Type</h3> */}
                      {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p> */}
                      <ul className={`${st.serve_listing}`}>
                        <li>
                          <span className={`${st.categoryName} ${typeFilter === 'all' ? st.active : ''}`} onClick={() => setTypeFilter('all')}>All Promotion Type</span>
                          <span className={`${st.categoryAvail}`}>
                            <span className={`${st.value}`}>{promotionList?.length}</span>
                          </span>
                        </li>
                        <li>
                          <span className={`${st.categoryName}`}>Wellnezza Promotion</span>
                          <span className={`${st.categoryAvail}`}>
                            {/* <span className={`${st.value}`}>10</span> */}
                          </span>
                        </li>
                        {wellnezzaPromotionList?.map((item: any, p: number) => {
                          return (
                            <li style={{ paddingLeft: '10px' }} key={p}>
                              <span className={`${st.categoryName} ${typeFilter.includes(`wellnezza ${item}`) ? st.active : ''}`} onClick={() => setTypeFilter(`wellnezza ${item}`)}>{item}</span>
                              <span className={`${st.categoryAvail}`}>
                                <span className={`${st.value}`}>{promotionCounter?.[`wellnezza ${item}`] || 0}</span>
                              </span>
                            </li>
                          )
                        })}
                        <li>
                          <span className={`${st.categoryName}`}>Business Promotion</span>
                          <span className={`${st.categoryAvail}`}>
                            {/* <span className={`${st.value}`}>10</span> */}
                          </span>
                        </li>
                        <li style={{ paddingLeft: '10px' }}>
                          <span className={`${st.categoryName} ${typeFilter.includes(`business Regular`) ? st.active : ''}`} onClick={() => setTypeFilter(`business Regular`)}>Regular</span>
                          <span className={`${st.categoryAvail}`}>
                            <span className={`${st.value}`}>{promotionCounter?.[`business Regular`] || 0}</span>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Col>
                <Col md={8} className="mt-2">

                  <Row className="g-2">
                    {/* <Col md={5}>
                          <div className={`${st.reFormBox}`}>
                            <select className="form-select" value={manageFilter} onChange={(e: any) => setManageFilter(e.target.value)}>
                              <option value="">Managed by</option>
                              <option value="business">Platform</option>
                              <option value="wellnezza">Wellnezza</option>
                            </select>
                          </div>
                        </Col> */}

                    <Col md={2}>
                      <div className={`${st.reFormBox}`}>
                        <select className="form-select" onChange={(e: any) => setPromotionStatus(e.target.value)} value={promotionStatus}>
                          <option value='all'>All</option>
                          <option value='active'>Active</option>
                        </select>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className={`${st.reFormBox}`}>
                        <select className="form-select" value={branchFilter} onChange={(e: any) => setBranchFilter(e.target.value)}>
                          <option value="">Branch</option>
                          {branchList?.map((branch: any) => {
                            return (
                              <option value={branch?._id} key={branch?._id}>{branch?.branchName}</option>
                            )
                          })}
                        </select>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className={`${st.reFormBox} ${st.searchBoxIcon} position-relative`}>
                        <input type="search" placeholder="Search for Promotion" className="form-control" value={query} onChange={(e: any) => setQuery(e.target.value)} />
                        <IoSearchSharp />
                      </div>
                    </Col>

                  </Row>
                  {!loading && filterPromotion?.map((promotion: any) => {
                    return (
                      <PromotionCard key={promotion?._id} promotion={promotion} getPromotion={getPromotion} />
                    )
                  })}
                  {loading && (
                    <div>
                      <Loader />
                    </div>
                    )}
                </Col>
              </Row>
            </>
          ) : (
            <div className={`${st.tablePadding_shadow}`}>
              <div className={`${st.buttonsAction}`}>
                {canCreatePromo && <ul>
                  <li>
                    <ExportBtn />
                  </li>
                  <li>
                    <ImportBtn />
                  </li>
                  <li>
                    <NavLink to="addPromotion/info" className={`btn ${st.darkBtn}`}>
                      <FaPlus />  Launch New Promotion
                    </NavLink>

                  </li>
                  {/* <HoverEyeContent number={1013} /> */}
                  <li>
                    <NavLink to="WellnezzaAddPromotion/wellnezza-info" className={`btn ${st.darkBtn}`}>
                      <FaPlus />  Enroll in Wellnezza Promotion
                    </NavLink>
                  </li>
                  {/* <HoverEyeContent number={1014} /> */}
                </ul>}
              </div>

              <div className={`${st.tableBody}`}>
                <div className={`${st.tableBodyIn}`}>
                  <DataGridTable permotion={promotionList} getPromotion={getPromotion} />
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Promotion;
