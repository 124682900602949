import React from "react";
import m from "./Modal.module.scss";
import { NavLink } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";

const ServicesNotes = (props: any) => {
  let { show, handleServicesNotesClose, data, type = null } = props;

  return (
    <>
      <Modal
        centered
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleServicesNotesClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={m.modalCts}
      >
        <Modal.Header className="p-2">
          {/* <Modal.Title>{type === "help" ? `Screen No. ${data?.screenNumber} ${data?.mainMenu} - ${data?.tab} `  : "Notes on Price"}</Modal.Title> */}
          <Modal.Title>
            {type === "help"
              ? data?.screenNumber && data?.mainMenu && data?.tab
                ? `#${data.screenNumber}`
                : "No Screen No. available for this screen."
              : "Notes on Price"}
          </Modal.Title>

          <button
            className={`${m.closeIcon} ${m.v2}`}
            onClick={handleServicesNotesClose}
          >
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: 'calc(100vh - 215px )', overflowY: "auto" }}>
          <div className={`${type === "help" ? "" : m.logoutPopup}`}>
            {type === "help" ? (
              <div dangerouslySetInnerHTML={{ __html: data?.details }} />
            ) : (
              <h3 className={`${m.completedConfirmation} mb-0 w-100`}>
                {data}
              </h3>
            )}
            {/* <div className={`${m.btnsAlignments}`}>
              <NavLink
                className={`btn ${m.cancelBtn}`}
                to="#"
                onClick={handleNoShowClose}
              >
                Cancel
              </NavLink>
              <button
                type="button"
                className={`btn ${m.actionBtn}`}
                onClick={handleSubmit}
              >
                Ok
              </button>
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-white">

        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ServicesNotes;
