import React, { useEffect, useState } from 'react';
import BasicInfo from './BasicInfo';
import OpHrs from "./OpHrs";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { httpRequest } from '../../../Apis/commonApis';
import NonOpHrs from './NonOpHrs';

export default function AddBrachRouts(props: any) {
    const { dynamicName, handleStepsFormData, loading,branchRef, selectedCatIds,setPublish, branchName, setSelectedCatIds , setBranchName, setErrorTrig} = props;
    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    const location = useLocation();

    const [editBranchData, setEditBranchData] = useState<any>(null);
    const [userData, setUserData] = useState<any>(null);

    useEffect(() => {
        if(editBranchData?.branchName){
            setSelectedCatIds(editBranchData?.businessCatagoryId?.map((item:any) => ({label:item?.BusinessCategoryName, value:item?._id})))
            setBranchName(editBranchData?.branchName)
            setPublish(editBranchData?.isPublish !== undefined ? editBranchData?.isPublish : true)
        }
    },[editBranchData])

    useEffect(() => {
        let udata = localStorage.getItem("user_data") || "";
        let userdata = JSON.parse(udata);
        setUserData(userdata);
    }, []);
    useEffect(() => {
        if (location.state && businessData) {
            getBranchData();
        }
    }, [businessData, location.state]);

    const getBranchData = async () => {
        let res = await httpRequest(`getBranch?businessId=${businessData._id}&_id=${location.state.branchId}&type=list`, "get", null, "json");
        if (res.status) {
            setEditBranchData(res.data[0])
        }
    };

    return (
        <>
            {dynamicName === "info" &&
                <BasicInfo businessData={businessData} userData={userData}
                    handleStepsFormData={handleStepsFormData}
                    editBranchData={editBranchData}
                    loading={loading}
                    selectedCatIds={selectedCatIds}
                    branchName={branchName}
                    setSelectedCatIds={setSelectedCatIds}
                    setErrorTrig={setErrorTrig}
                    branchRef={branchRef}
                />}
            
            {dynamicName === "opHrs" &&
                <OpHrs
                    handleStepsFormData={handleStepsFormData}
                    editBranchData={editBranchData}
                    loading={loading} />}
            {dynamicName === "non_opHrs" &&
                <NonOpHrs
                    handleStepsFormData={handleStepsFormData}
                    editBranchData={editBranchData}
                    loading={loading} />}
        </>
    )
}
