import { useEffect, useState } from "react";
import { httpRequest } from "../../../Apis/commonApis";
import { iconLogo } from "../../../assets/images";
import ErrorPopup from "../../../components/Business/Modals/ErrorPopup";
import ConfirmationModal from "../../../components/Business/Modals/ConfirmationModal";
import PriceItem from "./PriceItem";

export default function PriceTable({ prices, getBusinessPrice }: any) {
    
    return (
        <>
            <table className="table" >
                <thead className="thead-light">
                    <tr>
                        <th scope="col">Team Title</th>
                        <th scope="col">Service Type</th>
                        <th scope="col">Price (SGD)</th>
                        <th scope="col">Duration (min)</th>
                        <th scope="col">Note</th>
                        <th scope="col" style={{ maxWidth: '95px', minWidth: '95px' }}>Publish on Wellnezza</th>
                    </tr>
                </thead>
                <tbody>
                    {prices?.map((price: any) => {
                        return (
                            <PriceItem price={price} key={price?._id} getBusinessPrice={getBusinessPrice}/>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}