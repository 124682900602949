import { Col } from "react-bootstrap";
import { deleteIcon, editButton, iconLogoGrey, teamPerson1 } from "../../../assets/images";
import { Status } from "../../../components/Business";
import st from "../../../assets/stylesheet/style.module.scss";
import { httpRequest } from "../../../Apis/commonApis";
import { useEffect, useState } from "react";
import { DeletePopup } from "../../../components/Business/Modals";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ErrorPopup from "../../../components/Business/Modals/ErrorPopup";
import ConfirmationModal from "../../../components/Business/Modals/ConfirmationModal";

export default function TeamList({ team, getTeamMember, handleAddNewTeamShow, type = 'all' }: any) {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [isPublished, setIsPublished] = useState<boolean>(false)
    const handleClose = () => {
        setError('')
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const [showErrorModal, setShowErrorModal] = useState("");
    const handleAlertModalClose = () => setShowErrorModal("");
    const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);

    const [showConfirmation, setShowConfirmation] = useState<any>(false)
    const handleConfirmationClose = () => {
        setShowConfirmation(false)
    }

    const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);

    const deleteTeamMember = async () => {
        setError('')
        const res = await httpRequest(`deleteTeamMember/${team?._id}`, "DELETE", null, "json");
        if (res?.status) {
            getTeamMember && getTeamMember();
        }
        else {
            setError(res?.messaeg)
        }
    };
    const handleEditClick = () => {
        const route = type === 'publish' ? "/business/publish/edit-team/info" : "/business/new-teams/edit-team/info"
        navigate(route, { state: { teamId: team?._id } });
    };

    const canDeleteTeam = subModuleRoles?.['Teams']?.Team?.Delete;
    const canEditTeam = subModuleRoles?.['Teams']?.Team?.Edit;
    const canDeactivate = subModuleRoles?.['Teams']?.Team?.deactivate;
    const teamTitleName = team?.Role?.length > 0 ? team?.Role?.[0]?.role?.map((item: any) => item?.teamTitle)?.join(',') : '';



    async function handlePublish() {
        const checked = !isPublished
        const formdata = new FormData();
        formdata.append('isPublish', JSON.stringify(checked))
        if (checked && !team.isActive) {
            handleAlertModalShow(`Error, you can't publish this team member because it's status is disabled`)
            return
        }
        const res = await httpRequest(`updateTeamMember/${team?._id}`, 'PATCH', formdata, "formdata");
        if (res?.status) {
            getTeamMember && getTeamMember();
            handleAlertModalShow(`Success, team member is ${res?.data?.isPublish ? 'published' : 'unpublished'}`)
        } else {
            handleAlertModalShow(`Error, team member publishing status is not updated`)
        }
        handleConfirmationClose()
    }

    useEffect(() => {
        setIsPublished(team?.isPublish)
    }, [team?.isPublish])

    const isDisabled = team?.hoursDetail === null || team?.hoursDetail?.length ===0
    return (
        <>
            <ErrorPopup
                showErrorModal={showErrorModal}
                handleErrorModalClose={handleAlertModalClose}
            />
            <ConfirmationModal showConfirmation={showConfirmation} message={`${isPublished ? 'unpublish' : 'publish'} this team member`} handleConfirmationClose={handleConfirmationClose} onConfirm={handlePublish} />
            <DeletePopup show={show} deleteTeamMember={deleteTeamMember}
                handleClose={handleClose} apiError={error} />
            <Col md={12}>
                <div className={`${st.edit_publishBranchMain} mb-3`}>
                    <div className={`${st.topBox_withBtn} mb-2`}>
                        <div className={`${st.topBox_imgBox} mb-1`}>
                            {team?.image ? (
                                <img src={team?.image} alt="img" />
                            ) : (
                                <div className={st.fakeImage}>
                                    <span>{team?.firstName?.[0]?.toLocaleUpperCase()}</span>
                                </div>
                            )}
                            <h5>{team?.firstName} {team?.lastName || ''} {team?.nickName ? <span>({team?.nickName})</span> : ''}</h5>
                        </div>
                        <ul className={`${st.btnList} align-items-center`}>
                            {canEditTeam && <li onClick={() => {
                                handleEditClick()
                                handleAddNewTeamShow()
                            }}>
                                <button> <img src={editButton} alt="edit" /></button>
                            </li>}
                            {canDeleteTeam && type !== 'publish' && <li onClick={handleShow}>
                                <button> <img src={deleteIcon} alt="deleteIcon" /></button>
                            </li>}
                            {canDeactivate && type !== 'publish' && <li className="ms-2">
                                <Status status={team?.isActive}
                                    api='updateTeamMember' id={team?._id}
                                    apiMethod='formdata'
                                    getLatest={getTeamMember} 
                                    disabled={isDisabled}
                                    />
                            </li>}
                        </ul>
                    </div>
                    <ul className={`${st.bottomBox_details}`}>
                        <li className={`${st.leftWidth}`} style={{ minWidth: '80%' }}>
                            <div className="d-flex align-items-center gap-3">
                                <div className={`${st.infoSide}`}><span>Employee ID:</span> {team?.teamMemberIdNo || ''}</div>
                                <div className={`${st.infoSide}`}><span>Joining Date:</span> {team?.createdAt?.split('T')?.[0] || ''}</div>
                            </div>
                            <div className="d-flex align-items-center gap-3">
                                <div className={`${st.infoSide}`}><span>Branch:</span> {team?.Role?.length > 0 ? team?.Role?.map((role: any) => role?.branchName)?.join(', ') : 'not assigned'}</div>
                                {teamTitleName && <div className={`${st.infoSide}`}><span>Team title:</span> {teamTitleName}</div>}
                            </div>
                            {isDisabled && <div className="d-flex align-items-center gap-3">
                                <div className={`${st.infoSide} text-danger`}>Please maintain team member working hours first</div>
                            </div>}
                        </li>
                        <li>
                            {/* <div className={`${st.edit_publish_checkBox} p-0 ps-1 text-end`}>
                <label className={`d-inline-flex ${st.checkbox}`}>
                  <input type="checkbox" />
                  <span className={`${st.checkmark}`}></span>
                  <span className={`${st.labelName} ms-1`} style={{ marginTop: "3px" }}>Published On Wellnezza</span>
                </label>
              </div> */}
                            <div className="text-end">
                                <span className={`${st.iconLogo_checkBox} `}>
                                    <span className={`${st.borderBox} `}>
                                        <label className={`d-inline-flex ${st.checkbox}`}>
                                            <input type="checkbox" checked={isPublished} onChange={(e: any) => {
                                                setShowConfirmation(true)
                                                // handlePublish(e)
                                            }} />
                                            <span className={`${st.checkmark}`}></span>
                                            <span className={`${st.labelName} ms-1`}> <img src={iconLogoGrey} alt="icon" /></span>
                                        </label>
                                    </span>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </Col>
        </>
    )
}