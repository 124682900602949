import React, { useEffect, useState } from 'react';
import st from '../../../../assets/stylesheet/style.module.scss';
import cx from "../index.module.scss";
import { Col, Row } from 'react-bootstrap';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, Value } from 'react-phone-number-input'
import { useForm } from 'react-hook-form';
import { watch } from 'fs';
import { httpRequest } from '../../../../Apis/commonApis';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoadingBtn from '../../Buttons/LoadingBtn';

export default function BasicInfo(props: any) {
    const { handleStepsFormData, loading, editTeamData, apiError } = props;
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    );
    const navigate = useNavigate()
    const [allBranchList, setAllBranchList] = useState([]);
    const [serviceIds, setServiceIds] = useState<any>([])
    const [selectedBrachRoles, setSelectedBrachRoles] = useState<any>([]);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        control,
    } = useForm();
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");

    const handlePhoneChange: any = (value: any) => {
        if (value) {
            setPhone(formatPhoneNumber(value) ? value : "");
        } else {
            setPhone("");
        }
        if (phoneError !== '') {
            setPhoneError('');
        }
    };

    useEffect(() => {
        if (editTeamData) {
            reset();
            setPhone(editTeamData?.mobile ? `${editTeamData?.mobile?.includes("+") ? "" : "+"}${editTeamData?.mobile}` : "");
            setValue('isBooking', editTeamData?.isBooking || false)
            setValue('email', editTeamData?.email);
            setValue('about', editTeamData?.about);
            setValue('isPublish', editTeamData?.isPublish)
        }
    }, [editTeamData]);

    useEffect(() => {
        if (businessData) {
            getBranches();
            getService();
        };
    }, [businessData]);

    const getBranches = async () => {
        let res = await httpRequest(`getBranch?businessId=${businessData._id}`, "get", null, 'json');
        if (res.status) {
            let allBranches = res.data;
            allBranches.reverse();
            setAllBranchList(allBranches);
        }
    }
    const getService = async () => {
        const res = await httpRequest(`getBusinessService?businessId=${businessData._id}&type=list`, "get", null, "json");
        if (res.status) {
            setServiceIds(res?.data?.map((item: any) => item?._id));
        }
    };

    const submitHandler = async (data: any) => {
        const existRole = editTeamData?.Role ? editTeamData?.Role?.map((item: any) => ({ ...item, branch: item?.branch?._id, role: item?.role?.map((roleItem: any) => roleItem?._id) })) : []
        let basicinfo = { ...data };
        basicinfo.mobile = phone;
        // basicinfo.Role = JSON.stringify(existRole);
        if (!editTeamData) {
            basicinfo.serviceId = JSON.stringify(serviceIds)
        }
        if (data === "logo") {
            handleStepsFormData(basicinfo);
        } else {
            handleStepsFormData(basicinfo, "step1");
        }
    };
    return (
        <>
            <div className={`${st.offTab_heading}`}>
                <p className={`${st.offcanvas_para}`}>Maintain Contact details of team member.   Email will be used as  user ID if you
                    provide them access to Wellnezza APP. Contact Details will not be visible to Public.</p>
            </div>
            <Row>
                <Col md={7}>
                    <div className={`${st.reFormBox}`}>
                        <label htmlFor="">Email</label>
                        <input type="email" placeholder="Enter Email" className="form-control" {...register("email", {
                            // required: true,
                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        })}
                        />
                        {errors?.email?.type === "pattern" && (
                            <p className={"errorMessage"}>Invalid email address</p>
                        )}
                        {apiError && (
                            <p className={"errorMessage"}>{apiError}</p>
                        )}
                    </div>
                </Col>
                <Col md={5}>
                    <div className={`${st.reFormBox}`}>
                        <label htmlFor="">Phone Number</label>
                        <div className={`${st.formBox} ${st.formBox_new}`}>
                            {/* <label className="form-label">Phone number 1 <HoverEyeContent number={9075} /></label> */}
                            <div className={`${st.phoneBox}`}>
                                <PhoneInput
                                    defaultCountry='SG'
                                    international
                                    placeholder="Enter Your Phone Number"
                                    value={phone}
                                    onChange={handlePhoneChange}
                                />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={12}>
                    <div className={`${st.reFormBox}`}>
                        <label htmlFor="">About</label>
                        <p className={`${st.offcanvas_para} mb-2`}>Provide brief Details about your service staff. This information will be available to public</p>
                        <textarea className='form-control' placeholder='Enter About' style={{ height: '80px' }} {...register("about", {
                            // required: true,
                        })}></textarea>
                    </div>
                </Col>
                <Col md={12}>
                    <div className='mb-3'>
                        <p className={`${st.offcanvas_para} mb-2`}>Choose if Profile of this team member should be available to public</p>
                        <div className={`${st.edit_publish_checkBox} p-0 `}>
                            <label className={`d-inline-flex ${st.checkbox}`}>
                                <input type="checkbox" {...register("isPublish")} />
                                <span className={`${st.checkmark}`}></span>
                                <span className={`${st.labelName} ms-1`} style={{ marginTop: "0px", fontSize: '15px' }}>Publish Profile on website</span>
                            </label>
                        </div>
                    </div>
                </Col>
                <Col md={12}>
                    <div className='mb-3'>
                        <p className={`${st.offcanvas_para} mb-2`}>Choose if Profile of this team member is visible during customer booking.</p>
                        <div className={`${st.edit_publish_checkBox} p-0 `}>
                            <label className={`d-inline-flex ${st.checkbox}`}>
                                <input type="checkbox" {...register("isBooking")} />
                                <span className={`${st.checkmark}`}></span>
                                <span className={`${st.labelName} ms-1`} style={{ marginTop: "0px", fontSize: '15px' }}>Profile Available for booking</span>
                            </label>
                        </div>
                    </div>
                </Col>
            </Row>

            <div className={`${cx.offcanvas_footer}`}>
                <Col md={12}>
                    <div className={`${st.formBox} ${cx.formSign}`}>
                        <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-3`}>
                            <button className={`btn ${st.btn3} ${cx.btnGray}`} type="submit" onClick={handleSubmit(submitHandler)} disabled={loading}>
                                {loading ? <LoadingBtn /> : 'Save'}
                            </button>
                            {/* <button className={`btn ${st.btn3} ${cx.btnGray} ${cx.btnGrayLight}`} type="submit" onClick={(e) => {
                                e.preventDefault();
                                handleStepsFormData(null, 'cancel')
                            }}>
                                Cancel
                            </button> */}
                        </div>
                    </div>
                </Col>
            </div>
        </>
    )
}
