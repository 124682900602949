import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import cx from "./index.module.scss";
import st from '../../../assets/stylesheet/style.module.scss';
import {
  home,
  homeActive,
  bookings,
  bookingsActive,
  calendar,
  calendarActive,
  category,
  categoryActive,
  promotionV2,
  assignRoleV2,
  // businessIcon,
  branchIcon,
  sales,
  amenities,
  clients,
  salesV2,
  profile,
  branchSetupA,
  businessIconA,
  notification,
  notificationIcon,
  salesA,
  messagecta,
  messagectaA,
  team,

  homeNew,
  bookingNew,
  servicesNew,
  servicesNewActive,
  teamNew,
  teamNewActive,
  customerNew,
  promotionNew,
  chatNew,
  businessSetupNew,
  businessSetupNewActive,
  menuIcon,
  // new sidebar icons
  wellnezaaIconDashboard,
  teamIconSlidebar,
  teamIconSlidebarActive,
  servicesIconSlidebar,
  servicesIconSlidebarActive,
  reportsIconSlidebar,
  reportsIconSlidebarActive,
  publishIconSlidebar,
  publishIconSlidebarActive,
  promotionIconSlidebar,
  promotionIconSlidebarActive,
  customerIconSlidebar,
  customerIconSlidebarActive,
  chatIconSlidebar,
  chatIconSlidebarActive,
  businessIconSlidebar,
  businessIconSlidebarActive,
  bookingIconSlidebar,
  bookingIconSlidebarActive
} from "../../../assets/images";
import GlobalContext from "../../../store/global-context";

import { useContext } from "react";
import { ProgressBar, Accordion, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { refreshPage, sidebarFullAccessRoles, sidebarFullAccessRoles2 } from "../../../Utils/Funcs";
import { changeAccessRolesState } from "../../../redux_toolkit/reducer/branchReducer";
import BusinessSubSidebar from "../BusinessSubSidebar";
import { Chat } from "../../../pages/Business";
import { changeSideBarSpand, getBusinessUserRoles } from "../../../redux_toolkit/reducer/shareDataReducer";
import { IoIosArrowForward } from "react-icons/io";
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';


const imageMap: any = {
  dashboard: wellnezaaIconDashboard,
  dashboardActive: wellnezaaIconDashboard,
  bookings: bookingIconSlidebar,
  bookingsActive: bookingIconSlidebarActive,
  reports: reportsIconSlidebar,
  reportsActive: reportsIconSlidebarActive,
  customer: customerIconSlidebar,
  customerActive: customerIconSlidebarActive,
  promotion: promotionIconSlidebar,
  promotionActive: promotionIconSlidebarActive,
  chat: chatIconSlidebar,
  chatActive: chatIconSlidebarActive,
  publish: publishIconSlidebar,
  publishActive: publishIconSlidebarActive,

};

const BusinessSidebar = (props: any) => {
  let { businessData, spand } = useSelector((state: any) => state.shareDataReducer);
  const [userData, setUserData] = useState<any>(null);
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState<any>(null);

  useEffect(() => {
    let udata = localStorage.getItem("user_data") || "";
    let userdata = JSON?.parse(udata);
    setUserData(userdata);
    if (userdata?.businessId) {
      dispatch(getBusinessUserRoles({ id: userdata?.businessId }));
    }
  }, []);

  const [subMenuName, setSubMenuName] = useState("");
  const globalCtx = useContext(GlobalContext);

  const [initialModuleRoles, setInitialModuleRoles] = useState<any>(null);
  const [subModuleRoles, setSubModuleRoles] = useState<any>(null);
  const [subModuleRolesWithoutTransform, setSubModuleRolesWithoutTransform] = useState<any>([]);
  const [sidebarItemRoles1, setSidebarItemRoles1] = useState<any>([]);
  const [sidebarItemRoles2, setSidebarItemRoles2] = useState<any>([]);
  const [showBusinessSetupDrop, setShowBusinessSetupDrop] = useState<boolean>(false);

  const [showServiceSetupDrop, setShowServiceSetupDrop] = useState<boolean>(false);
  const [showTeamSetupDrop, setShowTeamSetupDrop] = useState<boolean>(false);

  const [showBranchSetupDrop, setShowBranchSetupDrop] = useState<boolean>(false);

  const now = 60;
  let { businessUserRoles } = useSelector((state: any) => state.shareDataReducer);
  let { branchId } = useSelector((state: any) => state.BranchReducer);

  function mergeAccess(roles: any) {
    // Check if there are at least two objects to compare
    if (roles.length < 2) return roles[0];

    // Initialize with the first object
    let mergedObject = JSON.parse(JSON.stringify(roles[0]));

    // Loop through the rest of the objects
    for (let i = 1; i < roles.length; i++) {
      let currentRole = roles[i];

      currentRole.module.forEach((module: any, moduleIndex: number) => {
        module.actions.forEach((action: any, actionIndex: any) => {
          if (action.access) {
            mergedObject.module[moduleIndex].actions[actionIndex].access = action.access;
          }
        });
      });
    }

    return mergedObject;
  }

  function mergeAccess2(roles: any) {
    // Check if there are at least two objects to compare
    if (roles.length < 2) return roles[0];

    // Initialize with the first object
    let mergedObject = JSON.parse(JSON.stringify(roles[0]));

    // Recursive function to merge nested arrays
    function mergeRecursive(target: any, source: any) {
      if (Array.isArray(target) && Array.isArray(source)) {
        source.forEach((sourceItem, index) => {
          if (typeof sourceItem === 'object' && sourceItem !== null) {
            if (!target[index]) {
              target[index] = sourceItem;
            } else {
              mergeRecursive(target[index], sourceItem);
            }
          }
        });
      } else if (typeof target === 'object' && target !== null && typeof source === 'object' && source !== null) {
        for (let key in source) {
          if (source[key] && typeof source[key] === 'object') {
            if (!target[key]) {
              target[key] = source[key];
            } else {
              mergeRecursive(target[key], source[key]);
            }
          } else if (source[key] === true) {
            target[key] = source[key];
          }
        }
      }
    }

    // Loop through the rest of the objects
    for (let i = 1; i < roles.length; i++) {
      mergeRecursive(mergedObject, roles[i]);
    }

    return mergedObject;
  }

  function mergeAccessNested(roles: any) {
    // Check if there are at least two objects to compare
    if (roles.length < 2) return roles[0];

    // Initialize with the first object
    let mergedObject = JSON.parse(JSON.stringify(roles[0]));

    // Recursive function to merge nested objects
    function mergeRecursive(target: any, source: any) {
      for (let key in source) {
        if (source.hasOwnProperty(key)) {
          if (typeof source[key] === 'object' && !Array.isArray(source[key]) && source[key] !== null) {
            if (!target[key]) {
              target[key] = {};
            }
            mergeRecursive(target[key], source[key]);
          } else if (source[key] === true) {
            target[key] = source[key];
          }
        }
      }
    }

    // Loop through the rest of the objects
    for (let i = 1; i < roles.length; i++) {
      mergeRecursive(mergedObject, roles[i]);
    }

    return mergedObject;
  }


  useEffect(() => {
    let userdata: any = JSON.parse(localStorage.getItem('user_data') || '');
    if (userdata?.userType === "teamMember") {
      if (branchId && initialModuleRoles && subModuleRoles) {
        let findBranchRole = userdata?.Role?.find((item: any) => item.branch === branchId);
        let findBranchAccessType;
        let findBranchAccessType2;
        let subModuleRolesAccess;
        if (findBranchRole?.accessManager?.length > 0) {

          findBranchAccessType = initialModuleRoles?.filter((item: any) => findBranchRole?.accessManager?.some((accessM: any) => item?.role === accessM));
          findBranchAccessType2 = subModuleRoles?.filter((item: any) => findBranchRole?.accessManager?.some((accessM: any) => item?.role === accessM));

          subModuleRolesAccess = roleWiseAccessObj2(subModuleRolesWithoutTransform)?.filter((item: any) => findBranchRole?.accessManager?.some((accessM: any) => item?.role === accessM));
          subModuleRolesAccess = subModuleRolesAccess?.length > 0 ? mergeAccessNested(subModuleRolesAccess) : []

        } else {
          // findBranchAccessType = initialModuleRoles?.find((item: any) => item?.role === findBranchRoleIsGlobal?.accessManager);
          // findBranchAccessType2 = subModuleRoles?.find((item: any) => item?.role === findBranchRoleIsGlobal?.accessManager);
          // subModuleRolesAccess = roleWiseAccessObj2(subModuleRolesWithoutTransform)?.find((item: any) => item?.role === findBranchRoleIsGlobal?.accessManager);
          findBranchAccessType = {}
          findBranchAccessType2 = {}
          subModuleRolesAccess = {}
        }
        const sidebarItemRoles1 = findBranchAccessType?.length > 0 ? mergeAccess(findBranchAccessType) : {}
        const sidebarItemRoles2 = findBranchAccessType2?.length > 0 ? mergeAccess2(findBranchAccessType2) : {}
        // const sidebarItemRoles2 = mergeRoles(findBranchAccessType2)

        setSidebarItemRoles1(sidebarItemRoles1?.module || []);
        setSidebarItemRoles2(sidebarItemRoles2?.modules);

        // console.log(findBranchAccessType?.module , findBranchAccessType2?.modules , "findBranchAccessType2?.modules");

        let mainModuleRolesAccess = findBranchAccessType ? roleWiseAccessObj1([sidebarItemRoles1]) : []

        dispatch(changeAccessRolesState({ mainModuleRoles: mainModuleRolesAccess[0], subModuleRoles: subModuleRolesAccess }));

        // const branchSetUpAtleastOne = areAllFalse(subModuleRolesAccess['Branch Set Up']);
        const businessSetUpAtleastOne = areAllFalse(subModuleRolesAccess?.['Business Set Up']);
        const ServicesAtleastOne = areAllFalse(subModuleRolesAccess?.['Services']);
        const TeamAtleastOne = areAllFalse(subModuleRolesAccess?.['Teams']);


        // setShowBranchSetupDrop(branchSetUpAtleastOne);
        setShowBusinessSetupDrop(businessSetUpAtleastOne);
        setShowServiceSetupDrop(ServicesAtleastOne);
        setShowTeamSetupDrop(TeamAtleastOne);
      }
    } else if (userdata?.userType === "businessUser") {
      // setShowBranchSetupDrop(true);
      setShowBusinessSetupDrop(true);
      setShowServiceSetupDrop(true);
      setShowTeamSetupDrop(true);
      let businessUserRoles = sidebarFullAccessRoles();
      let businessUserRoles2 = sidebarFullAccessRoles2();
      setSidebarItemRoles1(businessUserRoles);
      setSidebarItemRoles2(businessUserRoles2);
    }
  }, [branchId, initialModuleRoles, subModuleRoles]);


  function areAllFalse(obj: any) {
    for (const branchKey in obj) {
      if (obj.hasOwnProperty(branchKey)) {
        const branch = obj[branchKey];
        if (Object.values(branch).some((value) => value === true)) {
          return true;
        }
      }
    }
    return false;
  }

  useEffect(() => {
    if (businessUserRoles) {
      let newArrayofRoles = [...businessUserRoles]
      const reversedRoleManagement = newArrayofRoles.reduce((result: any, moduleData: any) => {
        if (moduleData.roles) {
          moduleData.roles?.forEach((roleData: any) => {
            const moduleName = moduleData.module;
            const link = moduleData.link;
            const actionName = roleData.action;
            roleData?.actionFor?.forEach((roleItem: any) => {
              const access = roleItem.access;
              const role = roleItem.role;
              if (!result[role]) {
                result[role] = {
                  role,
                  module: [],
                };
              }
              const roleModule = result[role]?.module?.find((item: any) => item?.moduleName === moduleName);
              if (!roleModule) {
                result[role].module.push({
                  moduleName,
                  link,
                  actions: [
                    {
                      actionName,
                      access,
                    },
                  ],
                });
              } else {
                roleModule.actions.push({
                  actionName,
                  access,
                });
              }
            });
          });
        }
        return result;
      }, {});

      const reversedRoleArray = Object.values(reversedRoleManagement);
      setInitialModuleRoles(reversedRoleArray);
      createnewArray(businessUserRoles);
    }
  }, [businessUserRoles]);

  function createnewArray(roleManagementList: any) {
    const reversedRoleManagement = roleManagementList.reduce((result: any, moduleData: any) => {
      moduleData.subModule?.forEach((subModuleData: any) => {
        const moduleName = moduleData.module;
        const subModuleName = subModuleData.name;
        const link = subModuleData.link;

        subModuleData.roles.forEach((roleData: any) => {
          const actionName = roleData.action;

          roleData.actionFor.forEach((roleItem: any) => {
            const role = roleItem.role;
            const access = roleItem.access;

            if (!result[role]) {
              result[role] = {
                role,
                modules: [],
              };
            }

            let roleModule = result[role]?.modules?.find((item: any) => item?.moduleName === moduleName);
            if (!roleModule) {
              roleModule = {
                moduleName,
                subModules: [],
              };
              result[role].modules.push(roleModule);
            }

            let subModule = roleModule?.subModules?.find((sub: any) => sub?.subModuleName === subModuleName);
            if (!subModule) {
              subModule = {
                subModuleName,
                link,
                actions: [],
              };
              roleModule.subModules.push(subModule);
            }
            subModule.actions.push({
              actionName,
              access,
            });
          });
        });
      });

      return result;
    }, {});
    const reversedRoleArray = Object.values(reversedRoleManagement);
    setSubModuleRolesWithoutTransform(reversedRoleArray)
    restructureArray(reversedRoleArray);

  }

  function restructureArray(orgArr: any) {
    interface Module {
      moduleName: string;
      subModules: any;
    }

    interface Role {
      role: string;
      modules: Module[];
    }

    const originalArray: Role[] = [...orgArr];

    const restructuredArray: Role[] = originalArray.map((item) => {
      return {
        role: item.role,
        modules: item.modules.map((module) => {
          const businessSetUp = module.moduleName === 'Business Set Up';
          const Services = module.moduleName === '"Services"';
          const Teams = module.moduleName === '"Teams"';
          const branchSetUp = module.moduleName === 'Branch Set Up';
          const subModules: any = [];
          subModules.push(...module.subModules);

          if (businessSetUp) {
          }

          // if (branchSetUp) {
          //   subModules.push(...module.subModules.filter((subModule: any) => (
          //     subModule.subModuleName !== 'Services' && subModule.subModuleName !== 'Packages'
          //   )));
          //   let serviceMenus = module.subModules.filter((subModule: any) => (
          //     subModule.subModuleName === 'Services' || subModule.subModuleName === 'Packages'
          //   ));
          //   subModules.splice(2, 0, {
          //     menuName: 'Service Menu',
          //     menus: serviceMenus
          //   });
          // }

          return {
            moduleName: module.moduleName,
            subModules: subModules,
          };
        }),
      };
    });

    setSubModuleRoles(restructuredArray);
  }

  function roleWiseAccessObj1(origArray: any) {
    const transformedArray = origArray.map((item: any) => {
      const newObj: any = { role: item?.role };

      item?.module?.forEach((module: any) => {
        const moduleName: any = module?.moduleName;
        // const viewAccess = module.actions.find((action: any) => action.actionName === 'View' || action.actionName === 'View History');
        // newObj[moduleName] = viewAccess ? viewAccess.access : false;

        const resultObject: { [key: string]: boolean } = {};
        module.actions.forEach((obj: { access: boolean, actionName: string }) => {
          resultObject[obj.actionName] = obj.access;
        });
        newObj[moduleName] = resultObject;
      });

      return newObj;
    });
    return transformedArray;
  }

  function roleWiseAccessObj2(inputArray: any) {
    const transformedArray = inputArray.map((item: any) => {
      const rolesObj: any = {};

      item.modules.forEach((module: any) => {
        const moduleName: any = module.moduleName;
        const subModulesObj: any = {};

        module.subModules.forEach((subModule: any) => {
          const subModuleName = subModule.subModuleName;
          // const viewAccess = subModule.actions.find((action: any) => action.actionName === 'View' || action.actionName === 'View History');
          // const viewAccessValue = viewAccess ? viewAccess.access : false;

          const resultObject: { [key: string]: boolean } = {};
          subModule.actions.forEach((obj: { access: boolean, actionName: string }) => {
            resultObject[obj.actionName] = obj.access;
          });

          subModulesObj[subModuleName] = resultObject;
        });

        rolesObj[moduleName] = subModulesObj;
      });

      return { role: item.role, ...rolesObj };
    });
    return transformedArray;
  }


  // if subsidebar active then we add a activesubsidebar class in style_pageWrapper__BvqU2 class
  useEffect(() => {
    const page: any = document.querySelector("section");
    if (spand && page?.getAttribute("class")?.includes("style_pageWrapper")) {
      page?.classList.add("activesubsidebar");
    } else {
      page?.classList.remove("activesubsidebar")
    }

    return () => {
      page?.classList.remove("activesubsidebar")
    }

  }, [spand, window.location.pathname]);

  const isNotAccessable = businessData?.stepCompleted < 10 && userData?.userType === "businessUser";

  const [show, setShow] = useState('');

  const handleToggle = (module: any) => {
    if (isNotAccessable) {
      return
    }
    if (module === show) {
      setShow('')
      setSubMenuName('')
    } else {
      setSubMenuName(module)
      setShow(module)
    }
  };

  const handleReset = () => {
    setShow('')
  }
  useEffect(() => {
    const page: any = document.querySelector("section");
    if (page) {
      page.addEventListener('click', handleReset)
    }

    return () => {
      page?.removeEventListener('click', handleReset)
    }
  }, [show])

  function handleNavigate(link: string) {
    navigate(link)
    handleReset()
  }

  const handleToggleAccordion = (index: any) => {
    if (!isNotAccessable) {
      setActiveKey(activeKey === `${index}` ? null : `${index}`);
    }
  };

  // let { mainModuleRoles, subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  return (
    <>
      <aside
        className={
          globalCtx.showMenu
            ? `${cx.show} ${cx.sidebarBody}`
            : `${cx.sidebarBody}`
        }

      >
        <div
          className={`${cx.hideBg}`}
          onClick={() => {
            globalCtx.displayMenu();
          }}
        ></div>
        <div className={`${cx.sidebarMain} ${spand ? cx.sidebarExpandActive : ''}`}>
          <div className={`${cx.menuList}`}>
            <ul className={`${cx.scroll}`}>
              {sidebarItemRoles1?.map((item: any, index: number) => {

                let imgName = item?.moduleName?.toLowerCase();
                if (item.moduleName === 'Publish') {
                  return
                }
                let isViewEnabled = item?.moduleName === "Bookings" ?
                  item?.actions?.find((elem: any) => elem?.actionName === 'ViewOwnBookings')?.access || item?.actions?.find((elem: any) => elem?.actionName === 'ViewAllBookings')?.access :
                  item?.moduleName === "Reports" ? item?.actions?.some((elem: any) => elem?.access) :
                    item?.actions?.find((elem: any) => elem?.actionName === 'View')?.access;

                if (isViewEnabled) {
                  return (
                    <li key={item.link}>
                      {isNotAccessable ? (
                        <button className={`${cx.signleMenu}`} >
                          <div className={`${cx.menuIcon}`}>
                            <img
                              src={imageMap[imgName]}
                              className={`${cx.icon} ${cx.defalut}`}
                              alt="menu"
                            />
                            <img
                              src={imageMap[`${imgName}`]}
                              className={`${cx.icon} ${cx.active}`}
                              alt="menu"
                            />
                          </div>
                          <h5 className={cx.menuMainHeading}>{item.moduleName}</h5>
                        </button>
                      ) : (
                        <NavLink className={`${cx.signleMenu}`} to={item.link} onClick={() => {
                          setSubMenuName('')
                          setShow('')
                        }}>
                          <div className={`${cx.menuIcon}`}>
                            <img
                              src={imageMap[imgName]}
                              className={`${cx.icon} ${cx.defalut}`}
                              alt="menu"
                            />
                            <img
                              src={imageMap[`${imgName}Active`]}
                              className={`${cx.icon} ${cx.active}`}
                              alt="menu"
                            />
                          </div>
                          <h5 className={cx.menuMainHeading}>{item.moduleName}</h5>
                          {/* <div className={`${cx.menuName}`}>{item.moduleName}</div> */}
                        </NavLink>
                      )}
                      {/* <h5 className={cx.menuMainHeading}>{item.moduleName}</h5> */}
                    </li>
                  )
                } else {
                  return;
                }
              })}
              {sidebarItemRoles2?.map((element: any, index: number) => {
                let showDropdown = element.moduleName === 'Business Set Up' ? showBusinessSetupDrop : element.moduleName === 'Services' ? showServiceSetupDrop : showTeamSetupDrop
                if (showDropdown) {
                  const icon = element?.moduleName === "Business Set Up" ? businessIconSlidebar : element?.moduleName === "Services" ? servicesIconSlidebar : element?.moduleName === "Teams" ? teamIconSlidebar : publishIconSlidebar
                  const iconA = element?.moduleName === "Business Set Up" ? businessIconSlidebarActive : element?.moduleName === "Services" ? servicesIconSlidebarActive : element?.moduleName === "Teams" ? teamIconSlidebarActive : publishIconSlidebarActive
                  return (
                    <li className={`${cx.accordion_Expand}`} key={element.moduleName}>
                      {spand ? (
                        <>
                          <Accordion activeKey={activeKey}>
                            <Accordion.Item eventKey={`${index}`}>
                              <Accordion.Header
                                onClick={() => handleToggleAccordion(index)}
                              >
                                <span className="img_withContent">
                                  <img src={icon} alt="icon" />
                                  <span className="contentSpan">{element.moduleName?.split(" ")[0]}</span>
                                </span>
                              </Accordion.Header>
                              <Accordion.Body>
                                {element?.subModules?.map((elem: any, i: number) => {
                                  const isEnable = elem?.actions?.some((item: any) => item?.actionName === 'View' && item?.access);
                                  if(!isEnable){
                                    return
                                  }
                                  
                                  if ((elem.subModuleName === 'Team Title' || elem.subModuleName === 'Service Category' || elem.subModuleName === 'Bundles')) {
                                    return
                                  }
                                  return (
                                    <li key={i}>
                                      <NavLink
                                        to={elem.link}
                                        className={`${cx.signleMenu} `}
                                        onClick={(e) => isNotAccessable && e.preventDefault()}
                                      >
                                        <div className={`${cx.menuName}`}>
                                          <span className={`${cx.menuNameArrow}`}>&rarr;</span>
                                          {elem?.subModuleName}
                                        </div>
                                      </NavLink>
                                    </li>
                                  );
                                })}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </>
                      ) : (
                        <div className={`${cx.ddd} `}>
                          <OverlayTrigger
                            trigger={['click']}
                            placement="right"
                            overlay={
                              <Tooltip id={`${element.moduleName}-click`}>
                                <ul>
                                  {element?.subModules?.map((elem: any, i: number) => {
                                    console.log(elem, 'element');
                                    const isEnable = elem?.actions?.some((item: any) => item?.actionName === 'View' && item?.access);
                                    if(!isEnable){
                                      return
                                    }
                                    if ((elem.subModuleName === 'Team Title' || elem.subModuleName === 'Service Category' || elem.subModuleName === 'Bundles')) {
                                      return
                                    }
                                    return (
                                      <li key={i}>
                                        <button className="dropList" onClick={() => handleNavigate(elem.link)}>{elem?.subModuleName}</button>
                                      </li>
                                    )
                                  })}
                                </ul>
                              </Tooltip>
                            }
                            show={show === element.moduleName}
                            onToggle={() => handleToggle(element.moduleName)}
                          >
                            <button className={`${cx.signleMenu} ${(subMenuName === `${element.moduleName}` || show === element.moduleName) && !isNotAccessable ? cx.active : ""}`}>
                              <div className={`${cx.menuIcon}`}>
                                <img
                                  src={icon}
                                  className={`${cx.icon} ${cx.defalut} ${isNotAccessable ? cx.deactive : ''}`}
                                  alt="menu"
                                />
                                {!isNotAccessable && <img
                                  src={iconA}
                                  className={`${cx.icon} ${!isNotAccessable ? cx.active : ''}`}
                                  alt="menu"
                                />}
                              </div>
                              {/* <div className={`${cx.menuName}`}>{element.moduleName}</div> */}
                              <h5 className={`${cx.menuMainHeading} `}>{element.moduleName?.split(" ")[0]}</h5>
                            </button>

                          </OverlayTrigger>
                        </div>
                        // <Dropdown>
                        //   <Dropdown.Toggle variant="success" id="dropdown-basic">
                        //     <button className={`${cx.signleMenu} ${subMenuName === `${element.moduleName}` && subSideBar ? cx.active : ""}`}>
                        //       <div className={`${cx.menuIcon}`}>
                        //         <img
                        //           src={icon}
                        //           className={`${cx.icon} ${cx.defalut}`}
                        //           alt="menu"
                        //         />
                        //         <img
                        //           src={iconA}
                        //           className={`${cx.icon} ${cx.active}`}
                        //           alt="menu"
                        //         />
                        //       </div>
                        //       {/* <div className={`${cx.menuName}`}>{element.moduleName}</div> */}
                        //       <h5 className={`${cx.menuMainHeading} `}>{element.moduleName?.split(" ")[0]}</h5>
                        //     </button>
                        //   </Dropdown.Toggle>

                        //   <Dropdown.Menu>
                        //     <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        //     <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                        //     <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                        //   </Dropdown.Menu>
                        // </Dropdown>

                      )}
                      {/* <div className={`${cx.businessAbsolute}`}>
                        <ul>
                          <li>
                            <NavLink to={"#"}>
                              Business Home
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={"#"}>
                              Branch / Location
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={"#"}>
                              Application Role
                            </NavLink>
                          </li>
                        </ul>
                      </div> */}
                      {/* <h5 className={cx.menuMainHeading}>{element.moduleName?.split(" ")[0]}</h5> */}
                    </li>
                  )
                }
              })}
              {sidebarItemRoles1?.map((item: any, index: number) => {
                let imgName = item?.moduleName?.toLowerCase();
                if (item.moduleName !== 'Publish') {
                  return
                }
                let isViewEnabled = item?.actions?.find((elem: any) => elem?.actionName === 'View')?.access;

                if (isViewEnabled) {
                  return (
                    <li key={item.link}>
                      {isNotAccessable ? (
                        <button className={`${cx.signleMenu}`} >
                          <div className={`${cx.menuIcon}`}>
                            <img
                              src={imageMap[imgName]}
                              className={`${cx.icon} ${cx.defalut}`}
                              alt="menu"
                            />
                            <img
                              src={imageMap[`${imgName}`]}
                              className={`${cx.icon} ${cx.active}`}
                              alt="menu"
                            />
                          </div>
                          <h5 className={cx.menuMainHeading}>{item.moduleName}</h5>
                        </button>
                      ) : (
                        <NavLink className={`${cx.signleMenu}`} to={isNotAccessable ? '' : item.link} onClick={() => {
                          setSubMenuName('')
                          setShow('')
                        }}>
                          <div className={`${cx.menuIcon}`}>
                            <img
                              src={imageMap[imgName]}
                              className={`${cx.icon} ${cx.defalut}`}
                              alt="menu"
                            />
                            <img
                              src={imageMap[`${imgName}Active`]}
                              className={`${cx.icon} ${cx.active}`}
                              alt="menu"
                            />
                          </div>
                          <h5 className={cx.menuMainHeading}>{item.moduleName}</h5>
                          {/* <div className={`${cx.menuName}`}>{item.moduleName}</div> */}
                        </NavLink>
                      )}
                      {/* <h5 className={cx.menuMainHeading}>{item.moduleName}</h5> */}
                    </li>
                  )
                } else {
                  return;
                }
              })}
            </ul>
          </div>
          <div className={`${cx.collpaseExpandBtn}`}>
            <button className="btn" onClick={() => {
              dispatch(changeSideBarSpand(!spand))
              // setSpand(!spand)
            }}>
              <span className={`${cx.borderApply}`}>
                <IoIosArrowForward />
              </span>
            </button>
          </div>
        </div>
      </aside >
      {/* <BusinessSubSidebar subSideBar={subSideBar} setSubSideBar={setSubSideBar} subSideBarData={subSideBarData} subMenuName={subMenuName} /> */}
    </>
  );
};

export default BusinessSidebar;
