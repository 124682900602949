import React from 'react'
import cx from './index.module.scss';
import { teamPerson1 } from '../../../assets/images';
import { useNavigate } from 'react-router-dom';
import { timeAgo } from '../../../constants/Constants';

export default function RecentNotification({ notification }: any) {
    const navigate = useNavigate();
    function handleNavigate () {
        navigate('/business/notification')
    }
    return (
        <div className={`${cx.notification_card}`}>
            <div className={`${cx.notification_header}`}>
                <div className={`${cx.notification_titleCount}`}>
                    <h2>Recent Notification</h2>
                    {/* <span className={`${cx.count}`}>3</span> */}
                </div>
                <div className={`${cx.notification_action}`}>
                    {/* <button>Mark all as read</button> | */}
                    <button onClick={handleNavigate}>View All</button>
                </div>
            </div>

            <ul className={`${cx.notification_item}`}>
                {notification?.map((item: any, i:number) => {
                    console.log(item, 'itemDataaaaaaaaa')
                    let timeDate:any = '';
                    if(item?.date){
                        timeDate = new Date(item?.date?.seconds * 1000 + item?.date?.nanoseconds / 1000000)
                    }
                    return (
                        <li key={i}>
                            <div className={`${cx.notification_ImgPara} align-items-center`}>
                                {item?.booking?.client?.image && <img src={item?.booking?.client?.image} alt="avatar" />}
                                {!item?.booking?.client?.image && <div className={`${cx.avatarAlt}`}>{item?.booking?.client?.name?.[0]?.toLocaleUpperCase()}</div>}
                                <div className={`${cx.paraBox}`}>
                                    {/* <p className={`${cx.paraTop}`}> <b>wants to edit</b> wellnezza design file.</p> */}
                                    <p className={`${cx.paraTop}`}>{item?.message}</p>
                                </div>
                            </div>
                            <div className={`${cx.notification_time}`}>
                                {timeAgo(timeDate)}
                            </div>
                        </li>
                    )
                })}
                {notification?.length === 0 && <li>No notification yet</li>}
                {/* <li>
                    <div className={`${cx.notification_ImgPara} align-items-center`}>
                        <img src={teamPerson1} alt="avatar" />
                        <div className={`${cx.paraBox}`}>
                            <p className={`${cx.paraTop}`}>Ava <b>wants to edit</b> wellnezza design file.</p>
                        </div>
                    </div>
                    <div className={`${cx.notification_time}`}>
                        5 min ago
                    </div>
                </li>
                <li>
                    <div className={`${cx.notification_ImgPara} align-items-center`}>
                        <img src={teamPerson1} alt="avatar" />
                        <div className={`${cx.paraBox}`}>
                            <p className={`${cx.paraTop}`}>Ava <b>wants to edit</b> wellnezza design file.</p>
                        </div>
                    </div>
                    <div className={`${cx.notification_time}`}>
                        5 min ago
                    </div>
                </li> */}
            </ul>
        </div>
    )
}
