import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import st from "../../../../assets/stylesheet/style.module.scss";
import cx from '../index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { httpRequest } from '../../../../Apis/commonApis';
import { getBusinessData } from '../../../../redux_toolkit/reducer/shareDataReducer';
import SuccessPopup from '../../Modals/SuccessPopup';
import LoadingBtn from '../../Buttons/LoadingBtn';

export default function AssignBranch(props: any) {
    const { handleStepsFormData, editServiceData } = props
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const location = useLocation();

    const [showSuccessModal, setShowSuccessModal] = useState<any>(false);
    const handleSuccessModalClose = () => setShowSuccessModal(false);

    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    );
    const [branchData, setBranchData] = useState<any>([]);
    const [selectedBranchId, setSelectedBranchId] = useState<any>([]);

    useEffect(() => {
        if (editServiceData?.branchId?.length !== 0) {
            setSelectedBranchId(editServiceData?.branchId?.map((item: any) => item?._id))
        }
    }, [editServiceData])

    const getBranches = async () => {
        let res = await httpRequest(`getBranch?businessId=${businessData._id}`, "get", null, 'json');
        if (res.status === true) {
            setBranchData(res.data);
        }
    }

    useEffect(() => {
        if (businessData) {
            getBranches();
        }
    }, [businessData]);

    const handleBranch = (e: any) => {
        if (!e.target.checked) {
            const filterBranch = selectedBranchId?.filter((item: any) => item !== e.target.value);
            setSelectedBranchId(filterBranch)
        } else {
            if (selectedBranchId) {
                setSelectedBranchId([...selectedBranchId, e.target.value])
            }
        }
    }

    const handleSave = async (e: any) => {
        e.preventDefault();
        if (editServiceData?._id) {
            let srvCatId = editServiceData?.ServiceCategory?._id ? editServiceData?.ServiceCategory?._id : editServiceData?.ServiceCategory;
            setLoading(true)
            const basicData = { ...editServiceData, branchId: selectedBranchId }
            const res: any = await httpRequest(`updateBusinessService/${editServiceData?._id}`, "PATCH", basicData, "json");
            setLoading(true)
            if (res?.status) {
                // setShowSuccessModal(true);
                const priceRes: any = await httpRequest(`getBusinessPrice?businessId=${businessData?._id}&accessType=list`, "get", null, "json");
                let serviceArr: any = makeCustomServiceArr(priceRes?.data);
                const filterPrice = priceRes?.data?.filter((item: any) => item?.BusinessService?._id === location.state?.serviceId)

                if (priceRes.status) {
                    handlePriceSelectionFromBranch(srvCatId, location.state?.serviceId, filterPrice, serviceArr)
                }
            }

        }
        // handleStepsFormData(selectedBranchId, 'step4');
    }


    // const handlePriceSelectionFromBranch = (ServCatId: string, ServId: string, priceData: any) => {

    function makeCustomServiceArr(allServices: any) {
        const restructuredPricesArray: any = {};
        allServices?.forEach((priceObj: any) => {
            const { _id, seasonType, duration, seasonPrice, BusinessService } = priceObj;
            if (BusinessService?.serviceCatagoryId) {
                const { _id: service_id, serviceName: serviceName, ServiceCategory: { _id: serviceCatagoryId, serviceCategoryName: serviceCategoryName } } = BusinessService;

                if (!restructuredPricesArray[serviceCatagoryId]) {
                    restructuredPricesArray[serviceCatagoryId] = {
                        serviceCategoryName,
                        serviceCatagoryId,
                        services: {}
                    };
                }

                if (!restructuredPricesArray[serviceCatagoryId]?.services[service_id]) {
                    restructuredPricesArray[serviceCatagoryId].services[service_id] = {
                        serviceName,
                        service_id,
                        prices: []
                    };
                }
                restructuredPricesArray[serviceCatagoryId].services[service_id].prices.push({ seasonType, duration, seasonPrice, _id });
            }
        });
        const finalResult = Object.values(restructuredPricesArray).map((category: any) => ({
            serviceCategoryName: category.serviceCategoryName,
            _id: category.serviceCatagoryId,
            services: Object.values(category.services).map((service: any) => ({
                serviceName: service.serviceName,
                _id: service.service_id,
                prices: service.prices
            }))
        }));
        return finalResult;
    }


    const handlePriceSelectionFromBranch = (ServCatId: string, ServId: string, priceData: any, serviceList: any) => {
        let assingedArr = businessData?.originalAssignServiceArray?.[0];
        if (serviceList?.length !== 0) {
            let updatedCheckboxes: any = { ...assingedArr.selectedCheckboxes };
            let selectAllCheckboxes: any = { ...assingedArr.customAllSrvboxes };
            // console.log(selectAllCheckboxes, "selectAllCheckboxes")

            branchData?.map((branch: any) => {
                updatedCheckboxes[branch?._id] = { ...updatedCheckboxes[branch?._id], checked: selectedBranchId?.includes(branch?._id) };
                serviceList?.forEach((serviceCat: any) => {
                    if (ServCatId === serviceCat?._id) {
                        updatedCheckboxes[branch?._id][ServCatId] = { ...updatedCheckboxes[branch?._id][ServCatId], checked: selectedBranchId?.includes(branch?._id) };
                        selectAllCheckboxes[ServCatId] = { ...selectAllCheckboxes[ServCatId], checked: !arraysEqual(branchData?.map((item: any) => item?._id), selectedBranchId) ? false : true };
                        serviceCat?.services?.forEach((service: any) => {
                            if (service._id === ServId) {
                                updatedCheckboxes[branch?._id][ServCatId][service._id] = { checked: selectedBranchId?.includes(branch?._id) };
                                selectAllCheckboxes[ServCatId][service._id] = { checked: !arraysEqual(branchData?.map((item: any) => item?._id), selectedBranchId) ? false : true };
                                service?.prices?.forEach((price: any) => {
                                    updatedCheckboxes[branch?._id][ServCatId][service._id][price._id] = selectedBranchId?.includes(branch?._id);
                                    selectAllCheckboxes[ServCatId][service._id][price._id] = !arraysEqual(branchData?.map((item: any) => item?._id), selectedBranchId) ? false : true;
                                });
                            }
                        });
                    }
                });
            })

            // console.log(updatedCheckboxes , "selectedBrances")


            let updatedAssignedArr = [
                {
                    selectedCheckboxes: updatedCheckboxes,
                    customAllSrvboxes: selectAllCheckboxes,
                    allServicesChecked: !arraysEqual(branchData?.map((item: any) => item?._id), selectedBranchId) ? false : true
                }
            ];


            let deletedBranch = editServiceData?.branchId?.filter((item: any) => !selectedBranchId?.includes(item?._id))?.map((item: any) => item?._id)

            let deleteArray: any = deletedBranch?.map((item: any) => ({ branchId: item, priceIds: priceData?.map((price: any) => price?._id), serviceIds: [ServId] }))

            let data = {
                originalAssignServiceArray: updatedAssignedArr,
                deleteArray: deleteArray,
            }
            changeAssignArray(data);
        } else {
            setLoading(false)
            handleStepsFormData(null, 'amenities');
        }
    };

    const changeAssignArray = async (updatedObj: any) => {
        const res = await httpRequest(`assignBusinessService?businessId=${businessData._id}&fromBranch=${true}`, "PATCH", updatedObj, "json");
        if (res.status) {

            let userdata: any = JSON.parse(localStorage.getItem('user_data') || '');
            if (userdata !== '' && userdata?._id) {
                let bid = '';
                if (userdata?.userType === "teamMember") {
                    bid = `businessId=${userdata?.businessId}`;
                } else {
                    bid = `id=${userdata?._id}`;
                }
                dispatch(getBusinessData({ id: bid }));
            }
            setLoading(false)
            setTimeout(() => {
                handleStepsFormData(res?.data, 'amenities');
            }, 1000)
            // handleStepsFormData(res, "assign-branch");
        }
    }

    function arraysEqual(arr1: any, arr2: any) {
        arr1?.sort()
        arr2?.sort()
        if (arr1?.length !== arr2?.length) {
            return false;
        }
        for (let i = 0; i < arr1?.length; i++) {
            if (arr1?.[i] !== arr2?.[i]) {
                return false;
            }
        }
        return true;
    }
    return (
        <React.Fragment>
            <SuccessPopup showSuccessModal={showSuccessModal}
                handleSuccessModalClose={handleSuccessModalClose}
                message={"Branches assign successfully"} />
            <Row>
                <Col md={10}>
                    <div className={`${st.reFormBox}`}>
                        <label htmlFor="" className='mb-2'>Service Available For</label>
                        <div>
                            <div className={`${st.edit_publish_checkBox} ${st.checkBox_FlexColumn}`}>
                                {branchData?.map((item: any) => {
                                    return (
                                        <label className={`d-inline-flex ${st.checkbox}`} key={item?._id}>
                                            <input type="checkbox" onChange={handleBranch} value={item?._id} checked={selectedBranchId?.some((branchId: any) => branchId === item?._id)} />
                                            <span className={`${st.checkmark}`}></span>
                                            <span className={`${st.labelName} ms-1`}>{item?.branchName}</span>
                                        </label>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className={`${cx.offcanvas_footer}`}>
                <Row>
                    <Col md={12}>
                        <div className={`${st.formBox} ${cx.formSign}`}>
                            <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-3`}>
                                <button className={`btn ${st.btn3} ${cx.btnGray}`} onClick={handleSave} id='submit-service' type="submit">
                                    {loading ? <LoadingBtn /> : 'Save'}
                                </button>
                                {/* <button className={`btn ${st.btn3} ${cx.btnGray} ${cx.btnGrayLight}`} onClick={() => {
                                    handleStepsFormData(null, 'amenities')
                                }}
                                    type="submit">
                                    Cancel
                                </button> */}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}
