
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { httpRequest } from '../../../Apis/commonApis';
import BasicTab from "./Details";
import SettingTab from "./SettingTab";
import AdditionalTab from "./AdditionalTab";
import ServicesTab from './ServicesTab';
import BlackOut from './BlackOut';
import AssignBranch from './AssignBranch';
import WellnezzaBasicTab from './WellnezzaDetail';
import WellnezzaServicesTab from './WellnezzaServiceTab';
import WellnezzaAdditionalTab from './WellnezzaAdditionalTab';
import WellnezzaBlackOut from './WellnezzaBlackout';
import WellnezzaAssignBranch from './WellnezzaAssignBranch';
import OffPeakHours from './OffPeakHours';


export default function WellnezzaPromotionRoutes(props: any) {
    const { dynamicName, handleStepsFormData, loading, promotionList, formObj, setEditData } = props;
    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    const location = useLocation();
    const [editLoading, setEditLoading] = useState<boolean>(false)
    const [businessPriceData, setBusinessPriceData] = useState<any>(null);
    const [userData, setUserData] = useState<any>(null);
    const [serviceCategoryList, setServiceCategoryList] = useState<any>([]);
    const [permotion, setPromotion] = useState<any>(null);

    useEffect(() => {
        let udata = localStorage.getItem("user_data") || "";
        let userdata = JSON.parse(udata);
        setUserData(userdata);
    }, []);

    useEffect(() => {
        if (location.state && businessData?._id) {
            getPromotion();
        }
    }, [businessData, location?.state]);

    const getPromotion = async () => {
        if (businessData) {
            setEditLoading(true)
            let res = await httpRequest(`getPromotion?_id=${location?.state?.promotionId}&businessId=${businessData?._id}&type=list`, "get", null, "json");
            if (res.status) {
                setPromotion(res?.data?.[0]);
                setEditData(res?.data?.[0])
            }
            setEditLoading(false)
        }
    };

    return (
        <>
            {dynamicName === "wellnezza-info" &&
                <WellnezzaBasicTab businessData={businessData} userData={userData}
                    handleStepsFormData={handleStepsFormData}
                    editPromotionData={permotion}
                    serviceCategoryList={serviceCategoryList}
                    loading={loading}
                    promotionList={promotionList}
                    formObj={formObj}
                    editLoading={editLoading}
                />}
            {dynamicName === "wellnezza-services" &&
                <ServicesTab businessData={businessData} userData={userData}
                    handleStepsFormData={handleStepsFormData}
                    editPromotionData={permotion}
                    serviceCategoryList={serviceCategoryList}
                    loading={loading}
                    editLoading={editLoading}
                    type="wellnezza-"
                />}
            {dynamicName === "wellnezza-off-peak-hours" &&
                <OffPeakHours
                    handleStepsFormData={handleStepsFormData}
                    editPromotionData={permotion}
                    loading={loading}
                    editLoading={editLoading}
                    type="wellnezza-"
                />}
            {dynamicName === "wellnezza-additional" &&
                <AdditionalTab
                    handleStepsFormData={handleStepsFormData}
                    editPromotionData={permotion}
                    loading={loading}
                    editLoading={editLoading}
                    type="wellnezza-"
                />}
            {dynamicName === "wellnezza-blackout" &&
                <BlackOut
                    handleStepsFormData={handleStepsFormData}
                    editPromotionData={permotion}
                    loading={loading}
                    editLoading={editLoading}
                    type="wellnezza-"
                />}
            {dynamicName === "wellnezza-assign-branch" &&
                <AssignBranch
                    handleStepsFormData={handleStepsFormData}
                    editPromotionData={permotion}
                    loading={loading}
                    editLoading={editLoading}
                    type="wellnezza-"
                />}
        </>
    )
}

{/* <Tab.Content>
<Tab.Pane eventKey="tabNew1">
  <BasicTab setPromotionData={setPromotionData} basicSubmitHandler={basicSubmitHandler} promotionData={promotionData} setActiveTab={setActiveTab} />
</Tab.Pane>
<Tab.Pane eventKey="tabNew2">
  <SettingTab settingHandler={settingHandler} setPromotionData={setPromotionData} promotionData={promotionData} setActiveTab={setActiveTab} />
</Tab.Pane>
<Tab.Pane eventKey="tabNew3">
  <AdditionalTab handleSubmit={handleSubmit} setPromotionData={setPromotionData} promotionData={promotionData} setActiveTab={setActiveTab} />
</Tab.Pane>
</Tab.Content> */}