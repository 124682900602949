import React from 'react'

export default function LoadingBtn() {
    return (
        <div
            className="spinner-border spinner-border-sm text-light"
            role="status"
        ></div>
    )
}
