import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
    crossRed,
    iconPassword,
    loginBg,
    successLarge,
} from "../../../assets/images";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { httpRequest } from "../../../Apis/commonApis";
import ErrorPopup from "../../../components/Business/Modals/ErrorPopup";

const TeamVerification = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [submitState, setSubmitState] = useState<String>("loading ");

    console.log(searchParams , "searchParmas" , );
    
    const user:any = searchParams.get("user")
    const slug:any = searchParams.get("slug");

    console.log(slug, user , "userDAta");
    
    useEffect(() => {
        if (user) {
            handleSubmit()
        }
        async function handleSubmit() {
            const res = await httpRequest(`teamMemberVerification/${user}?otp=${slug}`, 'PATCH', {}, "json");
            if (res?.status) {
                setSubmitState(`Success : ${res?.message}`)
            } else {
                setSubmitState(`Error : ${res?.message}`)
            }
        }

    }, [user, slug])

    return (
        <>
            {submitState !== "loading" && <section className={`${cx.loginSection}`}>
                <img src={loginBg} alt="login background" className={`${cx.loginBg}`} />

                <div className={`${cx.modalContent}`}>
                    <div className={`${cx.modalCenter}`}>
                        <div className={`${cx.formBody}`}>
                            <div className={cx.content}>
                                <Row>
                                    <Col md={12}>
                                        <img
                                            src={submitState?.includes("Error") ? crossRed : successLarge}
                                            alt="success-img"
                                            className={`${cx.successImage}`}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <h5>{submitState?.split(":")?.[1]}</h5>
                                    </Col>

                                </Row>
                            </div>


                        </div>
                    </div>
                </div>

            </section>}
        </>
    );
};

export default TeamVerification;
