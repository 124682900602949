import React from "react";
import st from "../../../assets/stylesheet/style.module.scss";
import { exportIcon } from "../../../assets/images";

const ExportBtn = (props: any) => {
  return (
    <>
     <button className={`btn ${st.whiteBtn}`}>
        <img src={exportIcon} alt="export" /> Export CSV
      </button>
    </>
  );
};

export default ExportBtn;
