import { useState } from "react";
import { deleteIcon, editButton, sales, teamNew, teamWorking } from "../../../assets/images";
import st from "../../../assets/stylesheet/style.module.scss";
import { AddTeamTitle, DeletePopup } from "../../../components/Business/Modals";
import { useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";
import { Dropdown } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import cx from './index.module.scss';

export default function TitleList({ item, getTitle, setSelectedTitle, selectedTitle }: any) {
    const [showTitle, setShowTitle] = useState(false);
    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleTitleClose = () => {
        setShowTitle(false);
        getTitle();
    };
    const handleTitleShow = () => setShowTitle(true);

    const deleteApi = async () => {
        const res = await httpRequest(
            `deleteTeamTitle/${item?._id}`,
            "DELETE",
            null,
            "json"
        );
        if (res.status) {
            getTitle();
            handleClose();
        } else {
        }
    };

    const canEditTitle = subModuleRoles?.['Teams']?.['Team Title']?.Edit;
    const canDeleteTitle = subModuleRoles?.['Teams']?.['Team Title']?.Delete;
    const canDeactivate = subModuleRoles?.['Teams']?.['Team Title']?.deactivate;
    return (
        <>
            {showTitle && <AddTeamTitle showTitle={showTitle} handleTitleClose={handleTitleClose}
                getTitle={getTitle} businessData={businessData} userData={item} />}
            <DeletePopup
                show={show}
                handleClose={handleClose}
                deleteApi={deleteApi}
            />
            <li>
                <span>
                    <span className={`${st.categoryName} ${selectedTitle?._id === item?._id ? st.active : ""}`} onClick={() => setSelectedTitle(item)}>{item?.teamTitle}</span>
                    {item?.customerService && <img className={`${st.titlesIcon}`} src={sales} alt="team" />}
                </span>
                <span className={`${st.categoryAvail}`}>
                    {/* <span className={`${st.value}`}>10</span> */}
                    {/* <button><img src={teamWorking} alt="view" /></button> */}
                    {(canEditTitle || canDeleteTitle) && <span className={`${st.dotsDropDown}`}>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                                <BsThreeDotsVertical />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <span className='editDeleteBtn flex-column justify-content-left'>
                                    {canEditTitle && <button className="btn" onClick={handleTitleShow}>
                                        Edit
                                    </button>}
                                    {canDeleteTitle && <button className="btn" onClick={handleShow}>
                                        Delete
                                    </button>}
                                </span>
                            </Dropdown.Menu>
                        </Dropdown>
                    </span>}
                    {/* {canEditTitle && <button><img src={editButton} onClick={handleTitleShow} alt="edit" /></button>}
                    {canDeleteTitle && <button><img src={deleteIcon} alt="delete" onClick={handleShow} /></button>} */}
                </span>
            </li>
        </>
    )
}