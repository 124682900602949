import React from "react";
import m from "./Modal.module.scss";
import { NavLink } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";

const ConfirmationModal = (props: any) => {
  let { showConfirmation, handleConfirmationClose, message, onConfirm } = props;
  return (
    <>
      <Modal
        centered
        show={showConfirmation}
        backdrop="static"
        keyboard={false}
        onHide={handleConfirmationClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={m.modalCts}
      >
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
          <button className={`${m.closeIcon}`} onClick={handleConfirmationClose}>
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className={`${m.logoutPopup}`}>
            <h3>
              Are you sure you want to {message}
            </h3>
            {/* {apiMessage && <h3>
              <span className={m.deleteItem} style={{color:"red"}}>{apiMessage ? apiMessage : ""}</span>?
            </h3>} */}
            <div className={`${m.btnsAlignments}`}>
              <NavLink
                className={`btn ${m.cancelBtn}`}
                to=""
                onClick={handleConfirmationClose}
              >
                Cancel
              </NavLink>
              
              <button type="button" className={`btn ${m.actionBtn}`} onClick={onConfirm}>Ok</button>
              </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
