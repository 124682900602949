import React, { useRef, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { NavLink } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import OtpInput from 'react-otp-input';

import { httpRequest } from "../../../Apis/commonApis";
import ErrorPopup from "./ErrorPopup";

const ChangeEmailModal = (props: any) => {
    let { showEmailModal, handleEmailModalClose, getUserDetils, userlist, resendOtp } = props;

    const [otp, setOtp] = useState('');
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState("")
    const [showErrorModal, setShowErrorModal] = useState('');
    const handleAlertModalClose = () => setShowErrorModal('');
    const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);
    const userData: any = localStorage.getItem('user_data');
    let userId = JSON.parse(userData)
    const otpVerify = async () => {
        if (otp?.length < 4) {
            return setError(true)
        }
        let data = {
            enteredOTP: otp,
            userType: userId?.userType,
        };
        const res = await httpRequest(`verifyotp/${userId?._id}`, "POST", data, "withoutToken");
        if (res.status) {
            handleAlertModalShow(`Success, ${res.message}`);
            getUserDetils()
            // handleEmailModalClose()
        } else {
            setErrors(res.message)
            // handleAlertModalShow(`Error, ${res.message}`);
        }
    };

    return (
        <>
            <Modal
                centered
                show={showEmailModal}
                backdrop="static"
                keyboard={false}
                onHide={handleEmailModalClose}
                aria-labelledby="example-modal-sizes-title-sm"
                className={m.modalCts}
            >
                <Modal.Header>
                    <Modal.Title>Change Email</Modal.Title>
                    <button className={`${m.closeIcon}`} onClick={handleEmailModalClose}>
                        <MdClose />
                    </button>
                </Modal.Header>
                <Modal.Body>

                    <div className={`${m.change}`}>
                        <Row>
                            <Col md={12}>
                                <h2 className={`${m.title}`}>Verification</h2>
                                <p className={m.desc}>
                                    Enter the One-Time Verification Code
                                </p>
                            </Col>
                            <Col md={12}>
                                <h5 className={`${m.numberTitle}`}>
                                    An OTP has been sent to the register number:{" "}
                                    <span> +{userlist?.dialCode} {userlist?.mobile}</span>
                                </h5>
                            </Col>
                        </Row>
                        <Row>
                            <div className={m.otpScreen}>
                                <Col md={7}>
                                    <Row>
                                        <OtpInput
                                            value={otp}
                                            onChange={setOtp}
                                            numInputs={4}
                                            renderSeparator={<span> </span>}
                                            inputType="number"
                                            renderInput={(props) => <input {...props} />}
                                        />

                                    </Row>
                                    <p className={m.resend}>
                                        Didn't come the OTP?{" "}
                                        <span onClick={resendOtp}>Resend</span>
                                        {/* <span>Resend</span> */}
                                    </p>

                                </Col>

                                <Col md={3}>
                                    <div className={`${st.formBox}`}>
                                        <div className={`${st.btnsGroup} ${m.varifyBtn}`}>
                                            <button
                                                className={`btn ${st.btn3}`}
                                                // disabled={error !== ""}
                                                onClick={otpVerify}

                                            >
                                                Verify
                                            </button>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                            {error == true ? (<p>please Enter Otp</p>) : ""}
                            {errors !== "" ? (<p className={"errorMessage"}>{errors}</p>) : ""}
                            {/* <Col md={12}>
                                <div className={`${m.entry} ${st.formBox} mb-3`}>
                                    <label className="form-label">Old Email</label>

                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Old Email"
                                    />

                                </div>
                            </Col> */}
                            {/* <Col md={12}>
                                <div className={`${m.entry} ${st.formBox} mb-3`}>
                                    <label className="form-label">New Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="New Email"
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className={m.buttonGroup}>
                                    <button className={`${m.changeBtn} singleBtn`}>Send Link</button>
                                    <button className={`${m.changeBtn} singleBtn`}>Submit</button>
                                </div>
                            </Col> */}
                        </Row>
                    </div>

                </Modal.Body>
            </Modal>
            <ErrorPopup
                showErrorModal={showErrorModal}
                handleErrorModalClose={handleAlertModalClose}
                handleEmailModalClose={handleEmailModalClose}
            />
        </>
    );
};

export default ChangeEmailModal;
