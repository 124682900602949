import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    branchId: null,
    allBranch:[],
    currentBranchData: null,
    mainModuleRoles: {},
    subModuleRoles: {}
};

const branchSlice: any = createSlice({
    name: "manageAccess",
    initialState,
    reducers: {
        changeBranchIdState(state = initialState, payload: any) {
            state.branchId = payload?.payload?.branchId;
            setTimeout(() => {
                localStorage.setItem('currentBranchId', payload?.payload?.branchId);
            }, 1000);
        },
        getAllBranch(state = initialState, payload: any) {
            state.allBranch = payload?.payload?.branchData;
            // state.branchId = payload?.payload?.branchData?.[0]?._id;
            // setTimeout(() => {
            //     localStorage.setItem('currentBranchId', payload?.payload?.branchData?.[0]?._id);
            // }, 1000);
        },
        changeCurrentBranchData(state = initialState, payload: any) {
            state.currentBranchData = payload?.payload?.currentBranch;
            
        },
        changeAccessRolesState(state = initialState, payload: any) {
            if (payload?.payload?.mainModuleRoles) {
                state.mainModuleRoles = payload?.payload?.mainModuleRoles;
            }
            if (payload?.payload?.subModuleRoles) {
                state.subModuleRoles = payload?.payload?.subModuleRoles;
            }
        },
    },
});

export const { changeBranchIdState, changeAccessRolesState, changeCurrentBranchData,getAllBranch } =
    branchSlice.actions;
export default branchSlice.reducer;
