import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Row } from "react-bootstrap";
import { Switch as CustomSwitch } from "../../../components/Website/Forms";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import CustomTimeSelect from "../../../constants/CustomTimeSelect";
import { timeValues, weekValues } from "../../../constants/Constants";
import { disablePastDate } from "../../../Utils/Funcs";
import { Switch } from "@mui/material";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import HoverEyeContent from "../../../components/Business/HoverEyeContent/HoverEyeContent";
import { FaPlus } from "react-icons/fa";

interface FormValues {
    [key: string]: string;
}

export default function NonOpHrs(props: any) {
    const { handleStepsFormData, editBranchData, loading } = props;

    const { register, setValue, control, handleSubmit, watch, formState: { errors }, reset } = useForm<FormValues>();
    const {
        register: regNonOpHrs,
        control: controlNonOpHrs,
        handleSubmit: handleSubmitNonOpHrs,
        watch: watchNonOpHrs,
        setValue: setNonOpHrsValue,
        formState: { errors: NonOpHrsErr },
        reset: resetNonOpHrs,
    } = useForm<FormValues>();

    const [selectedNonOpHrs, setSelectedNonOpHrs] = useState<any>([]);
    const [fullDayCheck, setFullDayCheck] = useState(false);

    useEffect(() => {
        if (editBranchData) {
            resetNonOpHrs()
            setSelectedNonOpHrs(editBranchData.notWorkingHours);
        }
    }, [editBranchData]);



    const onSubmit: SubmitHandler<FormValues> = (data) => {
        // let workingHours: any = [];
        const finalNotWorkingHour = selectedNonOpHrs?.map((item: any) => {
            if (item?.isFullDay) {
                return { ...item, from: "00:00", to: "23:59" }
            } else {
                return item
            }
        })
        // weekValues.forEach((elem, index) => {
        //     workingHours.push({
        //         dayName: elem.label,
        //         from: dayWiseOff?.[index]?.value ? "Closed" : data[`${elem.label}from`],
        //         to: dayWiseOff?.[index]?.value ? "Closed" : data[`${elem.label}to`],
        //     })
        // });
        // let sameEveryDayObj = { isSameEveryDay: isSameEveryDay, from: data.from, to: data.to };
        let allData = {
            // workingHours: JSON.stringify(workingHours),
            // sameEveryDay: JSON.stringify(sameEveryDayObj),
            notWorkingHours: JSON.stringify(finalNotWorkingHour),
        }
        handleStepsFormData(allData, 'step4');
    };


    // -------------- multi start time and end time selection ----------------- //

    const onNonOpHrsSubmit: SubmitHandler<FormValues> = (data) => {
        setSelectedNonOpHrs((prev: any) => [
            ...prev,
            {
                fromDate: data.fromDate,
                toDate: data.toDate,
                isFullDay: fullDayCheck,
                from: fullDayCheck ? '' : data.from,
                to: fullDayCheck ? '' : data.to,
            }
        ]);
        setFullDayCheck(false);
        resetNonOpHrs();
        setNonOpHrsValue('fromDate', '');
        setNonOpHrsValue('toDate', '');
        setNonOpHrsValue('from', '');
        setNonOpHrsValue('to', '');
    };




    const nonOpHrsRemoveChangeHandler = (e: any, indexToRemove: any) => {
        e.preventDefault();
        const clonefield = selectedNonOpHrs.slice();
        clonefield.splice(indexToRemove, 1);
        setSelectedNonOpHrs(clonefield);
    };

    function handleCheckBoxSelection(e: any) {
        resetNonOpHrs();
        setFullDayCheck(e.target.checked);
    }

    console.log(NonOpHrsErr, 'NonOpHrsErr');

    return (
        <>
            <div className={`${st.newRedesign_main} mb-4`}>
                <Row>
                    <Col xl={10} lg={11} md={12}>
                        <div className={`${st.branchContact_tabBox} ${st.branchContact_tabBox2} `}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>Non Operating Hours and Holidays</h4>
                                <button type='submit' className="saveButton" disabled={loading} onClick={handleSubmit(onSubmit)}>
                                    {loading ? (
                                        <div
                                            className="spinner-border spinner-border-sm text-light"
                                            role="status"
                                        ></div>
                                    ) : (
                                        'Next'
                                    )}
                                </button>
                            </div>
                            <p>Please maintain the non-operating hours and holidays for this branch.
                                You can specify a few hours or an entire day. Customers will not be able to book services during these times</p>
                            <ul className={`${st.branch_nonOperating_tabing}`}>
                                <li>
                                    <div className={`${st.nonOperating_inputBox}`}>
                                        <label className={`${st.labelling}`}>Date</label>
                                        <div className={`${st.nonOperating_input}`}>
                                            <div>
                                                <div className="position-relative">
                                                    <input type="date" className="form-control" min={disablePastDate()}
                                                        {...regNonOpHrs("fromDate", {
                                                            required: true,
                                                        })} />
                                                </div>
                                                {NonOpHrsErr?.fromDate?.type === "required" && (
                                                    <p className="errorMessage">required*</p>
                                                )}
                                            </div>
                                            <div>
                                                <div className="position-relative">
                                                    <input type="date" className="form-control"
                                                        min={watchNonOpHrs('fromDate', disablePastDate())}
                                                        {...regNonOpHrs("toDate", {
                                                            required: false,
                                                        })} />
                                                </div>
                                                {NonOpHrsErr?.fromDate?.type === "required" && (
                                                    <p className="errorMessage opacity-0">required*</p>
                                                )}
                                            </div>
                                            {/* {NonOpHrsErr?.toDate?.type === "required" && (
                                                <p className="errorMessage out">required*</p>
                                            )} */}
                                        </div>
                                    </div>
                                    <div className={`${st.nonOperating_inputBox}`}>
                                        <label className={`${st.labelling}`}>Full Day</label>
                                        <div className={`${st.nonOperating_input}`}>
                                            <label className={`${st.checkbox}`}>
                                                <input type="checkbox" checked={fullDayCheck} onChange={handleCheckBoxSelection} />
                                                <span className={`${st.checkmark}`}></span>
                                                <span className="ms-1"></span>
                                            </label>
                                            {!fullDayCheck && (<>
                                                <Controller
                                                    name="from"
                                                    control={controlNonOpHrs}
                                                    rules={{ required: !fullDayCheck }}
                                                    render={({ field }) => (
                                                        <div className="w-100 position-relative">
                                                            <select className="form-select" value={field.value} onChange={field.onChange}>
                                                                <option value="">-Select-</option>
                                                                {timeValues?.map((item: any, d: number) => {
                                                                    return (
                                                                        <option value={item?.value} key={d}>{item?.label}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            {NonOpHrsErr?.from?.type === "required" && (
                                                                <p className="errorMessage">required*</p>
                                                            )}
                                                        </div>
                                                    )} />
                                                <Controller
                                                    name="to"
                                                    control={controlNonOpHrs}
                                                    rules={{ required: !fullDayCheck }}
                                                    render={({ field }) => {
                                                        const filterTimeData = timeValues.filter((item: any) => item.value >= watchNonOpHrs('from', ''))
                                                        return (
                                                            <div className="w-100 position-relative">
                                                                <select className="form-select" value={field.value} onChange={field.onChange}>
                                                                    <option value="">-Select-</option>
                                                                    {filterTimeData?.map((item: any, d: number) => {
                                                                        return (
                                                                            <option value={item?.value} key={d}>{item?.label}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                                {NonOpHrsErr?.to?.type === "required" && (
                                                                    <p className="errorMessage">required*</p>
                                                                )}
                                                            </div>
                                                        )
                                                    }} />
                                            </>)}
                                            <button className="btn saveBtn" onClick={handleSubmitNonOpHrs(onNonOpHrsSubmit)}>Save</button>
                                        </div>
                                    </div>
                                </li>

                                {/* <div className={`${st.addBtn} mb-4`}>
                                    <button className="btn"><FaPlus /> Add</button>
                                </div> */}
                            </ul>
                            {selectedNonOpHrs?.map((item: any, i: any) => {
                                console.log(item, 'itemDataKaBaap');

                                return (
                                    <ul className={`${st.branch_nonOperating_tabing}`} key={i}>
                                        <li>
                                            <div className={`${st.nonOperating_inputBox}`}>
                                                <label className={`${st.labelling}`}>Date</label>
                                                <div className={`${st.nonOperating_input}`}>
                                                    <div className="position-relative">
                                                        <input type="date" className="form-control" value={item?.fromDate} disabled readOnly />

                                                    </div>
                                                    <div className="position-relative">
                                                        <input type="date" className="form-control"
                                                            value={item?.toDate ? item?.toDate : item?.fromDate} disabled readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${st.nonOperating_inputBox}`}>
                                                <label className={`${st.labelling}`}>Full Day</label>
                                                <div className={`${st.nonOperating_input}`}>
                                                    <label className={`${st.checkbox}`}>
                                                        <input type="checkbox" checked={item?.isFullDay} disabled readOnly />
                                                        <span className={`${st.checkmark}`}></span>
                                                        <span className="ms-1"></span>
                                                    </label>
                                                    {!item?.isFullDay && (<>
                                                        <p className='fake_select'>{item?.from}</p>
                                                        <p className='fake_select'>{item?.to}</p>
                                                    </>)}

                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                )
                            })}

                        </div>
                    </Col>
                </Row>
            </div>
            {/* <Row>
                <Col md={12}>
                    <div className="text-end">
                        <HelpButon number={36} type={"help"} className={"btn"} />
                    </div>
                </Col>
            </Row> */}


            {/* {selectedNonOpHrs?.map((item: any, i: any) => {
                return (
                    <Row key={i}>
                        <Col xl={3} lg={4} md={6}>
                            <div className={`${st.formBox} ${cx.dateBox} `}>
                                <div className="position-relative">
                                    <input
                                        type="date"
                                        id="from"
                                        className={`${cx.dobInput} form-control`}
                                        disabled
                                        defaultValue={item.fromDate}
                                    />
                                </div>
                            </div>
                        </Col>

                        <Col xl={3} lg={4} md={6}>
                            <div className={`${st.formBox} ${cx.dateBox} `}>
                                <div className="position-relative">
                                    <input
                                        type="date"
                                        id="from"
                                        className={`${cx.dobInput} form-control`}
                                        disabled
                                        defaultValue={item.toDate}
                                    />
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={12}>
                            <div className={`${st.formBox}`}>
                                <div className={` ${cx.timeCheckbox}`}>
                                    <label className={`${st.checkbox}`}>
                                        <input type="checkbox" checked={item.isFullDay === 'true' || item.isFullDay} disabled />
                                        <span className={`${st.checkmark}`}></span>
                                        <span className="ms-2"></span>
                                    </label>
                                    {item.from !== '' && <select className="form-select" disabled>
                                        <option>{item.from}</option>
                                    </select>}
                                    {item.from !== '' && <select className="form-select" disabled>
                                        <option>{item.to}</option>
                                    </select>}
                                </div>
                            </div>
                        </Col>

                        <Col lg={1}>
                            <button className={`${cx.plusBtn} grayout btn mt-0`} onClick={(e) => nonOpHrsRemoveChangeHandler(e, i)}>-</button>
                        </Col>
                    </Row>
                )
            })} */}
            {/* <Row>
                <Col md={12}>
                    <div className={`${st.formBox}`}>
                        <div className={`${st.btnsGroup}`}>
                            <button
                                className={`btn ${st.btn1}`}
                                onClick={() => handleStepsFormData('prevStep', 'opHrs')}
                            >
                                Back
                            </button>
                            <button type='submit' className={`btn ${st.btn4}`} disabled={loading} onClick={handleSubmit(onSubmit)}>
                                {loading ? (
                                    <div
                                        className="spinner-border spinner-border-sm text-light"
                                        role="status"
                                    ></div>
                                ) : (
                                    'Save'
                                )}
                            </button>
                        </div>
                    </div>
                </Col>
            </Row> */}
        </>
    )
}
