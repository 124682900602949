import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { httpRequest } from '../../../Apis/commonApis';
import { useSelector } from 'react-redux';

export default function UnassignAppointmentService({ booking, currService, errorTrig,selectedServices, addService, index, loading, bookingData }: any) {
    const [teamLoading, setTeamLoading] = useState<boolean>(false);
    const [teamList, setTeamList] = useState<any>([]);

    let { branchId } = useSelector((state: any) => state.BranchReducer);
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    );


    const getAllTeamMembers = async (duration: any, startTime: string) => {
        const date = bookingData?.salectDate;
        const time = bookingData?.salectTime;
        setTeamLoading(true)
        const res = await httpRequest(`getAvailableTeamMember?businessId=${businessData?._id}&branchId=${branchId}&bookingTime=${time}&bookingDate=${date}&newBookingDuration=${duration}&startTime=${startTime}&bookingId=${bookingData?._id ? bookingData?._id : ""}&serviceId=${booking?.serviceDetails?._id}&teamTitleId=${booking?.serviceId?.teamTitleId || ""}&bookingType=assignBooking`, "get", null, "json");
        if (res?.status) {
            // setallAvailableTeamList(res?.data)
            setTeamList(res?.data)
        }
        setTeamLoading(false)
    }

    useEffect(() => {
        if (bookingData && booking?.startTime && booking?.serviceId?.duration) {
            const duration = Number.parseInt(booking?.serviceId?.duration);
            getAllTeamMembers(duration, booking?.startTime)
        }
    }, [booking])

    let newTeamList = teamList?.filter((teamData: any) => {
        const teams = teamData?.serviceId?.some((data: any) => {
            return data?.serviceName === booking?.serviceDetails?.serviceName
        });
        return teams
    });
    const sameDurationServices = selectedServices?.filter((item:any) => item?.serviceId === booking?.serviceId?._id)    ;
    
    
    return (
        <Col md={12}>
            <div className={`${m.selectedServices}`}>
                <div className={m.top}>
                    <div className={m.left}>
                        <p className={m.makup}>{booking?.serviceDetails?.serviceName}</p>
                        <p className={m.makeupTime}>{`${booking?.serviceId?.duration} ${`${!booking?.serviceId?.seasonType ? "" : booking?.serviceId?.seasonType[0]?.toLocaleUpperCase()}${!booking?.serviceId?.seasonType ? "" : booking?.serviceId?.seasonType?.split("Price")?.[0]?.slice(1)} Price`} - $${booking?.serviceId?.seasonPrice ? booking?.serviceId?.seasonPrice : 0}`}</p>
                    </div>

                </div>
                <div className={m.bottom}>
                    <div className={`${st.formBox} ${m.options}`}>
                        {newTeamList?.length === 0 && !teamLoading ? (
                            <p className={"errorMessage"}>
                                Team member is not available
                            </p>
                        ) : !errorTrig ? "" : !currService?.TeamMemberId?.[0] || currService?.TeamMemberId?.[0] === null && !loading ? (
                            <p className={"errorMessage"}>
                                Please select a team member
                            </p>
                        ) : ""}
                        {/* <select className="form-select" value={currService?.TeamMemberId[0]} disabled={loading || newTeamList?.length === 0} onChange={(e: any) => addService("team", e.target.value, index, service?._id)}> */}
                        <select className="form-select" value={currService?.TeamMemberId?.[0]} disabled={teamLoading || newTeamList?.length === 0} onChange={(e: any) => addService("team", e.target.value, index, booking?.serviceId?._id, booking?.startTime)}>
                            {teamLoading ? (
                                <option value="">
                                    Checking Team Member's Availability
                                </option>
                            ) : (
                                <option value="">
                                    {newTeamList?.length === 0 ? "Team member is not available" : "Add a Team Member"}
                                </option>
                            )}
                            {newTeamList?.map((team: any) => {
                                if(sameDurationServices?.some((item:any) => item?.TeamMemberId?.[0] === team?._id && item?._id !== booking?._id)){
                                    return
                                }
                                return <option value={team?._id} key={team?._id}>{`${team?.firstName} ${team?.lastName}`}</option>
                            })}
                        </select>
                    </div>

                </div>
            </div>
        </Col>
    )
}
