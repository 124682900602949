import React from 'react';
import cx from './index.module.scss';

export default function EmptyBookingCard({ text, loading = false }: any) {
  console.log('====================================');
  console.log(loading, 'loadingData');
  console.log('====================================');
  return (
    <>
      {loading ? (
          <div
            className="spinner-border spinner-border-sm text-light"
            role="status"
          ></div>
        ) : (
          <h5 className={cx.notFoundMessage}>
            {text}
          </h5>
        )}
    </>
  )
}
