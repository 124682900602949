import React, { useState, Fragment, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../assets/stylesheet/datatable.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { DeletePopup } from "../../../components/Business/Modals";
import {
  deleteIcon,
  editIcon,
  addCatIcon,
} from "../../../assets/images";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import { Col } from "react-bootstrap";
import { Status } from "../../../components/Business";
import AddServicesCategory from "../../../components/Business/Modals/AddServicesCategory";


const StatusButton = (props: any) => {
  let { params, catData } = props;
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  const [apiMessage, setApiMessage] = useState<any>("");
  const [editedData, setEditedData] = useState<any>(null)
  const [showServicesCategory, setServicesCategoryShow] = useState(false);
  const handleServicesCategoryClose = () => {
    setServicesCategoryShow(false)
    setEditedData(null)
  };
  const handleServicesCategoryShow = () => setServicesCategoryShow(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setApiMessage("")
    setShow(true);
  };
  const navigate = useNavigate();

  const deletePermotion = async () => {
    const res = await httpRequest(
      `deleteServiceCatagory?_id=${params?.row?.id}`,
      "DELETE",
      null,
      "json"
    );
    if (res?.status == true) {
      props.getServiceCatagory();
      handleClose();
      setApiMessage("")
    } else {
      setApiMessage(res?.message)
    }
  };

  useEffect(() => {
    if (params?.row?.id && catData) {
      const dataToEdit = catData.find((val: any) => val._id === params?.row?.id);
      setEditedData(dataToEdit);
    }
  }, [params, catData]);

  const handleEditClick = () => {
    let dataToEdit = catData?.find((val: any, index: any) => {
      return val._id == params?.row?.id;
    });
    // setEditedData(dataToEdit)
    handleServicesCategoryShow()
    // navigate("/business/category/edit-category", { state: dataToEdit });
  };

  

  const canEditCategory = subModuleRoles?.['Services']?.['Service Category']?.Edit;
  const canDeleteCategory = subModuleRoles?.['Services']?.['Service Category']?.Delete;
  const canDeactivate = subModuleRoles?.['Services']?.['Service Category']?.deactivate;
  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          {/* <li>
            <NavLink className={`btn `} title="add" to="addCategory">
              <img src={addCatIcon} alt="add" />
            </NavLink>
          </li> */}
          {canEditCategory && <li>
            <p
              className={`btn ${tb.edit}`}
              title="Edit"
            >
              <img src={editIcon} alt="edit"
                onClick={() => {
                  handleEditClick();
                }} />
            </p>
          </li>}

          {canDeleteCategory && <li>
            <button
              type="button"
              className={`btn ${tb.delete}`}
              title="Delete"
              onClick={handleShow}
            >
              <img src={deleteIcon} alt="Delete" />
            </button>
          </li>}

          {canDeactivate && <li>
            <Status status={params.row.isActive}
              api='updateServiceCatagory' id={params?.id}
              apiMethod='json'
              getLatest={props.getServiceCatagory}
              apiMessage={apiMessage}
            />
          </li>}
        </ul>
      </div>

      {showServicesCategory && <AddServicesCategory
        show={showServicesCategory}
        handleServicesCategoryClose={handleServicesCategoryClose}
        getServiceCatagory={props.getServiceCatagory}
        cat={editedData}
      />}
      <DeletePopup
        show={show}
        handleClose={handleClose}
        deleteApi={deletePermotion}
        apiMessage={apiMessage}
      />
    </>
  );
};


export default function CategoryDataGrid(props: any) {
  const [selectedRows, setSelectedRows] = useState<any>([])
  const [apiMessage, setApiMessage] = useState<any>("")
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const handleDeleteClose = () => {
    setApiMessage("");
    setShowDeletePopup(false);
  }
  const columns = [
    { field: "serialNumber", headerName: "S.R.", flex: 1, minWidth: 60 },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      minWidth: 220,
      sortable: true, // Enable sorting
      sortComparator: (v1: any, v2: any) => {
        if (v1 === v2) return 0;
        return v1 ? -1 : 1;
      },
      renderCell: (params: any) => (
        <StatusButton
          catData={props?.catData}
          params={params}
          getServiceCatagory={props?.getServiceCatagory}
        />
      ),
    },
    {
      field: "serviceCategory",
      headerName: "Service Category",
      flex: 2,
      minWidth: 180,
    },
    {
      field: "businessCategory",
      headerName: "Business Category",
      flex: 2,
      minWidth: 180,
    },
    // {
    //   field: "colorCode",
    //   headerName: "Color Code",
    //   flex: 2,
    //   minWidth: 160,
    //   renderCell: (params: any) => <ColorIcon params={params} />,
    // },

  ];
  let rows = props?.catData?.map((val: any, index: any) => {
    console.log(val, "valData");

    return {
      id: val?._id,
      serialNumber: index + 1,
      serviceCategory: val?.serviceCategoryName,
      businessCategory: val?.businessCategoryId ? val?.businessCategoryId?.map((item: any) => item?.BusinessCategoryName)?.join(",") : "",
      colorCode: val?.serviceCategoryColor,
      catDetail: val?.serviceCategoryDetails,
      catTag: val?.serviceCategoryTag,
      action: val?.isActive,
      isActive: val?.isActive
    };
  });

  const handleSelectionChange = (data: any) => {
    setSelectedRows(data);
  }
  const deletePermotion = async () => {
    const body = {
      deleteServiceCategoryIds: selectedRows
    }
    const res = await httpRequest(
      `deleteServiceCatagory`,
      "DELETE",
      body,
      "json"
    );
    if (res?.status == true) {
      props.getServiceCatagory();
      setShowDeletePopup(false)
      setApiMessage("")
    } else {
      setApiMessage(res?.message)
    }
  };
  return (
    <div className={`${tb.dataTable}`}>
      <Col md={12}>
        <div className="text-end mb-2">
          <HelpButon number={24} type={"help"} className={"btn"} />
        </div>
      </Col>
      <div style={{ width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          autoHeight
          hideFooterPagination={false}
          disableRowSelectionOnClick={true}
          checkboxSelection
          rowHeight={60}
          components={{ Toolbar: GridToolbar }}
          onRowSelectionModelChange={handleSelectionChange}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
        {selectedRows.length > 1 && (
          <button
            className={`btn ${tb.delete} d-flex align-items-center gap-1`}
            onClick={() => setShowDeletePopup(true)}
          >
            <img src={deleteIcon} alt="Delete" />
            <span className="mt-1">Delete All</span>
          </button>
        )}

        <DeletePopup
          show={showDeletePopup}
          handleClose={handleDeleteClose}
          deleteApi={deletePermotion}
          apiMessage={apiMessage}
        />
      </div>
    </div>
  );
}
