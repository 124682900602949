import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import { NavLink } from "react-router-dom";
import { Col, Modal, Row, Table } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import moment from 'moment';
import {
  call,
  clockA,
  dateIcon,
  emailGreen,
  serviceGreen,
  starBox,
  test,
  testBig,
} from "../../../assets/images";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import { formatDate } from "@fullcalendar/core";
import { formatDateFormat, formatFormatTime } from "../../../constants/Constants";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

const ViewHistoryModal = (props: any, type: any = "") => {
  let { showHistory, handleHistoryClose, data, serviceType, bundleGroup} = props;
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  const stylistId: any = [];
  data?.services?.forEach((data: any) => {
    data?.TeamMemberId?.forEach((team: any) => {
      stylistId?.push(team?._id)
    })
  })

  const [team, setTeam] = useState<any>([]);

  const getTeam = async (id: any) => {
    let res = await httpRequest(`getAllTeamMembers?branchId=${id}&businessId=${businessData._id}`, "get", null, "json");
    if (res.status) {
      const filterTeam = res?.data?.filter((team: any) => stylistId?.includes(team?._id));
      setTeam(filterTeam)
    }
  }

  useEffect(() => {
    if (branchId) {
      getTeam(branchId)
    }
  }, [branchId, data]);


  // total Price
  const totalPrice = data?.services?.reduce((acc: any, service: any) => {
    if (service?.serviceId?.seasonPrice) {
      return Number(acc) + Number(service?.serviceId?.seasonPrice)
    } else {
      return Number(acc)
    }
  }, 0)

  function calReview(avg: any) {
    if (!avg) {
      return <></>
    }
    return [1, 2, 3, 4, 5]?.map((rev: any) => {
      if (avg >= rev) {
        return (
          <React.Fragment key={rev}>
            <FaStar />
          </React.Fragment>
        )
      } else if (Math.ceil(avg) >= rev) {
        return (
          <React.Fragment key={rev}>
            <FaStarHalfAlt />
          </React.Fragment>
        )
      }
    })
  }

  const startArr: any = data?.salectTime?.split(":");

  const starthours: any = Number?.parseInt(startArr?.[0]);
  const startMinutes: any = Number?.parseInt(startArr?.[1]);

  const totalDuration: any = data?.services?.reduce((acc: any, val: any) => Number(acc) + Number?.parseInt(val?.serviceId?.duration), 0)
  let newMinutes = startMinutes + totalDuration
  let endHour = starthours + Math.floor(newMinutes / 60);
  let endMinute = newMinutes % 60;

  const bookingId = `${data?.bookedBy === "online" ? data?.booking_Id || "" : data?.businessBookingId || ""}`;
  
  const services = true ? data?.services?.map((item: any) => item?.serviceDetails?.serviceName)?.join(",") : bundleGroup?.map((item:any) =>{
    return `${Object.keys(item)?.[0]} - (${item?.[Object.keys(item)?.[0]]?.map((service: any, s: any) => `${service?.serviceDetails?.serviceName}` )?.join(", ")})`
  })

  return (
    <>
      <Modal
        centered
        show={showHistory}
        backdrop="static"
        keyboard={false}
        onHide={handleHistoryClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={`${m.modalCts} ${m.viewHistoryHead_imgBox}`}
      >
        <Modal.Header className="p-2">
          <Modal.Title>
            <div className={`${m.person_infos}`}>
              <div className={`${m.left}`}>
                <div className={m.imageBox}>
                  <span>{`${data?.Client?.firstName?.[0]?.toLocaleUpperCase()}`}</span>
                </div>
                <div className={`${m.content}`}>
                  <h3 className="mb-0">{`${data?.Client?.firstName} ${data?.Client?.lastName || ""}`} {data?.guestname ? ` - (${data?.guestname})` : ""} <span title={bookingId}>({`booking #${bookingId}`})</span></h3>
                  <p>{data?.Client?.country}</p>
                </div>
              </div>
            </div>
          </Modal.Title>
          <button
            className={`${m.closeIcon} ${m.v2}`}
            onClick={handleHistoryClose}
          >
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className={`${m.by_services_flex}`}>
              <div className={`${m.service} m-0`}>
                <div className={m.serviceEntry}>
                  <img src={serviceGreen} alt="schedule" />
                  <span>{services}</span>
                </div>
                <div className={m.serviceEntry}>
                  <img src={dateIcon} alt="date" />
                  <span>{formatDateFormat(data?.salectDate || "")}</span>
                </div>
                <div className={`${m.serviceEntry} mt-2 mb-2`} >
                  <img src={clockA} className={`${m.v2}`} alt="clock" />
                  <span>{data?.salectTime}  - {endHour < 10 ? `0${endHour}` : endHour} : {endMinute < 10 ? `0${endMinute}` : endMinute}</span>
                </div>
              </div>

            </div>
          </div>
          <div className={`${m.divider}`} role="img"></div>
          <div className={`${m.appointmentSection}`}>
            <div className={`${m.booked}`}>
              <div className={`${m.top}`}>
                <h5>
                  Booked by <span>{data?.bookingHistory?.length < 1 ? (data?.createdBy) : (data?.bookingHistory?.flat()?.[0]?.createdBy)}</span>
                </h5>
                <span className={`${m.time}`}>{data?.bookingHistory?.length < 1 ? (moment(data?.createdAt).format('DD.MM.YYYY')) : (moment(data?.bookingHistory?.flat()?.[0].createdAt).format('DD.MM.YYYY'))}, {data?.bookingHistory?.length < 1 ? (moment(data?.createdAt).format('hh:mm a')) : (moment(data?.bookingHistory?.flat()?.[0].createdAt).format('hh:mm a'))}</span>
              </div>

            </div>
            {data?.bookingHistory === null || data?.bookingHistory?.length === 0 ? ("") : (
              <div className={`${m.booked}`}>
                <div className={`${m.top}`}>
                  <h5 className={`${m.v2}`}>
                    Rescheduled by {data?.createdBy}
                  </h5>
                </div>
                <div className={`${m.bottom}`}>
                  <p>
                    <img src={dateIcon} alt="schedule" />
                    <span>{moment(data?.salectDate).format('DD.MM.YYYY')}</span>
                  </p>
                  <p>
                    <img src={clockA} className={``} alt="time" />
                    <span>{data?.salectTime}</span>
                  </p>
                </div>
              </div>
            )}

          </div>
          {/* <div className={`${m.divider}`} role="img"></div> */}
          <div className={`${m.serviceSection}`}>
            {/* <table>
              <thead>
                <tr>
                  <th>Package</th>
                </tr>
              </thead>
              <tbody>
                {packServices?.map((service: any) => {

                  return (
                    <tr className={`${m.active}`} key={service?.serviceId?._id}>
                      <td>{service?.serviceId?.serviceId?.serviceName}</td>
                      <td>{service?.serviceId?.duration}</td>
                      <td>{
                        service?.amenitiesId?.length !== 0 && service?.amenitiesId?.[0]?.itemName?.itemName
                      }</td>
                      <td>{service?.serviceId?.seasonPrice ? `USD ${service?.serviceId?.seasonPrice}` : "USD 0"}</td>
                    </tr>
                  )
                })}


              </tbody>
            </table> */}

            {/* <table>
              <thead>
                <tr>
                  <th>Services</th>
                </tr>
              </thead>
              <tbody>
                {data?.services?.map((service: any) => {
                  return (
                    <tr className={`${m.active}`} key={service?.serviceId?._id}>
                      <td>{service?.serviceDetails?.serviceName}</td>
                      <td>{service?.serviceId?.duration}</td>
                      <td>{
                        service?.amenitiesId?.length !== 0 && service?.amenitiesId?.map((data: any) => <span key={data?._id}>{`${data?.itemName?.itemName}, `}</span>)
                      }</td>
                      <td>{service?.serviceId?.seasonPrice ? `USD ${service?.serviceId?.seasonPrice}` : "USD 0"}</td>
                    </tr>
                  )
                })}

                <tr>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td>{totalPrice !== Number(data?.finalPrice) ? <span style={{ textDecoration: "line-through", color: "red" }}>USD {totalPrice}</span> : <span>USD{totalPrice}</span>}</td>
                </tr>
                <tr>
                  <td>Final Price</td>
                  <td></td>
                  <td></td>
                  <td>USD {data?.finalPrice}</td>
                </tr>
              </tbody>
            </table> */}
          </div>
          <div className={`${m.appointmentNote}`}>
            <h5>Appointment note</h5>
            <p>
              {data?.notes ? data?.notes : 'no notes'}
            </p>
          </div>
          <div className={`${m.divider}`} role="img"></div>
          <div className={`${m.stylist}`}>
            <h5>Stylist</h5>
            <Row className="">
              {team?.length === 0 ? <p>No Stylist assigned for this booking </p> : team?.map((team: any) => {
                return (
                  <Col md={12} key={team?._id}>
                    <div className={`${m.stylistBox}`} key={team?._id}>
                      {team?.image ? (
                        <img src={team?.image} className={`${m.image}`} alt="team" />
                      ) : (
                        <div className={m.emptyImage}>
                          <span>{team?.firstName?.[0] || ""}</span>
                        </div>
                      )}
                      <div className={`${m.content}`}>
                        <h5>{`${team?.firstName} ${team?.lastName || ""} ${team?.nickName ? `(${team?.nickName})` : ""}`}</h5>
                        <ul className={m.roleList}>
                          {team?.Role?.map((role: any) => {                            
                            if(role?.branch?._id !== branchId){
                              return
                            }
                            return (
                              <li>{`${role?.role?.map((r: any, i: any) => `${r?.teamTitle}${i < role?.length ? "," : ""}`)}`}</li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  </Col>
                )
              })}

            </Row>
          </div>
          <div className={`${m.divider}`} role="img"></div>
          {data?.bookingReviews && data?.bookingReviews?.length !== 0 && <div className={`${m.feedbackSection}`}>
            <h5>Feedback</h5>
            {data?.bookingReviews?.map((item: any, i: number) => {

              return (
                <React.Fragment key={i}>
                  <div className={`${m.star}`}>

                    {calReview(item?.rating)}
                    <span className={`${m.date}`}>{formatDate(item?.reviewDate)} {formatFormatTime(item?.reviewTime)}</span>
                  </div>
                  <p>{item?.comment || ""}</p>
                </React.Fragment>
              )
            })}
            {/* <button className={`${m.replyBtn}`}>Reply</button> */}
          </div>}
          {/* <div className={`${m.gotButton}`}>
            <button onClick={handleHistoryClose}>Got It</button>
          </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewHistoryModal;
