import { convertTimeToMinute, convertTimetoTotalMinutes, convertToHoursMinutes, formatSlashDate, mergeTimeRanges } from "../../../constants/Constants";
import { toTitleCase } from "../../../Utils/Funcs";

export function generateTimeArray() {
    const times = [];
    for (let hours = 0; hours < 24; hours++) {
        for (let minutes = 0; minutes < 60; minutes++) {
            const hourFormatted = hours.toString().padStart(2, '0');
            const minuteFormatted = minutes.toString().padStart(2, '0');
            const time = `${hourFormatted}:${minuteFormatted}`;
            times.push(time);
        }
    }
    return times;
}

export function divideTimeRangeToHourIntervals(timeRange: any, type: any = "") {
    const result: any = [];
    const { from, to } = timeRange;
    if (from === to) {
        return result;
    }

    const fromTime = new Date(`2000-01-01T${from}`);
    const toTime = new Date(`2000-01-01T${to}`);

    let current = fromTime;
    let timeDifferece = 0;

    let roundHrs: any;
    if (type === "leaves") {
        roundHrs = roundUpToNextHour(from, type);
    } else {
        roundHrs = roundUpToNextHour(from, "");
    }

    if (roundHrs === "24:00") {
        roundHrs = "23:59"
    }


    if (from !== roundHrs) {
        const timeDifferenceInMinutes = getTimeDifferenceInMinutes(from, roundHrs);
        timeDifferece = timeDifferenceInMinutes;
        current.setMinutes(current.getMinutes() + timeDifferece);

        result.push({
            from: from,
            to: roundHrs,
        });
    }


    while (current < toTime) {
        const nextHour = new Date(current);
        nextHour.setHours(nextHour.getHours() + 1);

        let slotEnd = toTime;

        if (nextHour < toTime) {
            slotEnd = nextHour;
        }

        const formattedFrom = current.getHours() === 0 && current.getMinutes() === 0
            ? '00:00'
            : current.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });


        // slotEnd.setMinutes(slotEnd)
        const formattedTo = slotEnd.getHours() === 0 && slotEnd.getMinutes() === 0
            ? '00:00'
            : slotEnd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
        const adjustedTime = formattedTo >= '24:00' ? `00:${formattedTo?.split(':')[1]}` : formattedTo;

        result.push({
            from: formattedFrom,
            to: adjustedTime === "24:00" ? "23:59" : adjustedTime,
        });
        current = nextHour;
    }

    return removeDuplicates(result);
}

export function stringToMinutes(item: any) {
    const [h, m] = item?.split(":");
    return +h * 60 + +m
}
export function removeDuplicates(array: any) {
    return array?.filter((item: any) => stringToMinutes(item?.from) !== stringToMinutes(item?.to))
}


export function breakTimeRangesToHourIntervals(timeRanges: any) {
    const brokenDownRanges = [];
    for (const range of timeRanges) {
        const dividedRanges = divideTimeRangeToHourIntervals(range, range?.type);
        brokenDownRanges.push(...dividedRanges);
    }
    return brokenDownRanges;
}

export function roundUpToNextHour(inputTime: string, type: any) {
    const [inputHours, inputMinutes] = inputTime.split(':').map(Number);

    const resultHours = inputMinutes > 0 ? inputHours + 1 : inputHours;
    let formattedResult: any = "";
    if (type === "leaves") {
        formattedResult = `${inputHours}:${inputMinutes}`;
    } else {
        formattedResult = `${String(resultHours).padStart(2, '0')}:00`
    }

    return formattedResult;
}

export function getTimeDifferenceInMinutes(time1: string, time2: string) {
    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);

    // Convert hours and minutes to total minutes
    const totalMinutes1 = hours1 * 60 + minutes1;
    const totalMinutes2 = hours2 * 60 + minutes2;

    // Calculate the time difference in minutes
    const timeDifferenceInMinutes = totalMinutes2 - totalMinutes1;

    return timeDifferenceInMinutes;
}

export function isDateToday(calendarDate: string) {
    let todayDate = formatSlashDate(new Date());
    return calendarDate === todayDate;
}

export function getDayName(dateString: string) {
    // Create a Date object from the date string
    const dateObject = new Date(dateString);

    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = dateObject.getDay();

    // Define an array of day names
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // Get the day name using the day of the week
    const dayName = days[dayOfWeek];
    return dayName
}

export function getWeekStartAndEndDates(inputDate: any) {
    const date = new Date(inputDate);
    const dayOfWeek = date.getDay();
    const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust when the day is Sunday
    const weekStartDate = new Date(date.setDate(diff));
    const weekEndDate = new Date(weekStartDate);
    weekEndDate.setDate(weekStartDate.getDate() + 6);

    return { weekStartDate, weekEndDate };
}

export function getDates(startDate: any, endDate: any) {
    let dates = [];
    let currentDate = new Date(startDate);
    endDate = new Date(endDate);

    while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
}

export function pushTimeIfInRange(obj: any, date: any) {
    let arr: any = []
    obj?.forEach((item: any) => {
        const fromDate = new Date(item?.fromDate);
        const toDate = new Date(item?.toDate);
        const checkDate = new Date(date);
        // Check if date falls within the range
        if (checkDate >= fromDate && checkDate <= toDate) {
            const timeRange = {
                from: item?.fromTime,
                to: item?.toTime
            };
            arr.push(timeRange)
            // return timeRange;
        }
    })
    return arr;
}

export function splitIntoSlots(from: any, to: any, slotSize: any) {
    const slots = [];
    const fromTime = new Date(`2000-01-01 ${from}`);
    const toTime = new Date(`2000-01-01 ${to}`);


    while (fromTime <= toTime) {
        const slotEndTime = new Date(fromTime);
        slotEndTime.setMinutes(fromTime.getMinutes() + slotSize);
        if (Number(slotEndTime.toTimeString().slice(0, 5).split(":")[1]) % 15 == 0) {
            const endTime = slotEndTime.toTimeString().slice(0, 5)
            slots.push({
                from: fromTime.toTimeString().slice(0, 5),
                to: endTime === "00:00" ? "23:59" : endTime
            });
        }
        fromTime.setMinutes(fromTime.getMinutes() + 1);
    }
    return slots;


};

export function convertToTime(minutes: any) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
}

export function removeConflictingSlots(slots: any, bookings: any) {
    if (slots && bookings) {
        const parsedSlots = slots.map((slot: any) => ({
            start: convertTimeToMinute(slot.from),
            end: convertTimeToMinute(slot.to)
        }));

        const parsedBookings = bookings.map((booking: any) => ({
            start: convertTimeToMinute(booking.startTime),
            end: convertTimeToMinute(booking.startTime) + Number.parseInt(booking?.serviceId?.duration)
        }));

        const updatedSlots = [];

        for (const slot of parsedSlots) {
            let conflicts = parsedBookings.filter((booking: any) =>
                (booking.start < slot.end && booking.start >= slot.start) ||
                (booking.end > slot.start && booking.end <= slot.end) ||
                (booking.start <= slot.start && booking.end >= slot.end)
            );


            if (conflicts.length === 0) {
                updatedSlots.push(slot);
            } else {
                conflicts.sort((a: any, b: any) => a?.start - b?.start);

                let currentStart = slot.start;

                for (const conflict of conflicts) {
                    if (currentStart < conflict.start) {
                        updatedSlots.push({ start: currentStart, end: conflict.start });
                    }
                    currentStart = Math.max(currentStart, conflict.end);
                }

                if (currentStart < slot.end) {
                    updatedSlots.push({ start: currentStart, end: slot?.end });
                }
            }
        }

        return updatedSlots.map(slot => ({
            from: convertToTime(slot?.start),
            to: convertToTime(slot?.end)
        }));
    }

}

export function groupNotAvailableHours(timeArr: any, shifts: any) {
    const notAvailableGroups: any = [];
    let currentGroup: any = null;
    timeArr?.forEach((time: any) => {
        const isAvailable = shifts?.some((shift: any) => {
            const shiftStart = shift.from;
            const shiftEnd = shift.to;
            return time >= shiftStart && time < shiftEnd;
        });

        if (!isAvailable) {
            if (!currentGroup) {
                currentGroup = { from: time, to: time };
            } else {
                currentGroup.to = time;
            }
        } else {
            if (currentGroup) {
                notAvailableGroups.push(currentGroup);
                currentGroup = null;
            }
        }
    });

    if (currentGroup) {
        notAvailableGroups.push(currentGroup);
    }
    return notAvailableGroups;
}
function notAvailableBoxes(hoursDetail: any, fullDayName: any, branchOpHrs: any, item: any, notWorkingBranch: any, eventDataArr: any, timeArray = [], type = 'day') {
    // repeated point

}

let fullDayTimeArray = generateTimeArray();
const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export function handleEventsResources(currentBranchData: any, selectedDate: any, selectedViewDateTeam: any, setResources: any, setEventData: any, dayStart: any, dayEnd: any, roleObj: any, bookingData: any, getBooking: any, branchId: string, handleTeamBooking:any) {
    let newresources: any = []
    let eventDataArr: any = [];

    const dayOfWeek = selectedDate.getDay();
    const fullDayName = dayNames[dayOfWeek];
    let branchOpHrs = currentBranchData?.workingHours?.find((item: any) => item?.dayName === fullDayName);
    const year = selectedDate?.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
    const day = String(selectedDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const formattedToday = formattedDate ? new Date(formattedDate) : new Date();

    const notWorkingBranch: any = [];
    currentBranchData?.notWorkingHours?.forEach((item: any) => {
        const from = new Date(item?.fromDate)
        const to = new Date(item?.toDate)
        if (+formattedToday >= +from && +formattedToday <= +to) {
            notWorkingBranch.push({ from: item?.from, to: item?.to })
        }
    })


    let branchStart = branchOpHrs?.from !== "Closed" ? branchOpHrs?.from?.split(':') : ["00", "00"]
    let branchEnd = branchOpHrs?.to !== "Closed" ? branchOpHrs?.to?.split(':') : ["00", "00"]
    if (!branchOpHrs) {
        setResources(newresources);
        setEventData(eventDataArr);
        return;
    }
    if (branchStart) {
        // dayStart.setHours(branchStart[0], branchStart[1], 0);
        dayStart.setHours(branchStart[0], 0, 0);
        dayEnd.setHours(branchEnd[0], branchEnd[1], 0);
    }
    let allUnBooking: any = []
    if (selectedViewDateTeam?.view === 'day') {
        if (!selectedViewDateTeam.team && (!roleObj?.userData || roleObj?.canViewAllBooking)) {
            let resourceObj = {
                id: `0`,
                title: "Total Available Slots",
                teamTitle: "",
                availablity: "",
                image: "",
                type: "allTeamAvailblityView",
                teamId: selectedViewDateTeam?.team
            }

            if (!roleObj?.userData || roleObj?.canCreateAllBooking) {
                newresources.push(resourceObj);
            }
            const Upcoming = {
                id: 1,
                title: "Unassigned appointments",
                isUnassign: true,
                teamId: selectedViewDateTeam?.team
                // availablity: `(${bookingData?.unAssigned?.length} NEW)`,
            }
            newresources.push(Upcoming);
        }

        let filterUnassignBooking: any = [];

        bookingData?.unAssigned?.forEach((unAssignBooking: any) => {
            const serviceCount = unAssignBooking?.services?.reduce((acc: any, item: any) => {
                const serviceId = item?.serviceId?._id;
                const bundleId = item?.bundleId?._id; // maybe null or undefined
                const startTime = item?.startTime
                if (serviceId) {
                    const key = bundleId ? `${serviceId}-${bundleId}` : serviceId;

                    if (!acc[key]) {
                        acc[key] = { count: 0, serviceId, bundleId, startTime };
                    }
                    acc[key].count += 1;
                }

                return acc;
            }, {});

            // Convert the object to an array if needed
            const serviceCountArray = Object.keys(serviceCount).map(key => ({
                serviceId: serviceCount?.[key]?.serviceId,
                bundleId: serviceCount?.[key]?.bundleId,
                count: serviceCount?.[key]?.count,
                startTime: serviceCount?.[key]?.startTime
            }));

            if (unAssignBooking?.bookingStatus !== "Cancelled" && unAssignBooking?.bookingStatus !== "Client Cancelled") {
                let filterServices: any = unAssignBooking?.services?.filter((item: any) => {
                    return item?.TeamMemberId?.length === 0
                })

                const arrayWithSameTime = serviceCountArray?.filter((item: any) => item?.count > 1);
                filterServices?.forEach((service: any, i: number, arr: any) => {
                    const currentEndTime = convertTimeToMinute(service?.startTime)
                    const nextStartTime = arr?.[i + 1] ? convertTimeToMinute(arr?.[i + 1]?.startTime) + Number.parseInt(arr?.[i + 1]?.serviceId?.duration) : 0
                    if (currentEndTime !== nextStartTime) {
                        filterServices = filterServices?.filter((unassign: any) => unassign?._id !== service?._id)
                        filterUnassignBooking.push({ ...unAssignBooking, salectTime: service?.startTime, services: [service] });
                    }
                })



                if (arrayWithSameTime?.length > 0) {
                    arrayWithSameTime?.forEach((serviceWithCount: any) => {
                        for (let i = 0; i < serviceWithCount?.count; i++) {
                            filterUnassignBooking.push({ ...unAssignBooking, services: filterServices, salectTime: filterServices?.[0]?.startTime })
                        }
                    })
                } else {
                    filterUnassignBooking.push({ ...unAssignBooking, services: filterServices, salectTime: filterServices?.[0]?.startTime })
                }
            }
        })

        filterUnassignBooking?.forEach((item: any) => {
            const [hours, minutes] = item?.salectTime ? item?.salectTime?.split(':').map(Number) : ["00", "00"];
            const [year, month, day] = item?.salectDate ? item?.salectDate?.split('-').map(Number) : ["00", "00", "00"];
            const selectedDate = new Date(year, month - 1, day, hours, minutes);
            let duration: any = [];
            let serviceItem: any = [];


            item?.services?.forEach((service: any, i: any, arr: any) => {
                if (!service?.TeamMemberId?.[0] && !serviceItem?.some((serviceInside: any) => service?.bundleId?._id ? serviceInside?.serviceId === service?.serviceId?._id && serviceInside?.bundleId === service?.bundleId?._id : serviceInside?.serviceId === service?.serviceId?._id)) {
                    duration.push(Number.parseInt(service?.serviceId?.duration));
                    serviceItem?.push({ serviceId: service?.serviceId?._id, bundleId: service?.bundleId?._id })
                    allUnBooking.push({ ...service, bookingStatus: item?.bookingStatus })
                }
            });

            const services = item?.services?.map((prices: any) => {
                return { ...prices, serviceId: { ...prices?.serviceId, serviceId: { ...prices?.serviceDetails, serviceCatagoryId: prices?.serviceCategoryDetails } } }
            })?.filter((item: any) => !item?.TeamMemberId?.[0])

            const total = duration.reduce((accumulator: any, currentValue: any) => accumulator + currentValue, 0);

            let unassval = {
                title: `${item?.Client?.firstName} ${item?.guestname ? `(${item?.guestname})` : ""}`,
                bookingId: `${item?.bookedBy === "online" ? item?.booking_Id || "" : item?.businessBookingId || ""}`,
                start: selectedDate,
                end: new Date(selectedDate.getTime() + total * 60 * 1000),
                service: services,
                resourceId: 1,
                updateBooking: item,
                allUnassignedBooking: filterUnassignBooking,
                eventType: 'Upcoming',
                booking: item,
                teamId: selectedViewDateTeam?.team,
                _id: item?._id,
                getBooking: getBooking
            }
            eventDataArr.push(unassval);
        });


        // const result = [{ from: "00:00", to: `${branchEnd?.[0]}:${branchEnd?.[1]}` }].reduce((acc: any, entry: any, i: any) => {
        //     const timeDifferences = splitIntoSlots(entry.from, entry.to, 15);
        //     return acc.concat(timeDifferences);
        // }, []);
        const result = [{ from: "00:00", to: '23:59' }].reduce((acc: any, entry: any, i: any) => {
            const timeDifferences = splitIntoSlots(entry.from, entry.to, 15);
            return acc.concat(timeDifferences);
        }, []);
        const deductedSlots = allUnBooking?.length !== 0 ? removeConflictingSlots(result, allUnBooking) : result

        deductedSlots.forEach((slot: any) => {
            const originalDate = new Date(selectedDate);
            const year = originalDate.getFullYear();
            const month = originalDate.getMonth();
            const day = originalDate.getDate();
            const [startHrs, startMins] = slot.from.split(':').map(Number);
            const start = new Date(year, month, day, startHrs, startMins);
            const end = new Date(start.getTime() + 15 * 60 * 999); // 15 minutes later
            eventDataArr.push({
                title: 'emptyBoxes',
                start,
                end,
                resourceId: 1,
                eventType: 'emptyBoxes',
                getBooking: getBooking
            });
        });
    }

    let allBooking: any = []
    bookingData?.assigned?.forEach((item: any) => {
        const checkTeam = roleObj?.singleTeamViewCheck ? roleObj?.userData?._id !== item?._id : false;
        if (checkTeam) {
            return
        }

        let resHours = item?.hoursDetail?.filter((shifts: any) => shifts.branchId === branchId)
        let hoursDetail = resHours?.length ? resHours[0]?.shift : [];


        if (selectedViewDateTeam?.view === 'day') {
            // repeated point
            let availableHrsArr: any = [];
            hoursDetail?.forEach((element: any) => {
                let hrs = element.workingHours?.find((event: any) => event?.day === fullDayName);
                availableHrsArr.push(hrs);
            });
            // Update array if condition is met
            const updateArray = (arr: any, obj: any) => {
                const objToMinutes = convertTimeToMinute(obj.to);

                arr.forEach((item: any) => {
                    if (convertTimeToMinute(item.to) > objToMinutes) {
                        item.to = obj.to;
                    }
                });
            };

            updateArray(availableHrsArr, branchOpHrs);
            let notAvailableHrs = groupNotAvailableHours(fullDayTimeArray, availableHrsArr);
            // if (branchOpHrs?.from !== 'Closed' && notAvailableHrs?.some((hrs: any) => !Number.isNaN(+hrs?.to?.split(':')?.[1] + 1) &&  `${branchOpHrs?.from?.split(":")?.[0]}:${(+hrs?.to?.split(':')?.[1] + 1)}` !== branchOpHrs?.from)) {
            if (branchOpHrs?.from !== 'Closed' && +branchOpHrs?.from?.split(":")?.[1] !== 0) {
                const fromPartEnd = +branchOpHrs?.from?.split(":")?.[1];
                notAvailableHrs?.push({ from: `${branchOpHrs?.from?.split(":")?.[0]}:00`, to: `${branchOpHrs?.from?.split(":")?.[0]}:${fromPartEnd - 1}` })
            }
            // notAvailableHrs = notAvailableHrs.filter((item:any) => convertTimeToMinute(item?.to) > convertTimeToMinute(branchOpHrs?.from))
            if (branchOpHrs?.from !== 'Closed') {
                notAvailableHrs = [...notAvailableHrs, ...item.leaves?.map((item: any) => ({ ...item, type: "leaves" })), ...notWorkingBranch?.map((item: any) => ({ ...item, type: "leaves" }))];
            } else {
                notAvailableHrs = [
                    { from: '00:00', to: '23:59' }]
            }
            notAvailableHrs = mergeTimeRanges(notAvailableHrs);
            const isOnLeave = item?.availablity?.toLowerCase() === 'not available';
            let brokenDownRanges = [];
            if (isOnLeave) {
                brokenDownRanges = divideTimeRangeToHourIntervals(branchOpHrs);
            } else {
                brokenDownRanges = breakTimeRangesToHourIntervals(notAvailableHrs);
            }

            const uniqueIntervals: any = {};
            brokenDownRanges = brokenDownRanges?.filter((range: any) => {
                return branchOpHrs?.from === 'Closed' ? true : range?.from?.split(':')?.[0] >= branchOpHrs?.from?.split(":")?.[0]
            })
            // Iterate over the array and store each object as a key in the uniqueIntervals object
            brokenDownRanges.forEach((interval: any) => {
                uniqueIntervals[JSON.stringify(interval)] = interval;
            });

            // Extract the values from the uniqueIntervals object to get unique intervals
            const uniqueTimeIntervals = Object.values(uniqueIntervals);
            uniqueTimeIntervals?.forEach((elem: any) => {
                const originalDate = new Date(selectedDate);
                const year = originalDate.getFullYear();
                const month = (originalDate.getMonth()).toString().padStart(2, '0');
                const day = originalDate.getDate().toString().padStart(2, '0');
                const [startHrs, startMins] = elem?.from?.split(':').map(Number);
                const [endHrs, endMins] = elem?.to?.split(':').map(Number);

                eventDataArr.push({
                    title: 'Not Available',
                    start: new Date(year, +month, +day, +startHrs, +startMins),
                    end: new Date(year, +month, +day, +endHrs, +endMins),
                    resourceId: item?._id,
                    availablity: item?.availablity,
                    hoursDetail: item?.hoursDetail,
                    bookingData: item,
                    eventType: 'Not Available',
                    getBooking: getBooking
                });
            });

        } else {
            const { weekStartDate, weekEndDate } = getWeekStartAndEndDates(selectedViewDateTeam?.date);
            let datesArray = getDates(weekStartDate, weekEndDate);
            eventDataArr = []
            datesArray?.map((dateItem: any) => {
                const branchTeamLeave = item?.allLeave?.filter((leaveItem: any) => leaveItem?.branchId === branchId);
                const selectedDate = new Date(dateItem)
                const dayOfWeek = selectedDate.getDay();
                const fullDayName = dayNames[dayOfWeek];
                let branchOpHrs = currentBranchData?.workingHours?.find((item: any) => item?.dayName === fullDayName);
                const dateToCheck = new Date(formatSlashDate(selectedDate))
                const result = branchTeamLeave?.find((obj: any) => {
                    const fromDate = new Date(obj.fromDate);
                    const toDate = new Date(obj.toDate);
                    return dateToCheck >= fromDate && dateToCheck <= toDate;
                });
                const fromTimes = currentBranchData?.workingHours?.filter((day: any) => day.from !== 'Closed')?.map((day: any) => day.from);

                // Find the minimum 'from' time
                const minFromTime = fromTimes.reduce((min: any, current: any) => current < min ? current : min);
                const timeArray: any = result?.length !== 0 ? pushTimeIfInRange(branchTeamLeave, formatSlashDate(selectedDate)) : []
                // if (timeRange) {
                //     timeArray.push(timeRange);
                // }
                if (minFromTime) {
                    // dayStart.setHours(branchStart[0], branchStart[1], 0);
                    dayStart.setHours(minFromTime?.split(':')?.[0], 0, 0);
                    dayEnd.setHours(branchEnd?.[0], branchEnd[1], 0);
                }

                const year = selectedDate?.getFullYear();
                const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
                const day = String(selectedDate.getDate()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}`;
                const formattedToday = formattedDate ? new Date(formattedDate) : new Date();

                const notWorkingBranch: any = [];
                currentBranchData?.notWorkingHours?.forEach((item: any) => {
                    const from = new Date(item?.fromDate)
                    const to = new Date(item?.toDate)

                    if (+formattedToday >= +from && +formattedToday <= +to) {
                        notWorkingBranch.push({ from: item?.from, to: item?.to })
                    }
                })

                // repeated point
                let availableHrsArr: any = [];
                hoursDetail?.forEach((element: any) => {
                    let hrs = element.workingHours?.find((event: any) => event?.day == fullDayName);
                    availableHrsArr.push(hrs);
                });
                // Update array if condition is met
                const updateArray = (arr: any, obj: any) => {
                    const objToMinutes = convertTimeToMinute(obj.to);
                    arr.forEach((item: any) => {
                        if (convertTimeToMinute(item.to) > objToMinutes) {
                            item.to = obj.to;
                        }
                    });
                };

                updateArray(availableHrsArr, branchOpHrs);
                let notAvailableHrs = groupNotAvailableHours(fullDayTimeArray, availableHrsArr);
                if (branchOpHrs?.from !== 'Closed') {
                    const fromPartEnd = +branchOpHrs?.from?.split(":")?.[1];
                    notAvailableHrs?.push({ from: `${minFromTime?.split(":")?.[0]}:00`, to: `${branchOpHrs?.from?.split(":")?.[0]}:${fromPartEnd === 0 ? '00' : fromPartEnd - 1}` })
                }
                if (branchOpHrs?.from !== 'Closed') {
                    notAvailableHrs = [...notAvailableHrs, ...item.leaves?.map((item: any) => ({ ...item, type: "leaves" })), ...notWorkingBranch?.map((item: any) => ({ ...item, type: "leaves" })), ...timeArray?.map((item: any) => ({ ...item, type: "leaves" }))];

                } else {
                    notAvailableHrs = [
                        { from: `${minFromTime?.split(':')?.[0]}:00`, to: '23:59' }]
                }
                notAvailableHrs = mergeTimeRanges(notAvailableHrs);
                const isOnLeave = item?.availablity?.toLowerCase() === 'not available';
                let brokenDownRanges = [];
                if (isOnLeave) {
                    brokenDownRanges = divideTimeRangeToHourIntervals(branchOpHrs);
                } else {
                    brokenDownRanges = breakTimeRangesToHourIntervals(notAvailableHrs);
                }

                const uniqueIntervals: any = {};
                brokenDownRanges = brokenDownRanges?.filter((range: any) => {
                    return branchOpHrs?.from === 'Closed' ? true : range?.from?.split(':')?.[0] >= minFromTime?.split(":")?.[0]
                })
                // Iterate over the array and store each object as a key in the uniqueIntervals object
                brokenDownRanges.forEach((interval: any) => {
                    uniqueIntervals[JSON.stringify(interval)] = interval;
                });

                // Extract the values from the uniqueIntervals object to get unique intervals
                const uniqueTimeIntervals = Object.values(uniqueIntervals);
                uniqueTimeIntervals?.forEach((elem: any) => {
                    const originalDate = new Date(selectedDate);
                    const year = originalDate.getFullYear();
                    const month = (originalDate.getMonth()).toString().padStart(2, '0');
                    const day = originalDate.getDate().toString().padStart(2, '0');
                    const [startHrs, startMins] = elem?.from?.split(':').map(Number);
                    const [endHrs, endMins] = elem?.to?.split(':').map(Number);

                    eventDataArr.push({
                        title: 'Not Available',
                        start: new Date(year, +month, +day, +startHrs, +startMins),
                        end: new Date(year, +month, +day, +endHrs, +endMins),
                        resourceId: item?._id,
                        availablity: item?.availablity,
                        hoursDetail: item?.hoursDetail,
                        bookingData: item,
                        eventType: 'Not Available',
                        getBooking: getBooking
                    });
                });
            });
        }

        const totalMinutes = item?.availablity?.split(":")?.[1] ? convertTimetoTotalMinutes(item?.availablity?.split(":")?.[1]) : 0
        const bookedMinutes = item?.booked?.split(":")?.[1] ? convertTimetoTotalMinutes(item?.booked?.split(":")?.[1]) : 0;
        const availableHrs = totalMinutes - bookedMinutes;

        const formattedString = convertToHoursMinutes(availableHrs)
        let resourceObj = {
            id: item?._id,
            title: toTitleCase(`${item?.firstName} ${`${item?.nickName ? `(${item?.nickName})` : ""}`}`),
            teamTitle: item?.Role?.find((item: any) => item?.branch === branchId),
            availablity: formattedString,
            image: item?.image ?? "",
        }
        newresources.push(resourceObj);

        let selectedDayEvents: any = [];

        const modifiedBookingDetails: any = [];

        item?.bookingDetails?.forEach((booking: any) => {
            const services = booking?.services?.filter((service: any, i: number, arr: any) => {
                const currentEndTime = convertTimeToMinute(service?.startTime)
                const nextStartTime = arr?.[i + 1] ? convertTimeToMinute(arr?.[i + 1]?.startTime) + Number.parseInt(arr?.[i + 1]?.serviceId?.duration) : "00:00"
                if (service?.bookingStatus === "Cancelled" || service?.bookingStatus === "Client Cancelled") {
                    return
                }
                if (arr?.[i + 1] && currentEndTime !== nextStartTime) {
                    modifiedBookingDetails.push({ ...booking, salectTime: service?.startTime, services: [service] });
                    return false
                    // filterServices = filterServices?.filter((unassign: any) => unassign?._id !== service?._id)
                } else {
                    return true
                }

            })
            modifiedBookingDetails.push({ ...booking, services })
        })

        modifiedBookingDetails?.forEach((val: any) => {
            val?.services?.forEach((service: any) => {
                allBooking.push({ ...service, bookingStatus: val?.bookingStatus })

            })
            if (val?.bookingStatus === "Cancelled" || val?.bookingStatus === "Client Cancelled") {
                return
            }
            const services = val?.services?.map((prices: any) => {
                return { ...prices, serviceId: { ...prices?.serviceId, serviceId: { ...prices?.serviceDetails, serviceCatagoryId: prices?.serviceCategoryDetails } } }
            })

            const [hours, minutes] = val?.services?.[0]?.startTime ? val?.services?.[0]?.startTime?.split(':') : ["0", "0"];
            const [year, month, day] = val?.salectDate?.split('-').map(Number);
            const eventDate = new Date(year, month - 1, day, +hours, +minutes);

            let duration: any = []
            let filterTeamService = val?.services.filter((filterId: any) => {
                return filterId?.TeamMemberId?.[0]?._id === item?._id
            });
            filterTeamService?.forEach((item: any) => {
                duration.push(parseInt(item?.serviceId?.duration))
            });
            const total = duration.reduce((accumulator: any, currentValue: any) => accumulator + currentValue, 0);

            let endtime = new Date(eventDate.getTime() + total * 60 * 1000);
            const endDateformate = (endtime.getHours() < 10 ? `0${endtime.getHours()}` : `${endtime.getHours()}`) + ':' + (endtime.getMinutes() < 10 ? `0${endtime.getMinutes()}` : `${endtime.getMinutes()}`);
            const dayOfEventWeek = eventDate.getDay();
            const eventDayName = dayNames[dayOfEventWeek];

            let eventObj = {
                title: `${val?.Client?.firstName || ""} ${val?.guestname ? `(${val?.guestname})` : ""}`,
                bookingId: `${val?.bookedBy === "online" ? val?.booking_Id || "" : val?.businessBookingId || ""}`,
                start: eventDate,
                service: services,
                booking: val,
                end: new Date(eventDate.getTime() + total * 60 * 1000),
                resourceId: item?._id,
                availablity: item?.availablity,
                hoursDetail: item?.hoursDetail,
                bookingData: item,
                eventType: 'Upcoming',
                globalEventType: val?.bookingStatus,
                updateBooking: val,
                getBooking: getBooking
            }

            eventDataArr.push(eventObj);
            const originalDate = new Date(selectedDate)
            const compyear = originalDate.getFullYear();
            const compmonth = originalDate.getMonth();
            const compday = originalDate.getDate();
            if (year === compyear && (month - 1) === compmonth && day === compday) {
                selectedDayEvents.push({ day: `${eventDayName}`, from: `${val?.salectTime}`, to: `${endDateformate}`, resourceId: `${item?._id}` })
            }
        });
    })

    if (selectedViewDateTeam?.view === 'day') {
        const totalBooking: any = [...allUnBooking, ...allBooking]
        const slots = bookingData?.availSlots ? bookingData?.availSlots : []
        const result = [{ from: `${branchStart[0]}:00`, to: "23:59" }].reduce((acc: any, entry: any, i: any) => {
            const timeDifferences = splitIntoSlots(entry.from, entry.to, 15);
            return acc.concat(timeDifferences);
        }, []);

        result.forEach((slot: any) => {
            const originalDate = new Date(selectedDate);
            const year = originalDate.getFullYear();
            const month = originalDate.getMonth();
            const day = originalDate.getDate();
            const [startHrs, startMins] = slot.from.split(':').map(Number);
            const start = new Date(year, month, day, startHrs, startMins);
            const end = new Date(start.getTime() + 15 * 60 * 999); // 15 minutes later

            const teamSlots = slots?.[slot.from];

            let occupiedTime = 0;

            totalBooking?.forEach((item: any) => {
                if (item?.bookingStatus !== "Upcoming") {
                    return
                }
                const totalStartMinute = convertTimeToMinute(item?.startTime);
                let totalDuration = Number.parseInt(item?.serviceId?.duration);

                totalDuration = totalDuration % 15 !== 0 ? totalDuration + 15 - totalDuration % 15 : totalDuration;

                const totalEndMinute = totalStartMinute + totalDuration;
                const slotTotalMinute = convertTimeToMinute(slot.from);
                // Check if the slot falls within any booking time range
                if (slotTotalMinute >= totalStartMinute && slotTotalMinute < totalEndMinute) {
                    occupiedTime += 1; // increment occupied time
                }
            });

            eventDataArr.push({
                title: 'teamCount',
                start,
                end,
                resourceId: `0`,
                eventType: 'teamCount',
                teamCount: teamSlots ? teamSlots : 0,
                bookedCount: occupiedTime,
                teamId: selectedViewDateTeam?.team,
                handleTeamBooking: handleTeamBooking,
                getBooking: getBooking
            });
        });
    }

    const filterEvents = eventDataArr.filter((item: any) => {
        const formattedFrom = item.start.getHours() === 0 && item.start.getMinutes() === 0
            ? '00:00'
            : item.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

        const formattedTo = item.end.getHours() === 0 && item.end.getMinutes() === 0
            ? '00:00'
            : item.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
        return branchOpHrs.from === 'Closed' ? true : formattedFrom >= branchOpHrs.from;
        // return formattedFrom >= branchOpHrs.from && formattedTo <= branchOpHrs.to;
    });
    setResources(newresources);
    setEventData(eventDataArr);
}