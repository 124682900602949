import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Accordion, Button, Col, Modal, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  deleteIcon,
  plusFill,
  plusWhite,
  userFrame,
} from "../../../assets/images";
import AddServiceModal from "./AddServiceModal";
import AddClientModal from "./AddClientModal";
import ProfilePopup from "./ProfilePopup";
import { disablePastDate } from "../../../Utils/Funcs";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import SuccessPopup from "./SuccessPopup";
import AddPackageModal from "./AddPackageModal";
import { quarterlyTimeValues, timeValues } from "../../../constants/Constants";
import BookingTeam from "./BookingServicePage";
import { v4 as uuidv4 } from 'uuid';
import BookingBundleDesc from "./BookingBundleDesc";

const NewBookingModal = (props: any) => {
  let { showBooking, handleBooking, getBooking, type = "list", getBookings, data: oldData, reschedule, handleCustomerClose } = props;
  const [emptyError, setEmptyError] = useState(false);
  const [deletedServices, setDeleteServices] = useState<any>([])
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const { currentBranchData } = useSelector((state: any) => state.BranchReducer);

  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const dayOfWeek = date ? new Date(date)?.getDay() : new Date()?.getDay();
  const fullDayName = dayNames[dayOfWeek];
  let branchOpHrs = currentBranchData?.workingHours?.find((item: any) => item?.dayName === fullDayName);
  let branchStart = branchOpHrs?.from?.split(':');
  let branchEnd = branchOpHrs?.to?.split(':');

  let startTime: any = Number(time?.split(":")[0]) * 60 ? Number(time?.split(":")[0]) * 60 + Number(time?.split(":")[1]) : 0;
  const [hide, setHide] = useState(false);
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );
  const [data, setData] = useState(() => {
    return oldData
  })
  const [removePromotion, setRemovePromotion] = useState<any>([]);

  useEffect(() => {
    setSelectedServices(selectedServices?.map((item: any) => {
      const removable = removePromotion?.find((promo: any) => promo?.serviceId === item?.serviceId);
      return {
        ...item,
        promotionId: removable?.promotionId === item?.promotionId ? null : item?.promotionId
      }
    }))

  }, [removePromotion])

  const [notes, setNotes] = useState("");
  const [showPackage, setShowPackage] = useState(false);
  const handleAddPackageClose = () => setShowPackage(false);


  const [packageData, setPackageData] = useState<any>([]);
  const [packageeData, setPackageeData] = useState<any>([]);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [message, setMessage] = useState("")
  const handleSuccessModalClose = () => setShowSuccessModal(false);

  const [errorTrig, setErrorTrig] = useState(false);
  const [teamError, setTeamError] = useState(false);

  const [clientList, setClientList] = useState<any>([]);

  const [services, setServices] = useState<any>([]);
  const [serviceList, setServiceList] = useState<any>([]);

  const [serviceType, setServiceType] = useState<string>("service")

  const [selectedBundle, setSelectedBundle] = useState<any>([]);
  const [bundleList, setBundleList] = useState<any>([]);

  const [selectedServices, setSelectedServices] = useState<any>([])

  const [amenitiesList, setAmenitiesList] = useState<any>([])
  const [team, setTeam] = useState<any>([]);

  const [promotionList, setPromotionList] = useState<any>([]);

  const [showAddCategory, setAddCategoryShow] = useState(false);
  const handleAddCategoryClose = () => setAddCategoryShow(false);
  const handleAddCategoryShow = () => {
    setAddCategoryShow(true);
  };
  const [showClient, setShowClient] = useState(false);
  const handleAddClientClose = () => setShowClient(false);
  const handleAddClientShow = () => setShowClient(true);

  const [showProfile, setShowProfile] = useState(false);
  const handleProfileClose = () => setShowProfile(false);
  const handleProfileShow = () => setShowProfile(true);

  const [counterServices, setCounterServices] = useState<any>([])
  
  const initialState: any = {
    otherChargeMsg: "",
    otherChargeAmt: "",
    discountType: "",
    discount: "",
    paymentStatus: "Success",
    paymentType: "",
    paymentRef: "",
  }
  const [otherCharges, setOtherCharges] = useState<any>(initialState);

  const fetchServices = async (id: any) => {
    let res = await httpRequest(
      `getBusinessPrice?branchId=${id}&businessId=${businessData?._id}&type=package&bookingTime=${time}&bookingDate=${date}`,
      "get",
      null,
      "json"
    );
    setServices(res?.data);
  };

  const fetchBundles = async () => {
    const res = await httpRequest(`getBundle?businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      setBundleList(res?.data?.filter((bundle: any) => bundle?.branchArr?.includes(branchId)))
    }
  }


  const getAmenities = async (id: any) => {
    const res = await httpRequest(`getAmenities?branchId=${id}&businessId=${businessData?._id}&dataForBooking=${true}`, "get", null, "json");
    if (res.status) {
      setAmenitiesList(res.data);
    }
  };

  

  const deleteServiceHandler = (id: any, index: any, priceId: any = "", bundleId: any = null) => {
    if (id) {
      const filterService = serviceList?.filter((service: any, i: number) => service?.id !== id);
      const filterSelectedServices = selectedServices?.filter((service: any, i: number) => service?._id !== id);
      setSelectedServices(filterSelectedServices)

      const filterCounterService = counterServices?.filter((item:any) => item?.serviceId !== priceId);
      setCounterServices(filterCounterService)
      // if (data) {
      //   const filterOldService = data?.services?.filter((service: any , i:number) => service?._id !== id );
      //   console.log(data?.services, id , "filterOldService", selectedServices, serviceList);
      //   return

      //   setData({ ...data, services: filterOldService })
      // }

      const deletedServices = serviceList?.find((service: any, i: number) => service?.id === id);
      setServiceList(filterService)
      if (deletedServices?.length !== 0) {
        setDeleteServices((prev: any) => [...prev?.filter((deletedService: any, i: number) => deletedService !== priceId), priceId])
      } else {
        setDeleteServices((prev: any) => [...deletedServices, priceId])
      }
      setTeam(team?.filter((data: any, i: any) => i !== index))

      // startTime -= Number.parseFloat(deletedServices?.duration)
    }
    if (bundleId) {
      const filterSelectedServices = selectedServices?.filter((service: any, i: number) => service?.bundleId !== bundleId);
      setSelectedServices(filterSelectedServices)

      const filterCounterService = counterServices?.filter((item:any) => item?.bundleId !== bundleId);
      setCounterServices(filterCounterService)

      const filterBundle = selectedBundle?.filter((item: any) => item?._id !== bundleId);
      setSelectedBundle(filterBundle)

      let deletedBundleService: any = [];
      selectedBundle?.forEach((bundle: any) => {
        if (bundle?._id === bundleId) {
          deletedBundleService.push(...bundle?.services?.map((service: any) => ({ bundleId: bundle?._id, priceId: service?._id })))
        }
      })
      if (deletedServices?.length !== 0) {
        setDeleteServices((prev: any) => [...prev?.filter((deletedService: any, i: number) => deletedService !== priceId), priceId])
      } else {
        setDeleteServices((prev: any) => [...deletedServices, priceId])
      }
      setTeam(team?.filter((data: any, i: any) => i !== index))
    }
  }

  const addService = (type: any, value: any, index: any, _id: any, packageId: any = null, bundleId: any = null, bundlePromotionalPrice: string = "") => {
    let updateService = [...selectedServices];
    let serviceIndex: number = -1;

    if (type === "bundlePromotion") {
      updateService = updateService?.map((item: any) => {
        if (item?.bundleId === bundleId) {
          return {
            ...item,
            bundlePromotionalPrice,
            bundlePromotionId: value
          }
        }
        return item
      })
    }

    if (bundleId) {
      serviceIndex = updateService?.findIndex((service: any, i: any) => service?._id === _id && service?.bundleId === bundleId);
    } else {
      serviceIndex = updateService?.findIndex((service: any, i: any) => service?._id === _id);
    }

    if (type === "team") {
      updateService[serviceIndex] = {
        ...updateService[serviceIndex],
        TeamMemberId: value ? [value] : [],
      }
    }
    if (type === "radeemedPackage") {
      updateService[serviceIndex] = {
        ...updateService[serviceIndex],
        radeemPackage: value,
        radeemPackageViaUpdate: oldData ? value : false,
        packageId: packageId && value ? packageId : null,
      }
    }
    if (type === "amenity") {
      updateService[serviceIndex] = {
        ...updateService[serviceIndex],
        amenitiesId: value ? value?.map((am: any) => am?.value) : []
      }
    }
    if (type === "promotion") {
      updateService[serviceIndex] = {
        ...updateService[serviceIndex],
        promotionId: value === "" ? null : value
      }
    }
    setSelectedServices(updateService)
  }

  useEffect(() => {
    setErrorTrig(false);
    if (serviceList && serviceList?.length !== 0) {
      // const filterService = selectedServices?.filter((data: any) => !serviceList?.map((existData: any) => existData?._id)?.includes(data?.serviceId)) || [];
      const filterService = selectedServices?.filter((data: any) => !serviceList?.map((existData: any) => existData?.id)?.includes(data?._id)) || [];
      setSelectedServices([...filterService, ...serviceList?.map((service: any, i: any) => {
        const serviceIndex = selectedServices?.map((data: any) => data?._id)?.findIndex((data: any, index: number) => data === service?.id);
        return {
          serviceId: service?._id,
          TeamMemberId: selectedServices[serviceIndex]?.TeamMemberId && selectedServices[serviceIndex]?.TeamMemberId?.length !== 0 ? selectedServices[serviceIndex]?.TeamMemberId : [],
          amenitiesId: selectedServices[serviceIndex]?.TeamMemberId && selectedServices[serviceIndex]?.amenitiesId?.length !== 0 ? selectedServices[serviceIndex]?.amenitiesId : [],
          packageId: selectedServices[serviceIndex]?.packageId && selectedServices[serviceIndex]?.packageId?.length !== 0 ? selectedServices[serviceIndex]?.packageId : null,
          promotionId: selectedServices[serviceIndex]?.promotionId && selectedServices[serviceIndex]?.promotionId?.length !== 0 ? selectedServices[serviceIndex]?.promotionId : null,
          bundleId: selectedServices[serviceIndex]?.bundleId && selectedServices[serviceIndex]?.bundleId?.length !== 0 ? selectedServices[serviceIndex]?.bundleId : null,
          radeemPackage: selectedServices[serviceIndex]?.radeemPackage ? selectedServices[serviceIndex]?.radeemPackage : false,
          bundlePromotionalPrice: selectedServices[serviceIndex]?.bundlePromotionalPrice ? selectedServices[serviceIndex]?.bundlePromotionalPrice : "",
          bundlePromotionId: selectedServices[serviceIndex]?.bundlePromotionId ? selectedServices[serviceIndex]?.bundlePromotionId : null,
          radeemPackageViaUpdate: selectedServices[serviceIndex]?.radeemPackageViaUpdate ? selectedServices[serviceIndex]?.radeemPackageViaUpdate : false,
          _id: service?.id
        }
      })])
    }
  }, [serviceList]);

  useEffect(() => {
    setErrorTrig(false);
    if (selectedBundle && selectedBundle?.length !== 0) {
      let newBundleServiceArr: any = []
      selectedBundle?.forEach((bundle: any) => {
        bundle?.services?.forEach((service: any) => {
          const serviceIndex = selectedServices?.map((data: any) => data?._id)?.findIndex((data: any, index: number) => data === service?.id);

          const formattedServiceObj = {
            serviceId: service?._id,
            TeamMemberId: selectedServices[serviceIndex]?.TeamMemberId && selectedServices[serviceIndex]?.TeamMemberId?.length !== 0 ? selectedServices[serviceIndex]?.TeamMemberId : [],
            amenitiesId: selectedServices[serviceIndex]?.TeamMemberId && selectedServices[serviceIndex]?.amenitiesId?.length !== 0 ? selectedServices[serviceIndex]?.amenitiesId : [],
            packageId: selectedServices[serviceIndex]?.packageId && selectedServices[serviceIndex]?.packageId?.length !== 0 ? selectedServices[serviceIndex]?.packageId : null,
            promotionId: selectedServices[serviceIndex]?.promotionId && selectedServices[serviceIndex]?.promotionId?.length !== 0 ? selectedServices[serviceIndex]?.promotionId : null,
            bundleId: selectedServices[serviceIndex]?.bundleId && selectedServices[serviceIndex]?.bundleId?.length !== 0 ? selectedServices[serviceIndex]?.bundleId : bundle?._id,
            radeemPackage: selectedServices[serviceIndex]?.radeemPackage ? selectedServices[serviceIndex]?.radeemPackage : false,
            bundlePromotionalPrice: selectedServices[serviceIndex]?.bundlePromotionalPrice ? selectedServices[serviceIndex]?.bundlePromotionalPrice : "",
            bundlePromotionId: selectedServices[serviceIndex]?.bundlePromotionId ? selectedServices[serviceIndex]?.bundlePromotionId : null,
            radeemPackageViaUpdate: selectedServices[serviceIndex]?.radeemPackageViaUpdate ? selectedServices[serviceIndex]?.radeemPackageViaUpdate : false,
            _id: service?.id
          }
          newBundleServiceArr.push(formattedServiceObj)

        })
      })

      setSelectedServices(newBundleServiceArr)
    }
  }, [selectedBundle]);

  const bundleServices: any = []

  selectedBundle?.forEach((item: any) => {
    bundleServices.push(...item?.services)
  })

  const bundleDurations = bundleServices?.reduce((acc: any, val: any) => acc + Number.parseInt(val?.duration), 0)

  const totalDuration = selectedBundle?.length !== 0 ? bundleDurations : serviceList?.reduce((acc: any, service: any) => {
    return Number(acc) + Number.parseInt(service?.duration)
  }, 0)

  const getPromotion = async () => {
    if (businessData) {
      let res = await httpRequest(`getPromotion?businessId=${businessData?._id}`, "get", null, "json");
      if (res.status) {
        setPromotionList(res?.data);
      }
    }
  };

  useEffect(() => {
    getPromotion()
  }, [businessData])

  useEffect(() => {
    if (branchId && businessData) {
      getAmenities(branchId)
      fetchServices(branchId);
      fetchBundles()
    }
  }, [branchId, businessData]);


  useEffect(() => {
    if (branchId && businessData && time && date) {
      fetchServices(branchId);
    }
  }, [branchId, businessData, time, date]);


  useEffect(() => {
    if (data) {
      setOtherCharges({
        otherChargeMsg: data?.otherChargeMsg ? data?.otherChargeMsg : "",
        otherChargeAmt: data?.otherChargeAmt ? data?.otherChargeAmt : "",
        discountType: data?.discountType ? data?.discountType : "",
        discount: data?.discount ? data?.discount : "",
        paymentStatus: data?.paymentStatus ? data?.paymentStatus : "",
        paymentType: data?.paymentType ? data?.paymentType : "",
        paymentRef: data?.paymentRef ? data?.paymentRef : "",
      })

      setNotes(data?.notes || "")
      setDate(data?.salectDate);
      setTime(data?.salectTime);
      setClientList([data?.Client]);

      const services = data?.services?.map((service: any, i: number) => {
        const [hours, minutes] = service?.startTime ? service?.startTime?.split(":") : ["00", "00"];
        const existStartTime = +hours * 60 + +minutes;

        return { service: { ...service?.serviceId, id: service?._id, bundleId: service?.bundleId, BusinessService: { ...service?.serviceDetails, globalAmenities: service?.globalAmenities, amenitiesDetails: service?.amenitiesDetails }, ServiceCategory: service?.serviceCategoryDetails, startTime: existStartTime, } }
      }) || []

      let existBundleList: any[] = [];

      services?.forEach((item: any) => {
        if (item?.service?.bundleId?._id) {
          const existBundle = existBundleList.find((bundle) => bundle?._id === item?.service?.bundleId?._id);
          if (existBundle) {
            existBundle.services.push(item?.service);
          } else {
            existBundleList.push({ bundleName: item?.service?.bundleId?.bundleName, _id: item?.service?.bundleId?._id, finalPrice: item?.service?.bundleId?.finalPrice, services: [item?.service] });
          }
        }
      });

      if (existBundleList?.length !== 0) {
        setServiceType("bundle")
        setSelectedBundle(existBundleList);
      } else {
        setServiceType("service")
        setServiceList(services?.map((data: any) => data?.service))
      }

      const serviceCount = data?.services?.reduce((acc: any, item: any) => {
        const serviceId = item?.serviceId?._id;
        const bundleId = item?.bundleId?._id; // maybe null or undefined
        const startTime = item?.startTime
        if (serviceId) {
          const key = bundleId ? `${serviceId}-${bundleId}` : serviceId;

          if (!acc[key]) {
            acc[key] = { count: 0, serviceId, bundleId, startTime, teamCount:0 };
          }
          acc[key].count += 1;
        }

        return acc;
      }, {});

      // Convert the object to an array if needed
      const serviceCountArray = Object.keys(serviceCount).map(key => ({
        serviceId: serviceCount?.[key]?.serviceId,
        bundleId: serviceCount?.[key]?.bundleId,
        count: serviceCount?.[key]?.count,
        startTime: serviceCount?.[key]?.startTime,
        teamCount: serviceCount?.[key]?.teamCount,
        
      }));      

      setCounterServices(serviceCountArray);

      setSelectedServices(data?.services?.map((serviceData: any) => {
        return {
          serviceId: serviceData?.serviceId?._id,
          TeamMemberId: serviceData?.TeamMemberId[0]?._id ? serviceData?.TeamMemberId?.map((team: any) => team?._id) : [],
          amenitiesId: serviceData?.amenitiesId[0]?._id ? serviceData?.amenitiesId?.map((am: any) => am?._id) : [],
          packageId: serviceData?.packageId?._id ? serviceData?.packageId?._id : null,
          promotionId: serviceData?.promotionId?._id ? serviceData?.promotionId?._id : null,
          radeemPackage: serviceData?.radeemPackage ? serviceData?.radeemPackage : false,
          radeemPackageViaUpdate: false,
          bundleId: serviceData?.bundleId ? serviceData?.bundleId?._id : null,
          bundlePromotionId: serviceData?.bundlePromotionId ? serviceData?.bundlePromotionId : null,
          bundlePromotionalPrice: serviceData?.bundlePromotionalPrice ? serviceData?.bundlePromotionalPrice : "",
          _id: serviceData?._id
        }
      }))
    }
  }, [data]);
  

  useEffect(() => {
    if (emptyError && date !== "" && time !== "" && clientList?.length !== 0) {
      setEmptyError(false);
    }
  }, [date, time, clientList]);

  function handleAddServices(serviceArr: any) {
    if (serviceType === "service") {
      let transformServiceArray = serviceArr?.map((item: any, index: any) => {
        const existService = selectedServices?.find((selectedService: any) => selectedService?._id === item?.id);
        let timeWithDuration = startTime;
        if (item?._id === serviceList?.[index + 1]?._id) {
          startTime = startTime
        } else {
          startTime = startTime + Number.parseInt(item?.duration)
        }
        return ({
          ...item,
          startTime: timeWithDuration,
          id: existService?._id ? existService?._id : uuidv4()
        })
      })
      setServiceList(transformServiceArray);
    } else {
      let existBundleTotalDuration: any = 0;
      selectedBundle?.forEach((bundle: any) => {
        existBundleTotalDuration += bundle?.services.reduce((acc: any, val: any) => acc + Number.parseInt(val?.duration), 0)
      })

      startTime = startTime + existBundleTotalDuration;
      const formattedBundle = serviceArr?.map((bundle: any) => (
        {
          bundleName: bundle?.bundleName,
          finalPrice: bundle?.finalPrice,
          _id: bundle?._id,
          services: bundle?.priceId?.map((price: any) => {
            const existService = selectedServices?.find((selectedService: any) => selectedService?._id === price?.id);
            let timeWithDuration = startTime;
            startTime = startTime + Number.parseInt(price?.id?.duration)

            return { ...price?.id, BusinessService: price?.id?.serviceId, ServiceCategory: price?.id?.serviceId?.serviceCatagoryId, startTime: timeWithDuration, id: existService?._id ? existService?._id : uuidv4() }
          })
        }))
      setSelectedBundle([...selectedBundle, ...formattedBundle])
    }
  }

  let updateTime: any = Number(time?.split(":")[0]) * 60 ? Number(time?.split(":")[0]) * 60 + Number(time?.split(":")[1]) : 0;


  useEffect(() => {
    if (serviceList?.length !== 0 && serviceType === "service") {
      let transformServiceArray = serviceList?.map((item: any, index: any) => {
        let timeWithDuration = updateTime;

        if (item?._id === serviceList?.[index + 1]?._id) {
          updateTime = updateTime
        } else {
          updateTime = updateTime + Number.parseInt(item?.duration)
        }

        return ({
          ...item,
          startTime: timeWithDuration,
        })
      })
      setServiceList(transformServiceArray);
    }
    if (selectedBundle?.length !== 0 && serviceType === "bundle") {
      let transformServiceArray = selectedBundle?.map((item: any) => {
        const services = item?.services?.map((service: any) => {
          let timeWithDuration = updateTime;

          updateTime = updateTime + Number.parseInt(service?.duration)
          return ({
            ...service,
            startTime: timeWithDuration,
          })
        })
        return { ...item, services }
      })
      setSelectedBundle(transformServiceArray);
    }
  }, [time])

  // const getTeamCheck = (value: any) => {
  //   setTeamError(value)
  // }

  const appliedPromotion: any = []
  promotionList
    ?.filter((data: any) => selectedServices?.find((service: any) => service?.promotionId && service?.promotionId === data?._id))
    ?.flatMap((data: any) => data?.priceId)
    ?.forEach((promo: any) => {
      const serviceObj = selectedServices?.find((selected: any) => selected?.promotionId && selected?.serviceId === promo?.id?._id)
      if (serviceObj?.serviceId) {
        appliedPromotion.push({ ...promo, priceObjUId: serviceObj?._id })
      }
    });

  const selectedPromotions = selectedServices?.map((ser: any) => promotionList?.find((promo: any) => promo?._id === ser?.promotionId)?.priceId?.find((data: any) => data?.id?._id === ser?.serviceId));

  const promoServicePrice = selectedPromotions?.reduce((acc: any, data: any) => {
    if (data) {
      return Number(acc) + Number?.parseFloat(data?.promotionalPrice)
    } else {
      return acc
    }
  }, 0);



  let ServicePrice = serviceList?.filter((data: any) => {
    if (selectedBundle?.length !== 0) {
      return false
    }
    return appliedPromotion?.length === 0 ? true : !appliedPromotion?.some((pro: any) => pro?.priceObjUId === data?.id)
  })
    ?.map((service: any) => {
      if (service?.seasonPrice) {
        return service?.seasonPrice
      } else {
        return "0"
      }
    })?.reduce((acc: any, val: any) => {
      return acc + Number.parseFloat(val)
    }, 0)

  const bundlePrice = selectedBundle?.reduce((acc: any, bundle: any) => {
    const promotionableBundle = selectedServices?.find((item: any) => item?.bundleId === bundle?._id && item?.bundlePromotionId);
    if (promotionableBundle) {
      return acc + Number.parseFloat(promotionableBundle?.bundlePromotionalPrice)
    } else {
      return acc + Number.parseFloat(bundle?.finalPrice)
    }
  }, 0)


  let totalPrice: any = 0
  totalPrice = ServicePrice + promoServicePrice + bundlePrice;

  const otherChargesPrice: any = otherCharges?.otherChargeAmt ? totalPrice + +otherCharges?.otherChargeAmt : totalPrice;

  let finalPrice: any = otherChargesPrice

  if (otherCharges?.discount) {
    if (otherCharges?.discountType === "%") {
      finalPrice = otherChargesPrice - (otherChargesPrice * (+otherCharges?.discount / 100))
    } else {
      finalPrice = otherChargesPrice - +otherCharges?.discount
    }
  }


  const handleInputChange = (e: any, discountType: any) => {
    const value = e.target.value;
    const numericValue = Number(value);

    if (!isNaN(numericValue) && numericValue <= 100 && discountType === "%") {
      setOtherCharges({ ...otherCharges, discount: value });
    } else if (!isNaN(numericValue) && numericValue <= +totalPrice && discountType !== "%") {
      setOtherCharges({ ...otherCharges, discount: value });
    }
    else if (value === '') {
      setOtherCharges({ ...otherCharges, discount: '' }); // Allow empty value
    }
  };

  const bookingId = data ? `${data?.bookedBy === "online" ? data?.booking_Id || "" : data?.businessBookingId || ""}` : ""

  let totalMinute = startTime
  let totalBundleMinute = startTime;
  

  return (
    <>
      {<Modal
        scrollable
        centered
        backdrop="static"
        keyboard={false}
        show={showBooking}
        onHide={handleBooking}
        aria-labelledby="example-modal-sizes-title-sm"
        className={`${m.modalCts} ${m.profile_serviceDiscount_coupon}   ${m.ModalHeaderBG} ${showAddCategory || showPackage || showClient || hide || showClient ? m.hide : ""
          }`}
      >
        <Modal.Header className="ps-4 pe-4 mt-3">
          <Modal.Title>{reschedule === true ? `Reschedule (booking #${bookingId})` : (data ? `Edit Booking (booking #${bookingId})` : "Create a New Booking")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Row>
            <Col md={12}>
              <div className={`${m.bookingHeading} `}>
                <div className={`${m.titleMakeup}`}>
                  <div className={`${m.heading}`}>
                    <h2>Select Date/Time</h2>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className={`${st.formBox} ${m.dateBox} `}>
                <label htmlFor="select-date" className="form-label">
                  Select Date
                  <div className="position-relative">
                    <input
                      type="date"
                      className="form-control"
                      min={disablePastDate()}
                      disabled={type === "calendar"}
                      onChange={(e: any) => setDate(e.target.value)}
                      value={date}
                    />

                  </div>
                </label>
                {errorTrig && date === "" && (
                  <p className={"errorMessage"}>This field is required</p>
                )}
                {!errorTrig && date === "" && emptyError && (
                  <p className={"errorMessage"}>Please select a date</p>
                )}

              </div>
            </Col>
            
            <Col md={6}>
              <div className={`${st.formBox} ${m.dateBox} `}>
                <label htmlFor="select-time" className="form-label">
                  Select Time
                  <div className="position-relative">
                    <select
                      className="form-select"
                      onChange={(e: any) => setTime(e.target.value)} value={time}
                      disabled={type === "calendar"}
                    >
                      <option value="">Select</option>
                      {quarterlyTimeValues?.map((item: any, i: any) => {
                        const timeHour = Number(item?.value?.split(":")[0]) * 60
                        const timeMinute = Number(item?.value?.split(":")[1]);

                        const timeMillisecond = timeHour + timeMinute;

                        const currentDate = new Date();
                        // Get the current hour and minute
                        const currentHour = currentDate.getHours();
                        const currentMinute = currentDate.getMinutes();

                        const currentTotalMinute = currentHour * 60 + currentMinute;
                        const day = currentDate.getDate();
                        const month = currentDate.getMonth() + 1;
                        const year = currentDate.getFullYear();

                        const todayCount = [day, month, year]?.reduce((acc: any, count: any) => acc * count, 1);
                        const selectedDateCount = date?.split("-")?.[0] === "" ? 1 : date?.split("-")?.reduce((acc: any, count: any) => acc * Number(count), 1);
                        const isTodayCheck = todayCount === selectedDateCount && timeMillisecond <= currentTotalMinute

                        const availableStartHour = Number(branchStart?.[0]) * 60;
                        const availableStartMinute = Number(branchStart?.[1]);
                        const availableStartMilliSecond = availableStartHour + availableStartMinute

                        const availableEndHour = Number(branchEnd ? branchEnd[0] : 0) * 60;
                        const availableEndMinute = Number(branchEnd ? branchEnd[1] : 0);
                        const availableEndMilliSecond = availableEndHour + availableEndMinute;

                        if ((timeMillisecond < availableStartMilliSecond) || isTodayCheck || (timeMillisecond > availableEndMilliSecond)) {
                          return
                        }

                        return (
                          <option value={item.value} key={i}>
                            {item.label}
                          </option>
                        )
                      })}
                    </select>
                    {/* {<TimeField onChange={(e: any) => setTime(e.target.value)} value={time} />} */}
                    {/* <img src={clock} alt="shedule" className={m.clockIcon} /> */}
                  </div>
                </label>
                {errorTrig && time === "" && (
                  <p className={"errorMessage"}>This field is required</p>
                )}
                {!errorTrig && time === "" && emptyError && (
                  <p className={"errorMessage"}>Please select time</p>
                )}
              </div>
            </Col>
            <Col md={12}>
              <div className={`${m.makeupHeading}`}>
                <div className={`${m.titleMakeup}`}>
                  <div className={`${m.heading}`}>
                    <h2>Select Client</h2>

                  </div>
                </div>
              </div>
            </Col>

            {clientList?.length === 0 && <Col md={12}>
              <div className={m.addClient} onClick={handleAddClientShow}>
                <div className={m.left}>
                  <img src={userFrame} alt="user" />
                  <div className={m.userInfo}>
                    <h4>Add new client</h4>
                    <p>Select a client</p>
                  </div>
                </div>
                <div className={m.right}>
                  <img src={plusFill} alt="add" />
                </div>
              </div>
              {errorTrig && clientList?.length === 0 && (
                <p className={"errorMessage"}>Please select a client</p>
              )}
              {!errorTrig && clientList?.length === 0 && emptyError && (
                <p className={"errorMessage"}>Please select a client</p>
              )}
            </Col>}

            {clientList?.map((client: any) => {

              return (
                <Col md={12} key={client?._id}>
                  <div className={`${m.selectedClient}`}>
                    <div className={m.top}>
                      <div className={m.left}>
                        <img src={userFrame} alt="user" />
                        <div className={m.userInfo}>
                          <h4>{`${client?.firstName} ${client?.lastName || ""} ${data?.guestname ? `(${data?.guestname})` : ""}`}</h4>
                          <p>{client?.email}</p>
                          <p>{client?.mobile}</p>
                        </div>

                      </div>
                      {!data && <div className={m.right}>
                        <NavLink to="#" onClick={handleAddClientShow}>
                          <span>Change</span>
                        </NavLink>
                      </div>}
                    </div>
                    {/* {client?.packageService && client?.packageService?.length !== 0 && <div className={`${m.packInfo}`}>
                      <h5>{client?.packageService && client?.packageService?.length !== 0 ? `Package Name : ${client?.packageService?.[0]?.packageId?.PackageName || client?.packageService?.[0]?.packageId?.packageName}` : ""}</h5>
                      <h5>{client?.packageService && client?.packageService?.length !== 0 ? `Package Type : ${client?.packageService?.[0]?.type}` : ""}</h5>
                      <h4>Available Services in package</h4>
                      <ul>
                        {client?.packageService?.map((data: any) => {
                          
                          if (serviceName(data?.serviceId) === "") {
                            return
                          }
                          return <li key={data?.serviceId}>{`${serviceName(data?.serviceId)} ${data?.type === "duration" ? `(${Number(data?.noOfServices) * 60 - data?.duration} mins)` : data?.type === "noOfServices" ? `(${Number(data?.noOfServices) - data?.usedCounter} times)` : ""}`}</li>
                        })}
                      </ul>
                    </div>} */}
                    {/* <div className={m.bottom}>
                      <span className={m.tags}>5 yearly membership</span> <br />
                      <span className={m.tags}>35 reviews</span>
                    </div> */}
                  </div>
                </Col>
              )
            })}

            <Col md={12}>
              <div className={`${m.makeupHeading}`}>
                <div className={`${m.titleMakeup}`}>
                  <div className={`${m.heading}`}>
                    <h2>Select Service{bundleList?.length > 0 ? "/Bundle" : ""}</h2>
                  </div>
                </div>
              </div>
            </Col>

            {/* for bundle  */}
            {selectedBundle?.length !== 0 && selectedBundle?.map((bundle: any, index: any) => {              
              const duration = bundle?.services?.reduce((acc: any, val: any) => acc + Number.parseInt(val?.duration), 0)
              const totalDeductionMinute = totalBundleMinute + duration;

              const startHours = Math.floor(Math.min(totalBundleMinute / 60));
              const startMinutes = totalBundleMinute % 60;
              const formatStartTime = `${startHours < 10 ? `0${startHours}` : startHours} : ${startMinutes < 10 ? `0${startMinutes}` : startMinutes}`

              totalBundleMinute = totalDeductionMinute;

              return (
                <div className={m.bundleList} key={index}>
                  <BookingBundleDesc
                    key={index}
                    bundle={bundle}
                    deleteServiceHandler={deleteServiceHandler}
                    selectedClient={clientList?.length !== 0 ? clientList[0] : {}}
                    formattedDate={date}
                    formatStartTime={formatStartTime}
                    selectedServices={selectedServices}
                    data={data}
                    addService={addService}
                  />
                  {bundle?.services?.map((service: any, b: any) => {
                    return (
                      <BookingTeam key={b} index={index}
                        setRemovePromotion={setRemovePromotion}
                        selectedServices={selectedServices}
                        formattedDate={date}
                        // getTeamCheck={getTeamCheck}
                        totalDuration={totalDuration}
                        service={service}
                        data={data}
                        addService={addService}
                        amenitiesList={amenitiesList}
                        promotionList={promotionList}
                        time={time} date={date} oldData={oldData}
                        deleteService={deleteServiceHandler} errorTrig={errorTrig}
                        selectedClient={clientList?.length !== 0 ? clientList[0] : {}}
                        bundleId={bundle?._id}
                        type={"bundle"}
                        setCounterServices={setCounterServices}
                        counterServices={counterServices}
                      />
                    )
                  })}
                </div>
              )

            })}

            {/* for services  */}
            {selectedBundle?.length === 0 && serviceList?.map((service: any, index: any) => {
              return (
                <>
                  <BookingTeam key={index}
                    setRemovePromotion={setRemovePromotion}
                    selectedServices={selectedServices}
                    formattedDate={date}
                    // getTeamCheck={getTeamCheck}
                    totalDuration={totalDuration}
                    service={service}
                    data={data}
                    addService={addService}
                    amenitiesList={amenitiesList}
                    index={index}
                    promotionList={promotionList}
                    time={time}
                    date={date} oldData={oldData} deleteService={deleteServiceHandler} errorTrig={errorTrig}
                    selectedClient={clientList?.length !== 0 ? clientList[0] : {}}
                    counterServices={counterServices}
                    setCounterServices={setCounterServices}
                  />
                </>
              )

            })}

            <Col md={12}>
              {selectedBundle?.length === 0 && <button
                className={`${m.addTeamBtn} singleBtn`}
                disabled={emptyError}
                onClick={() => {
                  if (date === "" || time === "" || clientList?.length === "") {
                    setEmptyError(true)
                  } else {
                    setServiceType("service")
                    setEmptyError(false)
                    handleAddCategoryShow();
                  }
                }}
              >
                <img src={plusWhite} alt="add" /> Add Service
              </button>}

              {bundleList?.length !== 0 && serviceList?.length === 0 && <button
                className={`${m.addTeamBtn} singleBtn`}
                disabled={emptyError}
                onClick={() => {
                  if (date === "" || time === "" || clientList?.length === "") {
                    setEmptyError(true)
                  } else {
                    setEmptyError(false)
                    setServiceType("bundle")
                    handleAddCategoryShow();
                  }
                }}
              >
                <img src={plusWhite} alt="add" /> Add Bundle
              </button>}

              {errorTrig && serviceList?.length === 0 && selectedBundle?.length === 0 && (
                <p className={"errorMessage"}>Please select a service</p>
              )}
            </Col>

            {/* <Col md={12}>
              <div className={m.total} role="img">
                <p>
                  Total: USD {totalPrice} <span className={m.hours}>({duration < 60 ? `${duration} minutes` : `${(duration / 60).toFixed(1)} hours`})</span>
                </p>
              </div>
            </Col> */}
          </Row>
          <Row>
            <Col md={12}>
              <div className={`${st.formBox} mt-4`}>
                <label className="form-label">
                  Appointment Notes
                </label>
                <textarea
                  className="form-control"
                  placeholder="Appointment Notes"
                  style={{ minHeight: "120px" }}
                  onChange={(e: any) => setNotes(e.target.value)}
                  value={notes}
                ></textarea>

              </div>
            </Col>
          </Row>
          <Row>
            <div className={`${m.discount_charges_accordian} mt-2`}>
              <Accordion className="mb-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Other Charges & Discount</Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <Col md={12}>
                        <div className={`${m.new_appoint_discount_box}`}>
                          <div className={`${m.new_appoint_discountDiv}`}>
                            <h4>Other Charges</h4>
                            <Row>
                              <Col md={6} className="px-2">
                                <div className={`${m.input_left}`}>
                                  <label>Details</label>
                                  <input type="text" className='form-control' value={otherCharges?.otherChargeMsg} onChange={(e: any) => setOtherCharges({ ...otherCharges, otherChargeMsg: e.target.value })} />
                                </div>
                              </Col>
                              <Col md={6} className="px-2">
                                <div className={`${m.right_left}`}>
                                  <label>Amount</label>
                                  <input type="number" disabled={!otherCharges?.otherChargeMsg} className='form-control' value={otherCharges?.otherChargeAmt} onChange={(e: any) => setOtherCharges({ ...otherCharges, otherChargeAmt: e.target.value })} />
                                </div>
                              </Col>
                              {otherCharges?.otherChargeAmt && <div className={`${m.line_withTotal_amt}`}>
                                <span className={`${m.line}`}></span>
                                <div className={`${m.total_amt}`}>Total: SGD {otherChargesPrice}</div>
                              </div>}
                            </Row>
                          </div>
                          <div className={`${m.new_appoint_discountDiv}`}>
                            <h4>Additional Discount</h4>
                            <Row>
                              <Col md={6}>
                                <div className={`${m.input_left}`}>
                                  <label>Discount Type</label>
                                  <select className='form-select' value={otherCharges?.discountType} onChange={(e: any) => setOtherCharges({ ...otherCharges, discountType: e.target.value })}>
                                    <option value="">--select--</option>
                                    <option value="%">discount percentage</option>
                                    <option value="discount amount">discount amount</option>
                                  </select>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className={`${m.right_left}`}>
                                  <label>Amount</label>
                                  <input type="text" disabled={!otherCharges?.discountType} className='form-control' min={otherCharges?.discountType === "%" ? "100" : Number.parseFloat(totalPrice)?.toFixed(2)} value={otherCharges?.discount} onChange={(e: any) => handleInputChange(e, otherCharges?.discountType)} />
                                </div>
                              </Col>
                              {otherCharges?.discount && <div className={`${m.line_withTotal_amt}`}>
                                <span className={`${m.line}`}></span>
                                <div className={`${m.total_amt}`}>Total: SGD {finalPrice?.toFixed(2)}</div>
                              </div>}
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Payment</Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <Col md={12}>
                        <div className={`${m.new_appoint_discount_box}`}>
                          <div className={`${m.new_appoint_discountDiv}`}>
                            <h4>Payment</h4>
                            <Row>
                              <Col md={12}>
                                <div className={`${st.formBox} mb-2 mt-2`}>
                                  <label className={`${st.checkbox} mt-1`}>
                                    <input type="checkbox" value="Pending" checked={otherCharges?.paymentStatus === "Pending"} onChange={(e: any) => setOtherCharges({ ...otherCharges, paymentType: e.target?.checked ? "" : otherCharges?.paymentType, paymentRef: e.target?.checked ? "" : otherCharges?.paymentRef, paymentStatus: e.target?.checked ? e.target.value : "Success" })} />
                                    <span className={`${st.checkmark}`}></span>
                                    <span className="ms-2">Pending</span>
                                  </label>
                                </div>
                              </Col>
                            </Row>
                            {otherCharges?.paymentStatus !== "Pending" && <Row>
                              <Col md={6}>
                                <div className={`${m.input_left}`}>
                                  <label>Payment Method</label>
                                  <select className='form-select' value={otherCharges?.paymentType} onChange={(e: any) => setOtherCharges({ ...otherCharges, paymentType: e.target.value })}>
                                    <option value="">--select--</option>
                                    <option value="Credit card">Credit card</option>
                                    <option value="Cash">Cash</option>
                                    <option value="Nets">Nets</option>
                                    <option value="Paynow">Paynow</option>
                                    <option value="Voucher">Voucher</option>
                                    <option value="Others">Others</option>
                                  </select>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className={`${m.right_left}`}>
                                  <label>Reference Number</label>
                                  <input type="text" className='form-control' value={otherCharges?.paymentRef} onChange={(e: any) => setOtherCharges({ ...otherCharges, paymentRef: e.target.value })} />
                                </div>
                              </Col>
                              {errorTrig && otherCharges?.paymentStatus === "Success" && (otherCharges?.paymentType === "" || otherCharges?.paymentRef === "") && <p style={{ fontSize: "13px", color: "red", marginTop: "4px" }}>please add payment status with reference</p>}


                            </Row>}
                          </div>
                        </div>
                      </Col>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {errorTrig && otherCharges?.paymentStatus === "Success" && (otherCharges?.paymentType === "" || otherCharges?.paymentRef === "") && <p style={{ fontSize: "13px", color: "red", marginTop: "4px" }}>please add payment status</p>}

            </div>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Col md={12}>
            <div className={`${m.new_appoint_discount_box}`}>
              <div className={`${m.new_appoint_discountDiv}`}>
                {counterServices?.some((item: any) => item?.teamCount < item?.count ) && teamError && <p className={"errorMessage"} style={{ textAlign: "center" }}>
                  Team member is not available for all services
                </p>}

                <Row>
                  <div className={`${m.line_withTotal_amt} px-4 mt-0`}>
                    <span className={`${m.line}`}></span>
                    <div className={`${m.total_amt}`}>Total: SGD {finalPrice} <span className={`${m.total_time}`}>&#40;{totalDuration} mins&#41;</span></div>
                  </div>
                </Row>
              </div>
            </div>
          </Col>
          <button
            type="button"
            className={`btn ${m.cancelBtn}`}
            onClick={handleBooking}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`btn ${m.actionBtn} ${st.btn2}`}
            // disabled={teamError}
            onClick={() => {
              if (counterServices?.some((item: any) => item?.teamCount < item?.count )) {
                setTeamError(true)
                setTimeout(() =>{
                  setTeamError(false)
                },3000)
                return
              }
              if (otherCharges?.otherChargeMsg !== "" && otherCharges?.otherChargeAmt === "") {
                setErrorTrig(true)
                return
              }

              if (otherCharges?.paymentStatus === "Success" && (otherCharges?.paymentType === "" || otherCharges?.paymentRef === "")) {
                setErrorTrig(true)
                return
              }
              // if (checkEmpty) {
              //   setErrorTrig(true)
              //   return
              // }
              // if (time !== "" && date !== "" && clientList?.length !== 0 && serviceList?.length !== 0 && selectedServices?.some((service:any) => service?.TeamMemberId?.length !==0) && selectedServices?.some((am:any) => am?.amenitiesId?.length !==0)) { 
              if (time !== "" && date !== "" && clientList?.length !== 0 && serviceList?.length !== 0 || selectedBundle?.length !== 0) {

                handleProfileShow()
              } else {
                setErrorTrig(true)
              }
            }}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>}
      <SuccessPopup handleSuccessModalClose={handleSuccessModalClose} message={message} showSuccessModal={showSuccessModal} />
      <AddClientModal
        showClient={showClient}
        handleAddClientClose={handleAddClientClose}
        clientData={[]}

        setSelectedClient={setClientList}
        type={"booking"}
        selectedOneClient={clientList}
      />
      {showPackage && <AddPackageModal
        packageData={packageData}
        showAddCategory={showPackage}
        handleAddCategoryClose={handleAddPackageClose}
        setPackageeData={setPackageeData}

      />}
      {showAddCategory && <AddServiceModal
        showAddCategory={showAddCategory}
        handleAddCategoryClose={handleAddCategoryClose}
        service={services}
        existData={serviceList}
        packageData={handleAddServices}
        bundleList={bundleList}
        selectedBundle={selectedBundle}
        serviceType={serviceType}
      />}
      <ProfilePopup
        showProfile={showProfile}
        handleProfileClose={handleProfileClose}
        serviceList={serviceList}
        clientList={clientList}
        apiServices={selectedServices}
        time={time}
        date={date}
        handleBooking={handleBooking}
        getBooking={getBooking}
        getBookings={getBookings}
        setShowSuccessModal={setShowSuccessModal}
        setMessage={setMessage}
        setHide={setHide}
        showSuccessModal={showSuccessModal}
        packageeData={packageeData}
        notes={notes}
        data={data}
        handleCustomerClose={handleCustomerClose}
        promotionList={promotionList}
        type={type}
        deletedServices={deletedServices}
        otherCharges={otherCharges}
        finalPrice={finalPrice}
        otherChargesPrice={otherChargesPrice}
        selectedBundle={selectedBundle}
        totalPrice={totalPrice}
      />
    </>
  );
};

export default NewBookingModal;
