import React from "react";
import m from "./Modal.module.scss";
import { NavLink } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { crossRed, successLarge, successTick } from "../../../assets/images";

const ErrorPopup = (props: any) => {
  let { showErrorModal, handleErrorModalClose , message = "",handlePasswordModalClose,handleEmailModalClose } = props;
  let alertType = showErrorModal !== '' ? showErrorModal.split(',')[0] : '';
  let alertMsg = showErrorModal !== '' ? showErrorModal.split(',')[1] : '';

  
  return (
    <>
      <Modal
        centered
        show={showErrorModal !== ''}
        backdrop="static"
        keyboard={false}
        onHide={handleErrorModalClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={m.modalCts}
      >
        <Modal.Body className="p-5">
          <Row>
            <Col md={12}>
              {alertType !== '' && <img
                src={alertType === 'Error' ? crossRed : successTick}
                alt="success-img"
                className={`${m.errorImage}`}
              />}
            </Col>
            <Col md={12}>
              <h5 className={`${m.successHeading}`}>{alertType}</h5>
              <p className={`${m.desc2} ${m.desc3}`}>{message ? message : alertMsg}</p>
            </Col>

            <Col md={12}>
              <div className={`${m.buttonGroup}`}>
                <button className={m.ok} onClick={() => { 
                  handleErrorModalClose()
                  handlePasswordModalClose && handlePasswordModalClose();
                  handleEmailModalClose && handleEmailModalClose();
                  }}>
                  Ok
                </button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ErrorPopup;
