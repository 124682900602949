import React, { useEffect, useState } from "react";
// import st from "../../../assets/stylesheet/style.module.scss";
import { httpRequest } from "../../../Apis/commonApis";
import tg from "./toggle.module.scss"


const ToggleNew = (props: any) => {
  return (
    <>
      <span className={`${tg.switch} `}>
        <input id="switch1" type="checkbox" {...props}/>
        <label htmlFor="switch1"></label>
      </span>
    </>
  );
};

export default ToggleNew;
