import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Accordion, Button, Col, Nav, Row, Tab, Table } from "react-bootstrap";
import AddServiceModal from "../../../components/Business/Modals/AddServiceModal";
import { clock, reSchedule, plusWhite, deleteIcon } from "../../../assets/images";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { httpRequest } from "../../../Apis/commonApis";
import TimeField from "../../../constants/TimeField";
import { useSelector } from "react-redux";
import { getMinDate, quarterlyTimeValues, timeValues, weekValues } from "../../../constants/Constants";
import { disablePastDate } from "../../../Utils/Funcs";
import LoadingBtn from "../../../components/Business/Buttons/LoadingBtn";
import { log } from "console";
import { watch } from "fs";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import HoverEyeContent from "../../../components/Business/HoverEyeContent/HoverEyeContent";


export default function BasicTab(props: any) {
  const { handleStepsFormData, editPromotionData, loading, type, formObj, editLoading } = props;
  const {
    register,
    handleSubmit,
    watch: watchNonOpHrs,
    formState: { errors },
    setValue,
    reset,
    watch
  } = formObj;

  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );
  const singleValue = watchNonOpHrs("promotionType");
  const [dynamicPromotionStatus, setDynamicPromotionStatus] = useState(true);

  const handleStatus = (e: any) => {
    setDynamicPromotionStatus(e.target.checked)
  }

  const [time, setTime] = useState<any>({
    startTime: editPromotionData?.startTimeFrom ? editPromotionData?.startTimeFrom : "00:00",
    endTime: editPromotionData?.endTimeTo ? editPromotionData?.endTimeTo : "23:45",

    sellstartTimeFrom: editPromotionData?.sellstartTimeFrom ? editPromotionData?.sellstartTimeFrom : "00:00",
    sellendTimeTo: editPromotionData?.sellendTimeTo ? editPromotionData?.sellendTimeTo : "23:45",
  });
  const [errrorTrig, setErrorTrig] = useState(false);
  const navigate = useNavigate();
  const [superPermotion, setSuperPermotion] = useState<any>([]);
  const [branchData, setBranchData] = useState<any>([]);
  const [priceId, setPriceId] = useState<any>([]);
  const [bundleId, setBundleId] = useState<any>([]);


  const [trigger, setTrigger] = useState(false);
  const [settingData, setSettingData] = useState<any>({
    LimitUsePerClient: { active: false, maxUse: "" },
    LimitNumberOfUses: {
      active: false,
      maxUser: ""
    },
  });

  useEffect(() => {
    if (editPromotionData) {
      reset();
      setSettingData({
        LimitUsePerClient: {
          active: editPromotionData && editPromotionData?.LimitUsePerClient ? true : false,
          maxUse: editPromotionData && editPromotionData?.LimitUsePerClient ? editPromotionData?.LimitUsePerClient : ""
        },
        LimitNumberOfUses: {
          active: editPromotionData && editPromotionData?.LimitNumberOfUses?.active ? editPromotionData?.LimitNumberOfUses?.active : false,
          maxUser: editPromotionData && editPromotionData?.LimitNumberOfUses?.maxUser ? editPromotionData?.LimitNumberOfUses?.maxUser : ""
        },
      });
      const prices = editPromotionData?.priceId?.map((item: any) => ({ ...item, id: item?.id?._id }))
      setPriceId(prices)

      const bundles = editPromotionData?.bundleId?.map((item: any) => ({ ...item, id: item?.id?._id }))
      setBundleId(bundles)

      setValue('promotionType', editPromotionData?.promotionType);
      setValue('name', editPromotionData?.promotionName)
      setValue('tagline', editPromotionData?.tagline);

      setValue("selldateFrom", editPromotionData?.selldateFrom || "")
      setValue("selldateTo", editPromotionData?.selldateTo || "")

      setValue("dateFrom", editPromotionData?.dateFrom)
      setValue("dateTo", editPromotionData?.dateTo)
      setValue("discountCode", editPromotionData?.discountCode || "")

      setValue("promotionProvidesCategory", editPromotionData?.promotionProvidesCategory ? editPromotionData?.promotionProvidesCategory?.split(',') : [])
      setValue("forFirstTime", editPromotionData?.forFirstTime ? "newUser" : "")

      setTime({
        startTime: editPromotionData?.startTimeFrom ? editPromotionData?.startTimeFrom : "",
        endTime: editPromotionData?.endTimeTo ? editPromotionData?.endTimeTo : "",

        sellstartTimeFrom: editPromotionData?.sellstartTimeFrom ? editPromotionData?.sellstartTimeFrom : "",
        sellendTimeTo: editPromotionData?.sellendTimeTo ? editPromotionData?.sellendTimeTo : "",
      });
    }
  }, [editPromotionData, superPermotion]);


  const getAdminPromotion = async () => {
    let res = await httpRequest(`getSuperPromotion`, "get", null, "json");
    if (res.status) {
      setSuperPermotion(res?.data);
    }
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    getAdminPromotion()
  }, [businessData, editPromotionData]);


  const submitHandler = (formdata: any) => {

    setErrorTrig(false);
    const finalData = { ...formdata, startTime: time?.startTime, sellstartTimeFrom: time?.sellstartTimeFrom, sellendTimeTo: time?.sellendTimeTo, endTime: time?.endTime, unit: formdata?.unit, frequency: formdata?.frequency };
    if (finalData?.dateFrom !== "" && finalData?.dateTo !== "" || singleValue === "Dynamic Pricing") {

      let basicData: any = {
        promotionType: finalData?.promotionType,
        promotionName: finalData?.name,
        branchArr: editPromotionData?.branchArr && editPromotionData?.branchArr?.length !== 0 ? editPromotionData?.branchArr : branchData,
        dateFrom: finalData?.dateFrom ? finalData?.dateFrom : "",
        dateTo: finalData?.dateTo ? finalData?.dateTo : "",
        startTimeFrom: finalData?.startTime ? finalData?.startTime : "",
        endTimeTo: finalData?.endTime ? finalData?.endTime : "",
        tagline: formdata?.tagline ? formdata?.tagline : "",

        selldateFrom: finalData?.selldateFrom ? finalData?.selldateFrom : "",
        selldateTo: finalData?.selldateTo ? finalData?.selldateTo : "",
        sellstartTimeFrom: finalData?.sellstartTimeFrom ? finalData?.sellstartTimeFrom : "",
        sellendTimeTo: finalData?.sellendTimeTo ? finalData?.sellendTimeTo : "",
        isPublish: editPromotionData ? editPromotionData?.isPublish : true,
        frequency: finalData?.frequency ? finalData?.frequency : "",
        unit: finalData?.unit ? finalData?.unit : "",
        discountCode: formdata?.discountCode,
        priceId: priceId,
        promotionProvidesCategory: formdata?.promotionProvidesCategory ? formdata?.promotionProvidesCategory?.join(',') : "",
        forFirstTime: formdata?.forFirstTime,
        bundleId,
      }

      if (time?.endTime === "" || time?.sellendTimeTo === "" || time?.sellstartTimeFrom === "" || time?.startTime === "") {
        setErrorTrig(true);
        return
      }

      handleStepsFormData({ ...basicData, ...settingData }, 'step1');
    }
  }



  // end time filter check if date is of today then apply filter if not today then not applied filter 
  const currDate = new Date();
  const endDate = watch("dateTo");
  const endSaleDate = watch("selldateTo");
  const formatToday = `${currDate?.getFullYear()}-${currDate?.getMonth() + 1}-${currDate?.getDate()}`
  const dateCheck = endDate === formatToday;
  const saleDateCheck = endSaleDate === formatToday

  const allPermotion = ["Regular"]

  useEffect(() => {
    // window.scrollTo(0, 0);
    if (businessData) {
      getBranches();
    }
  }, [businessData]);

  const getBranches = async () => {
    let res = await httpRequest(`getBranch?businessId=${businessData._id}`, "get", null, 'json');
    if (res.status === true) {
      setBranchData(res.data?.map((item: any) => item?._id));
    }
  }



  return (
    <>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Row>
          <Col md={12}>
            <div className={`${st.formBox} ${st.formSign}`}>
              <div className={`${st.btnsGroup} ${st.formSignGroup} mt-0 pb-3 justify-content-end`}>
                {/* <button
                  className={`btn ${st.btn1} ${st.btnGray} ${st.btnGrayLight} `}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                >
                  Back
                </button> */}
                <button className={`btn ${st.btn4} ${st.btnGray} mx-0`} onClick={(e) => setErrorTrig(true)} disabled={loading || editLoading}>
                  {loading ? <LoadingBtn /> : 'Save'}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </form>

      <div className={`${st.newRedesign_main}`}>
        <Row>
          <Col md={6} className="mb-4">
            <div className={`${st.branchContact_tabBox}`}>
              <h4 className="mb-0">Sale creation date</h4>
              <p>This period indicates duration which promotion is visible to customer . Customer can create booking during this period only</p>
              <Row>
                <Col xl={9} lg={10}>
                  <Row className="g-3">
                    <Col md={6}>
                      <div className={`${st.reFormBox} ${st.label_Relative} mb-4`}>
                        <label htmlFor="creation-from">Date From</label>
                        <input id="creation-from" type="date" className='form-control' placeholder='DD-MM-YYYY'
                          min={getMinDate(!editPromotionData)}
                          {...register("selldateFrom", { required: singleValue !== "Dynamic Pricing" })}
                        />
                        {errors?.selldateFrom?.type === "required" && (
                          <p className={"errorMessage"}>This field is required</p>
                        )}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className={`${st.reFormBox} ${st.label_Relative} mb-4`}>
                        <label>Start Time</label>
                        <select className="form-select" value={time?.sellstartTimeFrom}
                          onChange={(e: any) => setTime({ ...time, sellstartTimeFrom: e.target.value })}>
                          <option value="">Select</option>
                          {timeValues?.map((item, i) => (
                            <option value={item.value} key={i}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                        {errrorTrig && singleValue !== "Dynamic Pricing" && time?.sellstartTimeFrom === "" && (
                          <p className={"errorMessage"}>This field is required</p>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xl={9} lg={10}>
                  <Row className="g-3">
                    <Col md={6}>
                      <div className={`${st.reFormBox} ${st.label_Relative}`}>
                        <label >Date To</label>
                        <input type="date" className='form-control' placeholder='DD-MM-YYYY'
                          min={watchNonOpHrs('selldateFrom', disablePastDate())}
                          {...register("selldateTo", { required: singleValue !== "Dynamic Pricing" })}
                        />
                        {errors?.selldateTo?.type === "required" && (
                          <p className={"errorMessage"}>This field is required</p>
                        )}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className={`${st.reFormBox} ${st.label_Relative}`}>
                        <label htmlFor="">End Time</label>
                        <select className="form-select"
                          value={time.sellendTimeTo}
                          onChange={(e: any) => setTime({ ...time, sellendTimeTo: e.target.value })}>
                          <option value="">Select</option>
                          {timeValues.filter((item: any) => saleDateCheck ? item.value > time.sellstartTimeFrom : true)?.map((item, i) => (
                            <option value={item.value} key={i}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                        {errrorTrig && singleValue !== "Dynamic Pricing" && time.sellendTimeTo === "" && (
                          <p className={"errorMessage"}>This field is required</p>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={6} className="mb-4">
            <div className={`${st.branchContact_tabBox}`}>
              <h4 className="mb-0">Booking creation date</h4>
              <p>Duration in which Services can be booked . This can be different from Sales creation date.</p>
              <Row>
                <Col xl={9} lg={10}>
                  <Row className="g-3">
                    <Col md={6}>
                      <div className={`${st.reFormBox} ${st.label_Relative} mb-4`}>
                        <label>Date From</label>
                        <input type="date" className='form-control' placeholder='DD-MM-YYYY' min={watchNonOpHrs('selldateFrom')}
                          {...register("dateFrom", { required: singleValue !== "Dynamic Pricing" })}
                        />
                        {errors?.dateFrom?.type === "required" && (
                          <p className={"errorMessage"}>This field is required</p>
                        )}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className={`${st.reFormBox} ${st.label_Relative} mb-4`}>
                        <label htmlFor="">Start Time</label>
                        <select className="form-select"
                          onChange={(e: any) => setTime({ ...time, startTime: e.target.value })} value={time.startTime}
                        >
                          <option value="">Select</option>
                          {timeValues?.map((item, i) => (
                            <option value={item.value} key={i}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                        {errrorTrig && singleValue !== "Dynamic Pricing" && time?.startTime === "" && (
                          <p className={"errorMessage"}>This field is required</p>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col xl={9} lg={10}>
                  <Row className="g-3">
                    <Col md={6}>
                      <div className={`${st.reFormBox} ${st.label_Relative}`}>
                        <label htmlFor="">Date To</label>
                        <input type="date" className='form-control' placeholder='DD-MM-YYYY' min={watchNonOpHrs('dateFrom', disablePastDate())}
                          {...register("dateTo", { required: singleValue !== "Dynamic Pricing" })}
                        />
                        {errors?.dateTo?.type === "required" && (
                          <p className={"errorMessage"}>This field is required</p>
                        )}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className={`${st.reFormBox} ${st.label_Relative}`}>
                        <label htmlFor="">End Time</label>
                        <select className="form-select"
                          value={time.endTime}
                          onChange={(e: any) => setTime({ ...time, endTime: e.target.value })}>
                          <option value="">Select</option>
                          {timeValues.filter((item: any) => dateCheck ? item.value > time.startTime : true)?.map((item, i) => (
                            <option value={item.value} key={i}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                        {errrorTrig && singleValue !== "Dynamic Pricing" && time.endTime === "" && (
                          <p className={"errorMessage"}>This field is required</p>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h3 className={`${st.outerBox_heading} mt-2`}>Promotion Settings</h3>
          </Col>
          <Col md={12} className="mb-4">
            <div className={`${st.branchContact_tabBox}`}>
              <Row>
                <Col md={6} className="mb-4">
                  <h4 className="mb-0">Promotion Available to</h4>
                  <p className="mb-2">Indicate the gender that the promotion applies to</p>

                  <div className={`${st.reFormBox}`}>
                    <div>
                      <div className={`${st.edit_publish_checkBox} p-0`}>
                        <label className={`d-inline-flex ${st.checkbox}`}>
                          <input type="checkbox" value='female' {...register("promotionProvidesCategory")} />
                          <span className={`${st.checkmark}`}></span>
                          <span className={`${st.labelName} ms-1`} style={{ fontSize: '14px', lineHeight: 'inherit' }}>Female</span>
                        </label>
                        <label className={`d-inline-flex ${st.checkbox}`}>
                          <input type="checkbox" value="male" {...register("promotionProvidesCategory")} />
                          <span className={`${st.checkmark}`}></span>
                          <span className={`${st.labelName} ms-1`} style={{ fontSize: '14px', lineHeight: 'inherit' }}>Male</span>
                        </label>
                        <label className={`d-inline-flex ${st.checkbox}`}>
                          <input type="checkbox" value="anyone" {...register("promotionProvidesCategory")} />
                          <span className={`${st.checkmark}`}></span>
                          <span className={`${st.labelName} ms-1`} style={{ fontSize: '14px', lineHeight: 'inherit' }}>Everyone</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6} className="mb-4">
                  <h4 className="mb-0">New Customer Only Promotion</h4>
                  <p className="mb-2">Specify if this promotion is available to new customers only.</p>
                  <div className={`${st.reFormBox}`}>
                    <div>
                      <div className={`${st.edit_publish_checkBox} p-0`}>
                        <label className={`d-inline-flex ${st.checkbox}`}>
                          <input type="checkbox" value={"newUser"}
                            {...register("forFirstTime")} />
                          <span className={`${st.checkmark}`}></span>
                          <span className={`${st.labelName} ms-1`} style={{ fontSize: '14px', lineHeight: 'inherit' }}>Only For New Customers</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6} className="pt-1">
                  <h4 className="mb-0">Max uses per this promotion</h4>
                  <p className="mb-2">Specify the maximum number of bookings allowed for this promotion</p>
                  <Col md={8}>
                    <div className={`${st.reFormBox} d-flex gap-2 align-items-center`} style={{marginLeft:'4px'}}>
                      <label style={{ whiteSpace: 'nowrap', fontWeight:'400', fontSize:'14px' }}>Maximum</label>
                      <input type="number" className='form-control' style={{minWidth:'55px'}}
                        value={settingData?.LimitNumberOfUses.maxUser}
                        onChange={(e: any) => setSettingData({ ...settingData, LimitNumberOfUses: { active: e.target.value === "" ? false : true, maxUser: e.target.value } })}

                      />
                      {trigger && settingData?.LimitNumberOfUses?.active && settingData?.LimitNumberOfUses?.maxUser === "" && (
                        <p className={"errorMessage"}>This field is required</p>
                      )}
                      <label style={{ whiteSpace: 'nowrap', fontWeight:'400', fontSize:'14px' }}>bookings allowed .</label>
                    </div>
                  </Col>
                </Col>
                <Col md={6} className="pt-1">
                  <h4 className="mb-0">Limit Per customer for this promotion</h4>
                  <p className="mb-2">Indicate the number of bookings a single customer can schedule with this promotion</p>
                  <Col md={6}>
                    <div className={`${st.reFormBox}`}>
                      <input type="number" className='form-control' placeholder='Enter Limit per Customer'
                        value={settingData?.LimitUsePerClient.maxUse}
                        onChange={(e: any) => setSettingData({ ...settingData, LimitUsePerClient: { ...settingData.LimitUsePerClient, maxUse: e.target.value } })}
                      />
                      {trigger && settingData?.LimitUsePerClient?.active && settingData?.LimitUsePerClient?.maxUse === "" && (
                        <p className={"errorMessage"}>This field is required</p>
                      )}
                    </div>
                  </Col>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
