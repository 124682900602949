import React, { useEffect, useState } from "react";
import st from "../../../assets/stylesheet/style.module.scss";
import cx from "./index.module.scss";
import { plus } from "../../../assets/images";
import DataGridTable from "./DataGrid";
import { ExportBtn, ImportBtn } from "../../../components/Business/Buttons";
import AssignPage from "../../../components/Business/Modals/AssignPage";
import { NavLink, useNavigate } from "react-router-dom";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { BiGridAlt } from "react-icons/bi";
import { FiList } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";
import AddCustomerCanvas from "../../../components/Business/OffCanvas/AddCustomerCanvas";
import BreadCrumbs from "../../../components/Business/BreadCrumbs/BreadCrumbs";

const Clients = () => {
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  const { mainModuleRoles } = useSelector((state: any) => state.BranchReducer);
  const navigate = useNavigate()
  const [showAssignModal, setShowAssignModal] = useState(false);
  const handleAssignModalClose = () => setShowAssignModal(false);
  const handleAssignModalShow = () => setShowAssignModal(true);
  const [clientData, setClientData] = useState<any[]>([]);

  const [showAddCustomerCanvas, setAddCustomerCanvasShow] = useState(false);
  const handleAddCustomerCanvasClose = () => {
    navigate('/business/clients');
    getClientData()
    setAddCustomerCanvasShow(false)
  }
  const handleAddCustomerCanvasShow = () => setAddCustomerCanvasShow(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (businessData) {
      getClientData();
    }
  }, [businessData]);

  const getClientData = async () => {
    let res = await httpRequest(`getClient?businessId=${businessData?._id}&type=list`, "get", null, "json");
    if (res.status) {
      setClientData(res.data);
    }
  };

  const canCreateClient = mainModuleRoles?.Customer?.Create;

  return (
    <>
      <section className={`${st.pageWrapper}`}>
        {/* <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>Client List</h5>
          </div>
          <div className={`${st.rightInfo}`}></div>
        </div> */}
        <div className={`${st.newRedesign_main}`}>
          <Row>
            <Col md={12}>
              <div className={`${st.head_paraBox} ${st.pt50}`}>
                <h2>Customer List</h2>
                <p>Add the team members . You can differentiate team member providing customer
                  service and support service by check box selection in team title</p>
              </div>
            </Col>
            {/* <Col md={12}>
              <div className={`${st.gridList_view} justify-content-end mb-4`}>
                <button className="btn grid active">
                  <BiGridAlt />
                </button>
                <button className="btn listView">
                  <FiList />
                </button>
              </div>
            </Col> */}
          </Row>
        </div>

        <div className={`${st.tablePadding_shadow}`}>
          <div className={`${st.buttonsAction} ${cx.actionBtn}`}>
            {canCreateClient && <ul>
              <li>
                <ExportBtn />
              </li>
              <li>
                <ImportBtn />
              </li>
              <li>
                <NavLink to="/business/clients/addClient" onClick={() => {
                  handleAddCustomerCanvasShow()
                }} className={`btn ${st.darkBtn}`}>
                  <FaPlus />  Add a new Customer
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="addClient" className={`btn ${st.darkBtn}`}>
                  <FaPlus />  Add a new Customer
                </NavLink>
              </li> */}
              {/* <li>
              <NavLink
                to="#"
                className={`btn ${st.darkBtn}`}
                onClick={handleAssignModalShow}
              >
                <img src={plus} alt="Assign Team" /> Assign Client
              </NavLink>
            </li> */}
            </ul>}
          </div>

          <div className={`${st.tableBody}`}>
            <div className={`${st.tableBodyIn}`}>
              <DataGridTable clientData={clientData} getClient={getClientData} handleAddCustomerCanvasShow={handleAddCustomerCanvasShow} />
            </div>
          </div>
        </div>
      </section>
      <AssignPage
        showAssignModal={showAssignModal}
        handleAssignModalClose={handleAssignModalClose}
        page={"Client"}
      />

      <AddCustomerCanvas show={showAddCustomerCanvas} handleAddCustomerCanvasClose={handleAddCustomerCanvasClose} />
    </>
  );
};

export default Clients;
