import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Accordion, Button, Col, Modal, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  massage,
  hair,
  lashes,
  nails,
  facilities,
  waxfill,
  makeupfill,
} from "../../../assets/images";
import { FiSearch } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { Switch } from "@mui/material";
import { httpRequest } from "../../../Apis/commonApis";

const AddPackageModal = (props: any) => {
  const [term, setTerm] = useState("");
  let {
    showAddCategory,
    handleAddCategoryClose,
    setSaleModalShow,
    service,
    packageData,
    setPackageeData,
  } = props;

  const [count, setCount] = useState(0);
  const [showBranch, setshowBranch] = useState<any[]>([]);
  const [packageList, setPackageList] = useState<any>([])
  useEffect(() => {
    if (packageData?.length !== 0) {
      setPackageList(packageData)
    }
  }, [packageData])

  const label = { inputProps: { "aria-label": "Switch demo" } };
  let countincrement = (e: any) => {
    let arr: any = [...showBranch];
    arr?.push(e);
    setshowBranch(arr);
    setCount(count + 1);
  };
  let countdincrement = (e: any) => {
    let filteredArray = showBranch?.filter((obj) => obj?._id !== e?._id);

    setshowBranch(filteredArray);
    setCount(count - 1);
  };
  let clear = () => {
    setshowBranch([]);
  };
  const [isChecked, setIsChecked] = useState<any>([]);
  const [serviceData, setServiceData] = useState<any>([]);

  let savePackage = () => {
    setPackageeData((prev:any) => [...prev, ...serviceData]);
  };
  // Function to toggle the checkbox state
  const toggleCheckbox = (e: any, val: any) => {
    const check = e.target.checked;
    if (check) {
      setIsChecked([...isChecked, e.target.value]);
      setServiceData([...serviceData, val]);
    } else {
      let filteredArray = serviceData?.filter(
        (obj: any) => obj?._id !== val?._id
      );
      let checkdata = isChecked?.filter((obj: any) => obj !== e.target.value);
      setServiceData(filteredArray);
      setIsChecked(checkdata);
    }
  };

  useEffect(() => {
    if (term !== "") {
      const filterPackage = packageData?.filter((data: any) => {
        return `${data?.PackageName}`?.toLocaleLowerCase()?.includes(term?.toLocaleLowerCase())

      })
      setPackageList(filterPackage)
    } else {
      setPackageList(packageData)
    }
  }, [term])

  return (
    <>
      <Modal
        scrollable
        centered
        backdrop="static"
        keyboard={false}
        show={showAddCategory}
        onHide={handleAddCategoryClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={`${m.modalCts} ${m.modalSm} ${m.ModalHeaderBG}`}
      >
        <Modal.Header>
          <Modal.Title>
            <NavLink
              to="#"
              onClick={() => {
                handleAddCategoryClose();
                setSaleModalShow && setSaleModalShow(true);
              }}
            >
              <svg
                width="38"
                height="37"
                viewBox="0 0 38 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z"
                  fill="#3C746D"
                ></path>
                <path
                  d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z"
                  stroke="#ACD9D0"
                ></path>
                <path
                  d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z"
                  stroke="#3C746D"
                ></path>
                <path
                  d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z"
                  fill="#3C746D"
                ></path>
                <path
                  d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z"
                  stroke="#ACD9D0"
                ></path>
                <path
                  d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z"
                  stroke="#3C746D"
                ></path>
              </svg>
            </NavLink>
            Select Package
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Row>
            <Col md={12}>
              <div className={`${m.searchBox}`}>
                <input
                  className="form-control"
                  type="search"
                  placeholder="Search by name"
                  onChange={(e: any) => setTerm(e.target.value)}
                  value={term}
                />
                <FiSearch />
              </div>
            </Col>
            <Accordion className={`${m.serviceListCard}`}>
              {packageList?.map((Val: any, index: any) => {

                return (
                  <Accordion.Item eventKey={index} key={Val?._id}>
                    <Accordion.Header>
                      {/* {val?.serviceName} */}
                      {Val?.PackageName}
                    </Accordion.Header>
                    {/* {val?.Services?.map((list:any)=>{ */}
                    {/* return( */}
                    <Accordion.Body>
                      <ul>
                        <li>
                          <div className={`${m.bodyLeft}`}>
                            <p>{Val?.typeOfService?.type ? `Package Type : ${Val?.typeOfService?.type}` : ""}</p>
                            <p>{Val?.finalPrice ? `Package Regular Price : $${Val?.finalPrice}` : ""}</p>
                            <p>{Val?.typeOfService?.memberPrice ? `Package Member Price : $${Val?.typeOfService?.memberPrice}` : ""}</p>
                            <ul className={m.list}>
                              {Val?.Services?.map((service: any) => {
                                const priceType = `${service?.priceId?.seasonType?.split("Price")[0][0]?.toLocaleUpperCase()}${service?.priceId?.seasonType?.split("Price")[0]?.slice(1)} Price`;
                                return (
                                  <li key={service?._id}>{`${service?.priceId?.serviceId?.serviceName ? `${service?.priceId?.serviceId?.serviceName} -` : ""} ${priceType} ${service?.priceId?.seasonPrice ? `: $${service?.priceId?.seasonPrice}` : "$0"}`}</li>
                                )
                              })}
                            </ul>


                            {/* <p>
                              {Val?.typeOfService?.regularPrice ? `Regular Price : $${Val?.typeOfService?.regularPrice}` : ""}
                            </p>
                            <p>
                              {Val?.typeOfService?.memberPrice ? `Member Price : $${Val?.typeOfService?.memberPrice}` : ""}
                            </p> */}
                          </div>
                          <div className={`${m.bodyRight}`}>
                            <label className={`d-block ${st.checkbox} mb-4`}>
                              <input type="checkbox" value={Val._id} onClick={(e: any) => { toggleCheckbox(e, Val) }} />
                              <span className={`${st.checkmark}`}></span>
                            </label>
                            {/* <input type="checkbox" checked={isChecked} id={list?._id} name="service" onChange={toggleCheckbox}/> */}
                            {/* <div className={`${m.btnGroup}`}>
                            <button className="btn" onClick={() => countincrement(val?.serviceId)}>+</button>
                            <span>|</span>
                            <button className="btn" onClick={()=> countdincrement(val?.serviceId)}>-</button>
                          </div>
                          <button className={`${m.signleBtn} btn`}>{count}</button> */}
                          </div>
                        </li>
                        {/* <li>
                        <div className={`${m.bodyLeft}`}>
                          <h5>Hair extension</h5>
                          <p>1 hour 30 mins</p>
                        </div>
                        <div className={`${m.bodyRight}`}>
                          <div className={`${m.btnGroup}`}>
                            <button className="btn">+</button>
                            <span>|</span>
                            <button className="btn">-</button>
                          </div>
                          <button className={`${m.signleBtn} btn`}>1</button>
                        </div>
                      </li> */}
                      </ul>
                    </Accordion.Body>
                    {/* ) */}
                    {/* })} */}
                  </Accordion.Item>
                );
              })}
              {/* <Accordion.Item eventKey="0">
                <Accordion.Header>
                Makeup<span>(13)</span>
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      <div className={`${m.bodyLeft}`}>
                        <h5>Hair extension</h5>
                        <p>1 hour 30 mins</p>
                      </div>
                      <div className={`${m.bodyRight}`}>
                        <div className={`${m.btnGroup}`}>
                          <button className="btn" onClick={countincrement}>+</button>
                          <span>|</span>
                          <button className="btn" onClick={countdincrement}>-</button>
                        </div>
                        <button className={`${m.signleBtn} btn`}>{count}</button>
                      </div>
                    </li>
                    <li>
                      <div className={`${m.bodyLeft}`}>
                        <h5>Hair extension</h5>
                        <p>1 hour 30 mins</p>
                      </div>
                      <div className={`${m.bodyRight}`}>
                        <div className={`${m.btnGroup}`}>
                          <button className="btn">+</button>
                          <span>|</span>
                          <button className="btn">-</button>
                        </div>
                        <button className={`${m.signleBtn} btn`}>1</button>
                      </div>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item> */}
              {/* <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Hair <span>(13)</span>
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      <div className={`${m.bodyLeft}`}>
                        <h5>Hair extension</h5>
                        <p>1 hour 30 mins</p>
                      </div>
                      <div className={`${m.bodyRight}`}>
                        <div className={`${m.btnGroup}`}>
                          <button className="btn">+</button>
                          <span>|</span>
                          <button className="btn">-</button>
                        </div>
                        <button className={`${m.signleBtn} btn`}>1</button>
                      </div>
                    </li>
                    <li>
                      <div className={`${m.bodyLeft}`}>
                        <h5>Hair extension</h5>
                        <p>1 hour 30 mins</p>
                      </div>
                      <div className={`${m.bodyRight}`}>
                        <div className={`${m.btnGroup}`}>
                          <button className="btn">+</button>
                          <span>|</span>
                          <button className="btn">-</button>
                        </div>
                        <button className={`${m.signleBtn} btn`}>1</button>
                      </div>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item> */}
            </Accordion>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className={`btn ${m.cancelBtn}`}
            onClick={() => {
              handleAddCategoryClose();
              clear();
              setSaleModalShow && setSaleModalShow(true);
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`btn ${m.actionBtn}`}
            onClick={() => {
              handleAddCategoryClose();
              savePackage();
            }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddPackageModal;
