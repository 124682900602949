import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Tab, Nav, Row, Dropdown } from 'react-bootstrap';
import { editButton, deleteIcon, copyClipBoard, upArrow, iconLogoGrey } from '../../../assets/images';
import cx from "./index.module.scss";
import { AiFillCamera } from 'react-icons/ai';
import { RxCross2 } from 'react-icons/rx';
import HoverEyeContent from '../HoverEyeContent/HoverEyeContent';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, Value } from 'react-phone-number-input'
import { FaPlus } from 'react-icons/fa';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { NavLink, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import BundleRoute from './BundleCompoenent/BundleRoute';
import { httpRequest } from '../../../Apis/commonApis';
import { useSelector } from 'react-redux';
import { getDynamicURIName } from '../../../Utils/Funcs';
import BundleBodyInside from './BundleBodyInside';

function BundleCanvas(props: any) {
    let { show, handleBundleCanvasClose } = props;
    return (
        <>
            <div className={`${st.offcanvas_newPages1}`}>
                <Offcanvas show={show} onHide={handleBundleCanvasClose} placement="end" className="newOffcanvas_width">
                    {show && <BundleBodyInside {...props}/>}
                </Offcanvas>
            </div>
        </>
    )
}

export default BundleCanvas;