import { disablePastDate } from "../Utils/Funcs";

export const timeValues = [
  { value: "00:00", label: "00:00" },
  { value: "00:15", label: "00:15" },
  { value: "00:30", label: "00:30" },
  { value: "00:45", label: "00:45" },
  { value: "01:00", label: "01:00" },
  { value: "01:15", label: "01:15" },
  { value: "01:30", label: "01:30" },
  { value: "01:45", label: "01:45" },
  { value: "02:00", label: "02:00" },
  { value: "02:15", label: "02:15" },
  { value: "02:30", label: "02:30" },
  { value: "02:45", label: "02:45" },
  { value: "03:00", label: "03:00" },
  { value: "03:15", label: "03:15" },
  { value: "03:30", label: "03:30" },
  { value: "03:45", label: "03:45" },
  { value: "04:00", label: "04:00" },
  { value: "04:15", label: "04:15" },
  { value: "04:30", label: "04:30" },
  { value: "04:45", label: "04:45" },
  { value: "05:00", label: "05:00" },
  { value: "05:15", label: "05:15" },
  { value: "05:30", label: "05:30" },
  { value: "05:45", label: "05:45" },
  { value: "06:00", label: "06:00" },
  { value: "06:15", label: "06:15" },
  { value: "06:30", label: "06:30" },
  { value: "06:45", label: "06:45" },
  { value: "07:00", label: "07:00" },
  { value: "07:15", label: "07:15" },
  { value: "07:30", label: "07:30" },
  { value: "07:45", label: "07:45" },
  { value: "08:00", label: "08:00" },
  { value: "08:15", label: "08:15" },
  { value: "08:30", label: "08:30" },
  { value: "08:45", label: "08:45" },
  { value: "09:00", label: "09:00" },
  { value: "09:15", label: "09:15" },
  { value: "09:30", label: "09:30" },
  { value: "09:45", label: "09:45" },
  { value: "10:00", label: "10:00" },
  { value: "10:15", label: "10:15" },
  { value: "10:30", label: "10:30" },
  { value: "10:45", label: "10:45" },
  { value: "11:00", label: "11:00" },
  { value: "11:15", label: "11:15" },
  { value: "11:30", label: "11:30" },
  { value: "11:45", label: "11:45" },
  { value: "12:00", label: "12:00" },
  { value: "12:15", label: "12:15" },
  { value: "12:30", label: "12:30" },
  { value: "12:45", label: "12:45" },
  { value: "13:00", label: "13:00" },
  { value: "13:15", label: "13:15" },
  { value: "13:30", label: "13:30" },
  { value: "13:45", label: "13:45" },
  { value: "14:00", label: "14:00" },
  { value: "14:15", label: "14:15" },
  { value: "14:30", label: "14:30" },
  { value: "14:45", label: "14:45" },
  { value: "15:00", label: "15:00" },
  { value: "15:15", label: "15:15" },
  { value: "15:30", label: "15:30" },
  { value: "15:45", label: "15:45" },
  { value: "16:00", label: "16:00" },
  { value: "16:15", label: "16:15" },
  { value: "16:30", label: "16:30" },
  { value: "16:45", label: "16:45" },
  { value: "17:00", label: "17:00" },
  { value: "17:15", label: "17:15" },
  { value: "17:30", label: "17:30" },
  { value: "17:45", label: "17:45" },
  { value: "18:00", label: "18:00" },
  { value: "18:15", label: "18:15" },
  { value: "18:30", label: "18:30" },
  { value: "18:45", label: "18:45" },
  { value: "19:00", label: "19:00" },
  { value: "19:15", label: "19:15" },
  { value: "19:30", label: "19:30" },
  { value: "19:45", label: "19:45" },
  { value: "20:00", label: "20:00" },
  { value: "20:15", label: "20:15" },
  { value: "20:30", label: "20:30" },
  { value: "20:45", label: "20:45" },
  { value: "21:00", label: "21:00" },
  { value: "21:15", label: "21:15" },
  { value: "21:30", label: "21:30" },
  { value: "21:45", label: "21:45" },
  { value: "22:00", label: "22:00" },
  { value: "22:15", label: "22:15" },
  { value: "22:30", label: "22:30" },
  { value: "22:45", label: "22:45" },
  { value: "23:00", label: "23:00" },
  { value: "23:15", label: "23:15" },
  { value: "23:30", label: "23:30" },
  { value: "23:45", label: "23:45" },
];

export const quarterlyTimeValues = [
  { value: "00:00", label: "00 : 00" },
  { value: "00:15", label: "00 : 15" },
  { value: "00:30", label: "00 : 30" },
  { value: "00:45", label: "00 : 45" },

  { value: "01:00", label: "01 : 00" },
  { value: "01:15", label: "01 : 15" },
  { value: "01:30", label: "01 : 30" },
  { value: "01:45", label: "01 : 45" },

  { value: "02:00", label: "02 : 00" },
  { value: "02:15", label: "02 : 15" },
  { value: "02:30", label: "02 : 30" },
  { value: "02:45", label: "02 : 45" },

  { value: "03:00", label: "03 : 00" },
  { value: "03:15", label: "03 : 15" },
  { value: "03:30", label: "03 : 30" },
  { value: "03:45", label: "03 : 45" },

  { value: "04:00", label: "04 : 00" },
  { value: "04:15", label: "04 : 15" },
  { value: "04:30", label: "04 : 30" },
  { value: "04:45", label: "04 : 45" },

  { value: "05:00", label: "05 : 00" },
  { value: "05:15", label: "05 : 15" },
  { value: "05:30", label: "05 : 30" },
  { value: "05:45", label: "05 : 45" },

  { value: "06:00", label: "06 : 00" },
  { value: "06:15", label: "06 : 15" },
  { value: "06:30", label: "06 : 30" },
  { value: "06:45", label: "06 : 45" },

  { value: "07:00", label: "07 : 00" },
  { value: "07:15", label: "07 : 15" },
  { value: "07:30", label: "07 : 30" },
  { value: "07:45", label: "07 : 45" },

  { value: "08:00", label: "08 : 00" },
  { value: "08:15", label: "08 : 15" },
  { value: "08:30", label: "08 : 30" },
  { value: "08:45", label: "08 : 45" },

  { value: "09:00", label: "09 : 00" },
  { value: "09:15", label: "09 : 15" },
  { value: "09:30", label: "09 : 30" },
  { value: "09:45", label: "09 : 45" },

  { value: "10:00", label: "10 : 00" },
  { value: "10:15", label: "10 : 15" },
  { value: "10:30", label: "10 : 30" },
  { value: "10:45", label: "10 : 45" },

  { value: "11:00", label: "11 : 00" },
  { value: "11:15", label: "11 : 15" },
  { value: "11:30", label: "11 : 30" },
  { value: "11:45", label: "11 : 45" },

  { value: "12:00", label: "12 : 00" },
  { value: "12:15", label: "12 : 15" },
  { value: "12:30", label: "12 : 30" },
  { value: "12:45", label: "12 : 45" },

  { value: "13:00", label: "13 : 00" },
  { value: "13:15", label: "13 : 15" },
  { value: "13:30", label: "13 : 30" },
  { value: "13:45", label: "13 : 45" },

  { value: "14:00", label: "14 : 00" },
  { value: "14:15", label: "14 : 15" },
  { value: "14:30", label: "14 : 30" },
  { value: "14:45", label: "14 : 45" },

  { value: "15:00", label: "15 : 00" },
  { value: "15:15", label: "15 : 15" },
  { value: "15:30", label: "15 : 30" },
  { value: "15:45", label: "15 : 45" },

  { value: "16:00", label: "16 : 00" },
  { value: "16:15", label: "16 : 15" },
  { value: "16:30", label: "16 : 30" },
  { value: "16:45", label: "16 : 45" },

  { value: "17:00", label: "17 : 00" },
  { value: "17:15", label: "17 : 15" },
  { value: "17:30", label: "17 : 30" },
  { value: "17:45", label: "17 : 45" },

  { value: "18:00", label: "18 : 00" },
  { value: "18:15", label: "18 : 15" },
  { value: "18:30", label: "18 : 30" },
  { value: "18:45", label: "18 : 45" },

  { value: "19:00", label: "19 : 00" },
  { value: "19:15", label: "19 : 15" },
  { value: "19:30", label: "19 : 30" },
  { value: "19:45", label: "19 : 45" },

  { value: "20:00", label: "20 : 00" },
  { value: "20:15", label: "20 : 15" },
  { value: "20:30", label: "20 : 30" },
  { value: "20:45", label: "20 : 45" },

  { value: "21:00", label: "21 : 00" },
  { value: "21:15", label: "21 : 15" },
  { value: "21:30", label: "21 : 30" },
  { value: "21:45", label: "21 : 45" },

  { value: "22:00", label: "22 : 00" },
  { value: "22:15", label: "22 : 15" },
  { value: "22:30", label: "22 : 30" },
  { value: "22:45", label: "22 : 45" },

  { value: "23:00", label: "23 : 00" },
  { value: "23:15", label: "23 : 15" },
  { value: "23:30", label: "23 : 30" },
  { value: "23:45", label: "23 : 45" },
];

export const timeQuarterlyValue = [
  { value: "", label: "Select" },
  { value: "15", label: "15m" },
  { value: "30", label: "30m" },
  { value: "45", label: "45m" },
  { value: "60", label: "1h" },
  { value: "75", label: "1h : 15m" },
  { value: "90", label: "1h : 30m" },
  { value: "105", label: "1h : 45m" },
  { value: "120", label: "2h" },
  { value: "135", label: "2h : 15m" },
  { value: "150", label: "2h : 30m" },
  { value: "165", label: "2h : 45m" },
  { value: "180", label: "3h" },
  { value: "195", label: "3h : 15m" },
  { value: "210", label: "3h : 30m" },
  { value: "225", label: "3h : 45m" },
  { value: "240", label: "4h" },
  { value: "255", label: "4h : 15m" },
  { value: "270", label: "4h : 30m" },
  { value: "285", label: "4h : 45m" },
  { value: "300", label: "5h" },
  { value: "315", label: "5h : 15m" },
  { value: "330", label: "5h : 30m" },
  { value: "345", label: "5h : 45m" },
  { value: "360", label: "6h" },
  { value: "420", label: "7h" },
  { value: "480", label: "8h" },
  { value: "540", label: "9h" },
  { value: "600", label: "10h" },


]
export const weekValues = [
  { value: "monday", label: "Monday" },
  { value: "tuesday", label: "Tuesday" },
  { value: "wednesday", label: "Wednesday" },
  { value: "thursday", label: "Thursday" },
  { value: "friday", label: "Friday" },
  { value: "saturday", label: "Saturday" },
  { value: "sunday", label: "Sunday" }
];

export const shiftValues = [
  { value: "Shift 1", label: "shift1" },
  { value: "Shift 2", label: "shift2" },
  // { value: "Shift 3", label: "shift3" },
];

export const accessRoleValues = [
  { value: "", label: "--Select--" },
  { value: "Admin", label: "Admin" },
  { value: "FrontDesk", label: "Front Desk" },
  { value: "Accounts", label: "Accounts" },
  { value: "Staff", label: "Staff" },
];

export function formatDateFormat(originalDate: string) {
  if (!originalDate) {
    return
  }
  // Split the original date string by '-'
  var parts = originalDate.split('-');

  // Rearrange the parts in the desired order
  var formattedDate = parts[2] + '-' + parts[1] + '-' + parts[0];

  return formattedDate;
}

export default function convertHoursToHoursAndMinutes(hours: any) {
  if (hours) {
    const absoluteHour = hours < 0 ? 0 : hours
    var wholeHours = Math.floor(absoluteHour);
    var fractionalPart = String(absoluteHour.toFixed(2)).split(".")[1]//absoluteHour - wholeHours;
    var minutes = fractionalPart
    return `${wholeHours + " h "} ${" "} ${minutes ? minutes + " m " : ""}`;
  } else {
    return "0 h"
  }
}

// avg Review 
export function averageReview(reviewList: any) {
  const avgReview = reviewList && reviewList?.length !== 0 ? reviewList?.reduce((acc: any, review: any) => {
    if (Number.parseInt(review?.rating)) {
      return Number.parseFloat(acc + Number.parseFloat(review?.rating) / reviewList?.length)
    } else {
      return acc
    }
  }, 0.0) : 0.0;
  return avgReview
}
export const accessBranchRoleValues = [
  // { value: "", label: "--Select--" },
  { value: "Admin", label: "Admin" },
  { value: "FrontDesk", label: "Front Desk" },
  { value: "Accounts", label: "Accounts" },
  { value: "Staff", label: "Staff" },
];

export const pricetypeArr = [
  { value: 'regularPrice', label: 'Regular Price' },
  { value: 'introductionPrice', label: 'Introduction Price' },
  // { value: 'memberPrice', label: 'Member Price' },
  { value: 'offPeakPrice', label: 'Off Peak Price' },
  { value: 'complementaryPrice', label: 'Complementary' },
];

// country code with country 
export const countries = [
  { code: 'AF', name: 'Afghanistan' },
  { code: 'AL', name: 'Albania' },
  { code: 'DZ', name: 'Algeria' },
  { code: 'AD', name: 'Andorra' },
  { code: 'AO', name: 'Angola' },
  { code: 'AR', name: 'Argentina' },
  { code: 'AM', name: 'Armenia' },
  { code: 'AU', name: 'Australia' },
  { code: 'AT', name: 'Austria' },
  { code: 'AZ', name: 'Azerbaijan' },
  { code: 'BS', name: 'Bahamas' },
  { code: 'BH', name: 'Bahrain' },
  { code: 'BD', name: 'Bangladesh' },
  { code: 'BB', name: 'Barbados' },
  { code: 'BY', name: 'Belarus' },
  { code: 'BE', name: 'Belgium' },
  { code: 'BZ', name: 'Belize' },
  { code: 'BJ', name: 'Benin' },
  { code: 'BT', name: 'Bhutan' },
  { code: 'BO', name: 'Bolivia' },
  { code: 'BA', name: 'Bosnia and Herzegovina' },
  { code: 'BW', name: 'Botswana' },
  { code: 'BR', name: 'Brazil' },
  { code: 'BN', name: 'Brunei' },
  { code: 'BG', name: 'Bulgaria' },
  { code: 'BF', name: 'Burkina Faso' },
  { code: 'BI', name: 'Burundi' },
  { code: 'KH', name: 'Cambodia' },
  { code: 'CM', name: 'Cameroon' },
  { code: 'CA', name: 'Canada' },
  { code: 'CV', name: 'Cape Verde' },
  { code: 'CF', name: 'Central African Republic' },
  { code: 'TD', name: 'Chad' },
  { code: 'CL', name: 'Chile' },
  { code: 'CN', name: 'China' },
  { code: 'CO', name: 'Colombia' },
  { code: 'KM', name: 'Comoros' },
  { code: 'CD', name: 'Democratic Republic of the Congo' },
  { code: 'CG', name: 'Republic of the Congo' },
  { code: 'CR', name: 'Costa Rica' },
  { code: 'HR', name: 'Croatia' },
  { code: 'CU', name: 'Cuba' },
  { code: 'CY', name: 'Cyprus' },
  { code: 'CZ', name: 'Czech Republic' },
  { code: 'DK', name: 'Denmark' },
  { code: 'DJ', name: 'Djibouti' },
  { code: 'DM', name: 'Dominica' },
  { code: 'DO', name: 'Dominican Republic' },
  { code: 'TL', name: 'East Timor (Timor-Leste)' },
  { code: 'EC', name: 'Ecuador' },
  { code: 'EG', name: 'Egypt' },
  { code: 'SV', name: 'El Salvador' },
  { code: 'GQ', name: 'Equatorial Guinea' },
  { code: 'ER', name: 'Eritrea' },
  { code: 'EE', name: 'Estonia' },
  { code: 'ET', name: 'Ethiopia' },
  { code: 'FJ', name: 'Fiji' },
  { code: 'FI', name: 'Finland' },
  { code: 'FR', name: 'France' },
  { code: 'GA', name: 'Gabon' },
  { code: 'GM', name: 'Gambia' },
  { code: 'GE', name: 'Georgia' },
  { code: 'DE', name: 'Germany' },
  { code: 'GH', name: 'Ghana' },
  { code: 'GR', name: 'Greece' },
  { code: 'GD', name: 'Grenada' },
  { code: 'GT', name: 'Guatemala' },
  { code: 'GN', name: 'Guinea' },
  { code: 'GW', name: 'Guinea-Bissau' },
  { code: 'GY', name: 'Guyana' },
  { code: 'HT', name: 'Haiti' },
  { code: 'HN', name: 'Honduras' },
  { code: 'HU', name: 'Hungary' },
  { code: 'IS', name: 'Iceland' },
  { code: 'IN', name: 'India' },
  { code: 'ID', name: 'Indonesia' },
  { code: 'IR', name: 'Iran' },
  { code: 'IQ', name: 'Iraq' },
  { code: 'IE', name: 'Ireland' },
  { code: 'IL', name: 'Israel' },
  { code: 'IT', name: 'Italy' },
  { code: 'CI', name: 'Ivory Coast' },
  { code: 'JM', name: 'Jamaica' },
  { code: 'JP', name: 'Japan' },
  { code: 'JO', name: 'Jordan' },
  { code: 'KZ', name: 'Kazakhstan' },
  { code: 'KE', name: 'Kenya' },
  { code: 'KI', name: 'Kiribati' },
  { code: 'KP', name: 'North Korea' },
  { code: 'KR', name: 'South Korea' },
  { code: 'KW', name: 'Kuwait' },
  { code: 'KG', name: 'Kyrgyzstan' },
  { code: 'LA', name: 'Laos' },
  { code: 'LV', name: 'Latvia' },
  { code: 'LB', name: 'Lebanon' },
  { code: 'LS', name: 'Lesotho' },
  { code: 'LR', name: 'Liberia' },
  { code: 'LY', name: 'Libya' },
  { code: 'LI', name: 'Liechtenstein' },
  { code: 'LT', name: 'Lithuania' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'MK', name: 'North Macedonia' },
  { code: 'MG', name: 'Madagascar' },
  { code: 'MW', name: 'Malawi' },
  { code: 'MY', name: 'Malaysia' },
  { code: 'MV', name: 'Maldives' },
  { code: 'ML', name: 'Mali' },
  { code: 'MT', name: 'Malta' },
  { code: 'MH', name: 'Marshall Islands' },
  { code: 'MR', name: 'Mauritania' },
  { code: 'MU', name: 'Mauritius' },
  { code: 'MX', name: 'Mexico' },
  { code: 'FM', name: 'Micronesia' },
  { code: 'MD', name: 'Moldova' },
  { code: 'MC', name: 'Monaco' },
  { code: 'MN', name: 'Mongolia' },
  { code: 'ME', name: 'Montenegro' },
  { code: 'MA', name: 'Morocco' },
  { code: 'MZ', name: 'Mozambique' },
  { code: 'MM', name: 'Myanmar (Burma)' },
  { code: 'NA', name: 'Namibia' },
  { code: 'NR', name: 'Nauru' },
  { code: 'NP', name: 'Nepal' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'NZ', name: 'New Zealand' },
  { code: 'NI', name: 'Nicaragua' },
  { code: 'NE', name: 'Niger' },
  { code: 'NG', name: 'Nigeria' },
  { code: 'NO', name: 'Norway' },
  { code: 'OM', name: 'Oman' },
  { code: 'PK', name: 'Pakistan' },
  { code: 'PW', name: 'Palau' },
  { code: 'PS', name: 'Palestine' },
  { code: 'PA', name: 'Panama' },
  { code: 'PG', name: 'Papua New Guinea' },
  { code: 'PY', name: 'Paraguay' },
  { code: 'PE', name: 'Peru' },
  { code: 'PH', name: 'Philippines' },
  { code: 'PL', name: 'Poland' },
  { code: 'PT', name: 'Portugal' },
  { code: 'QA', name: 'Qatar' },
  { code: 'RO', name: 'Romania' },
  { code: 'RU', name: 'Russia' },
  { code: 'RW', name: 'Rwanda' },
  { code: 'KN', name: 'Saint Kitts and Nevis' },
  { code: 'LC', name: 'Saint Lucia' },
  { code: 'VC', name: 'Saint Vincent and the Grenadines' },
  { code: 'WS', name: 'Samoa' },
  { code: 'SM', name: 'San Marino' },
  { code: 'ST', name: 'Sao Tome and Principe' },
  { code: 'SA', name: 'Saudi Arabia' },
  { code: 'SN', name: 'Senegal' },
  { code: 'RS', name: 'Serbia' },
  { code: 'SC', name: 'Seychelles' },
  { code: 'SL', name: 'Sierra Leone' },
  { code: 'SG', name: 'Singapore' },
  { code: 'SK', name: 'Slovakia' },
  { code: 'SI', name: 'Slovenia' },
  { code: 'SB', name: 'Solomon Islands' },
  { code: 'SO', name: 'Somalia' },
  { code: 'ZA', name: 'South Africa' },
  { code: 'SS', name: 'South Sudan' },
  { code: 'ES', name: 'Spain' },
  { code: 'LK', name: 'Sri Lanka' },
  { code: 'SD', name: 'Sudan' },
  { code: 'SR', name: 'Suriname' },
  { code: 'SZ', name: 'Eswatini' },
  { code: 'SE', name: 'Sweden' },
  { code: 'CH', name: 'Switzerland' },
  { code: 'SY', name: 'Syria' },
  { code: 'TW', name: 'Taiwan' },
  { code: 'TJ', name: 'Tajikistan' },
  { code: 'TZ', name: 'Tanzania' },
  { code: 'TH', name: 'Thailand' },
  { code: 'TG', name: 'Togo' },
  { code: 'TO', name: 'Tonga' },
  { code: 'TT', name: 'Trinidad and Tobago' },
  { code: 'TN', name: 'Tunisia' },
  { code: 'TR', name: 'Turkey' },
  { code: 'TM', name: 'Turkmenistan' },
  { code: 'TV', name: 'Tuvalu' },
  { code: 'UG', name: 'Uganda' },
  { code: 'UA', name: 'Ukraine' },
  { code: 'AE', name: 'United Arab Emirates' },
  { code: 'GB', name: 'United Kingdom' },
  { code: 'US', name: 'United States' },
  { code: 'UY', name: 'Uruguay' },
  { code: 'UZ', name: 'Uzbekistan' },
  { code: 'VU', name: 'Vanuatu' },
  { code: 'VA', name: 'Vatican City' },
  { code: 'VE', name: 'Venezuela' },
  { code: 'VN', name: 'Vietnam' },
  { code: 'YE', name: 'Yemen' },
  { code: 'ZM', name: 'Zambia' },
  { code: 'ZW', name: 'Zimbabwe' }
];

export const formatTime = (time: any) => {
  if (time) {
    const [hours, minutes] = time.split(':')
    // Determine AM or PM
    const period = +hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    const hours12 = +hours % 12 || 12; // 0 should be converted to 12

    // Format the time
    const formattedTime = `${hours12 < 10 ? `0${hours12}` : hours12}:${Number.parseInt(minutes).toString().padStart(2, '0')} ${period}`;
    return formattedTime
  } else {
    return ""
  }
}

export const dateFromDateTime = (date: any, time: any) => {
  if (date && time) {
    return new Date(`${date}T${time}:00`)
  } else {
    return new Date()
  }
}

export function timeAgo(date: any) {
  if (date) {
    const now: any = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;

    if (diffInSeconds < secondsInMinute) {
      return `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < secondsInHour) {
      const minutes = Math.floor(diffInSeconds / secondsInMinute);
      return `${minutes} ${minutes > 1 ? 'mins' : 'min'} ago`;
    } else if (diffInSeconds < secondsInDay) {
      const hours = Math.floor(diffInSeconds / secondsInHour);
      return `${hours} ${hours > 1 ? 'hrs' : 'hr'} ago`;
    } else if (diffInSeconds < 2 * secondsInDay) {
      const days = Math.floor(diffInSeconds / secondsInDay);
      return `${days} ${days > 1 ? 'days' : 'day'} ago`;
    } else {
      // Return the date in "20 August 2024" format
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    }
  } else {
    return ''
  }
}

export function compareDates(date1: any, date2: any) {
  // Create Date objects for each input date
  var d1 = new Date(date1);
  var d2 = new Date(date2);

  // Extract year, month, and day values
  var year1 = d1.getFullYear();
  var month1 = d1.getMonth();
  var day1 = d1.getDate();

  var year2 = d2.getFullYear();
  var month2 = d2.getMonth();
  var day2 = d2.getDate();

  // Compare the dates
  if (year1 === year2 && month1 === month2 && day1 === day2) {
    return 0; // Dates are equal
  } else if (year1 < year2 || (year1 === year2 && month1 < month2) || (year1 === year2 && month1 === month2 && day1 < day2)) {
    return -1; // date1 is earlier than date2
  } else {
    return 1; // date1 is later than date2
  }
}

export function mergeTimeRanges(intervals: any) {
  // Convert time to minutes for easier comparison
  function timeToMinutes(time: any) {
    if (!time) {
      return
    }
    const [hours, minutes] = time?.split(':').map(Number);
    return hours * 60 + minutes;
  }

  // Sort intervals based on start time, and if equal, by end time in descending order
  intervals?.sort((a: any, b: any) => {
    const startA = timeToMinutes(a.from);
    const startB = timeToMinutes(b.from);
    const endA = timeToMinutes(a.to);
    const endB = timeToMinutes(b.to);

    if (startA === startB) {
      return endB - endA;
    }
    return startA - startB;
  });

  const result = [];
  let currentEnd = 0;

  for (const interval of intervals) {
    const start = timeToMinutes(interval.from);
    const end = timeToMinutes(interval.to);

    if (end > currentEnd) {
      result.push(interval);
      currentEnd = end;
    }
  }

  return result;
}

export function mergeTimeRange(ranges: any) {
  // Sort the ranges by start time

  ranges.sort((a: any, b: any) => {
    return a?.from?.localeCompare(b?.from);
  });

  let mergedRanges = [];
  let currentRange = null;

  for (let range of ranges) {
    if (!currentRange) {
      currentRange = range;
    } else {
      // If the current range overlaps with the next range
      if (range.from < currentRange.to) {
        currentRange.to = range.to;
      } else {
        mergedRanges.push(currentRange);
        currentRange = range;
      }
    }
  }

  if (currentRange) {
    mergedRanges.push(currentRange);
  }

  return mergedRanges;
}

export function convertTimetoTotalMinutes(totalBookedMinutesInDecimal: any) {
  const minutesArr = totalBookedMinutesInDecimal?.toString()?.split(".");
  const hours = minutesArr?.[0]
  const minutes = minutesArr?.[1] || "0";

  const totalBookedMinutes = +hours * 60 + +minutes

  return totalBookedMinutes
}

export function convertDecimalTimeTotalMinutes(decimalTime: number) {
  if (decimalTime) {
    const minutes = decimalTime * 60;
    return minutes
  } else {
    return 0
  }
}

export function convertToHoursMinutes(totalBookedMinutes: any) {
  const hr = Math.floor(totalBookedMinutes / 60)
  const min = (totalBookedMinutes % 60);

  return `${hr} hrs ${min !== 0 ? `${min} mins` : ""}`
}

export function getMinDate(condition: any = null) {
  if (condition) {
    return disablePastDate();
  } else {
    return ""
  }
}

export function formatSlashDate(date: any) {
  if (!date) {
    return ""
  }
  const year = new Date(date)?.getFullYear();
  const month = String(new Date(date)?.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(new Date(date)?.getDate()).padStart(2, '0');

  const todayFormated = `${year}-${month}-${day}`;
  return todayFormated
}

export const formatDate = (date: any, isFullDate = false) => {
  if (date) {
    const d = new Date(date);
    let options: any = { year: 'numeric', month: 'long', day: 'numeric' };
    if (isFullDate) {
      options = { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    }
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(d);
    return formattedDate
  }
}
export const formatDuration = (duration: number) => {
  if (duration) {
    const hours = Math.floor(duration / 60); // Divide by 60 to get hours
    const minutes: any = duration % 60; // Get the remainder for minutes
    return `${hours ? `${hours} h` : ''} ${minutes > 0 ? `${Number.parseInt(minutes)} mins` : ''}`
  } else {
    return '0h 0mins'
  }
}
export const formatFormatTime = (time: any) => {
  if (time) {
    const [hours, minutes] = time.split(':')
    // Determine AM or PM
    const period = +hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    const hours12 = +hours % 12 || 12; // 0 should be converted to 12

    // Format the time
    const formattedTime = `${hours12 < 10 ? `0${hours12}` : hours12}:${Number.parseInt(minutes).toString().padStart(2, '0')} ${period}`;
    return formattedTime
  } else {
    return ""
  }
}

export const convertTimeToMinute = (time: any) => {
  if (time) {
    const [hours, minutes] = time?.split(":");
    return +hours * 60 + +minutes
  } else {
    return 0
  }
}

export function convertTo24Hour(time: any) {
  if (!time) {
    return "00:00"
  }
  // Split the time string into components  
  const [timePart, modifier] = time?.split(' ');

  // Further split the time part into hours and minutes
  let [hours, minutes] = timePart?.split(':');

  // Convert hours to number
  hours = parseInt(hours);

  // Convert to 24-hour format
  if (modifier.toLowerCase() === 'pm' && hours !== 12) {
    hours += 12;
  } else if (modifier.toLowerCase() === 'am' && hours === 12) {
    hours = 0;
  }

  // Ensure hours and minutes are two digits
  hours = hours.toString().padStart(2, '0');
  minutes = minutes.padStart(2, '0');

  return `${hours}:${minutes}`;
}

export function convertMinutesToTime(minutes: any) {
  let hours: any = Math.floor(minutes / 60);
  let mins: any = minutes % 60;

  // Add leading zero to minutes if necessary
  mins = mins < 10 ? '0' + mins : mins;
  if (hours > 0) {
    return hours + ':' + mins;
  } else {
    return mins;
  }
}



export function bundleGrouping(data: any) {
  if (data) {
    const groupedData = data.reduce((acc: any, item: any) => {
      const bundleName = item.bundleId ? item.bundleId.bundleName : "no_bundle";
      if (!acc[bundleName]) {
        acc[bundleName] = [];
      }
      acc[bundleName].push(item);
      return acc;
    }, {});

    const resultArray = Object.keys(groupedData).map(bundleName => {
      const key = bundleName === "no_bundle" ? "noBundle" : bundleName;
      return { [key]: groupedData[bundleName] };
    });

    return resultArray
  } else {
    return []
  }
}
export function counterWiseBookingServices(services: any) {
  if (services) {
    return services?.reduce((acc: any, service: any) => {
      if (acc?.[service?.serviceId?._id]) {
        acc[service.serviceId._id] = {
          count: acc[service.serviceId._id].count + 1,
          services: [...acc[service.serviceId._id].services, service]
        };
      } else {
        acc[service.serviceId._id] = {
          count: 1,
          services: [service]
        };
      }
      return acc;
    }, {});
  } else {
    return {}
  }
}

export function formatDateRange(startDateStr: any, endDateStr: any = null) {
  if (startDateStr) {
    const startDate = new Date(startDateStr);
    const endDate = endDateStr ? new Date(endDateStr) : new Date()

    // Get the day from the start and end dates
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();

    // Use Intl.DateTimeFormat to get the full month name from the end date
    const monthFormatter = new Intl.DateTimeFormat('en-US', { month: 'long' });
    const month = monthFormatter.format(endDate);

    if (!endDateStr) {
      return `${endDay > 9 ? endDay : `0${endDay}`} ${month}`
    }
    // Format the result
    return `${startDay > 9 ? startDay : `0${startDay}`} - ${endDay > 9 ? endDay : `0${endDay}`} ${month}`;
  } else {
    return ''
  }
}

export function formatDateRangeISO(dateRangeStr: any, type = 'range') {
  if (type === 'single' && dateRangeStr) {
    const [year, month, day] = dateRangeStr?.split("-");

    const date = new Date(`${year}-${month}-${day}`);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: '2-digit',
      month: 'short',
    });
    return formattedDate

  } else {
    const [startDateStr, endDateStr] = dateRangeStr?.split(" - ");
    if (startDateStr && endDateStr) {
      const startDate = new Date(startDateStr);
      const endDate = new Date(endDateStr);

      const startDay = startDate.getDate();
      const startMonthName = startDate.toLocaleDateString("en-GB", { month: 'short' });
      const startYear = startDate.getFullYear();

      const endDay = endDate.getDate();
      const endMonthName = endDate.toLocaleDateString("en-GB", { month: 'short' });
      const endYear = endDate.getFullYear();

      let formattedRange;

      if (startMonthName === endMonthName && startYear === endYear) {
        formattedRange = `${startDay} - ${endDay}${startMonthName}`;
      } else {
        const startFormatted = `${startDay} ${startMonthName} ${startYear}`;
        const endFormatted = `${endDay} ${endMonthName} ${endYear}`;
        formattedRange = `${startFormatted} - ${endFormatted}`;
      }

      return formattedRange;
    } else {
      return ''
    }
  }
}

// Helper function to get the date label (e.g., Today, Yesterday, or a specific date)
export function getDateLabel(timestamp: any) {
  const messageDate = new Date(timestamp * 1000); // Convert seconds to milliseconds
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const options: any = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedMessageDate = messageDate.toLocaleDateString(undefined, options);
  const formattedToday = today.toLocaleDateString(undefined, options);
  const formattedYesterday = yesterday.toLocaleDateString(undefined, options);

  if (formattedMessageDate === formattedToday) {
    return 'Today';
  } else if (formattedMessageDate === formattedYesterday) {
    return 'Yesterday';
  } else {
    return formattedMessageDate; // Return formatted date (e.g., Nov 27, 2024)
  }
}

// Function to group messages by date
export function groupMessagesByDate(messages: any) {
  const grouped: any = {};

  messages.forEach((message: any) => {
    const label = getDateLabel(message.date.seconds);
    if (!grouped[label]) {
      grouped[label] = [];
    }
    grouped[label].push(message);
  });

  // Convert grouped object into the desired response format
  return Object.keys(grouped).map((date) => ({
    date,
    messages: grouped[date],
  }));
}