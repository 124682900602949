import React from 'react';
import cx from './index.module.scss';

function BookingCard({ title, children }: { title: string, children: any }) {
    return (
        <div className={`${cx.activity_mainBox}`}>
            <h3>{title}</h3>
            {children}
        </div>
    )
}

export default BookingCard