import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import cx from './index.module.scss';
import CustomarModal from "../../../components/Business/Modals/CustomerModal";
import { isDesktop, deviceType } from 'react-device-detect';
import { clockA, schedularA } from "../../../assets/images";
import ErrorPopup from "../../../components/Business/Modals/ErrorPopup";

const MyCustomEvents = (events: any) => {
    const [showErrorModal, setShowErrorModal] = useState("");
    const handleAlertModalClose = () => setShowErrorModal("");
    const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);
    const getBooking = events?.event?.getBooking
    const handleTeamBooking = events?.event?.handleTeamBooking

    const ref: any = useRef();
    const [eventBookingData, setEventBookingData] = useState<any>();
    const [viewBookingData, setViewBookingData] = useState<any>();
    const [showCustomer, setShowCustomer] = useState(false);
    const handleCustomerClose = () => setShowCustomer(false);
    const startTime = useMemo(() => moment(events?.event?.start).format('hh:mm a'), [events?.event?.start]);
    const endTime = useMemo(() => moment(new Date(events?.event?.end)).format('hh:mm a'), [events?.event?.end]);
    const bookingId = events?.event?.bookingId?.includes("-") ? events?.event?.bookingId?.split("-")[1] : events?.event?.bookingId;

    const unassignEvent = events?.event?.resourceId === 1;

    let service: any = events.event?.service?.map((item: any) => item?.serviceId?.serviceId?.serviceName).join(',');
    const bookedCount = events?.event?.bookedCount || 0;
    const availableCount = events?.event?.teamCount || 0;

    const handleShowCustomerClick = (events: any) => {
        // if (events?.event?.bookingData === undefined) {
        //     setShowCustomer(false)
        // } else {

        setShowCustomer(true)
        const bookingDetails = events?.event?.bookingData?.bookingDetails?.map((data: any) => {
            const services = data?.services?.map((prices: any) => {
                return { ...prices, serviceId: { ...prices?.serviceId, serviceId: { ...prices?.serviceDetails, serviceCatagoryId: prices?.serviceCategoryDetails } } }
            })
            return { ...data, services }
        });
        const data = { ...events?.event?.bookingData, bookingDetails };
        setEventBookingData(data)

        setViewBookingData(events)
        // }
    };
    // function handleDrag (e:any) {
    //     console.log(e, 'eData');
        
    // }
    // useEffect(() => {
    //     if (ref.current) {
    //         const parentElement = ref.current.parentNode;
    //         parentElement.addEventListener('drag',() =>handleDrag(parentElement))

    //         return () =>{
    //             parentElement.removeEventListener('drag')
    //         }
    //     }
    // }, [ref])
    const existBundleList = useMemo(() => {
        const bundles: any[] = [];
        events.event?.service?.forEach((item: any) => {
            if (item.bundleId?._id) {
                const existBundle = bundles.find((bundle) => bundle?.bundleId === item.bundleId?._id);
                if (existBundle) {
                    existBundle.services.push(item);
                } else {
                    bundles.push({ bundleName: item.bundleId?.bundleName, bundleId: item.bundleId?._id, finalPrice: item?.bundleId?.finalPrice, services: [item] });
                }
            }
        });
        return bundles;
    }, [events]);

    if (events?.event?.eventType === 'emptyBoxes') {
        return (
            <div className={`${cx.events} ${cx.emptyBoxes} justify-content-center d-flex`} role='img'>

            </div>
        );
    }
    if (events?.event?.eventType === 'Not Available') {
        return (
            <div className={`${cx.events} ${cx.unavailableBox} justify-content-center d-flex`}>
                <h2 className={`${cx.teamTitle} ${cx.unavailable}`}>{""}</h2>
            </div>
        );
    }

    if (events?.event?.eventType === "teamCount") {
        const selectedDateInMm = new Date(events?.event?.start)?.getTime();
        const currDateInMm = new Date()?.getTime();
        const pastDate = selectedDateInMm < currDateInMm

        return (
            <div className={`${cx.events} ${cx.noPadding} ${cx.unavailableBox} justify-content-center d-flex`}>
                {<div className={cx.compareBox}>
                    {<div className={cx.allTeamClount} style={{ width: `${(bookedCount / (bookedCount + availableCount)) * 100}%` }}>{bookedCount !== 0 ? bookedCount : ""}</div>}
                    {availableCount !== 0 && <div className={`${cx.teamCount} ${pastDate ? cx.allTeamClount : ""}`} style={{ cursor: "pointer", width: bookedCount === 0 ? "100%" : `${(availableCount / (bookedCount + availableCount)) * 100}%` }} onClick={() => handleTeamBooking(events?.event?.start, events)}>{availableCount > 0 ? availableCount : ""}</div>}
                </div>}
            </div>
        );
    }
    if (events?.event?.eventType === 'Not Available') {
        return (
            <div className={`${cx.events} ${cx.unavailableBox} justify-content-center d-flex`}>
                <h2 className={`${cx.teamTitle} ${cx.unavailable}`}>{""}</h2>
                {/* <h2 className={`${cx.teamTitle} ${cx.unavailable}`}>{events?.title}</h2> */}
            </div>
        );
    }
    function handleTouch(e: any) {

        // if (ref.current) {
        //     const parentElement = ref.current.parentNode;
        //     parentElement.tabIndex = 1;
        //     parentElement.focus();
        // }

        // const rbcContainer: any = document.querySelector('.rbc-events-container');
        // rbcContainer.tabIndex = 1
        // rbcContainer?.blur(); // Optionally blur the parent container if necessary
    }
    function handleClick(e: any) {
        if (!isDesktop) {
            return;
        }
        e.stopPropagation();
        handleShowCustomerClick(events);
    }

    function handleDoubleClick(e: any) {
        if (isDesktop) return;
        e.stopPropagation();
        handleShowCustomerClick(events);
    }

    const completedCheck = events?.event?.service?.[0]?.bookingStatus === "Completed";
    const globalEvent = events?.event?.globalEventType;


    return (
        <>
            <ErrorPopup
                showErrorModal={showErrorModal}
                handleErrorModalClose={handleAlertModalClose}
            />
            {showCustomer && <CustomarModal
                showCustomer={showCustomer}
                bookingData={eventBookingData}
                viewBookingData={viewBookingData}
                getBooking={getBooking}
                handleCustomerClose={handleCustomerClose}
            />}
            {events?.event?.eventType === 'Upcoming' &&
                <div className={`${cx.eventContainer}`} ref={ref} tabIndex={1}>
                    <div className={`${cx.eventContainerBorder} ${unassignEvent ? cx.unassignBorder : cx.bookingBorder}`} role='img'>

                    </div>
                    <div style={unassignEvent ? { transform: `` } : {}} className={`${cx.events} ${events?.event?.booking?.bookedBy !== "offline" ? cx.events_b2c : ""} ${completedCheck || globalEvent === "Completed" ? cx.completed : ""} ${globalEvent === "Cancelled" ? cx.cancelled : globalEvent === "No Show" ? cx.noShow : ""}`} onTouchStartCapture={handleTouch} onClick={handleClick} onDoubleClick={handleDoubleClick}>
                        <h2 className={cx.teamTitle}>{events?.title} <span className={cx.teamperson_id} title={events?.event?.bookingId}>{bookingId ? `(${bookingId})` : ""}</span></h2>
                        {existBundleList?.length > 0 ? (
                            <>
                                {existBundleList?.map((bundle: any, b: any) => {
                                    const bundleServices = bundle?.services?.map((service: any) => service?.serviceDetails?.serviceName)?.join(", ")
                                    return (
                                        <h6 className={cx.services} key={b}><img src={schedularA} alt="service" /><span>{bundle?.bundleName} - ({bundleServices})</span></h6>
                                    )
                                })}
                            </>
                        ) : (<h6 className={cx.services}><img src={schedularA} alt="service" /><span>{service}</span></h6>)}

                        < h6 className={cx.services}><img src={clockA} alt="time" /> <span>{startTime} - {endTime}</span></h6>
                    </div>
                </div>
            }
        </>

    );
};

export default MyCustomEvents