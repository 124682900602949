import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    alertCount: 0,
    alertMsg: '',
};

const alertModalSlice: any = createSlice({
    name: "manageAlert",
    initialState,
    reducers: {
        changeAlertCountState(state = initialState, payload: any) {
            state.alertCount += 1;
            state.alertMsg = payload?.payload?.msg;
        },
    },
});

export const { changeAlertCountState } =
alertModalSlice.actions;
export default alertModalSlice.reducer;
