import React, { useEffect, useState } from "react";
import { Row, Col, Dropdown, Button, Offcanvas } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import cx from "./index.module.scss";
import { MdMenu } from "react-icons/md";
import { logo, logo2, notification, notificationBell, notificationIcon, profile, search } from "../../../assets/images";
import GlobalContext from "../../../store/global-context";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorPopup from "../Modals/ErrorPopup";
import { httpRequest } from "../../../Apis/commonApis";
import { changeBranchIdState, changeCurrentBranchData, getAllBranch } from "../../../redux_toolkit/reducer/branchReducer";
import { refreshPage } from "../../../Utils/Funcs";
import { getBusinessData } from "../../../redux_toolkit/reducer/shareDataReducer";

const BusinessHeader = (props: any) => {
  const globalCtx = useContext(GlobalContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let { businessData, spand } = useSelector((state: any) => state.shareDataReducer);
  let { branchId, allBranch } = useSelector((state: any) => state.BranchReducer);
  let { alertCount, alertMsg } = useSelector((state: any) => state.AlertMsgReducer);
  
  const [userData, setUserData] = useState<any>(null);
  const [branchdata, setBranchData] = useState<any[]>([]);
  const [showErrorModal, setShowErrorModal] = useState("");

  const handleAlertModalClose = () => setShowErrorModal("");
  const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);

  useEffect(() => {
    let udata = localStorage.getItem("user_data") || "";
    let userdata = JSON?.parse(udata);
    setUserData(userdata);
  }, []);

  useEffect(() => {
    if (alertCount > 0) {
      handleAlertModalShow(alertMsg);
    }
  }, [alertCount]);

  useEffect(() => {
    if (businessData) {
      getBranch();
    }
  }, [businessData]);

  function handleLogout() {
    localStorage.setItem('user_token', '')
    localStorage.setItem('bl_token', '')
    localStorage.setItem('user_data', '');
    localStorage.setItem('business_data', '');
    localStorage.setItem('stepCompleted', '');
    refreshPage();
    // navigate("/business/login");
  }

  let handleChange = (e: any) => {
    let brId = e.target.value;
    dispatch(changeBranchIdState({ branchId: brId }));
    let currentBranch = branchdata.find((item: any) => item?._id == brId);
    if (currentBranch) {
      dispatch(changeCurrentBranchData({ currentBranch: currentBranch }));
    }
  };

  const getBranch = async () => {
    let res = await httpRequest(`getBranch?businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      dispatch(getAllBranch({branchData:res?.data}))
    }
  };

  useEffect(() =>{
    setBranchDataRoleWise(allBranch);
  },[allBranch])


  function setBranchDataRoleWise(allData: any) {
    let userdata: any = JSON.parse(localStorage.getItem('user_data') || '');
    if (userdata?.userType === "teamMember") {
      let findBranchRole = userdata?.Role?.find((item: any) => item.isGlobal);
      // let assignedBranchIds = userdata?.Role?.map((item: any) => item.branch);

      if (findBranchRole?.isGlobal) {
        setBranchData(allData);
        setDefaultBranchId(allData[0]);
      } else {
        let filteredBranches = allData.filter((item: any) => userdata?.Role?.some((role: any) => role?.branch === item?._id && role?.accessManager?.length > 0));
        setBranchData(filteredBranches);
        setDefaultBranchId(filteredBranches[0]);
      }
    } else if (userdata?.userType === "businessUser") {
      setBranchData(allData);
      setDefaultBranchId(allData[0]);
    }
  }

  function setDefaultBranchId(branchObj: any) {
    if (!branchId && branchObj) {
      localStorage.setItem('currentBranchId', branchObj._id);
      dispatch(changeBranchIdState({ branchId: branchObj._id }));
      dispatch(changeCurrentBranchData({ currentBranch: branchObj }));
    }
  }

  const businessImage = businessData?.profileImage ? businessData?.profileImage : businessData?.image && businessData?.image?.length !== 0 ? businessData?.image?.[0] : null

  async function handlePublish() {
    let res = await httpRequest(`updateBusiness/${businessData?._id}`, "PATCH", { isPublished: !businessData?.isPublished }, "json");
    if (res?.status) {
      dispatch(getBusinessData({ id: `businessId=${res?.data?._id}` }));
      handleAlertModalShow(`Success: ${res?.message}`)
    } else {
      handleAlertModalShow(`Error: ${res?.message}`)

    }
  }
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <ErrorPopup
        showErrorModal={showErrorModal}
        handleErrorModalClose={handleAlertModalClose}
      />
      <header className={`${cx.mainHeader} ${spand ? cx.activeHeader : ""}`}>
        <Row className={`align-items-center ${cx.headerRow}`}>
          <Col
            md={12}
            lg={12}
            xl={12}
            className={`col-12 ${cx.headerMobile} ${globalCtx.showMore ? cx.show : ""
              }`}
          >
            <Row className="align-items-center">
              <Col md={12} lg={12} className={`${cx.brand} col-12`}>
                <div className="d-flex align-items-center">
                  <button
                    className={`${cx.hembugmenu}`}
                    onClick={() => {
                      globalCtx.displayMenu();
                    }}
                  >
                    <MdMenu />
                  </button>
                  <div className="d-flex">
                    <NavLink to="#" className={`${cx.logo}`}>
                      <img src={logo} alt="logo" />
                    </NavLink>
                    {branchdata.length > 0 &&
                      <span className={`${cx.countryDropdownMain} `}>
                        <select
                          className={`${cx.countryDropdown} form-select`}
                          onChange={handleChange}
                        >
                          {branchdata?.map((item: any) => {
                            return (
                              <option key={item._id} value={item._id}>
                                {item?.branchName}
                              </option>
                            );
                          })}
                        </select>
                      </span>}
                  </div>
                  <div className={cx.right}>
                    <h5 className={cx.logoHeading}>{businessData?.businessName || ""}</h5>
                    <div className={cx.bottom}>
                      <ul className={cx.additionalMenu}>
                        {/* <li className={`${cx.notification}`}>
                           NavLink to="notification">
                           <img src={notificationIcon} alt="Notification" />
                           </NavLink>
                          </li> */}

                        {/* <li>
                          <button className="btn publish_btn" onClick={() => handlePublish()}>{businessData?.isPublished ? "Unpublish" : "Publish"}</button>
                        </li> */}
                        <li className={`${cx.notification}`}>
                          <NavLink to="notification">
                            <img src={notificationBell} alt="Notification" />
                          </NavLink>
                        </li>
                        {/* <li>
                          <Button variant="primary" onClick={handleShow}>
                            Publish
                          </Button>
                          <Offcanvas show={show} onHide={handleClose} placement="end">
                            <Offcanvas.Header closeButton>
                              <Offcanvas.Title>lorem</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quam quaerat
                              officiis ab facilis, nesciunt laborum veniam molestiae, temporibus cupiditate
                              atque perspiciatis, doloremque doloribus veritatis earum mollitia sint maxime! Eaque, sapiente.
                            </Offcanvas.Body>
                          </Offcanvas>
                        </li> */}
                        <li className={`${cx.profileBox}`}>
                          <Dropdown>
                            <Dropdown.Toggle variant="a" id="dropdown-basic">
                              {businessImage ? (
                                <img src={businessImage} alt="profile" />
                              ) : <div className={cx.fakeLogo}>
                                <span>{businessData?.businessName?.[0]}</span>
                              </div>}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item className={`${cx.borderM}`} href="#">
                                <p>{businessData?.businessName}</p>
                                <h5>{userData?.email}</h5>
                              </Dropdown.Item>
                              <Dropdown.Item className={`${cx.borderM} ${cx.settingM}`} href="#">
                                <NavLink to="/business/setting">
                                  Setting
                                </NavLink>
                              </Dropdown.Item>
                              <Dropdown.Item
                                className={`${cx.logout}`}
                                onClick={handleLogout}
                              >
                                Logout
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
              {/* <Col md={3} lg={3} className={`${cx.companyLogo} text-center`}>
                <NavLink to="#" className={`${cx.logo}`}>
                  <img src={logo2} alt="logo" />
                </NavLink>
              </Col>
              <Col md={4} lg={4} className={`col-6 ${cx.infoheaderRight}`}>
                <ul>
                  <li className={`${cx.searchBox}`}>
                    <NavLink to={"#"}>
                      <img src={search} alt="search" />
                    </NavLink>
                  </li>
                  <li className={`${cx.notificationBox}`}>
                    <NavLink to="notification">
                      <img src={notification} alt="Notification" />
                    </NavLink>
                  </li>
                  <li className={`${cx.profileBox}`}>
                    <Dropdown>
                      <Dropdown.Toggle variant="a" id="dropdown-basic">
                        <img src={profile} alt="profile" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item className={`${cx.borderM}`} href="#">
                          <p>{businessData?.businessName}</p>
                          <h5>{userData?.email}</h5>
                        </Dropdown.Item>
                        <Dropdown.Item className={`${cx.borderM} ${cx.settingM}`} href="#">
                          <NavLink to="/business/setting">
                            Setting
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={`${cx.logout}`}
                          onClick={handleLogout}
                        >
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </header>
    </>
  );
};

export default BusinessHeader;
