import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import {
  hair,
  basicInfo,
  basicInfoA,
  promotionSetting,
  promotionSettingA,
  amenitiesTab,
  amenitiesTabA,
  team,
  teamActive,
  clock,
  clockA,
} from "../../../assets/images";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import SuccessPopup from "../../../components/Business/Modals/SuccessPopup";
import PromotionRoutes from "./PromotionRoutes";
import { getDynamicURIName } from "../../../Utils/Funcs";
import WellnezzaPromotionRoutes from "./WellnezzaPromotionRoute";
import { useForm } from "react-hook-form";
import BreadCrumbs from "../../../components/Business/BreadCrumbs/BreadCrumbs";

const WellnezzaAddPromotion = (props: any) => {
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  const navigate = useNavigate();
  const location: any = useLocation();
  const dynamicName = getDynamicURIName(location.pathname);
  const [message, setMessage] = useState("");
  const [promotionId, setPromotionId] = useState("");
  const [activeTab, setActiveTab] = useState(dynamicName);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleSuccessModalClose = () => setShowSuccessModal(false);
  const handleSuccessModalShow = () => setShowSuccessModal(true);
  const [loading, setLoading] = useState(false);
  const [promotion, setPromotion] = useState<any>([]);
  const [editData, setEditData] = useState<any>(null);
  const [superPermotion, setSuperPermotion] = useState<any>([]);
  const formObj = useForm()
  const {
    register,
    formState: { errors },
  } = formObj;

  const selectedPromotion = location?.state?.promotionId ? promotion?.find((promo: any) => promo?._id === location?.state?.promotionId) : null
  const isOffPeak = selectedPromotion?.promotionType === "Off Peak Hours"


  useEffect(() => {
    if (businessData) {
      getPromotion();
    }
  }, [businessData, activeTab]);

  const getPromotion = async () => {
    if (businessData) {
      let res = await httpRequest(`getPromotion?businessId=${businessData?._id}&type=list`, "get", null, "json");
      if (res.status) {
        setPromotion(res?.data);
      }
    }
  };
  const handleStepsFormData = (formdata: any, step: any) => {
    if (formdata === 'prevStep') {
      setActiveTab(step);
      navigate(step, { state: { promotionId: location.state.promotionId }, replace: true });
      return;
    }


    if (step === 'step1') {
      basicSubmitHandler(formdata, 'wellnezza-blackout');
    } else if (step === 'step2') {
      updateBlackOutHandler(formdata, 'wellnezza-services')
    } else if (step === 'step3') {
      serviceHandler(formdata, isOffPeak ? 'wellnezza-off-peak-hours' : 'wellnezza-assign-branch');
    } else if (step === 'step4') {
      offPeakHandler(formdata, 'wellnezza-assign-branch')
    } else if (step === 'step5') {
      branchHandler(formdata, 'wellnezza-additional');
    }
    else if (step === 'step6') {
      onSubmit(formdata, 'back');
    }
  };

  const serviceHandler = async (basicData: any, step: string) => {
    // const priceId = basicData?.serviceId?.map((id: any) => id._id);
    setLoading(true);
    const formData = new FormData();
    formData.append("businessId", businessData?._id)
    formData.append("priceId", JSON.stringify(basicData?.priceId));
    formData.append("bundleId", JSON.stringify(basicData?.bundleId));
    formData.append("type", basicData?.type)

    const path = location?.state ? `updatePromotion/${location?.state?.promotionId}` : `createPromotion`
    const method = location?.state ? "PATCH" : "POST";
    const res = await httpRequest(path, method, formData, "formdata");
    if (res?.status) {
      setActiveTab(step);
      navigate(step, { state: { promotionId: res.data?._id }, replace: true });

    }
    setLoading(false);
  }

  const offPeakHandler = async (basicData: any, step: string) => {
    // const priceId = basicData?.serviceId?.map((id: any) => id._id);
    setLoading(true);
    const formData = new FormData();
    formData.append("businessId", businessData?._id)

    formData.append("offPeakHours", JSON.stringify(basicData))

    const path = location?.state ? `updatePromotion/${location?.state?.promotionId}` : `createPromotion`
    const method = location?.state ? "PATCH" : "POST";
    const res = await httpRequest(path, method, formData, "formdata");
    if (res?.status) {
      setActiveTab(step);
      navigate(step, { state: { promotionId: res.data?._id }, replace: true });
    }
    setLoading(false);
  }

  const updateBlackOutHandler = async (allData: any, step: string) => {
    setLoading(true);
    const formdata = new FormData();
    for (const key in allData) {
      formdata.append(key, allData[key]);
    }
    formdata.append("promotionIdentity", "wellnezza")

    const res = await httpRequest(`updatePromotion/${location.state.promotionId}`, "PATCH", formdata, "formdata",);
    if (res.status) {
      setActiveTab(step);
      navigate(step, { state: { promotionId: res.data?._id }, replace: true });
    }
    setLoading(false);
  }

  const branchHandler = async (basicData: any, step: string) => {
    // const priceId = basicData?.serviceId?.map((id: any) => id._id);
    setLoading(true);
    const formData = new FormData();
    formData.append("branchArr", JSON.stringify(basicData))

    const path = location?.state ? `updatePromotion/${location?.state?.promotionId}` : `createPromotion`
    const method = location?.state ? "PATCH" : "POST";
    const res = await httpRequest(path, method, formData, "formdata");
    if (res?.status) {

      if (step === "back") {
        setShowSuccessModal(true)
        setMessage(res?.message)
        setTimeout(() => {
          navigate(-1)
        }, 2000)
      } else {
        setActiveTab(step);
        navigate(step, { state: { promotionId: res.data?._id }, replace: true });
      }
    }
    setLoading(false);
  }

  const basicSubmitHandler = async (basicData: any, step: string) => {
    // const priceId = basicData?.serviceId?.map((id: any) => id._id);
    setLoading(true);
    const formData = new FormData();
    formData.append("businessId", businessData?._id)

    if (step !== "wellnezza-off-peak-hours" && step !== "wellnezza-assign-branch") {
      const limit = basicData?.LimitUsePerClient.maxUse || "0";
      const limitUser = {
        active: basicData?.LimitNumberOfUses.active,
        maxUser: basicData?.LimitNumberOfUses?.maxUser
      }

      formData.append("LimitUsePerClient", limit)
      // formData.append("discountCode", JSON.stringify(discount))
      formData.append("LimitNumberOfUses", JSON.stringify(limitUser));

      formData.append("promotionType", basicData?.promotionType)
      formData.append("promotionName", basicData?.promotionName)

      if (basicData?.superPromotionId) {
        formData.append("superPromotionId", basicData?.superPromotionId)
      }

      formData.append("unit", basicData?.unit)
      formData.append("tagline", basicData?.tagline)
      formData.append("frequency", basicData?.frequency)
      formData.append("branchArr", JSON.stringify(basicData?.branchArr));
      formData.append("isPublish", basicData?.isPublish)
      formData.append("dateFrom", basicData?.dateFrom)
      formData.append("dateTo", basicData?.dateTo)
      formData.append("startTimeFrom", basicData?.startTimeFrom)
      formData.append("endTimeTo", basicData?.endTimeTo)

      formData.append("selldateFrom", basicData?.selldateFrom)
      formData.append("selldateTo", basicData?.selldateTo)
      formData.append("sellstartTimeFrom", basicData?.sellstartTimeFrom)
      formData.append("sellendTimeTo", basicData?.sellendTimeTo)
      formData.append("promotionIdentity", "wellnezza")

      formData.append("discountCode", basicData?.discountCode)
      if (basicData?.promotionType === "Dynamic Pricing") {
        formData.append("dynamicPromotionStatus", basicData?.dynamicPromotionStatus)
      }
    }

    if (step === "wellnezza-assign-branch") {
      formData.append("offPeakHours", JSON.stringify(basicData))
    }


    if (basicData?.serviceCategory) {
      formData.append("serviceCategory", JSON.stringify(basicData?.serviceCategory))
    } else {
      formData.append("serviceCategory", JSON.stringify([]))
    }

    if (basicData?.priceId) {
      formData.append("priceId", JSON.stringify(basicData?.priceId));
    } else {
      formData.append("priceId", JSON.stringify([]));
    }
    if (basicData?.bundleId) {
      formData.append("bundleId", JSON.stringify(basicData?.bundleId));
    } else {
      formData.append("bundleId", JSON.stringify([]));
    }


    if (step === "wellnezza-blackout") {
      formData.append("promotionProvidesCategory", basicData?.promotionProvidesCategory);
      formData.append("forFirstTime", JSON.stringify(basicData?.forFirstTime === "newUser"))
    }

    if (basicData?.type) {
      formData.append("type", basicData?.type)
    }

    const path = location?.state ? `updatePromotion/${location?.state?.promotionId}` : `createPromotion`
    const method = location?.state ? "PATCH" : "POST";
    const res = await httpRequest(path, method, formData, "formdata");
    if (res?.status) {

      if (step === "back") {
        setShowSuccessModal(true)
        setMessage(res?.message)
        setTimeout(() => {
          navigate(-1)
        }, 2000)
      } else {
        setActiveTab(step);
        navigate(step, { state: { promotionId: res.data?._id }, replace: true });
      }
    }
    setLoading(false);
  }

  const onSubmit = async (data: any, step: string) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("businessId", businessData?._id)
    formData.append("details", data?.details)
    formData.append("termsAndCondition", data?.term)
    formData.append("redemptionInstruction", data?.redemption)
    formData.append("cancellationPolicy", data?.cancellation)
    formData.append("promotionIdentity", "wellnezza")

    for (let i = 0; i < data?.image.length; i++) {
      formData.append('image', data?.image[i]);
    }

    if (data?.existImage) {
      formData.append('oldImages', JSON.stringify(data?.existImage));
    }
    const res = await httpRequest(`updatePromotion/${promotionId ? promotionId : location?.state?.promotionId}`, "PATCH", formData, "formdata");
    if (res?.status) {
      if (step === "back") {
        setShowSuccessModal(true)
        setMessage(res?.message)
        setTimeout(() => {
          navigate(-1)
        }, 2000)
      } else {
        setActiveTab(step);
        navigate(step, { state: { promotionId: res.data?._id }, replace: true });
      }
    }
    setLoading(false);
  }

  const handleTabSelect = (tab: any) => {
    if (editData) {
      setActiveTab(tab);
      navigate(`${tab}`, { state: { promotionId: location?.state?.promotionId }, replace: true });
    }
  };

  const getAdminPromotion = async () => {
    let res = await httpRequest(`getSuperPromotion`, "get", null, "json");
    if (res.status) {
      setSuperPermotion(res?.data);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getAdminPromotion()
  }, [businessData, editData]);

  const allPermotion = editData?._id && !superPermotion?.some((item: any) => item?._id === editData?.superPromotionId) ? [...superPermotion, { ...editData, _id: editData?.superPromotionId }] : superPermotion
  const existWellnezzaPromotion = promotion?.filter((item: any) => item?.promotionIdentity === "wellnezza");

  return (
    <>
      <SuccessPopup
        showSuccessModal={showSuccessModal}
        handleSuccessModalClose={handleSuccessModalClose}
        message={message}
      />
      <section className={`${st.pageWrapper} ${st.maxWidth_pageWrapper}`}>
        <div className={`${st.newRedesign_main}`}>
        <BreadCrumbs main={{menu:'Promotion', link:'/business/promotion'}} current={{menu:'View Promotion Details', link:'disabled'}}/>
          <Row>
            <Col md={10}>
              <div className={`${st.head_paraBox}`}>
                <h2>{location.state && location?.state?.type !== "copy" ? `Wellnezza Managed Promotion -  ${selectedPromotion?.promotionName}` : " Sign up for Wellnezza Platform Promotion"}</h2>
                <p>Sign up for the Welnezza Platform promotion to unlock exclusive benefits and showcase your services to a wider audience. Take advantage of this opportunity to boost your visibility
                  and grow your business with our platform's promotional offerings</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={`${st.branch_locatedBox_bg} `}>
                {/* <h3>About</h3> */}
                <Row>
                  <Col md={5}>
                    <div className={`${st.reFormBox} mb-4`}>
                      <label>Select Promotion</label>
                      <span className={`${st.sub_label}`}>Select the latest Promotions offered by Wellnezza</span>
                      <select
                        className="form-select"
                        {...register("name", { required: true })}
                        disabled={editData?._id !== undefined || activeTab !== 'wellnezza-info'}
                      >
                        <option value="">Select</option>
                        {allPermotion?.map((item: any, i: any) => {
                          const existPromotion = existWellnezzaPromotion?.find((promo: any) => promo?.superPromotionId === item?._id && editData?.superPromotionId !== item?._id);

                          return (
                            <option disabled={existPromotion?._id !== undefined} style={existPromotion ? { color: "red" } : {}} value={`${item?.promotionName}/${item?._id}`} key={i}>{`${item?.promotionName}${existPromotion ? "- enrolled" : ""}`}</option>
                          )
                        })}
                      </select>
                      {errors?.name?.type === "required" && (
                        <p className={"errorMessage"}>This field is required</p>
                      )}
                    </div>
                  </Col>

                </Row>
                <Row style={{gap:'68px'}}>
                  <Col md={5}>
                    <div className={`${st.reFormBox}`}>
                      <label htmlFor="">Promo Code</label>
                      <span className={`${st.sub_label}`}>Create Promo code for which can be offered to your business partners</span>
                      <Col md={12}>
                        <input type="text" className='form-control' disabled={activeTab !== 'wellnezza-info'} placeholder='Enter Promo Code' {...register("discountCode")}
                        />
                      </Col>
                      {errors?.discountCode?.type === "required" && (
                        <p className={"errorMessage"}>This field is required</p>
                      )}
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className={`${st.reFormBox}`}>
                      <label htmlFor="">Promotion tag line</label>
                      <span className={`${st.sub_label}`}>Tagline will be display on promotion profile image as sticker</span>
                      <Col md={8}>
                        <input type="text" className='form-control' disabled={activeTab !== 'wellnezza-info'} placeholder='Enter Promotion tag line' {...register("tagline", {
                          required: "Tagline is required",
                          maxLength: {
                            value: 15,
                            message: "Tagline cannot exceed 15 characters"
                          }
                        })}
                        />
                      </Col>
                      {errors?.tagline?.type === "required" && (
                        <p className={"errorMessage"}>This field is required</p>
                      )}
                      {errors?.tagline?.type === "maxLength" && (
                        <p className={"errorMessage"}>Tagline cannot exceed 15 characters</p>
                      )}
                    </div>
                  </Col>
                </Row>

              </div>
            </Col>
          </Row>
        </div>

        <div className={`${st.tabbingBox} ${st.tabbingBox}`}>
          <Tab.Container id="left-tabs-example"
            activeKey={activeTab}
            onSelect={handleTabSelect}>
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="wellnezza-info">
                  Details
                </Nav.Link>
              </Nav.Item>
              <span className={`${st.linespan}`}></span>
              <Nav.Item>
                <Nav.Link eventKey="wellnezza-blackout">
                  Black Out Period
                </Nav.Link>
              </Nav.Item>
              <span className={`${st.linespan}`}></span>
              <Nav.Item>
                <Nav.Link eventKey="wellnezza-services">
                  Services
                </Nav.Link>
              </Nav.Item>
              {isOffPeak && <span className={`${st.linespan}`}></span>}
              {isOffPeak && <Nav.Item>
                <Nav.Link eventKey="wellnezza-off-peak-hours">
                  Off Peak Hours
                </Nav.Link>
              </Nav.Item>}
              <span className={`${st.linespan}`}></span>
              <Nav.Item>
                <Nav.Link eventKey="wellnezza-assign-branch">
                  Assign Branch
                </Nav.Link>
              </Nav.Item>
              <span className={`${st.linespan}`}></span>
              <Nav.Item>
                <Nav.Link eventKey="wellnezza-additional">
                  Wellnezza Promotion page
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey={activeTab}>
                <Routes>
                  <Route path={activeTab}
                    element={<WellnezzaPromotionRoutes dynamicName={dynamicName} promotionList={promotion} setEditData={setEditData} formObj={formObj} handleStepsFormData={handleStepsFormData} loading={loading} />} />
                </Routes>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>

    </>
  );
};

export default WellnezzaAddPromotion;
