import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { httpRequest } from '../../../Apis/commonApis';
import { useSelector } from 'react-redux';
import LoadingBtn from '../../../components/Business/Buttons/LoadingBtn';
import { useNavigate } from 'react-router-dom';
import HelpButon from '../../../components/Business/HelpButon.tsx/HelpButton';
import HoverEyeContent from '../../../components/Business/HoverEyeContent/HoverEyeContent';

export default function AssignBranch(props: any) {
  const { loading, editPromotionData, handleStepsFormData, type = "", editLoading } = props;
  const [branchData, setBranchData] = useState<any>([]);
  const [selectedBranchId, setSelectedBranchId] = useState<any>([])

  useEffect(() => {
    if (editPromotionData?.branchArr) {
      setSelectedBranchId(editPromotionData?.branchArr)
    }

  }, [editPromotionData])
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );

  const getBranches = async () => {
    let res = await httpRequest(`getBranch?businessId=${businessData._id}`, "get", null, 'json');
    if (res.status === true) {
      setBranchData(res.data);
    }
  }

  const handleBranch = (e: any) => {
    if (!e.target.checked) {
      const filterBranch = selectedBranchId?.filter((item: any) => item !== e.target.value);
      setSelectedBranchId(filterBranch)
    } else {
      setSelectedBranchId([...selectedBranchId, e.target.value])
    }
  }

  function arraysEqual(arr1: any, arr2: any) {
    if (arr1?.length !== arr2?.length) {
      return false;
    }
    for (let i = 0; i < arr1?.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }

  const navigate = useNavigate()

  useEffect(() => {
    if (businessData) {
      getBranches();
    }
  }, [businessData]);

  const handleSave = (e: any) => {
    e.preventDefault();
    handleStepsFormData(selectedBranchId, 'step5');
  }

  const skipStep = (e: any) => {
    e.preventDefault();
    handleStepsFormData("prevStep", `${type}additional`);
    // navigate(-1)
  }

  const gotoOffPeak = editPromotionData?.promotionType === "Off Peak Hours" ? "off-peak-hours" : "services"

  return (
    <>
      <div>
        <Row>
          <Col md={12}>
            <div className={`${st.formBox} ${st.formSign}`}>
              <div className={`${st.btnsGroup} ${st.formSignGroup} mt-0 pb-3 justify-content-end`}>
                {/* <button
                  className={`btn ${st.btn1} ${st.btnGray} ${st.btnGrayLight}`}
                  onClick={() => handleStepsFormData("prevStep", `${type}${gotoOffPeak}`)}

                >
                  Back
                </button> */}
                {arraysEqual(editPromotionData?.branchArr, selectedBranchId) && <button type='submit' onClick={skipStep} className={`btn ${st.btn4}  ${st.btnGray} mx-0`} >
                  No Update
                </button>}
                {!arraysEqual(editPromotionData?.branchArr, selectedBranchId) && <button className={`btn ${st.btn4}  ${st.btnGray} mx-0`} disabled={arraysEqual(editPromotionData?.branchArr, selectedBranchId)} onClick={handleSave || editLoading}>
                  {loading ? <LoadingBtn /> : 'Update'}
                </button>}
              </div>
            </div>
          </Col>
        </Row>

      </div>
      <div className={`${st.newRedesign_main}`}>
        <Row>
          <Col md={4}>
            <div className={`${st.branchContact_tabBox}`}>
              <h4>Assign Branches</h4>
              <div className={`${st.reFormBox} mb-1`}>
                <div className={`${st.edit_publish_checkBox} d-block mt-2 w-100`}>
                  {branchData?.map((item: any, i: number) => {
                    return (
                      <label className={`${st.checkbox} d-flex w-100 mb-3`} key={i}>
                        <input type="checkbox" onChange={handleBranch} value={item?._id} checked={selectedBranchId?.some((branchId: any) => branchId === item?._id)} />
                        <span className={`${st.checkmark}`}></span>
                        <span className={`${st.labelName} ms-1`}>{item?.branchName}</span>
                      </label>
                    )
                  })}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

    </>
  )
}
