import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import st from "../../../assets/stylesheet/style.module.scss";
import { FiPlus } from 'react-icons/fi';
import BundleCanvas from '../../../components/Business/OffCanvas/BundleCanvas';
import BundleCard from './BundleCard';
import { useSelector } from 'react-redux';
import { httpRequest } from '../../../Apis/commonApis';
import { useNavigate } from 'react-router-dom';

export default function BundleMenu({bundleList, getBundle, isVisible, handleNavigate} : any) {
    const navigate = useNavigate()
    const [showBundleCanvas, setBundleCanvasShow] = useState(false);
    const handleBundleCanvasClose = () => {
        navigate('/business/services');
        setBundleCanvasShow(false)
        getBundle()

    }
    const handleBundleCanvasShow = () => setBundleCanvasShow(true);

    const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
    const canCreateBundle = subModuleRoles?.['Services']?.Bundles?.Create;
   
    return (
        <>
            <div className={`${st.newRedesign_main}`}>
                <Row>
                    {isVisible && <Col md={4}>
                        <Col xl={11} lg={12}>
                            <div>
                                <div className={`${st.branch_maintain_bg} `}>
                                    <p>
                                        Once you've added all your branches and locations, be sure to add your service menu!
                                    </p>
                                    <button className="btn" onClick={handleNavigate}>
                                        Maintain Team Menu
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Col>}
                    <Col xl={8} lg={10} md={11} className="mt-2">
                        <Row>
                            {canCreateBundle && <Col md={12}>
                                <div className="text-end mb-3">
                                    <button onClick={() => {
                                        navigate('/business/services/add-bundle/info')
                                        handleBundleCanvasShow()
                                    }} className={`${st.addServe}`}><FiPlus /> Add Bundle</button>
                                </div>
                            </Col>}
                            {bundleList?.map((bundle:any) =>{
                                return (
                                    <BundleCard key={bundle?._id} bundle={bundle} getBundle={getBundle} handleBundleCanvasShow={handleBundleCanvasShow}/>
                                )
                            })}
                        </Row>
                    </Col>
                </Row>
            </div>
            <BundleCanvas show={showBundleCanvas} handleBundleCanvasClose={handleBundleCanvasClose} />
        </>
    )
}
