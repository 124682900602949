import React, { useEffect, useRef, useState } from "react";
import st from "../../../assets/stylesheet/style.module.scss";
import "react-quill/dist/quill.snow.css";

import { Button, Col, Dropdown, Nav, Row, Tab, Tabs } from "react-bootstrap";
import { NavLink, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { httpRequest } from "../../../Apis/commonApis";
import SuccessPopup from "../../../components/Business/Modals/SuccessPopup";
import ErrorPopup from "../../../components/Business/Modals/ErrorPopup";
import AddBrachRouts from "./AddBrachRouts";
import { getDynamicURIName } from "../../../Utils/Funcs";
import { getBusinessCategory, getBusinessData, updateCounter } from "../../../redux_toolkit/reducer/shareDataReducer";
import { useDispatch, useSelector } from "react-redux";
import ToggleNew from "../../../components/Business/Form/ToggleNew";
import MultiSelect from "../../../components/Business/MultiSelect/MultiSelect";
import MultiSelect2 from "../../../components/Business/MultiSelect/MultiSelect2";
import StepperRow from "../../../components/Business/StepperBox/StepperRow";
import { useForm } from "react-hook-form";
import BackArrrow from "../../../components/Business/Buttons/BackArrow";
import BreadCrumbs from "../../../components/Business/BreadCrumbs/BreadCrumbs";

const AddBranch = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const dynamicName = getDynamicURIName(location.pathname);
  const [publish, setPublish] = useState<boolean>(true);
  const [businessData, setBusinessData] = useState<any>(null);
  const [errorTrig, setErrorTrig] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState(dynamicName);
  const [loading, setLoading] = useState(false);
  const [branchData, setBranchData] = useState<any>(null);
  const [selectedCatIds, setSelectedCatIds] = useState<any>([]);
  let { allBusCategories, counter } = useSelector((state: any) => state.shareDataReducer);
  const [branchName, setBranchName] = useState<string>('');
  const [filterCat, setFilterCat] = useState<any>([])
  const [showErrorModal, setShowErrorModal] = useState('');
  const handleAlertModalClose = () => setShowErrorModal('');
  const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);
  const branchRef: any = useRef()

  const selectedBranch = branchData?.find((item: any) => item?._id === location?.state?.branchId)
  useEffect(() => {
    if (location.state && businessData) {
      getBranchData();
    }
  }, [businessData, activeTab]);

  const getBranchData = async () => {
    let res = await httpRequest(`getBranch?businessId=${businessData._id}&_id=${location?.state?.branchId}`, "get", null, "json");
    if (res.status) {
      setBranchData(res.data)
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let udata = localStorage.getItem("user_data") || "";
    let userdata = JSON.parse(udata);
    getBusiness(userdata._id);
  }, []);

  useEffect(() => {
    if (businessData && allBusCategories) {
      const filterCat = allBusCategories?.filter((item: any) => businessData?.businessCatagoryId?.includes(item?._id))?.map((cat: any) => ({ label: cat?.BusinessCategoryName, value: cat?._id }))
      if (window.location.pathname?.includes('add-branch')) {
        setSelectedCatIds(filterCat)
      }
      setFilterCat(filterCat)
    }
  }, [businessData, allBusCategories])

  const handleStepsFormData = (formdata: any, step: any, reset: boolean = false) => {
    if (formdata === 'prevStep') {
      setActiveTab(step);
      navigate(step, { state: { branchId: location.state.branchId }, replace: true });
      return;
    }
    if (step === 'step1') {
      if (location.state) {
        updateBranch(formdata, 'opHrs');
      } else {
        createNewBranch(formdata);
      }
    } else if (step === 'step2') {
      updateBranch(formdata, 'opHrs');
    } else if (step === 'step3') {
      updateBranch(formdata, 'non_opHrs');
    } else if (step === 'step4') {
      updateBranch(formdata, 'OffPeakHours');
    }
    else if (step === 'step5') {
      updateBranch(formdata, 'team', reset);
      // assignTeamToBranch(formdata, 'amenities');
    }
    // else if (step === 'step6') {
    //   createBranchAmenities(formdata);
    // } else if (step === "step7") {
    // }
  };

  const createNewBranch = async (allData: any) => {
    const formdata = new FormData();
    for (const key in allData) {
      if (allData.hasOwnProperty(key)) {
        if (key === 'image') {
          if (allData[key].length > 0) {
            for (var i = 0; i < allData[key].length; i++) {
              formdata.append("image", allData[key][i]);
            }
          }
        } else {
          formdata.append(key, allData[key]);
        }
      }
    }
    formdata.append('isPublish', JSON.stringify(publish))
    setLoading(true);
    const res = await httpRequest('addbranch', 'POST', formdata, "formdata");
    if (res.status) {
      setActiveTab('opHrs');
      navigate('opHrs', { state: { branchId: res.data._id }, replace: true });

      let userdata: any = JSON.parse(localStorage.getItem('user_data') || '');
      if (userdata !== '' && userdata?._id) {
        let bid = '';
        if (userdata?.userType === "teamMember") {
          bid = `businessId=${userdata?.businessId}`;
        } else {
          bid = `id=${userdata?._id}`;
        }
        dispatch(getBusinessData({ id: bid }));
        dispatch(getBusinessCategory());
      }

    } else {
      handleAlertModalShow(`Error, ${res.message}`);
    }
    setLoading(false);
  }

  const updateBranch = async (allData: any, step: string, reset: boolean = false) => {
    const formdata = new FormData();
    if (step === 'opHrs') {
      formdata.append("isPublish", JSON.stringify(!selectedBranch?.isActive ? false : publish))
    }
    if (step === "team") {
      formdata.append("offPeakHours", JSON.stringify(allData))

    } else {
      for (const key in allData) {
        if (allData.hasOwnProperty(key)) {
          if (key === 'image') {
            if (allData[key].length > 0) {
              for (var i = 0; i < allData[key].length; i++) {
                formdata.append("image", allData[key][i]);
              }
            }
          } else {
            formdata.append(key, allData[key]);
          }
        }
      }
    }

    if (!reset) {
      setLoading(true);
    }

    const res = await httpRequest(`updatebranch/${location.state.branchId}`, 'PATCH', formdata, "formdata");
    if (res.status) {
      if (step === "OffPeakHours") {
        if (res.status) {
          handleAlertModalShow(`Success, ${location.pathname?.includes("add-branch") ? "branch details added successfully" : "branch details updated successfully"}`);
          setTimeout(() => {
            handleAlertModalClose();
            setLoading(false);
            navigate('/business/branch');
          }, 2000);

          return
        }
      }
      setActiveTab(step);
      navigate(step, { state: { branchId: res.data._id }, replace: true });
    } else {
      handleAlertModalShow(`Error, ${res.message}`);
    }
    setLoading(false);
  }


  const getBusiness = async (uid: any) => {
    let res = await httpRequest(`getBusiness?id=${uid}`, "get", null, "json");
    if (res.status) {
      let bData = res?.data[0];
      setBusinessData(bData);
    }
  };

  const [showSuccess, setShowSuccess] = useState(false);
  const handleSuccessClose = () => setShowSuccess(false);
  const handleTabSelect = (tab: any) => {
    if (selectedBranch) {
      setActiveTab(tab);
      navigate(`${tab}`, { state: { branchId: location?.state?.branchId }, replace: true });
    }
  };

  function handleCatSelect(data: any) {
    setSelectedCatIds(data)
  }

  return (
    <>
      <section className={`${st.pageWrapper} ${st.maxWidth_pageWrapper}`}>
        <StepperRow key='branch' />
        <SuccessPopup
          showSuccessModal={showSuccess}
          handleSuccessModalClose={handleSuccessClose}
        />
        <div className={`${st.newRedesign_main}`}>
        <BreadCrumbs main={{menu:'Branch', link:'/business/branch'}} current={{menu:'View Branch Details', link:'disabled'}}/>
          <Col md={12}>
            <div className={`${st.head_paraBox} `} ref={branchRef}>
              <h2>{selectedBranch?._id ? selectedBranch?.branchName : "Add service locations & branches"}</h2>
              <p>Provide address of the Business location. Welnezza support multiple branches.
                You can manages all your branches under same business account.</p>
            </div>
          </Col>

          <Col md={12}>
            <div className={`${st.branch_locatedBox_bg} `}>
              <h3>Branch / Location Name</h3>
              <Row className="align-items-center">
                <Col sm={8}>
                  <div className={`${st.sub_headings} `}>
                    {<h6><span>Branch Name:</span> Provide Name of the branch. This name will be visible to Wellnezza Customers</h6>}
                    <div className={`${st.form_located} position-relative`}>
                      <input type="text" className="form-control" disabled={activeTab !== 'info'} placeholder="branch name" value={branchName} onChange={(e: any) => setBranchName(e.target.value)} />
                      {errorTrig && branchName === '' && (
                        <p className={"errorMessage"}>
                          this field is required
                        </p>
                      )}
                    </div>
                  </div>
                </Col>

                <Col sm={4}>
                  <div className="text-center">
                    <h6 style={{ fontSize: '15px' }}>Publish on  Wellnezza</h6>
                    <ToggleNew checked={publish} onChange={(e: any) => setPublish(e.target.checked)} disabled={selectedBranch && (!selectedBranch?.isActive || activeTab !== 'info')} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className={`${st.sub_headings} my-3 `}>
                    <h6><span>Business Categories:</span> By default, all service types you selected during Business account creation are assigned to a branch. <br />
                      You can remove any services listed below if they are not offered by this branch.</h6>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={8} lg={8} md={8}>
                  <div className={`${st.formBox} position-relative`}>
                    <span className={`${st.multiSelbookingect_whiteBg} mt-2`}>
                      <MultiSelect2 onChange={handleCatSelect} disabled={activeTab !== 'info'} value={selectedCatIds} list={filterCat} />
                    </span>
                    {errorTrig && selectedCatIds?.length < 1 && (
                      <p className={"errorMessage out"}>
                        this field is required
                      </p>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </div>
        {/* end */}
        <div className={`${st.tabbingBox}`}>
          <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={handleTabSelect}>
            <div className={`${st.tabbing_pillBox}`}>
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link eventKey="info">
                    {/* <img src={basicInfo} alt="info" className={`${st.defalut}`} />
                    <img src={basicInfoA} alt="info" className={`${st.active}`} /> */}
                    Branch Contact
                  </Nav.Link>
                </Nav.Item>
                <span className={`${st.linespan}`}></span>
                {/* <Nav.Item>
                  <Nav.Link eventKey="about">
                    <img src={about} alt="about" className={`${st.defalut}`} />
                    <img src={aboutA} alt="about" className={`${st.active}`} />
                    About
                  </Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link eventKey="opHrs">
                    {/* <img src={clock} alt="timing" className={`${st.defalut}`} />
                    <img src={clockA} alt="timimg" className={`${st.active}`} /> */}
                    Branch Operating Hours
                  </Nav.Link>
                </Nav.Item>
                <span className={`${st.linespan}`}></span>
                <Nav.Item>
                  <Nav.Link eventKey="non_opHrs">
                    {/* <img src={clock} alt="timing" className={`${st.defalut}`} />
                    <img src={clockA} alt="timimg" className={`${st.active}`} /> */}
                    Non-Operating Hours & Holidays
                  </Nav.Link>
                </Nav.Item>
                
                {/* <Nav.Item>
                <Nav.Link eventKey="OffPeakHours">
                  <img src={clock} alt="OffPeakHours" className={`${st.defalut}`} />
                  <img src={clockA} alt="OffPeakHours" className={`${st.active}`} />
                  Off Peak Hours
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="team">
                  <img
                    src={teamV2}
                    alt="teamIcon"
                    className={`${st.defalut}`}
                  />
                  <img
                    src={teamV2A}
                    alt="team-icon"
                    className={`${st.active}`}
                  />
                  Team
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="amenities">
                  <img
                    src={amenities}
                    alt="Amenities"
                    className={`${st.defalut}`}
                    style={{ height: "17px" }}
                  />
                  <img
                    src={amenitiesActive}
                    alt="Amenities"
                    className={`${st.active}`}
                    style={{ height: "17px" }}
                  />
                  Amenities
                </Nav.Link>
              </Nav.Item> */}
              </Nav>
              {/* <button className="btn saveBtn">Save</button> */}
            </div>

            <Tab.Content>
              <Tab.Pane eventKey={activeTab}>
                <Routes>
                  <Route path={activeTab}
                    element={<AddBrachRouts dynamicName={dynamicName}
                      selectedCatIds={selectedCatIds}
                      branchName={branchName}
                      setPublish={setPublish}
                      setErrorTrig={setErrorTrig}
                      handleStepsFormData={handleStepsFormData}
                      loading={loading}
                      setSelectedCatIds={setSelectedCatIds}
                      setBranchName={setBranchName}
                      branchRef={branchRef}
                    />} />
                </Routes>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>

      <ErrorPopup
        showErrorModal={showErrorModal}
        handleErrorModalClose={handleAlertModalClose}
      />
    </>
  );
};

export default AddBranch;
