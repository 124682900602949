import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";

import { deleteIcon, editButton, iconLogoGrey, openIcon, plus, teamPerson1, teamWorking } from "../../../assets/images";
import TeamGridTable from "./TeamGrid";
import { ExportBtn, ImportBtn } from "../../../components/Business/Buttons";
import { AddTeamTitle } from "../../../components/Business/Modals";
import { httpRequest } from "../../../Apis/commonApis";
import ErrorPopup from "../../../components/Business/Modals/ErrorPopup";
import { useDispatch, useSelector } from "react-redux";
import { Col, Dropdown, Row } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { Status } from "../../../components/Business";
import AddNewTeam from "../../../components/Business/OffCanvas/AddNewTeam";
import StepperRow from "../../../components/Business/StepperBox/StepperRow";
import { SlGrid } from "react-icons/sl";
import { BiGridAlt } from "react-icons/bi";
import { FiList } from "react-icons/fi";
import { IoSearchSharp } from "react-icons/io5";
import TeamList from "./TeamList";
import TitleList from "./TitleList";
import { BsThreeDotsVertical } from "react-icons/bs";
import DataGridTable from "./DataGrid";
import { getBusinessData, updateCounter } from "../../../redux_toolkit/reducer/shareDataReducer";
import BreadCrumbs from "../../../components/Business/BreadCrumbs/BreadCrumbs";
import Loader from "../../../components/Business/Loader/Loader";

const TeamTitle = (props: any) => {
  const { type } = props;
  const navigate = useNavigate();
  let { businessData, counter } = useSelector((state: any) => state.shareDataReducer);
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  const [branchData, setBranchData] = useState<any>([]);
  const [serviceList, setServiceList] = useState<any>([])
  const [selectedBranch, setSelectedBranch] = useState<string>('')
  const [titleInfo, setTitleInfo] = useState([]);
  const [showTitle, setShowTitle] = useState(false);
  const [teamMember, setteamMember] = useState<any[]>([])
  const [teamQuery, setTeamQuery] = useState<string>('')
  const [selectedTitle, setSelectedTitle] = useState<any>('all')
  const [view, setView] = useState('grid')
  const [typeSwitch, setTypeSwitch] = useState<string>('team');
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const handleTitleClose = () => {
    setShowTitle(false);
    getTitle();
  };
  const handleTitleShow = () => setShowTitle(true);
  const getTeamMember = async () => {
    setLoading(true)
    const res: any = await httpRequest(`getAllTeamMembers?businessId=${businessData._id}&type=list`, "get", null, "json");
    if (res?.status) {
      setteamMember(res?.data)
    }
    setLoading(false)
  };

  const getBranch = async () => {
    let res = await httpRequest(`getBranch?businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      setBranchData(res.data);
    }
  };

  const canCreateTeam = subModuleRoles?.['Teams']?.Team?.Create;
  const canCreateTitle = subModuleRoles?.['Teams']?.['Team Title']?.Create;
  const canViewTitle = subModuleRoles?.['Teams']?.['Team Title']?.View;
  const canViewTeam = subModuleRoles?.['Teams']?.Team?.View;

  useEffect(() => {
    if (businessData) {
      getTitle();
      getTeamMember();
      getBranch();
      if (businessData?.stepCompleted > 7 && businessData?.stepCompleted < 10) {
        getService()
      }
    }
  }, [businessData]);

  const getService = async () => {
    const res = await httpRequest(`getBusinessService?businessId=${businessData._id}&type=list`, "get", null, "json");
    if (res.status) {
      setServiceList(res.data)
    }
  };

  const getTitle = async () => {
    const res = await httpRequest(`getTeamTitle?businessId=${businessData._id}&type=list`, "get", null, "json");
    if (res.status) {
      setTitleInfo(res?.data);
    }
  };

  const [showAddNewTeam, setAddNewTeamShow] = useState(false);
  const handleAddNewTeamClose = () => {
    getTeamMember()
    navigate('/business/new-teams')
    setAddNewTeamShow(false)
  }
  const handleAddNewTeamShow = () => setAddNewTeamShow(true);


  const filterTeamMember = teamMember?.filter((team: any) => {
    const checkTitle = selectedTitle === 'all' || team?.Role?.some((t: any) => t?.role?.some((role: any) => role?._id === selectedTitle?._id))
    const checkBranch = !selectedBranch || team?.Role?.some((role: any) => role?.branch?._id === selectedBranch);
    const checkTeamName = !teamQuery || `${team?.firstName}${team?.lastName || ''}${team?.nickName || ''}`?.toLocaleLowerCase()?.replaceAll(' ', '')?.includes(teamQuery?.toLocaleLowerCase()?.replaceAll(' ', ''))
    return checkBranch && checkTeamName && checkTitle
  })
  const isVisible = +businessData?.stepCompleted < 10 && teamMember?.length > 0 && teamMember?.some((team: any) => team?.hoursDetail)
  async function goToWellnezza() {
    const formBusinessData = new FormData();
    formBusinessData.append('stepCompleted', JSON.stringify(9))
    let businessResponse = await httpRequest(
      `updateBusiness/${businessData?._id}`,
      "PATCH",
      formBusinessData,
      "formdata"
    );
    if (businessResponse?.status) {
      dispatch(getBusinessData({ id: `businessId=${businessData?._id}` }));
      navigate('/business/wellnezza/business')
    }
  }

  const handleNavigate = () => {
    if (teamMember?.length > 0) {
      if (businessData?.stepCompleted < 10) {
        goToWellnezza()
      } else {
        navigate('/business/wellnezza/business')
      }
    }
  }

  useEffect(() => {
    if (!canViewTeam) {
      setTypeSwitch('title')
    }
  }, [canViewTeam])

  useEffect(() => {
    if (businessData?.stepCompleted < 10) {
      dispatch(updateCounter({ ...counter, team: teamMember?.length, branch: branchData?.length, service: serviceList?.length }))
    }
  }, [teamMember, branchData, serviceList])


  return (
    <>
      <section className={`${st.pageWrapper} ${view === 'grid' ? st.maxWidth_pageWrapper : ''}`}>
        <StepperRow />
        <div className={`${st.newRedesign_main}`}>

          {/* <BreadCrumbs /> */}
          <Row>
            <Col md={12}>
              <div className={`${st.head_paraBox} ${st.pt50}`}>
                <h2>Team Menu</h2>
                <p>Add the team members . You can differentiate team member providing
                  customer service and support service by check box selection in team title</p>
              </div>
            </Col>
            <Col md={12}>
              <div className={`${st.gridList_view} justify-content-end mb-4`}>
                <button className={`btn grid ${view === 'grid' ? 'active' : ''}`} onClick={() => setView('grid')}>
                  <BiGridAlt />
                </button>
                <button className={`btn listview ${view === 'list' ? 'active' : ''}`} onClick={() => setView('list')}>
                  <FiList />
                </button>
              </div>
            </Col>
          </Row>

          {view === 'grid' ? (
            <Row>
              <Col md={4}>
                <Col lg={11}>
                  {canViewTitle && <div className={`${st.services_leftMain_shadow}`}>
                    <h3>Team Title</h3>
                    <ul className={`${st.serve_listing}`}>
                      <li>
                        <span className={`${st.categoryName} ${selectedTitle === 'all' ? st.active : ''}`} onClick={() => setSelectedTitle('all')}>All Team Title </span>
                        <span className={`${st.categoryAvail}`}>
                          {/* <span className={`${st.value}`}>10</span> */}
                        </span>
                      </li>
                      {titleInfo?.map((item: any) => {
                        return (
                          <TitleList item={item} getTitle={getTitle} setSelectedTitle={setSelectedTitle} selectedTitle={selectedTitle} />
                        )
                      })}
                    </ul>
                    {canCreateTitle && <div className="mt-2">
                      <button className={`btn ${st.addBlue}`} onClick={handleTitleShow}><FaPlus /> Add team Title   </button>
                    </div>}
                  </div>}

                  {isVisible && <div className={`${st.branch_maintain_bg} mt-3`}>
                    <p>Create your shiny new Webpage on Wellnezza Platform</p>
                    <button className="btn" onClick={handleNavigate}>Create Wellnezza Page</button>
                  </div>}
                </Col>
              </Col>
              {canViewTeam && <Col md={8} className="mt-2">
                <Row className="g-2">
                  <Col md={8}>
                    <Row className="g-2">
                      <Col md={4}>
                        <div className={`${st.reFormBox}`}>
                          <select className="form-select" value={selectedBranch} onChange={(e: any) => setSelectedBranch(e.target.value)}>
                            <option value="">Branch</option>
                            {branchData?.map((branch: any) => {
                              return (
                                <option value={branch?._id} key={branch?._id}>{branch?.branchName}</option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>

                      <Col md={8}>
                        <div className={`${st.reFormBox} ${st.searchBoxIcon} position-relative`}>
                          <input type="search" placeholder="Search for team member" className="form-control" value={teamQuery} onChange={(e: any) => setTeamQuery(e.target.value)} />
                          <IoSearchSharp />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  {canCreateTeam && <Col md={4}>
                    <button className={`btn ${st.addBlue}`} onClick={() => {
                      navigate('/business/new-teams/addTeams/info')
                      handleAddNewTeamShow()
                    }}><FaPlus /> Add Team Member</button>
                  </Col>}
                </Row>
                <Row className="mt-3">
                  {!loading && filterTeamMember?.map((team: any) => {
                    return (
                      <TeamList key={team?._id} team={team} getTeamMember={getTeamMember} handleAddNewTeamShow={handleAddNewTeamShow} />
                    )
                  })}
                  {loading && (
                    <div>
                      <Loader />
                    </div>
                  )}
                </Row>
              </Col>}
            </Row>
          ) : (
            <div className={`${st.tablePadding_shadow}`}>
              <div className={`${st.buttonsAction} mt-0`}>
                <ul>
                  <li>
                    <ExportBtn />
                  </li>
                  <li>
                    <ImportBtn />
                  </li>
                  {canCreateTitle && <li>
                    <NavLink
                      to="#"
                      className={`btn ${st.darkBtn}`}
                      onClick={handleTitleShow}
                    >
                      <FaPlus /> Add Team Title
                    </NavLink>
                  </li>}
                  {canCreateTeam && <li>
                    <NavLink
                      to="/business/new-teams/addTeams/info"
                      className={`btn ${st.darkBtn}`}
                      onClick={() => {
                        handleAddNewTeamShow()
                      }}
                    >
                      <FaPlus /> Add Team Member
                    </NavLink>
                  </li>}
                </ul>
              </div>

              <div className={`${st.tableBody} p-0`}>
                <div className={`${st.tableBodyIn}`}>
                  <div className={`${st.type}`}>
                    <div className={`${st.edit_publish_checkBox} `}>
                      {canViewTeam && <label className={`${st.radio} d-inline-flex`}>
                        <input type="radio" value='team' checked={typeSwitch === 'team'} onChange={(e: any) => setTypeSwitch(e.target.value)} />
                        <span className={`${st.checkmark}`}></span>
                        <span className={`${st.labelName} ms-1`}>Team Table</span>
                      </label>}

                      {canViewTitle && <label className={`${st.radio} d-inline-flex`}>
                        <input type="radio" value='title' checked={typeSwitch === 'title'} onChange={(e: any) => setTypeSwitch(e.target.value)} />
                        <span className={`${st.checkmark}`}></span>
                        <span className={`${st.labelName} ms-1`}>Team Title Table</span>
                      </label>}
                    </div>
                  </div>
                  {typeSwitch === 'team' ?
                    (
                      <TeamGridTable teamMember={teamMember} getTeamMember={getTeamMember} handleAddNewTeamShow={handleAddNewTeamShow} />
                    ) :
                    (
                      <DataGridTable titleInfo={titleInfo} getTitle={getTitle} type={type} />
                    )}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* end */}
      </section>

      {showTitle && <AddTeamTitle showTitle={showTitle} handleTitleClose={handleTitleClose}
        getTitle={getTitle} businessData={businessData} />}

      <AddNewTeam show={showAddNewTeam} handleAddNewTeamClose={handleAddNewTeamClose} getTeamMember={getTeamMember} />
    </>
  );
};

export default TeamTitle;
