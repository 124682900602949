import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpRequest } from "../../Apis/commonApis";

const initialState = {
    getBusinessDataState: 0,
    getBusinessCatState: 0,
    businessData: null,
    allBusCategories: [],
    serviceTags: [],
    businessUserRoles: null,
    spand:false,
    counter:{branch:0, service:0,team:0, bundles:0, promotion:0}
};

export let businessDataFromRedux: any;


export const getBusinessData: any = createAsyncThunk(
    "getBusinessData",
    async (body: any) => {
        const res = await httpRequest(`getBusiness?${body.id}`, "get", null, "json");
        return res;
    }
);

export const getBusinessCategory: any = createAsyncThunk(
    "getBusinessCategory",
    async () => {
        const res = await httpRequest(`getCatagory`, "get", null, "json");
        return res;
    }
);

export const getServiceTags: any = createAsyncThunk(
    "getServiceTags",
    async () => {
        const res = await httpRequest(`get_ServiceTag`, "get", null, "json");
        return res;
    }
);

export const getBusinessUserRoles: any = createAsyncThunk(
    "getBusinessUserRoles",
    async (body: any) => {
        const res = await httpRequest(`getAssignRole?businessId=${body.id}`, "get", null, "json");
        return res;
    }
);

const businessDataSlice: any = createSlice({
    name: "manageCoupon",
    initialState,
    reducers: {
        changeRegistrationState(state = initialState) {
            state.getBusinessDataState = 0;
        },
        changeSideBarSpand(state, action) {
            state.spand = action.payload
        },
        updateCounter(state, action) {
            state.counter = action.payload
        },
        clearCounter(state) {
            state.counter = {branch:0, service:0,team:0, bundles:0, promotion:0}
        }
    },

    extraReducers: {
        [getBusinessData.fulfilled]: (
            state: any,
            { payload }
        ) => {
           
            state.getBusinessDataState = 1;
            if (payload?.data) {
                state.businessData = payload?.data[0];
            }
            if (payload.error) {
                state.error = payload.error;
            } else {
                state.error = payload.message;
            }
        },
        [getBusinessCategory.fulfilled]: (
            state: any,
            { payload }
        ) => {
            state.getBusinessCatState = 1;
            if (payload?.data) {
                state.allBusCategories = payload?.data;
            }
            if (payload.error) {
                state.error = payload.error;
            } else {
                state.error = payload.message;
            }
        },
        [getServiceTags.fulfilled]: (
            state: any,
            { payload }
        ) => {
            if (payload?.data) {
                state.serviceTags = payload?.data;
            }
        },
        [getBusinessUserRoles.fulfilled]: (
            state: any,
            { payload }
        ) => {
            if (payload?.data && payload?.data.length > 0) {
                let roledata = payload?.data[0]?.roles;
                state.businessUserRoles = roledata;
            }
        },
    },
});

export const { changeRegistrationState, changeSideBarSpand, updateCounter, clearCounter } =
businessDataSlice.actions;
export default businessDataSlice.reducer;
