import { Col, Row } from "react-bootstrap";
import st from "../../../../assets/stylesheet/style.module.scss";
import cx from '../index.module.scss';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { httpRequest } from "../../../../Apis/commonApis";
import LoadingBtn from "../../Buttons/LoadingBtn";

export default function BasicInfo(props: any) {
    const { handleStepsFormData, editServiceData, serviceName, isExternal,serviceCategoryId, setApiError,selectedServiceCat, setErrorTrig , submitForm } = props;
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    );

    const [branchData, setBranchData] = useState<any>([]);

    const getBranches = async () => {
        let res = await httpRequest(`getBranch?businessId=${businessData._id}`, "get", null, 'json');
        if (res.status === true) {
            setBranchData(res.data);
        }
    }

    useEffect(() => {
        if (businessData) {
            getBranches();
        }
    }, [businessData]);

    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = submitForm;

    useEffect(() => {
        if (editServiceData) {
            reset();
            setValue('serviceAvilableFor', editServiceData.serviceAvilableFor?.split(","));
            setValue('serviceDescription', editServiceData.serviceDescription);
            setValue('ServiceAftercareDescription', editServiceData.ServiceAftercareDescription);
        }
    }, [editServiceData]);

    const submitHandler = async (postdata: any) => {
        const basicData = { ...postdata };;
        if (!serviceName) {
            setApiError('this field is required')
            setTimeout(() => {
                setApiError('')
            }, 2000)
            return
        }
        if (!selectedServiceCat && isExternal) {
            setErrorTrig(true)
            setTimeout(() => {
                setErrorTrig(false)
            }, 2000)
            return
        }

        basicData.serviceName = serviceName;
        basicData.serviceCatagoryId = isExternal ? selectedServiceCat : serviceCategoryId;
        basicData.serviceAvilableFor = postdata?.serviceAvilableFor?.join(",")
        basicData.businessId = businessData?._id;
        basicData.branchId = editServiceData?.branchId && editServiceData?.branchId?.length !== 0 ? editServiceData?.branchId?.map((item: any) => item?._id) : branchData?.map((item: any) => item?._id);
        setLoading(true);
        let apiUrl = 'createBusinessService';
        let apiMethod = 'post';
        if (editServiceData && editServiceData?._id) {
            apiUrl = `updateBusinessService/${editServiceData?._id}`;
            apiMethod = 'patch';
            if (editServiceData?.serviceName === basicData?.serviceName) {
                delete basicData['serviceName'];
            }
        }
        const res = await httpRequest(apiUrl, apiMethod, basicData, "json");
        if (res.status) {
            handleStepsFormData(res.data, 'pricing');
            setApiError("")
        } else {
            setApiError(res.message)
        }
        setLoading(false);
    }

    return (
        <>
            <Row>
                <Col md={10}>
                    <div className={`${st.reFormBox}`}>
                        <label htmlFor="">Service Available For</label>
                        <div>
                            <div className={`${st.edit_publish_checkBox} `}>
                                <label className={`d-inline-flex ${st.checkbox}`}>
                                    <input type="checkbox" value='female' {...register("serviceAvilableFor", {
                                        required: true,
                                    })} />
                                    <span className={`${st.checkmark}`}></span>
                                    <span className={`${st.labelName} ms-1`}>Female</span>
                                </label>
                                <label className={`d-inline-flex ${st.checkbox}`}>
                                    <input type="checkbox" value="male" {...register("serviceAvilableFor", {
                                        required: true,
                                    })} />
                                    <span className={`${st.checkmark}`}></span>
                                    <span className={`${st.labelName} ms-1`} >Male</span>
                                </label>
                                <label className={`d-inline-flex ${st.checkbox}`}>
                                    <input type="checkbox" value="anyone" {...register("serviceAvilableFor", {
                                        required: true,
                                    })} />
                                    <span className={`${st.checkmark}`}></span>
                                    <span className={`${st.labelName} ms-1`}>Everyone</span>
                                </label>
                            </div>
                            {errors?.serviceAvilableFor?.type === "required" && (
                                <p className={"errorMessage"}>
                                    this field is required
                                </p>
                            )}
                        </div>
                    </div>
                </Col>
                <Col md={10}>
                    <div className={`${st.reFormBox}`}>
                        <label htmlFor="">Service Description</label>
                        <textarea placeholder="Enter Your Service Description" style={{ height: '80px' }} className="form-control" {...register("serviceDescription")} />
                    </div>
                </Col>
                <Col md={10}>
                    <div className={`${st.reFormBox}`}>
                        <label htmlFor="">Service Aftercare Description</label>
                        <textarea placeholder="Enter Your Service Aftercare Description" style={{ height: '80px' }} className="form-control" {...register("ServiceAftercareDescription")} />
                    </div>
                </Col>
            </Row>
            <Row>
                <div className={`${cx.offcanvas_footer}`}>
                    <Col md={12}>
                        <div className={`${st.formBox} ${cx.formSign}`}>
                            <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-3`}>
                                <button className={`btn ${st.btn3} ${cx.btnGray}`} type="submit" onClick={handleSubmit(submitHandler)}>
                                    {loading ? <LoadingBtn /> : 'Save'}
                                </button>
                                {/* <button className={`btn ${st.btn3} ${cx.btnGray} ${cx.btnGrayLight}`} onClick={() => {
                                    handleStepsFormData(null, 'amenities')
                                }}
                                    type="submit">
                                    Cancel
                                </button> */}
                            </div>
                        </div>
                    </Col>
                </div>
            </Row>
        </>
    )
}