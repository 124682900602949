import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer, dateFnsLocalizer, Event } from 'react-big-calendar'
import withDragAndDrop, { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import cx from './index.module.scss';
import st from "../../../assets/stylesheet/style.module.scss";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";

import moment from 'moment';
import { httpRequest } from '../../../Apis/commonApis';
import { useSelector } from 'react-redux';
import { plusFill } from '../../../assets/images';
import { useNavigate } from 'react-router-dom';
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import convertHoursToHoursAndMinutes from '../../../constants/Constants';
moment.locale('ko', {
    month: {
        dow: 1,
        doy: 1,
    },
});


export default function MonthCalendar(props: any) {
    let { bookingDate, temaMemberData, showButton, teamMemberid, bookingObj } = props
    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    const localizer = momentLocalizer(moment)
    let { branchId } = useSelector((state: any) => state.BranchReducer);
    const [temaMemberlist, setTemaMemberlist] = useState<any>([])
    const [startDate, setStartDate] = useState(moment(new Date()).startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).endOf('month').format('YYYY-MM-DD'));
    const [teamMemberId, setTeammemberId] = useState<any>("");

    const MyCustomEvents = (events: any) => {

        const navigate = useNavigate();
        let bookingdayView = (e: any) => {
            if((bookingObj?.canCreateOwnBooking || bookingObj?.canCreateAllBooking)){
                navigate('/business/bookings', { state: { date: events?.event?.start, temMemberId: events?.event?.teamMemberId } })
            }
        }
        return (
            <div className={cx.box} onClick={bookingdayView}>
                <h1>Available : {convertHoursToHoursAndMinutes(+events?.event?.title)}</h1>
                <h1 className={cx.unavailable}>Booked : {convertHoursToHoursAndMinutes(+events?.event?.notAvailable)}</h1>
                {(bookingObj?.canCreateOwnBooking || bookingObj?.canCreateAllBooking) && <p><img src={plusFill} className={cx.dayButton} alt="Add" /></p>}
            </div>

        );
    };

    const handleNavigate = (newDate: any, view: any, action: any) => {
        const startDate = moment(newDate).startOf('month').format('YYYY-MM-DD');
        const endDate = moment(newDate).endOf('month').format('YYYY-MM-DD');
        setStartDate(startDate)
        setEndDate(endDate)
    };

    const getTememberCal = async () => {
        const res: any = await httpRequest(`getTeamMembersCalender?branchId=${branchId}&businessId=${businessData?._id}&startDate=${startDate}&endDate=${endDate}&teamMemberId=${teamMemberId}`, "get", null, "json");
        if (res?.status == true) {
            setTemaMemberlist(res?.data)
            bookingDate(res?.data)
        } else {
            setTemaMemberlist([])
        }
    };
    useEffect(() => {
        getTememberCal()
    }, [teamMemberId, branchId, startDate, endDate])

    useEffect(() => {
        showButton(teamMemberId)
    }, [teamMemberId])

    let events: any = temaMemberlist?.dateWise?.map((val: any) => {
        const momentObject = moment(val?.date, 'YYYY-MM-DD');
        const dateObject = momentObject.toDate();
        return {
            title: val?.available,
            //   available:val?.available,
            notAvailable: val?.notAvailable,
            teamMemberId: teamMemberId,
            start: dateObject.toString(), // month is 0-indexed, so subtract 1
            end: dateObject.toString(),
        }
    })
    useEffect(() => {
        if (teamMemberid) {
            setTeammemberId(teamMemberid)
        }
    }, [teamMemberid])

    const MyCustomHeader = (actions: any) => {
        const { onView, label, views, view, onNavigate } = actions;
        function handleNavigate(type: string) {
            onNavigate(type);
        }
        const handleTeamChange = (id: any) => {
            setTeammemberId(id)
        }
        return (
            <Row className="align-items-center">
                <Col lg={2} md={5} sm={6}>
                    <div>
                        {/* <p className={cx.hours}>Team List</p> */}
                        <p className={`${cx.number} ${cx.number_select_drop}`} >
                            <select
                                className="form-select" onChange={(e: any) => handleTeamChange(e.target.value)} value={teamMemberId}>
                                {temaMemberData?.length > 1 && <option value="">All</option>}
                                {temaMemberData?.map((item: any) => {
                                    return <option key={item?._id} value={item?._id}>{item?.firstName} {item?.lastName}</option>;
                                })}

                            </select>
                        </p>
                    </div>
                </Col>

                <Col lg={7} md={5} sm={6}>
                    <div className={`${st.formBox} ${cx.changeDateBox} `}>
                        <h5>{label}</h5>
                    </div>
                </Col>
                <Col lg={3} md={5} sm={6}>
                    <div className={`${st.formBox} ${cx.changeDateBox} `}>
                        <div className={`${cx.btnBox}`}>
                            <button className={cx.today} onClick={() => handleNavigate("TODAY")}>Today</button>
                            <button className={cx.leftBtn} onClick={() => handleNavigate("PREV")}><MdOutlineChevronLeft className={cx.left} /></button>
                            <button className={cx.rightBtn} onClick={() => handleNavigate("NEXT")}><MdOutlineChevronRight className={cx.left} /></button>
                        </div>

                    </div>
                </Col>
            </Row>
        )
    }
    return (
        <div className={` ${cx.monthlyCalendyBox} `}>
            <Calendar
                localizer={localizer}
                views={['month']}
                events={events}
                style={{ height: 500 }}
                onNavigate={handleNavigate}
                components={{
                    event: MyCustomEvents,
                    toolbar: MyCustomHeader,
                }}
            />
        </div>
    )
}
