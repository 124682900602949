import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import st from "../../../assets/stylesheet/style.module.scss";
import { deleteIcon, editButton } from '../../../assets/images';
import Status from '../../../components/Business/Form/Status';
import { IoSearchSharp } from 'react-icons/io5';
import { FaPlus } from 'react-icons/fa';
import BundleCard from '../AllServices/BundleCard';
import { useNavigate } from 'react-router-dom';
import BundleCanvas from '../../../components/Business/OffCanvas/BundleCanvas';
import { useSelector } from 'react-redux';

export default function BundleInfo(props: any) {
  const { bundleList, getBundle, setActiveTab } = props;
  const navigate = useNavigate();
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  const [published, setPublished] = useState<any>('')
  const [query, setQuery] = useState<any>('')
  const [showBundleCanvas, setBundleCanvasShow] = useState(false);
  const handleBundleCanvasClose = () => {
    navigate('/business/publish');
    setBundleCanvasShow(false)
    getBundle()
  }
  const handleBundleCanvasShow = () => setBundleCanvasShow(true);
  let publishCounter = 0
  const filterBundle = bundleList?.filter((bundle: any) => {
    if (bundle?.isPublish) {
      publishCounter++
    }
    const queryCheck = !query || bundle?.bundleName?.toLocaleLowerCase()?.replaceAll(' ', '')?.includes(query?.toLocaleLowerCase()?.replaceAll(' ', ''))
    const publishCheck = published === '' || (published === 'publish' ? bundle?.isPublish : !bundle?.isPublish)
    return publishCheck && queryCheck
  })

  const canCreateBundle = subModuleRoles?.['Services']?.Bundles?.Create;
  return (
    <>
      <BundleCanvas show={showBundleCanvas} handleBundleCanvasClose={handleBundleCanvasClose} />
      <Row>
        <Col md={12}>
          <div className='d-flex gap-3 mb-3 justify-content-end'>
            <div className={`${st.reFormBox} mb-0`} style={{ width: '155px' }}>
              <select className="form-select" value={published} onChange={(e: any) => setPublished(e.target.value)}>
                <option value="">All</option>
                <option value="publish">List on Wellnezza</option>
                <option value="unpublish">Unpublish</option>
              </select>
            </div>
            {/* <div className='text-end'>
              <button className={`${st.addGrey} btn`} onClick={() => setActiveTab('promotion')}>
                Next
              </button>
            </div> */}
          </div>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col xl={10} lg={12} className="mb-4 pb-2">
          <div className={`${st.publish_pagesHeading}`}>
            <p className='mb-0'>{publishCounter} Bundles will be published in Wellnezza</p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={9} lg={10} md={11}>
          <Row className="g-3 mb-3">
            <Col md={5}>
              <Row className="g-3">
                <Col md={12}>
                  <div className={`position-relative ${st.reFormBox} ${st.searchBoxIcon} mb-0`}>
                    <input type="search" placeholder="Search for Bundles" className="form-control" value={query} onChange={(e: any) => setQuery(e.target.value)} />
                    <IoSearchSharp />
                  </div>
                </Col>
              </Row>
            </Col>

            {canCreateBundle && <Col md={7}>
              <Row className="g-3">
                <Col md={12} className='text-end'>
                  <button className={`btn ${st.addBlue}`} onClick={() => {
                    navigate('/business/publish/add-bundle/info')
                    handleBundleCanvasShow()
                  }} ><FaPlus /> Add Bundles</button>
                </Col>
              </Row>
            </Col>}
          </Row>
          <Row>
            {filterBundle?.map((bundle: any) => {
              return (
                <BundleCard key={bundle?._id} bundle={bundle} getBundle={getBundle} type='publish' handleBundleCanvasShow={handleBundleCanvasShow} />
              )
            })}
          </Row>
        </Col>
      </Row>
    </>
  )
}
