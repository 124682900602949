import { Col, Dropdown } from "react-bootstrap";
import st from "../../../assets/stylesheet/style.module.scss";
import { BsThreeDotsVertical } from "react-icons/bs";
import { deleteIcon, editButton } from "../../../assets/images";
import { FaPlus } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";
import AddServicesCategory from "../../../components/Business/Modals/AddServicesCategory";
import ServiceCategoryItem from "./ServiceCategoryItem";
import Loader from "../../../components/Business/Loader/Loader";

export default function CategoryBox(props : any ) {
    const {setFilterCat, filterCat, handleServiceCanvasShow , getServiceCatagory, catData, categoryLoading} = props;
    const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
    const [showServicesCategory, setServicesCategoryShow] = useState(false);
    const handleServicesCategoryClose = () => setServicesCategoryShow(false);
    const handleServicesCategoryShow = () => setServicesCategoryShow(true);
    
    const totalServiceCount = catData?.reduce((acc: any, val: any) => {
        return acc + val?.count
    },0)
    const canCreateCat = subModuleRoles?.['Services']?.['Service Category']?.Create;

    return (
        <>

            {showServicesCategory && <AddServicesCategory
                show={showServicesCategory}
                handleServicesCategoryClose={handleServicesCategoryClose}
                getServiceCatagory={getServiceCatagory} />}
            <Col xl={11} lg={12}>
                <div className={`${st.services_leftMain_shadow}`}>
                    <h3>Service Categories</h3>
                    <ul className={`${st.serve_listing}`}>
                        <li onClick={() => setFilterCat('all')}>
                            <span className={`${st.categoryName} ${filterCat === 'all' ? st.active : ''}`}>All Categories</span>
                            <span className={`${st.categoryAvail}`}>
                                <span className={`${st.value}`}>{totalServiceCount}</span>
                            </span>
                        </li>
                        {!categoryLoading && catData?.map((cat: any, c: number) => {
                            return (
                                <ServiceCategoryItem key={c} cat={cat} getServiceCatagory={getServiceCatagory} handleServiceCanvasShow={handleServiceCanvasShow} {...props}/>
                            )
                        })}
                        {categoryLoading && (
                            <div>
                                <Loader />
                            </div>
                        )}
                    </ul>
                    {canCreateCat && <div className="mt-2 pt-1 text-center">
                        <button className={`btn ${st.addBlue}`} onClick={handleServicesCategoryShow} ><FaPlus /> Add New Categories </button>
                    </div>}
                </div>
            </Col>
        </>
    )
}