import React from 'react'
import BusinessInfo from './BusinessInfo';
import { useDispatch, useSelector } from 'react-redux';
import { getBusinessData } from '../../../redux_toolkit/reducer/shareDataReducer';

export default function WellnezzaRoute(props: any) {
    const { dynamicName } = props;
    const dispatch = useDispatch()
    let { businessData } = useSelector((state: any) => state.shareDataReducer);

    const getBusiness = async () => {
        let udata = localStorage.getItem('user_data');
        let userdata = udata ? JSON.parse(udata) : '';
        let bid = '';
        if (userdata?.userType === "teamMember") {
            bid = `businessId=${userdata?.businessId}`;
        } else {
            bid = `id=${userdata?._id}`;
        }
        dispatch(getBusinessData({ id: bid }));
    };
    return (
        <>
            {dynamicName === "business" && <BusinessInfo businessData={businessData} getBusiness={getBusiness} isEdit={true} />}
        </>
    )
}
