import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useNavigate } from "react-router-dom";
import { httpRequest } from '../../../../Apis/commonApis';
import BasicTab from './BasicTab';
import AddServiceTab from './AddServiceTab';
import AssignBranch from './AssignBranch';
export default function BundleRoute(props: any) {
    const { dynamicName, handleStepsFormData, loading,setEditBundleData, editBundleData, setBundleName } = props;
    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    let { branchId } = useSelector((state: any) => state.BranchReducer);
    const location = useLocation();
    const navigate = useNavigate();

    

    useEffect(() => {
        const prevBId = localStorage.getItem("currentBranchId");
        if (branchId && branchId !== prevBId) {
            navigate(-1);
        }
    }, [branchId]);

    useEffect(() => {
        if (location.state && businessData) {
            getBundle();
        }
    }, [businessData, location?.state]);

    
    async function getBundle() {
        const res = await httpRequest(
            `getBundle?_id=${location?.state?.bundleId}&type=list`,
            "get",
            null,
            "json"
        );
        if (res.status) {
            setEditBundleData(res.data?.[0]);
            setBundleName(res.data?.[0]?.bundleName)
        }
    }
    return (
        <>
            {dynamicName === "info" &&
                <BasicTab editBundleData={editBundleData} loading={loading} handleStepsFormData={handleStepsFormData}/>
            }
            {dynamicName === "pricing" &&
                <AddServiceTab editBundleData={editBundleData} loading={loading} handleStepsFormData={handleStepsFormData}/>
            }
            {dynamicName === "assign-branch" &&
                <AssignBranch editBundleData={editBundleData} loading={loading} handleStepsFormData={handleStepsFormData}/>
            }

        </>
    )
}