import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FaPlus } from 'react-icons/fa';
import st from '../../../../assets/stylesheet/style.module.scss';
import cx from '../index.module.scss'
import { deleteIcon } from '../../../../assets/images';
import { httpRequest } from '../../../../Apis/commonApis';
import { useSelector } from 'react-redux';
import LoadingBtn from '../../Buttons/LoadingBtn';
import ErrorPopup from '../../Modals/ErrorPopup';
import AddServiceModal from '../../Modals/AddServiceModal';

export default function AddServiceTab({ editBundleData, loading, handleStepsFormData }: any) {
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    );
    const [showErrorModal, setShowErrorModal] = useState("");
    const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);
    const handleAlertModalClose = () => setShowErrorModal("");
    const [service, setService] = useState<any[]>();
    const [showAddCategory, setAddCategoryShow] = useState<any>(false);
    const [submitValue, setSubmitValue] = useState<any>({ discount: "", finalPrice: "" })
    const handleAddCategoryClose = () => setAddCategoryShow(false);
    const handleAddCategoryShow = () => setAddCategoryShow(true);
    const [selectedServices, setSelectedServices] = useState<any>([]);
    const [errorTrig, setErrorTrig] = useState<boolean>(false);

    const [branchData, setBranchData] = useState<any>([]);
    const [selectedBranchId, setSelectedBranchId] = useState<any>([]);
    const [message, setMessage] = useState<any>("")

    let serviceHandler = (e: any) => {
        setSelectedServices(e);
        setSubmitValue({ discount: "", finalPrice: "" })
    }

    const getBusinessPrice = async (id: any) => {
        if (id) {
            let res = await httpRequest(`getBusinessPrice?businessId=${id}&type=package`, "get", null, "json");
            if (res.status) {
                setService(res?.data);
            }
        }
    };

    const getBranches = async () => {
        let res = await httpRequest(`getBranch?businessId=${businessData._id}`, "get", null, 'json');
        if (res.status) {
            setBranchData(res.data);
        }
    }

    useEffect(() => {
        if (businessData) {
            getBusinessPrice(businessData?._id);
            getBranches();
        }
    }, [businessData])

    function handleDelete(id: any) {
        const filterServices = selectedServices?.filter((item: any) => item?._id !== id);
        setSelectedServices(filterServices);
        setSubmitValue({ discount: "", finalPrice: "" })
    }

    function handleSubmitValue(e: any, type: string) {
        if (type === "%" && Number.parseInt(e.target.value) > 100) {
            return
        }
        if (type === "sgd" && Number.parseInt(e.target.value) > totalPrice) {
            return
        }
        const disc: any = e.target.value === "" ? "" : type === "%" ? `${Number.parseInt(e.target.value)} %` : `${Number.parseInt(e.target.value)} sgd`;
        const finalPrice = e.target.value === "" ? "" : type === "%" ? (totalPrice - (totalPrice * (Number.parseInt(e.target.value) / 100)))?.toFixed(2) : e.target.value
        setSubmitValue({ discount: disc, finalPrice })
    }
    const handleBranch = (e: any) => {
        if (!e.target.checked) {
            const filterBranch = selectedBranchId?.filter((item: any) => item !== e.target.value);
            setSelectedBranchId(filterBranch)
        } else {
            setSelectedBranchId([...selectedBranchId, e.target.value])
        }
    }
    function conflictedBranch(serviceArray: any, branchIds: any) {
        const conflictedArray: any = []
        // Check each service's branchId array against the first array
        serviceArray.forEach((service: any) => {
            const serviceBranchIds = new Set(service?.branchId);
            const missingBranchIds = branchIds.filter((branchId: any) => !serviceBranchIds.has(branchId));
            if (missingBranchIds.length > 0 || branchIds?.length === 0) {
                const serviceName = service.BusinessService.serviceName;
                conflictedArray.push({ service: serviceName, branchIds: missingBranchIds?.map((item: any) => branchData?.find((branch: any) => branch?._id === item)) })
            }
        });

        return conflictedArray
    }
    function handleSubmit() {
        if (submitValue?.discount === "") {
            setErrorTrig(true)
            setTimeout(() => {
                setErrorTrig(false)
            }, 3000)
            return
        } else {
            setErrorTrig(false)
        }
        const conflictedBranches: any = conflictedBranch(selectedServices, selectedBranchId);
        const checkIfServiceInEveryBranch = selectedBranchId?.every((branch: any) => {
            const serviceBranchCritiria = selectedServices?.every((item: any) => item?.branchId?.includes(branch))
            return serviceBranchCritiria
        })

        // if (selectedBranchId?.length === 0) {
        //     let message: any = 'please select branch'
        //     handleAlertModalShow(`Error,`);
        //     setMessage(message)
        //     setErrorTrig(true)
        //     setTimeout(() => {
        //         setErrorTrig(false)
        //     }, 3000)
        //     return
        // } else {
        //     setErrorTrig(false)
        // }

        if (!checkIfServiceInEveryBranch) {
            let message: any = conflictedBranches?.map((conflict: any) => `${conflict?.service} is not available in ${conflict?.branchIds?.map((branch: any) => branch?.branchName)} ${conflict?.branchIds?.length > 1 ? "Branches" : "Branch"}`)
            handleAlertModalShow(`Error,`);
            setMessage(message?.join(", "))
            setErrorTrig(true)
            setTimeout(() => {
                setErrorTrig(false)
            }, 3000)
            return
        } else {
            setErrorTrig(false)
        }
        const finalData = { branchArr: JSON.stringify(selectedBranchId), priceId: JSON.stringify(selectedServices?.map((item: any) => ({ id: item?._id }))), finalPrice: submitValue?.finalPrice, discount: submitValue?.discount }
        handleStepsFormData(finalData, "assign-branch")
    }

    useEffect(() => {
        if (editBundleData?._id) {
            const formattedPrice = editBundleData?.priceId?.map((item: any) => {
                return {
                    ...item?.id,
                    ServiceCategory: item?.id?.serviceId?.serviceCatagoryId,
                    BusinessService: item?.id?.serviceId

                }
            })
            setSelectedServices(formattedPrice)
            setSubmitValue({ discount: editBundleData?.discount, finalPrice: editBundleData?.finalPrice })
        }
        if (editBundleData?.branchArr && editBundleData?.branchArr?.length !== 0) {
            setSelectedBranchId(editBundleData?.branchArr)
        }

    }, [editBundleData]);

    const totalDuration = selectedServices?.reduce((acc: any, val: any) => acc + Number.parseInt(val?.duration), 0)
    const totalPrice = selectedServices?.reduce((acc: any, val: any) => acc + Number.parseFloat(val?.seasonPrice), 0)

    return (
        <>
            <ErrorPopup
                showErrorModal={showErrorModal}
                handleErrorModalClose={handleAlertModalClose}
                message={message}
            />
            {showAddCategory && <AddServiceModal
                service={service}
                packageData={serviceHandler}
                showAddCategory={showAddCategory}
                handleAddCategoryClose={handleAddCategoryClose}
                existData={selectedServices}
                serviceType="service"
            />}
             <Row>
                <Col md={10}>
                    <div className={`${st.reFormBox}`}>
                        <label className='mb-2'>Service Available For</label>
                        <div>
                            <div className={`${st.edit_publish_checkBox} ${st.checkBox_FlexColumn}`}>
                                {branchData?.map((item: any) => {
                                    return (
                                        <label className={`d-inline-flex ${st.checkbox}`} key={item?._id}>
                                            <input type="checkbox" onChange={handleBranch} value={item?._id} checked={selectedBranchId?.some((branchId: any) => branchId === item?._id)} />
                                            <span className={`${st.checkmark}`}></span>
                                            <span className={`${st.labelName} ms-1`}>{item?.branchName}</span>
                                        </label>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            {selectedServices?.length === 0 && <Row>
                <Col md={7} className='m-auto mt-2 mb-4'>
                    <div className={`${st.branch_maintain_bg} `}>
                        <p>
                            Add services in bundle
                        </p>
                        <button className="btn" onClick={(e: any) => {
                            e.preventDefault()
                            handleAddCategoryShow();
                        }}>
                            <FaPlus /> Add Services
                        </button>
                        {selectedServices?.length === 0 && errorTrig && <p className='errorMessage mt-2 text-center'>Please add atleast one service</p>}
                    </div>
                </Col>
            </Row>}
            {selectedServices?.length !== 0 && (
                <Col md={12}>
                    <div className={`${st.bundle_discountPriced} justify-content-between`}>
                        <h6>Services Included</h6>
                        <button className="btn" style={{ backgroundColor: '#7C7C7C', color: '#fff', borderRadius: '10px' }} onClick={(e: any) => {
                            e.preventDefault()
                            handleAddCategoryShow();
                        }}>Add Services</button>
                    </div>
                    <div className={`${st.tableCollapse}`} style={{ marginTop: '46px' }}>
                        <table className="table" >
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Category</th>
                                    <th scope="col">Service</th>
                                    <th scope="col">Details</th>
                                    <th scope="col">Duration (min)</th>
                                    <th scope="col">Price (SGD)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedServices?.map((price: any, i: number) => {
                                    return (
                                        <tr key={i}>
                                            <td>{price?.ServiceCategory?.serviceCategoryName}</td>
                                            <td>{price?.BusinessService?.serviceName}</td>
                                            <td>{`By ${price?.teamTitleId ? price?.TeamTitleData?.teamTitle : "Any Stylist"}`}</td>
                                            <td>{Number.parseInt(price?.duration)} mins</td>
                                            <td style={{ whiteSpace: 'nowrap' }}>{Number.parseFloat(price?.seasonPrice)?.toFixed(2)} SGD
                                                <button className='border-0 bg-transparent ms-2'> <img style={{ width: '14px' }} src={deleteIcon} alt="deleteIcon" onClick={() => handleDelete(price?._id)} /></button>
                                            </td>
                                            {/* <td scope="row"><span>{price?.ServiceCategory?.serviceCategoryName}</span></td>
                                            <td><span>{price?.BusinessService?.serviceName}</span></td>
                                            <td><span>{price?.teamTitleId ? price?.TeamTitleData?.teamTitle : "Any Stylist"}</span></td>
                                            <td><span>{price?.priceText || ""}</span></td>
                                            <td><span>{price?.duration}</span></td>
                                            <td><span>SGD {price?.seasonPrice}</span></td>
                                            <td>
                                                <div className={cx.actionButton} onClick={() => handleDelete(price?._id)}>
                                                    <img src={deleteIcon} alt="Delete" />
                                                </div>
                                            </td> */}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className={`${st.bundle_discountPriced}`}>
                        <h6>Bundle Price</h6>
                        <div className={`${st.reFormBox}`}>
                            <input type="text" placeholder="Discount (%)"
                                className="form-control"
                                style={errorTrig && submitValue?.discount === "" ? { border: "1px solid red", boxShadow: "none" } : {}}
                                onChange={(e: any) => handleSubmitValue(e, "%")}
                                value={submitValue?.discount?.includes("%") && submitValue?.discount ? +submitValue?.discount?.split("%")?.[0] : ""}
                            />
                        </div>
                        <div className={`${st.reFormBox}`}>
                            <input type="text"
                                placeholder="Discount (Price)"
                                className="form-control"
                                style={errorTrig && submitValue?.discount === "" ? { border: "1px solid red", boxShadow: "none" } : {}}
                                onChange={(e: any) => handleSubmitValue(e, "sgd")}
                                value={submitValue?.discount?.includes("sgd") && submitValue?.discount ? +submitValue?.discount?.split("sgd")?.[0] : ""}
                            />
                        </div>
                    </div>
                    {submitValue?.finalPrice && <div className={`${st.bundle_discountPricedText}`}>
                        <span>{`SGD ${submitValue?.finalPrice}`}</span>
                    </div>}

                    {selectedServices?.length === 0 && (
                        <p style={{ textAlign: 'center', color: '#222', fontSize: '14px' }}>No services added yet! </p>
                    )}

                </Col>
            )}
            <Row>
                <Col md={12}>
                    <div className={`${cx.offcanvas_footer}`}>
                        <Row>
                            <Col md={12}>
                                <div className={`${st.formBox} ${cx.formSign}`}>
                                    <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-3`}>
                                        <button className={`btn ${st.btn3} ${cx.btnGray}`} type="submit" onClick={handleSubmit} disabled={loading}>
                                            {loading ? <LoadingBtn /> : 'Save'}
                                        </button>
                                        <button className={`btn ${st.btn3} ${cx.btnGray} ${cx.btnGrayLight}`} type="submit" onClick={(e: any) => {
                                            e.preventDefault();
                                            handleStepsFormData(null, 'info');
                                        }}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
           
        </>
    )
}
