import React, { useState } from "react";
import m from "./Modal.module.scss";
import { Col, Modal, Row } from "react-bootstrap";

const TermsCondition = (props: any) => {

  let { show, handleTermsConditionClose } = props;

  return (
    <>
      <Modal
        centered
        show={show}
        backdrop="static"
        keyboard={false}
        size="lg"
        onHide={handleTermsConditionClose}
        className={`${m.add_card_detailMain} ${m.terms_Popup} `}
      >
        <Modal.Header closeButton>
          <Modal.Title>Terms & Condition</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className={`${m.termsBox}`}>
            <h3>Lorem ipsum dolor sit amet.</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Qui nisi, perspiciatis perferendis cum corrupti iusto, ullam
              doloremque est omnis mollitia magnam cupiditate ipsam ipsum, vero
              enim maiores dolore! Incidunt, maiores?</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Qui nisi, perspiciatis perferendis cum corrupti iusto, ullam
              doloremque est omnis mollitia magnam cupiditate ipsam ipsum, vero
              enim maiores dolore! Incidunt, maiores?</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Qui nisi, perspiciatis perferendis cum corrupti iusto, ullam
              doloremque est omnis mollitia magnam cupiditate ipsam ipsum, vero
              enim maiores dolore! Incidunt, maiores?</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Qui nisi, perspiciatis perferendis cum corrupti iusto, ullam
              doloremque est omnis mollitia magnam cupiditate ipsam ipsum, vero
              enim maiores dolore! Incidunt, maiores?</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Qui nisi, perspiciatis perferendis cum corrupti iusto, ullam
              doloremque est omnis mollitia magnam cupiditate ipsam ipsum, vero
              enim maiores dolore! Incidunt, maiores?</p>
          </div>
        </Modal.Body>

      </Modal>
    </>
  );
};
export default TermsCondition;
