import React from 'react';
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";

export default function Business404() {
    return (
        <section className={`${st.pageWrapper}`}>
            <div className={`${cx.error_404}`}>
                <h1>404</h1>
                <p>Sorry , this page is not found !</p>
            </div>
        </section>
    )
}
