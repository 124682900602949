import React from 'react'
import { Accordion } from 'react-bootstrap';
import st from '../../../assets/stylesheet/style.module.scss';
import { deleteIcon } from '../../../assets/images';

export default function PromotionFormattedServices({ cx, inputHandler, selectedPriceInput, handleDelete, serviceArray, categoryArr, type }: any) {


    return (
        <div className={cx.service_addAccordion}>
            <Accordion alwaysOpen>
                {serviceArray?.map((serviceItem: any, i: any) => {
                    const groupedByServiceName = serviceItem?.reduce((acc: any, item: any) => {

                        const serviceName = item?.BusinessService?.serviceName;
                        if (!acc[serviceName]) {
                            acc[serviceName] = [];
                        }
                        acc[serviceName].push(item);
                        return acc;
                    }, {});

                    const existCategory = selectedPriceInput?.find((item: any) => item?.categoryId === serviceItem?.[0]?.ServiceCategory?._id && (item?.type === "category" || item?.type === "all"))

                    return (
                        <Accordion.Item eventKey={i} className='mb-3'>
                            <Accordion.Header>
                                <div className={`${cx.catBox} ${type === "bundle" ? cx.bundleVersion : ""}`}>
                                    <h5>Category - {`${categoryArr?.[i]} (${serviceItem?.length})`}</h5>
                                    {type !== "bundle" && <div className={`${st.formBox} ${cx.inputBox}`}>
                                        <input
                                            type="number"
                                            id="discount percent"
                                            min={0}
                                            max={100}
                                            className={`form-control`}
                                            onClick={(e) => e.stopPropagation()}
                                            value={existCategory?.discount?.includes("%") ? Number.parseInt(existCategory?.discount) : ""}
                                            onChange={(e: any) => {
                                                e.preventDefault()
                                                if (e.target.value <= 100) {
                                                    inputHandler("category", e.target, serviceItem?.[0]?.ServiceCategory?._id)
                                                }
                                            }}
                                        />
                                        (%)
                                    </div>}
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className={cx.sss}>
                                    <Accordion alwaysOpen>
                                        {Object.values(groupedByServiceName)?.map((serviceItem: any, i: any) => {

                                            const existService = selectedPriceInput?.find((item: any) => item?.serviceId === serviceItem?.[0]?.BusinessService?._id && (item?.type === "service" || item?.type === "category" || item?.type === "all"))

                                            return (
                                                <Accordion.Item eventKey={i}>
                                                    <Accordion.Header>
                                                        <div className={`${cx.catBox} ${type === "bundle" ? cx.bundleVersion : ""}`}>
                                                            <h5 className={cx.v2}>{`Service - ${Object.keys(groupedByServiceName)?.[i]} (${serviceItem?.length})`}</h5>
                                                            <div className={`${st.formBox} ${cx.inputBox}`} style={{ marginTop: '4px' }}>
                                                                {type !== "bundle" && <input
                                                                    type="number"
                                                                    id="discount percent"
                                                                    min={0}
                                                                    max={100}
                                                                    className={`form-control`}
                                                                    onClick={(e) => e.stopPropagation()}
                                                                    value={existService?.discount?.includes("%") ? Number.parseInt(existService?.discount) : ""}
                                                                    onChange={(e: any) => {
                                                                        e.preventDefault()
                                                                        if (e.target.value <= 100) {
                                                                            inputHandler("service", e.target, serviceItem?.[0]?.BusinessService?._id)
                                                                        }
                                                                    }}
                                                                />}(%)
                                                            </div>
                                                        </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div>
                                                            <ul>
                                                                {serviceItem?.map((listItem: any, i: number) => {
                                                                    const existPrice = selectedPriceInput?.find((item: any) => item?.id === listItem?._id)

                                                                    return (
                                                                        <div key={i} className={cx.itemContainer}>
                                                                            <li>
                                                                                <div className={`${cx.bodyLeft}`}>
                                                                                    {/* <p>{`Price : ${listItem?.seasonPrice} SGD / Duration : ${listItem?.duration}`}</p>
                                                                                                        <p>{`Team title : ${listItem?.TeamTitleData?.teamTitle || ""}`}</p> */}
                                                                                    <div className={`${cx.category_selected} d-flex align-items-center gap-3`}>
                                                                                        <h5>{listItem?.TeamTitleData?.teamTitle ? listItem?.TeamTitleData?.teamTitle : "Any Stylist"}</h5>
                                                                                        <h5>{listItem?.priceText ? listItem?.priceText : ""}</h5>
                                                                                    </div>
                                                                                    <div className={`${cx.price_time}`}>
                                                                                        <h5>{listItem?.priceType === "from" ? "Fm" : ""} {listItem?.duration}</h5>
                                                                                        <h5>{listItem?.priceType === "from" ? "Fm" : ""} SGD {listItem?.seasonPrice}</h5>
                                                                                    </div>
                                                                                </div>
                                                                            </li>


                                                                            {type !== "bundle" && (
                                                                                <>
                                                                                    <div className={`${st.formBox} ${cx.inputBox}`}>
                                                                                        <input
                                                                                            type="number"
                                                                                            id="discount percent"
                                                                                            min={0}
                                                                                            max={100}
                                                                                            value={existPrice?.discount?.includes("%") ? Number.parseInt(existPrice?.discount) : ""}
                                                                                            className={`form-control`}
                                                                                            onClick={(e) => e.stopPropagation()}
                                                                                            onChange={(e: any) => {
                                                                                                e.preventDefault()
                                                                                                if (e.target.value <= 100) {
                                                                                                    inputHandler("price", e.target, listItem?._id)
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                        (%)
                                                                                    </div>
                                                                                    <div className={`${st.formBox} ${cx.inputBox}`}>
                                                                                        <input
                                                                                            style={{ minWidth: '58px' }}
                                                                                            type="number"
                                                                                            id="discount amount"
                                                                                            value={existPrice?.discount?.includes("usd") ? Number.parseInt(existPrice?.discount) : ""}
                                                                                            // disabled = {existPrice?.discount?.includes("usd")}
                                                                                            className={`form-control`}
                                                                                            onClick={(e) => e.stopPropagation()}
                                                                                            onChange={(e: any) => {
                                                                                                e.preventDefault()
                                                                                                if (e.target.value <= Number.parseFloat(existPrice?.regularPrice)) {
                                                                                                    inputHandler("price", e.target, listItem?._id)
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className={`${st.formBox} ${cx.inputBox} ${cx.finalPrice}`}>
                                                                                        <input
                                                                                            style={{ minWidth: '60px' }}
                                                                                            type="text"
                                                                                            id="finalPrice"
                                                                                            value={`${existPrice?.promotionalPrice ? Number.parseFloat(existPrice?.promotionalPrice) : ''}`}
                                                                                            className={`form-control`}
                                                                                            onClick={(e) => e.stopPropagation()}
                                                                                            disabled
                                                                                        />
                                                                                    </div>

                                                                                    <div className={cx.actionButton}>
                                                                                        <img src={deleteIcon} alt="Delete" onClick={() => handleDelete(listItem?._id)} />

                                                                                    </div>
                                                                                </>
                                                                            )}

                                                                        </div>
                                                                    )
                                                                })}

                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        })}
                                    </Accordion>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                })}
            </Accordion>
        </div>
    )
}
