import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { MdClose } from 'react-icons/md';


export const ActiveDeactive = (props: any) => {

    let { show, handleActiveDeactiveClose, handleActiveWithPopup, status } = props;
    const message = status
        ? `Are you sure you want to activate this item?`
        : `Are you sure you want to deactivate this item?`;

    return (
        <>
            <Modal
                centered
                show={show}
                aria-labelledby="example-modal-sizes-title-sm"
                onHide={handleActiveDeactiveClose}
                className={`${m.modalCts} ${m.profile_serviceDiscount_coupon} ${m.ModalHeaderBG}`}
            >
                <Modal.Header style={{ borderBottom: '1px solid #dee2e6', padding:'8px 18px' }}>
                    <Modal.Title>
                        Status
                    </Modal.Title>
                    <button
                        className={`${m.closeIcon} ${m.v2}`}
                        onClick={handleActiveDeactiveClose}
                    >
                        <MdClose />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>

                        {message}

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className={`btn ${m.cancelBtn}`}
                        onClick={handleActiveDeactiveClose}
                    >
                        No
                    </button>
                    <button
                        type="button"
                        className={`btn ${m.actionBtn} ${st.btn2}`}
                        onClick={() => {
                            handleActiveWithPopup()
                        }}
                    >
                        Yes
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

