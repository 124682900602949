import React, { useState, Fragment, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../assets/stylesheet/datatable.module.scss";
import { NavLink } from "react-router-dom";
import {
  AddTeamTitle,
  DeletePopup,
  ViewTeamTitle,
} from "../../../components/Business/Modals";
import { deleteIcon, editIcon, openIcon } from "../../../assets/images";
import { Status } from "../../../components/Business";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import { Col } from "react-bootstrap";
import st from '../../../assets/stylesheet/style.module.scss';

const CustomerService = ({ params }: any) => {


  return (
    <div>
      <label className={`d-block ${st.checkbox} mb-4`}>
        <input type="checkbox" checked={params?.row?.customerService} disabled />
        <span className={`${st.checkmark}`} ></span>
      </label>
    </div>
  )
}

const StatusButton = (props: any) => {
  const { params, type, allData } = props;

  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [editData, setEditData] = useState<any>(null)

  const [showTitleView, setShowTitleView] = useState(false);
  const handleTitleViewClose = () => {
    setEditData(null)
    setShowTitleView(false);
  };
  const handleTitleViewShow = () => setShowTitleView(true);

  const [showTitle, setShowTitle] = useState(false);
  const handleTitleClose = () => setShowTitle(false);
  const handleTitleShow = () => {
    const editedData = allData?.find((item:any) => item?._id === params?.row?.id)
    setEditData(editedData)
    setShowTitle(true)
  }

  const deleteApi = async () => {
    const res = await httpRequest(
      `deleteTeamTitle/${params?.row?.id}`,
      "DELETE",
      null,
      "json"
    );
    if (res.status) {
      props.getTitle();
      handleClose();
    } else {
    }
  };


  const canEditTitle = type === "service" ? subModuleRoles?.['Services']?.['Team Title']?.Edit : subModuleRoles?.['Teams']?.['Team Title']?.Edit;
  const canDeleteTitle = type === "service" ? subModuleRoles?.['Services']?.['Team Title']?.Delete : subModuleRoles?.['Teams']?.['Team Title']?.Delete;
  const canDeactivate = type === "service" ? subModuleRoles?.['Services']?.['Team Title']?.deactivate : subModuleRoles?.['Teams']?.['Team Title']?.deactivate;

  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          {canEditTitle && <li>
            <NavLink
              className={`btn ${tb.edit}`}
              title="Edit"
              to="#"
              onClick={handleTitleShow}
            >
              <img src={editIcon} alt="edit" />
            </NavLink>
          </li>}
          {/* <li>
            <NavLink
              className={`btn ${tb.edit}`}
              title="view"
              to="#"
              onClick={handleTitleViewShow}
            >
              <img src={openIcon} alt="view" />
            </NavLink>
          </li> */}
          {canDeleteTitle && <li>
            <button
              type="button"
              className={`btn ${tb.delete}`}
              title="Delete"
              onClick={handleShow}
            >
              <img src={deleteIcon} alt="Delete" />
            </button>
          </li>}
          {canDeactivate && <li>
            <Status status={params.row.isActive}
              api='updateTeamTitle' id={params?.id}
              apiMethod='json'
              getLatest={props.getTitle}
              />
          </li>}
        </ul>
      </div>
      <DeletePopup
        show={show}
        handleClose={handleClose}
        deleteApi={deleteApi}
      />
      {showTitle && <AddTeamTitle
        showTitle={showTitle}
        handleTitleClose={handleTitleClose}
        getTitle={props.getTitle}
        userData={editData}
      />}
      <ViewTeamTitle
        showTitleView={showTitleView}
        handleTitleViewClose={handleTitleViewClose}
        userData={params}
      />
    </>
  );
};

export default function DataGridTable(props: any) {
  const { titleInfo, getTitle, type } = props;

  let rows = [];

  if (titleInfo) {
    rows = titleInfo?.map((t: any, i: any) => {
      return {
        id: t?._id,
        serialNumber: i + 1,
        titleId: t?.titleID,
        customerService: t?.customerService,
        teamtitle: t?.teamTitle,
        action: t?.isActive,
        isActive: t.isActive,
      };
    });
  }

  const columns = [
    { field: "serialNumber", headerName: "S.R.", flex: 1, minWidth: 60 },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      minWidth: 220,
      sortable: true, // Enable sorting
      sortComparator: (v1: any, v2: any) => {
        if (v1 === v2) return 0;
        return v1 ? -1 : 1;
      },
      renderCell: (params: any) => (
        <StatusButton params={params} getTitle={getTitle} type={type} allData={titleInfo}/>
      ),
    },
    {
      field: "customerService",
      headerName: "Cusomer Service",
      flex: 1,
      minWidth: 50,
      renderCell: (params: any) => (
        <CustomerService params={params} />
      ),
    },
    { field: "titleId", headerName: "Title ID", flex: 1, minWidth: 60 },
    { field: "teamtitle", headerName: "Team Title", flex: 2, minWidth: 120 },

  ];

  return (
    <div className={`${tb.dataTable}`}>
      <div>
        <Col md={12}>
          <div className="text-end mb-2">

            <HelpButon number={30} type={"help"} className={"btn"} />
          </div>
        </Col>
      </div>
      <div style={{ width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          autoHeight
          hideFooterPagination={false}
          rowHeight={52}
          checkboxSelection
          disableRowSelectionOnClick
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
    </div>
  );
}
