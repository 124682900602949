import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form, Accordion } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './index.module.scss'; // Import your Sass module
import st from "../../../assets/stylesheet/style.module.scss";
import { cross, plusFill, userFrame } from '../../../assets/images';
import { NavLink } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';

const AddServiceSideBar = (props: any) => {
    const { setShowServiceBar, showServiceBar, service: services, packageData, existData, bundleList, serviceType, selectedBundle, setSelectedBundle } = props;
    const [showBranch, setshowBranch] = useState<any[]>([]);
    const [service, setService] = useState<any>([]);
    const [bundle, setBundle] = useState<any>([]);

    useEffect(() => {
        if (services) {
            setService(services)
        }
    }, [services])

    useEffect(() => {
        if (bundleList?.length !== 0) {
            setBundle(bundleList)
        }
    }, [bundleList])


    const [isChecked, setIsChecked] = useState<any>([]);

    let clear = () => {
        setshowBranch([]);
    };
    const [serviceData, setServiceData] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        setServiceData(existData)
    }, [existData]);

    // Function to toggle the checkbox state

    const toggleCheckbox = (e: any, data: any, type: string = "") => {
        const check = e.target.checked;
        if (type === "bundle") {
            if (check) {
                setSelectedBundle([...selectedBundle, data])
            } else {
                const filterBundle = selectedBundle?.filter((bundle: any) => bundle?._id !== data?._id);
                setSelectedBundle(filterBundle)
            }
            setShowServiceBar(false)
            return
        }
        if (check) {
            setIsChecked([...isChecked, e.target.value]);
            if (type === "category" || type === "services") {
                setServiceData([...serviceData, ...data]);
                packageData([...serviceData, ...data]);
                setIsChecked([])
            } else {
                setServiceData([...serviceData, data]);
                packageData([...serviceData, data]);
                setIsChecked([])
            }
        } else {
            let filteredArray = serviceData?.filter(
                (obj: any) => obj?._id !== data?._id
            );

            let checkdata = isChecked?.filter((obj: any) => obj !== e.target.value);
            setServiceData(filteredArray);
            packageData(filteredArray)
            setIsChecked(checkdata);
        }
        setShowServiceBar(false)
    };

    useEffect(() => {
        if (searchTerm !== "") {
            const filterData = services?.filter((data: any) => {
                const services = data?.data;
                const searchByServiceName = services?.some((serviceItem: any) => serviceItem?.BusinessService?.serviceName?.toLocaleLowerCase()?.replaceAll(" ", "")?.includes(searchTerm?.toLocaleLowerCase()?.replaceAll(" ", "")))
                return searchByServiceName
            })?.map((item: any) => {
                const services = item?.data;
                const searchWiseFilterService = services?.filter((serviceItem: any) => serviceItem?.BusinessService?.serviceName?.toLocaleLowerCase()?.replaceAll(" ", "")?.includes(searchTerm?.toLocaleLowerCase()?.replaceAll(" ", "")))
                return { ...item, count: searchWiseFilterService?.length, data: searchWiseFilterService }
            })

            const filterBundle = bundleList?.filter((bundle: any) => {
                return bundle?.bundleName?.toLocaleLowerCase()?.replaceAll(" ", "")?.includes(searchTerm?.toLocaleLowerCase()?.replaceAll(" ", ""))
            })
            setBundle(filterBundle)
            setService(filterData)
        } else {
            setService(services)
            setBundle(bundleList)
        }
    }, [searchTerm]);



    return (
        <div className={`${styles.bookingSidebar} ${styles.v2} ${showServiceBar ? styles.show : ""}`}>
            {showServiceBar && (
                <Container>
                    <div className={styles.mainheading_flexArea}>
                        <h1 className={styles.mainHeading}><FaArrowLeft className={styles.leftArr} onClick={() => setShowServiceBar(false)} /> {serviceType === "bundle" ? "Select Bundle" : "Select Service"}</h1>
                    </div>
                    <div className={`${styles.inside} ${styles.v2}`}>
                        <Row>
                            <Col md={12}>
                                <div className={`${styles.searchBox}`}>
                                    <input
                                        className="form-control"
                                        type="search"
                                        placeholder={`Search by ${serviceType} Name`}
                                        onChange={(e: any) => setSearchTerm(e.target.value)}
                                        value={searchTerm}
                                    />
                                    <FiSearch />
                                </div>
                            </Col>
                            <div className={styles.divider} role='img'>
                            </div>

                            {serviceType === "service" && <div className={`${styles.addServices_accordianMain}`}>
                                <Accordion>
                                    {service?.map((val: any, i: any) => {
                                        const old: any = [];
                                        val?.data?.forEach((data: any) => {

                                            const newExistData = existData?.map((data: any) => data?._id)
                                            if (newExistData?.includes(data?._id)) {
                                                old?.push({ categoryName: val?.categoryName, count: 2, data })
                                            }
                                        })


                                        if (old?.length !== 0 && Number(val.count) - old?.length === 0) {
                                            return
                                        }

                                        const groupedByServiceId = val?.data?.reduce((acc: any, item: any) => {
                                            const serviceId = item?.BusinessService?.serviceName;
                                            if (!acc[serviceId]) {
                                                acc[serviceId] = [];
                                            }
                                            acc[serviceId].push(item);
                                            return acc;
                                        }, {});

                                        const groupByServiceName = groupedByServiceId ? Object.values(groupedByServiceId) : []
                                        const groupByServiceNameArray = groupedByServiceId ? Object.keys(groupedByServiceId) : []

                                        return (

                                            <div className={styles.serviceList}>
                                                <Accordion.Item eventKey={i}>
                                                    <React.Fragment key={`${val?._id}${i}`}>
                                                        <Accordion.Header>
                                                            <div className={styles.catHeader} >
                                                                <h5 className={styles.catHeading} key={`${val?._id}${i}`}>
                                                                    {val?.categoryName}
                                                                    <span>({old?.length !== 0 ? Number(val.count) - old?.length : val?.count})</span>
                                                                </h5>
                                                                {/* <label
                                                                className={`d-block ${st.checkbox} mb-4`}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    value={val?._id}
                                                                    onClick={(e: any) => {
                                                                        toggleCheckbox(e, val?.data, "category");
                                                                    }}
                                                                />
                                                                <span
                                                                    className={`${st.checkmark}`}
                                                                ></span>
                                                            </label> */}
                                                            </div>
                                                        </Accordion.Header>

                                                        {groupByServiceName?.map((list: any, index: any) => {
                                                            const filterList = list?.filter((item: any) => !existData?.some((data: any) => data?._id === item?._id))

                                                            return (
                                                                <Accordion.Body key={`${list?._id}${index}`}>
                                                                    <div className={styles.insideAccordion}>
                                                                        {filterList?.length !== 0 && <Accordion>

                                                                            <Accordion.Item eventKey={index}>
                                                                                <Accordion.Header>
                                                                                    <div className={styles.catHeader} >
                                                                                        {filterList?.length !== 0 && <h5 className={`${styles.catHeading} ${styles.insideHead} mt-2`}>{groupByServiceNameArray?.[index]}</h5>}
                                                                                        {/* <label
                                                                                        className={`d-block ${st.checkbox} mb-4`}
                                                                                    >
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            value={list?._id}
                                                                                            onClick={(e: any) => {
                                                                                                toggleCheckbox(e, filterList, "services");
                                                                                            }}
                                                                                        />
                                                                                        <span
                                                                                            className={`${st.checkmark}`}
                                                                                        ></span>
                                                                                    </label> */}
                                                                                    </div>
                                                                                </Accordion.Header>
                                                                                <Accordion.Body>
                                                                                    <div>
                                                                                        <ul>
                                                                                            {filterList?.map((listItem: any, i: number) => {
                                                                                                return (
                                                                                                    <li key={i}>
                                                                                                        <div className={`${styles.bodyLeft}`}>
                                                                                                            {/* <p>{`Price : ${listItem?.seasonPrice} SGD / Duration : ${listItem?.duration}`}</p>
                                                                                                        <p>{`Team title : ${listItem?.TeamTitleData?.teamTitle || ""}`}</p> */}
                                                                                                            <div className={`${styles.category_selected}`}>
                                                                                                                <h5>{listItem?.TeamTitleData?.teamTitle ? listItem?.TeamTitleData?.teamTitle : "Any Stylist"}</h5>
                                                                                                                <h5>{listItem?.priceText ? listItem?.priceText : ""}</h5>
                                                                                                            </div>
                                                                                                            <div className={`${styles.price_time}`}>
                                                                                                                <h5>{listItem?.priceType === "from" ? "Fm" : ""} {listItem?.duration}</h5>
                                                                                                                <h5>{listItem?.priceType === "from" ? "Fm" : ""} SGD {listItem?.seasonPrice}</h5>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className={`${styles.bodyRight}`}>
                                                                                                            <label
                                                                                                                className={`d-block ${st.checkbox} mb-4`}
                                                                                                            >
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    value={listItem?._id}
                                                                                                                    checked={isChecked?.some((item: any) => item === listItem?._id)}
                                                                                                                    onClick={(e: any) => {
                                                                                                                        toggleCheckbox(e, listItem);
                                                                                                                    }}
                                                                                                                />
                                                                                                                <span
                                                                                                                    className={`${st.checkmark}`}
                                                                                                                ></span>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                )
                                                                                            })}

                                                                                        </ul>
                                                                                    </div>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>

                                                                        </Accordion>}
                                                                    </div>
                                                                </Accordion.Body>

                                                            );
                                                        })}
                                                    </React.Fragment>
                                                </Accordion.Item>
                                            </div>

                                        );
                                    })
                                    }
                                </Accordion>



                            </div>}
                            {serviceType === "bundle" && <div className={`${styles.addServices_accordianMain}`}>
                                <Accordion>
                                    {bundle?.map((val: any, i: any) => {
                                        if (selectedBundle?.some((item: any) => item?._id === val?._id)) {
                                            return
                                        }

                                        return (

                                            <div className={styles.serviceList} key={`${val?._id}${i}`}>
                                                <Accordion.Item eventKey={i}>
                                                    <React.Fragment>
                                                        <Accordion.Header>
                                                            <div className={styles.catHeader} >
                                                                <h5 className={styles.catHeading} key={`${val?._id}${i}`}>
                                                                    {val?.bundleName}
                                                                </h5>

                                                                <label
                                                                    className={`d-block ${st.checkbox} mb-4`}
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        value={val?._id}
                                                                        onClick={(e: any) => {
                                                                            toggleCheckbox(e, val, "bundle");
                                                                        }}
                                                                        checked={selectedBundle?.some((item: any) => item?._id === val?._id)}
                                                                    />
                                                                    <span
                                                                        className={`${st.checkmark}`}
                                                                    ></span>
                                                                </label>

                                                            </div>
                                                        </Accordion.Header>

                                                        <Accordion.Body>
                                                            <div>
                                                                <ul>
                                                                    {val?.priceId?.map((list: any, index: any) => {
                                                                        const listItem = list?.id;

                                                                        return (
                                                                            <li key={index}>
                                                                                <div className={`${styles.bodyLeft}`}>
                                                                                    {/* <p>{`Price : ${listItem?.seasonPrice} SGD / Duration : ${listItem?.duration}`}</p>
                                                                                                        <p>{`Team title : ${listItem?.TeamTitleData?.teamTitle || ""}`}</p> */}
                                                                                    <div className={`${styles.category_selected}`}>
                                                                                        <div className={styles.serviceDesc}>
                                                                                            <h5>{listItem?.serviceId?.serviceName}</h5>
                                                                                            <h5>{listItem?.TeamTitleData?.teamTitle ? listItem?.TeamTitleData?.teamTitle : "Any Stylist"}</h5>
                                                                                            <h4>{listItem?.priceText ? listItem?.priceText : ""}</h4>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={`${styles.price_time}`}>
                                                                                        <h5>{listItem?.priceType === "from" ? "Fm" : ""} {listItem?.duration}</h5>
                                                                                        <h5>{listItem?.priceType === "from" ? "Fm" : ""} SGD {listItem?.seasonPrice}</h5>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className={`${styles.bodyRight}`}>
                                                                                <label
                                                                                    className={`d-block ${st.checkbox} mb-4`}
                                                                                >
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        value={listItem?._id}
                                                                                        checked={isChecked?.some((item: any) => item === listItem?._id)}
                                                                                        onClick={(e: any) => {
                                                                                            toggleCheckbox(e, listItem);
                                                                                        }}
                                                                                    />
                                                                                    <span
                                                                                        className={`${st.checkmark}`}
                                                                                    ></span>
                                                                                </label>
                                                                            </div> */}
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </Accordion.Body>
                                                    </React.Fragment>
                                                </Accordion.Item>
                                            </div>

                                        );
                                    })
                                    }
                                </Accordion>
                            </div>}
                        </Row>
                    </div>
                </Container >
            )}
        </div >
    );
};

export default AddServiceSideBar;
