import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";

const CalendarContactDetails = (props: any) => {
  let { show, handleCalendarContactDetailsClose, clientDetails, data } = props;
  return (
    <>
      <Modal
        centered
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleCalendarContactDetailsClose}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        className={`${m.add_card_detailMain} ${m.contact_detailMain}`}
      >
        <Modal.Header closeButton>
          <Modal.Title>Contact Info</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: 'auto' }}>

          <div className={`${m.checkPopup}`}>
            {/* <p className={`${m.info_blow}`}> Contact information below:</p> */}

            <form>
              <Row>
                <Col md={12}>
                  <div className={`${m.inputForm} mb-3`}>
                    <label>Stylish Name</label>
                    <div className={`${m.inputBox}`}>
                      <div className={`form-control`}>{data?.firstName} {data?.lastName}</div>
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className={`${m.inputForm} mb-3`}>
                    <label>Stylish Number</label>
                    <div className={`${m.inputBox}`}>
                      <div className={`form-control`}>{data?.mobile}</div>
                    </div>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CalendarContactDetails;
