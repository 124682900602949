import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './index.module.scss'; // Import your Sass module
import { cross, plusFill, userFrame } from '../../../assets/images';
import { NavLink } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';
import { FaArrowLeft } from "react-icons/fa6";
import AddBookingClientModal from '../../../components/Business/Modals/AddBookingClientModal';

const AddClientBar = (props: any) => {
    const { setShowClientBar, getClient, showClientBar, clientList: clientData, selectedClient, setSelectedClient } = props;

    const [clientList, setClientList] = useState<any>([])
    const [term, setTerm] = useState("");
    const [showBookingClient, setShowClient] = useState(false);
    const handleBookingClientClose = () => setShowClient(false)
    const handleBookingClientShow = () => setShowClient(true);

    const handleClientSelect = (client: any) => {

        if (selectedClient?.some((data: any) => data?._id === client?._id)) {
            const oldClient = selectedClient?.filter((data: any) => data?._id !== client?._id)
            setSelectedClient(oldClient)
            return
        }
        setSelectedClient([client]);
        setShowClientBar(false)
    };

    useEffect(() => {
        setClientList(clientData)
    }, [clientData])

    useEffect(() => {
        if (term !== "") {
            const filterClient = clientData?.filter((data: any) => {
                return `${data?.firstName}${data?.lastName}${data?.email}${data?.mobile}`?.replaceAll(" ", "").toLocaleLowerCase()?.includes(term?.replaceAll(" ", "")?.toLocaleLowerCase())
            })
            setClientList(filterClient)

        } else {
            setClientList(clientData)
        }
    }, [term])
    const path = window.location.pathname;
    return (
        <div className={`${styles.bookingSidebar} ${styles.v2} ${showClientBar ? styles.show : ""}`}>
            {showClientBar && (
                <Container>
                    <div className={styles.mainheading_flexArea}>
                        <h1 className={styles.mainHeading}><FaArrowLeft className={styles.leftArr} onClick={() => setShowClientBar(false)} />Select client</h1>
                    </div>
                    <div className={`${styles.inside} ${styles.v2}`}>
                        <Row>
                            <Col md={12}>
                                <div className={`${styles.searchBox}`}>
                                    <input
                                        className="form-control"
                                        type="search"
                                        placeholder="Search by Client"
                                        onChange={(e: any) => setTerm(e.target.value)}
                                        value={term}
                                    />
                                    <FiSearch />
                                </div>
                            </Col>
                            <Col md={12}>
                                <h5 className={styles.addClientLink}>
                                    Didn’t find a client? <span onClick={() => handleBookingClientShow()}>Add another Client</span>
                                </h5>
                            </Col>
                            <div className={styles.divider} role='img'>
                            </div>
                            {clientList?.map((val: any, index: any) => {
                                return (
                                    <Col md={12} key={val?._id}>
                                        <div key={val?._id} className={`${styles.addClient} ${styles.noBorder}`} onClick={() => handleClientSelect(val)} style={{
                                            background: selectedClient?.includes(val) ? "#e9f6f1" : ""
                                        }}>
                                            <div className={styles.left}>
                                                <img src={userFrame} alt="user" />
                                                <div className={styles.userInfo}>
                                                    <h3>{val?.firstName} {val?.lastName}</h3>
                                                    <p>{val?.email}</p>
                                                    <p>{val?.mobile}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}

                        </Row>
                    </div>
                </Container>
            )}
            {showBookingClient && <AddBookingClientModal getClientData={getClient} showBookingClient={showBookingClient} handleBookingClientClose={handleBookingClientClose} />}
        </div>
    );
};

export default AddClientBar;
