import { Col, Row } from "react-bootstrap";
import st from "../../../assets/stylesheet/style.module.scss";
import { deleteIcon, editButton, iconLogoGrey } from "../../../assets/images";
import { Status } from "../../../components/Business";
import { httpRequest } from "../../../Apis/commonApis";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DeletePopup } from "../../../components/Business/Modals";
import ErrorPopup from "../../../components/Business/Modals/ErrorPopup";
import ConfirmationModal from "../../../components/Business/Modals/ConfirmationModal";

export default function PromotionCard({ promotion, getPromotion, type = '' }: any) {
    const { mainModuleRoles } = useSelector((state: any) => state.BranchReducer);
    const navigate = useNavigate();
    const [isPublished, setIsPublished] = useState<boolean>(false)

    const [showErrorModal, setShowErrorModal] = useState("");
    const handleAlertModalClose = () => setShowErrorModal("");
    const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showConfirmation, setShowConfirmation] = useState<any>(false)
    const handleConfirmationClose = () => {
        setShowConfirmation(false)
    }

    const handleEditClick = (promotion:any) => {
        const link = promotion?.promotionIdentity === "business" ? `/business/promotion/edit-promotion/info` : "/business/promotion/wellnezza-edit-promotion/wellnezza-info"
        navigate(link, { state: { promotionId: promotion?._id } });
    };

    const deleteApi = async () => {
        const res = await httpRequest(
            `deletePromotion/${promotion?._id}`,
            "DELETE",
            null,
            "json"
        );
        if (res?.status) {
            getPromotion();
            handleClose();
        } else {
        }
    };

    
    async function handlePublish() {
        const checked = !isPublished;
        const data = {
            isPublish: checked
        }
        if (checked && !promotion.isActive) {
            handleAlertModalShow(`Error, you can't publish this promotion because it it's status is disabled`)
            return
        }
        if (checked && promotion?.priceId?.length === 0 && promotion?.bundleId?.length === 0) {
            handleAlertModalShow(`Error, you can't publish this promotion please assign service first`)
            return
        }
        const res = await httpRequest(
            `updatePromotion/${promotion?._id}`,
            "PATCH",
            data,
            "json"
        );
        if (res?.status) {
            getPromotion();
            handleAlertModalShow(`Success, Promotion is ${res?.data?.isPublish ? 'published' : 'unpublished'}`)
        } else {
            handleAlertModalShow(`Error, Promotion publishing status is not updated`)
        }
        handleConfirmationClose()
    }

    useEffect(() => {
        setIsPublished(promotion?.isPublish)
    }, [promotion])

    const canEditPromo = mainModuleRoles?.Promotion?.Edit;
    const canDeletePromo = mainModuleRoles?.Promotion?.Delete;
    const candeactivate = mainModuleRoles?.Promotion?.deactivate;
    console.log(promotion?.promotionIdentity, 'promotion?.promotionIdentity');
    
    return (
        <>
            <ErrorPopup
                showErrorModal={showErrorModal}
                handleErrorModalClose={handleAlertModalClose}
            />
            <ConfirmationModal showConfirmation={showConfirmation} message={`${isPublished ? 'unpublish' : 'publish'} this promotion`} handleConfirmationClose={handleConfirmationClose} onConfirm={handlePublish} />
            <DeletePopup show={show} handleClose={handleClose} deleteApi={deleteApi} />
            <Row className="mt-3">
                <Col md={12}>
                    <div className={`${st.edit_publishBranchMain} mb-3`}>
                        <div className={`${st.topBox_withBtn} mb-2`}>
                            <div className={`${st.topBox_imgBox} mb-1`}>
                                <h4>{promotion?.promotionName}</h4>
                            </div>
                            <ul className={`${st.btnList} align-items-center`}>
                                {canEditPromo && <li onClick={() => handleEditClick(promotion)}>
                                    <button> <img src={editButton} alt="edit" /></button>
                                </li>}
                                {canDeletePromo && type !== 'publish' && <li onClick={handleShow}>
                                    <button> <img src={deleteIcon} alt="deleteIcon" /></button>
                                </li>}
                                {candeactivate && type !== 'publish' && <li className="ms-2">
                                    <li>
                                        <Status status={promotion.isActive}
                                            api='updatePromotion'
                                            id={promotion?._id}
                                            apiMethod='json'
                                            getLatest={getPromotion}
                                        />
                                    </li>
                                </li>}
                            </ul>

                        </div>
                        <ul className={`${st.bottomBox_details} align-items-center`}>
                            <li className={`${st.leftWidth}`} style={{ minWidth: '88%' }}>
                                {/* <div className={`${st.infoSide}`}><span>Promotion Type :</span> {promotion?.promotionType}</div> */}
                                <div className="d-flex align-items-center gap-2">
                                    <div className={`${st.infoSide}`}>
                                        {/* <span>Promotion Type :</span> */}
                                        {promotion?.promotionType} ,</div>
                                    <div className={`${st.infoSide}`}>
                                        {/* <span>Managed By :</span> */}
                                        {promotion?.promotionIdentity === 'wellnezza' ? 'Platform Managed,' : "Business Managed ,"}</div>
                                    <div className={`${st.infoSide}`}>
                                        {/* <span>New Customer :</span>  */}
                                        {promotion?.forFirstTime ? 'New Customer Only' : ''}</div>
                                </div>
                                <div className="d-flex flex-column">
                                    <div className={`${st.infoSide}`}><span>Booking Date :</span> {promotion?.dateFrom} to {promotion?.dateTo}</div>
                                    {promotion?.selldateFrom && <div className={`${st.infoSide}`}><span>Sell Date :</span> {promotion?.selldateFrom} to {promotion?.selldateTo}</div>}
                                </div>
                            </li>
                            <li>
                                <div className="text-end">
                                    <span className={`${st.iconLogo_checkBox} `}>
                                        <span className={`${st.borderBox} `}>
                                            <label className={`d-inline-flex ${st.checkbox}`}>
                                                <input type="checkbox" checked={isPublished} onChange={() => setShowConfirmation(true)} />
                                                <span className={`${st.checkmark}`}></span>
                                                <span className={`${st.labelName} ms-1`}> <img src={iconLogoGrey} alt="icon" /></span>
                                            </label>
                                        </span>
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </>
    )
}