import React from 'react'
import cx from './index.module.scss';
import { bundleGrouping, counterWiseBookingServices, formatDate, formatDuration } from '../../../constants/Constants';
export default function SingleBooking({ booking }: any) {
    let services: any = [];
    // let count:number = 1
    // booking?.services?.forEach((service:any) => {
    //     if(!services?.includes(service?.serviceDetails?.serviceName)){
    //         services.push(service?.serviceDetails?.serviceName)
    //     }else{
    //         count += 1
    //     }
    // });
    const totalDuration = booking?.services?.reduce((acc: any, service: any) => acc + Number.parseInt(service?.serviceId?.duration), 0);
    const teamMember = booking?.services?.map((service: any) => service?.TeamMemberId?.length > 0 ? service?.TeamMemberId[0] : '')
    const modifiedServices = counterWiseBookingServices(booking?.services)
    console.log(booking?.services, 'booking?.services');
    
    const bundleGroup: any = bundleGrouping(booking?.services);
    const bundlewiseServices: any = bundleGroup?.some((item: any) => Object.keys(item)?.[0] === "noBundle") ?
        Object.values(modifiedServices)?.map((service: any) => {
            return `${service?.services?.[0]?.serviceDetails?.serviceName}${service?.count > 1 ? ` X ${service?.count}` : ''}`
        })
        : bundleGroup?.map((item: any, i: any) => {
            const serviceArr: any = Object.values(item)[0];
            return `${Object.keys(item)?.[0]} - (${serviceArr?.map((services: any) => services?.serviceDetails?.serviceName)?.join(", ")})`
        })
        console.log(bundleGroup, 'bundlewiseServices');
        
    return (
        <div className={`${cx.dashboard_FlexBox}`}>
            <div className={`${cx.boxLeft}`}>
                <h4>{bundlewiseServices?.join(', ')}</h4>
                <span className={`${cx.timing}`}>{formatDate(`${booking?.salectDate}T${booking?.salectTime}`, true)}</span>
            </div>
            <div className={`${cx.boxRight}`}>
                <p>{formatDuration(totalDuration)} {teamMember?.join('') ? `with ${teamMember?.join(', ')}` : ''}</p>
                <span className={`${cx.price}`}>${booking?.finalPrice}</span>
            </div>
        </div>
    )
}
