import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import cx from '../index.module.scss';
import st from '../../../../assets/stylesheet/style.module.scss';
import { Controller, useForm } from 'react-hook-form';
import { timeValues } from '../../../../constants/Constants';
import { useNavigate } from 'react-router-dom';
import SuccessPopup from '../../Modals/SuccessPopup';
import { disablePastDate } from '../../../../Utils/Funcs';
import { deleteIcon, editButton } from '../../../../assets/images';
import LoadingBtn from '../../Buttons/LoadingBtn';

export default function LeavesTab(props: any) {
  const { handleStepsFormData, branchList, loading, editLeaveData, editTeamData } = props;
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleSuccessModalClose = () => setShowSuccessModal(false);
  const handleSuccessModalShow = () => setShowSuccessModal(true);
  const [message, setMessage] = useState("");
  const [customHrsSwitch, setCustomHrsSwitch] = useState(false);
  const [addedLeaves, setAddedLeaves] = useState([]);
  const [editLeaveIndex, setEditLeaveIndex] = useState(-1);
  const navigate = useNavigate();
  const selectedBranchId = watch("branchId");

  const fromDate = watch("fromDate");
  const toDate = watch("toDate");

  // Convert the date strings to Date objects
  let date1: any = fromDate ? new Date(fromDate) : new Date();
  let date2: any = toDate ? new Date(toDate) : new Date();

  // Calculate the difference in milliseconds
  let difference = date2 - date1;

  // Convert milliseconds to days
  let millisecondsPerDay = 1000 * 60 * 60 * 24;
  let differenceInDays = Math.floor(difference / millisecondsPerDay);

  const teamHoursArr = editTeamData?.hoursDetail;
  const selectedBranchWH: any = selectedBranchId ? teamHoursArr?.find((item: any) => item?.branchId?._id === selectedBranchId) || null : null
  const shiftsArr = selectedBranchWH ? selectedBranchWH?.shift : []


  let availableHrsArr: any = [];
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  for (let i = 0; i <= differenceInDays; i++) {
    const startDate = fromDate ? new Date(fromDate) : new Date();
    startDate?.setDate(startDate?.getDate() + i);

    const dayOfWeek = startDate ? new Date(startDate)?.getDay() : new Date()?.getDay();
    const fullDayName = dayNames[dayOfWeek];
    shiftsArr?.length !== 0 && shiftsArr?.forEach((element: any) => {
      let hrs = element.workingHours?.find((event: any) => event?.day == fullDayName);
      availableHrsArr.push(hrs);
    });
  }


  function groupNotAvailableHours(timeArr: any, shifts: any) {
    const notAvailableGroups: any = [];
    let currentGroup: any = null;
    timeArr?.forEach((time: any) => {

      const isAvailable = shifts?.some((shift: any) => {
        const shiftStart = shift.from;
        const shiftEnd = shift.to;
        return time >= shiftStart && time <= shiftEnd;
      });

      if (!isAvailable) {
        if (!currentGroup) {
          currentGroup = { from: time, to: time };
        } else {
          currentGroup.to = time;
        }
      } else {
        if (currentGroup) {
          notAvailableGroups.push(currentGroup);
          currentGroup = null;
        }
      }
    });
    if (currentGroup) {
      notAvailableGroups.push(currentGroup);
    }
    return notAvailableGroups;
  }

  function generateTimeArray() {
    const times = [];
    for (let hours = 0; hours < 24; hours++) {
      for (let minutes = 0; minutes < 60; minutes++) {
        const hourFormatted = hours.toString().padStart(2, '0');
        const minuteFormatted = minutes.toString().padStart(2, '0');
        const time = `${hourFormatted}:${minuteFormatted}`;
        times.push(time);
      }
    }
    return times;
  }

  let fullDayTimeArray = generateTimeArray();
  let notAvailableHrs = groupNotAvailableHours(fullDayTimeArray, availableHrsArr);

  let selectedBranchObj: any = {}
  const filterBranchList = branchList?.filter((branchItem: any) => {
    if (branchItem?._id === selectedBranchId) {
      selectedBranchObj = branchItem
    }
    return teamHoursArr?.some((assignBranchItem: any) => assignBranchItem?.branchId?._id === branchItem?._id)
  })


  useEffect(() => {
    if (editLeaveData) {
      reset();
      let leavesArr: any = [];
      editLeaveData.leaves?.forEach((elem: any) => {
        let isTimeAv = elem.isChecked ? true : false;
        leavesArr.push({
          branchName: elem?.branchName,
          branchId: elem.branchId?._id,
          isChecked: isTimeAv,
          fromDate: elem.fromDate,
          toDate: elem.toDate,
          fromTime: isTimeAv ? elem.fromTime : '',
          toTime: isTimeAv ? elem.toTime : ''
        });
      });
      setAddedLeaves(leavesArr);
    }
  }, [editLeaveData]);



  const handleShiftSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomHrsSwitch(event.target.checked);
  };


  const submitHandler = async () => {
    handleStepsFormData(addedLeaves, 'step4');
  }


  function handleAddLeaves(leaveData: any) {
    if (!customHrsSwitch) {
      leaveData = { ...leaveData, fromTime: "00:00", toTime: "23:59" }
    }

    let selectedBranch = branchList.find((item: any) => item._id === leaveData.branchId);
    let leaveObj = { ...leaveData };
    leaveObj.branchName = selectedBranch.branchName;
    leaveObj.isChecked = customHrsSwitch;
    let addedLeaveArr: any = [...addedLeaves];
    if (editLeaveIndex >= 0) {
      addedLeaveArr.splice(editLeaveIndex, 1);
      addedLeaveArr.splice(editLeaveIndex, 0, leaveObj);
    } else {
      addedLeaveArr.push(leaveObj);
    }
    setAddedLeaves(addedLeaveArr);
    setEditLeaveIndex(-1);
    reset();
    setValue('fromDate', '')
    setValue('toDate', '')
    setValue('fromTime', '')
    setValue('toTime', '')
    setCustomHrsSwitch(false);
  }

  function handleRemoveLeaves(obj: any) {
    let newSelected: any = [...addedLeaves];
    const index = newSelected.findIndex((item: any) => (
      JSON.stringify(item) === JSON.stringify(obj)
    ));
    newSelected.splice(index, 1);
    setAddedLeaves(newSelected);
  }

  function handleEditLeaves(obj: any) {
    const index = addedLeaves.findIndex((item: any) => (
      JSON.stringify(item) === JSON.stringify(obj)
    ));
    setEditLeaveIndex(index);
    setCustomHrsSwitch(obj.isChecked);
    setValue('branchId', obj.branchId);
    setValue('fromDate', obj.fromDate);
    setValue('toDate', obj.toDate);
    setValue('fromTime', obj.fromTime);
    setValue('toTime', obj.toTime);
    window.scrollTo(0, 0);
  }

  function mapSameRelatedValuesInArray(valsArr: any) {
    const sameValuesMap: any = {};
    valsArr.forEach((item: any) => {
      const { branchId, branchName } = item;
      if (!sameValuesMap[branchId]) {
        sameValuesMap[branchId] = { branchId, branchName, leaves: [] };
      }
      sameValuesMap[branchId].leaves.push(item);
    });
    const resultArray = Object.values(sameValuesMap);
    return resultArray;
  }

  const skipStep = (e: any) => {
    e.preventDefault();
    handleStepsFormData("prevStep", "accesscontrol")
  }


  let leavesBranchWise = mapSameRelatedValuesInArray(addedLeaves);

  function getMinutesFromtimeString(timeString: any) {
    if (timeString) {
      const [hours, minutes] = timeString?.split(":");
      return +hours * 60 + +minutes
    } else {
      return 0
    }
  }

  function filterTimeArray(timeArray: any, rangeObjects: any) {
    return timeArray.filter((time: any) => {
      for (const range of rangeObjects) {

        const fromTime = new Date(`2000-01-01T${range.from}`);
        const toTime = new Date(`2000-01-01T${range.to}`);

        const currentTime = new Date(`2000-01-01T${time.value}`);
        if (currentTime >= fromTime && currentTime <= toTime) {
          return false; // exclude this time
        }
      }
      return true; // include this time
    });
  }

  const filteredTimeArray = filterTimeArray(timeValues, notAvailableHrs);

  return (
    <>
      <SuccessPopup showSuccessModal={showSuccessModal} path="/business/teams" handleSuccessModalClose={handleSuccessModalClose} message={message} />
      <div className={`${st.offTab_heading}`}>
        <h6>Mark Leaves</h6>
        <p className={`${st.offcanvas_para}`}>Customer can not book a team member during non-working hours of team member </p>
      </div>
      <Row>
        <Col md={6}>
          <div className={`${st.reFormBox}`}>
            <label >Assign Location</label>
            <select className='form-select' {...register("branchId", {
              required: true,
            })}>
              <option value="">--Select--</option>
              {filterBranchList?.map((branch: any) => {
                return (
                  <option value={branch._id} key={branch._id}>{branch.branchName}</option>
                )
              })}
            </select>
            {errors?.branchId?.type === "required" && (
              <p className={"errorMessage"}>This field is required</p>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className={`${st.reFormBox}`}>
            <label htmlFor="">Date</label>
            <Row className='g-2'>
              <Col md={6}>
                <input type="date" className="form-control" min={disablePastDate()}
                  {...register("fromDate", {
                    required: true,
                  })} />
                {errors?.fromDate?.type === "required" && (
                  <p className={"errorMessage"}>required</p>
                )}
              </Col>
              <Col md={6}>
                <input type="date" className="form-control" min={watch('fromDate', disablePastDate())}
                  {...register("toDate", {
                    required: true,
                  })} />
                {errors?.toDate?.type === "required" && (
                  <p className={"errorMessage"}>required</p>
                )}
              </Col>
              {/* <Col md={6}>
                {errors?.fromDate?.type === "required" && (
                  <p className={"errorMessage"}>required</p>
                )}
              </Col> */}
              {/* <Col md={6}>
                {errors?.toDate?.type === "required" && (
                  <p className={"errorMessage"}>required</p>
                )}
              </Col> */}
            </Row>
          </div>
        </Col>
        <Col md={6}>
          <div className={`${st.reFormBox}`}>
            <label htmlFor="">Custom Hours</label>
            <Row className='g-2'>

              <Col md={1}>
                <label className={`${st.checkbox} ${cx.profileCheck} d-inline-flex`} style={{ marginTop: '8px', left: '-7px' }}>
                  <input type="checkbox" checked={customHrsSwitch}
                    onChange={handleShiftSwitchChange} />
                  <span className={`${st.checkmark}`}></span>
                  <span className="ms-1" style={{ fontSize: '13px', marginTop: '1px', opacity: '0' }}>t
                  </span>
                </label>
              </Col>
              {customHrsSwitch &&
                <>
                  <Col md={4}>
                    <Controller
                      name={`fromTime`} // This should match your form data structure
                      control={control}
                      defaultValue="" // Initial value
                      rules={{ required: true }}
                      render={({ field }) => {
                        return (
                          <select value={field.value} onChange={field.onChange} className='form-select'>
                            <option value="">-Select-</option>
                            {filteredTimeArray?.map((item: any) => {
                              return (
                                <option value={item?.value} key={item?.value}>{item?.label}</option>
                              )
                            })}
                          </select>
                          // <CustomTimeSelect value={field.value} onChange={field.onChange} timeValues={filteredTimeArray} required={false} />
                        )
                      }}

                    />
                    {errors[`fromTime`]?.type === "required" ? (
                      <span className="errorMessage">required*</span>
                    ) : <span></span>
                    }
                  </Col>
                  <Col md={4}>
                    <Controller
                      name={`toTime`} // This should match your form data structure
                      control={control}
                      defaultValue="" // Initial value
                      rules={{ required: true }}
                      render={({ field }) => {
                        const filteredTimeValues = filteredTimeArray.filter((time: any) => time.value >= watch(`fromTime`, ''));
                        return (
                          <select value={field.value} onChange={field.onChange} className='form-select'>
                            <option value="">-Select-</option>
                            {filteredTimeValues?.map((item: any) => {
                              return (
                                <option value={item?.value} key={item?.value}>{item?.label}</option>
                              )
                            })}
                          </select>
                        );
                      }} />
                    {errors[`toTime`]?.type === "required" ? (
                      <span className="errorMessage">required*</span>
                    ) : <span></span>
                    }
                  </Col>
                  {/* <Col md={4}>
                    {errors[`fromTime`]?.type === "required" ? (
                      <span className="errorMessage">required*</span>
                    ) : <span></span>
                    }</Col>
                  <Col md={4}>
                    {errors[`toTime`]?.type === "required" ? (
                      <span className="errorMessage">required*</span>
                    ) : <span></span>
                    }</Col> */}
                </>
              }
              <Col md={2}>
                <button className='btn saveGrey' onClick={handleSubmit(handleAddLeaves)}>
                  {editLeaveIndex >= 0 ? 'Edit' : 'Save'}
                </button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        {
          leavesBranchWise.map((elem: any, i: number) => {
            return (
              <div key={elem.branchId} className="mt-3">
                <Row>
                  <Col md={6}>
                    <div className={`${st.reFormBox}`}>
                      <label >Assign Location</label>
                      <select className='form-select' disabled value={elem?.branchId}>
                        {filterBranchList?.map((branch: any) => {
                          return (
                            <option value={branch._id} key={branch._id}>{branch.branchName}</option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>
                </Row>
                {elem.leaves?.map((item: any, index: number) => {
                  return (
                    <Row key={index}>
                      <Col md={6}>
                        <div className={`${st.reFormBox}`}>
                          <label htmlFor="">Date</label>
                          <Row className='g-2'>
                            <Col md={6}>
                              <input type="date" className="form-control" value={item?.fromDate} disabled />
                            </Col>
                            <Col md={6}>
                              {item?.toDate && <input type="date" className="form-control" value={item?.toDate} disabled
                              />}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className={`${st.reFormBox}`}>
                          <label htmlFor="">Custom Hours</label>
                          <Row className='g-2'>

                            {item.isChecked && <Col md={1}>
                              <label className={`${st.checkbox} ${cx.profileCheck} d-inline-flex`} style={{ marginTop: '8px', left: '-7px' }}>
                                <input type="checkbox"
                                  checked={item.isChecked}
                                  disabled />
                                <span className={`${st.checkmark}`}></span>
                                <span className="ms-1" style={{ fontSize: '13px', marginTop: '1px', opacity: '0' }}>t
                                </span>
                              </label>
                            </Col>}
                            {item.isChecked &&
                              <>
                                <Col md={4}>
                                  <select value={item.fromTime} disabled className='form-select'>
                                    <option value="">-Select-</option>
                                    {timeValues?.map((time: any) => {
                                      return (
                                        <option value={time?.value} key={time?.value}>{time?.label}</option>
                                      )
                                    })}
                                  </select>
                                </Col>
                                <Col md={4}>
                                  <select value={item.toTime} disabled className='form-select'>
                                    <option value="">-Select-</option>
                                    {[...timeValues, { label: '23:59', value: '23:59' }]?.map((time: any) => {
                                      return (
                                        <option value={time?.value} key={time?.value}>{time?.label}</option>
                                      )
                                    })}
                                  </select>
                                </Col>
                              </>
                            }
                            <Col md={3}>
                              <ul className={`${st.btnList} align-items-center justify-content-end mb-2`}>
                                <li
                                  onClick={() => handleEditLeaves(item)}
                                >
                                  <button> <img src={editButton} alt="edit" /></button>
                                </li>
                                <li onClick={() => handleRemoveLeaves(item)}>
                                  <button> <img src={deleteIcon} alt="deleteIcon" /></button>
                                </li>
                              </ul>
                            </Col>

                          </Row>
                        </div>
                      </Col>
                    </Row>
                  )
                })}
              </div>
            )
          })
        }
      </Row>

      <div className={`${cx.offcanvas_footer}`}>
        <Col md={12}>
          <div className={`${st.formBox} ${cx.formSign}`}>
            <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-3`}>
              <button className={`btn ${st.btn3} ${cx.btnGray}`} onClick={submitHandler} disabled={loading} type="submit">
                {loading ? (<LoadingBtn />
                ) : (
                  'Next'
                )}
              </button>
              {/* <button className={`btn ${st.btn3} ${cx.btnGray} ${cx.btnGrayLight}`} type="submit" onClick={(e) => {
                e.preventDefault();
                handleStepsFormData(null, 'cancel')
              }}>
                Cancel
              </button> */}
            </div>
          </div>
        </Col>
      </div>
    </>
  )
}
