import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { deals } from "../../../assets/images";
import { IoMdDownload } from "react-icons/io";
const ChatImgPopUp = (props: any) => {
  let { show, handleChatImgPopUpClose,photoUrl } = props;


  return (
    <>
      <Modal
        centered
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleChatImgPopUpClose}
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        className={`${m.chat_imgShowPop}`}
      >
        <Modal.Header closeButton>
          <Modal.Title>Image Preview
          </Modal.Title>
          <div className={`${m.download_btn}`}>
          <a href={photoUrl} download >
            <button className="btn"><IoMdDownload /></button>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>

        <div  className={`${m.imgBox}`}>
          <img className={`${m.img}`} src={photoUrl} width={100} height={100} alt={"img"} />
        </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChatImgPopUp;
