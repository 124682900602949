import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import {
  hair,
  basicInfo,
  basicInfoA,
  promotionSetting,
  promotionSettingA,
  amenitiesTab,
  amenitiesTabA,
  team,
  teamActive,
  clock,
  clockA,
} from "../../../assets/images";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import SuccessPopup from "../../../components/Business/Modals/SuccessPopup";
import PromotionRoutes from "./PromotionRoutes";
import { getDynamicURIName } from "../../../Utils/Funcs";
import { useForm } from "react-hook-form";
import BreadCrumbs from "../../../components/Business/BreadCrumbs/BreadCrumbs";

const AddPromotion = (props: any) => {
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  const navigate = useNavigate();
  const location: any = useLocation();
  const dynamicName = getDynamicURIName(location.pathname);
  const [promotionId, setPromotionId] = useState("");
  const [activeTab, setActiveTab] = useState(dynamicName);

  const [message, setMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleSuccessModalClose = () => setShowSuccessModal(false);

  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState<any>(null)
  
  
  const formObj = useForm()
  const {
    register,
    formState: { errors },
  } = formObj;

  const handleStepsFormData = (formdata: any, step: any) => {
    if (formdata === 'prevStep') {
      setActiveTab(step);
      navigate(step, { state: { promotionId: location.state.promotionId }, replace: true });
      return;
    }
    if (step === 'step1') {
      basicSubmitHandler(formdata, 'blackout');
    } else if (step === 'step2') {
      updateBlackOutHandler(formdata, 'services')
    } else if (step === 'step3') {
      serviceHandler(formdata, 'assign-branch');
    } else if (step === 'step4') {
      offPeakHandler(formdata, 'assign-branch')
    } else if (step === 'step5') {
      branchHandler(formdata, 'additional');
    }
    else if (step === 'step6') {
      handleSubmit(formdata, 'back');
    }
  };

  const offPeakHandler = async (basicData: any, step: string) => {
    // const priceId = basicData?.serviceId?.map((id: any) => id._id);
    setLoading(true);
    const formData = new FormData();
    formData.append("businessId", businessData?._id)

    formData.append("offPeakHours", JSON.stringify(basicData))

    const path = location?.state ? `updatePromotion/${location?.state?.promotionId}` : `createPromotion`
    const method = location?.state ? "PATCH" : "POST";
    const res = await httpRequest(path, method, formData, "formdata");
    if (res?.status) {
      setActiveTab(step);
      navigate(step, { state: { promotionId: res.data?._id }, replace: true });
    }
    setLoading(false);
  }

  const updateBlackOutHandler = async (allData: any, step: string) => {
    setLoading(true);
    const formdata = new FormData();
    for (const key in allData) {
      formdata.append(key, allData[key]);
    }

    const res = await httpRequest(`updatePromotion/${location.state.promotionId}`, "PATCH", formdata, "formdata",);
    if (res.status) {
      setActiveTab(step);
      navigate(step, { state: { promotionId: res.data?._id }, replace: true });
    }
    setLoading(false);
  }

  const branchHandler = async (basicData: any, step: string) => {
    // const priceId = basicData?.serviceId?.map((id: any) => id._id);
    setLoading(true);
    const formData = new FormData();
    formData.append("branchArr", JSON.stringify(basicData))

    const path = location?.state ? `updatePromotion/${location?.state?.promotionId}` : `createPromotion`
    const method = location?.state ? "PATCH" : "POST";
    const res = await httpRequest(path, method, formData, "formdata");
    if (res?.status) {
      if (step === "back") {
        setShowSuccessModal(true)
        setMessage(res?.message)
        setTimeout(() => {
          navigate(-1)
        }, 2000)
      } else {
        setActiveTab(step);
        navigate(step, { state: { promotionId: res.data?._id }, replace: true });
      }
    }
    setLoading(false);
  }

  const serviceHandler = async (basicData: any, step: string) => {
    // const priceId = basicData?.serviceId?.map((id: any) => id._id);
    setLoading(true);
    const formData = new FormData();
    formData.append("businessId", businessData?._id)
    formData.append("priceId", JSON.stringify(basicData?.priceId));
    formData.append("bundleId", JSON.stringify(basicData?.bundleId));
    formData.append("type", basicData?.type)


    const path = location?.state ? `updatePromotion/${location?.state?.promotionId}` : `createPromotion`
    const method = location?.state ? "PATCH" : "POST";
    const res = await httpRequest(path, method, formData, "formdata");
    if (res?.status) {
      setActiveTab(step);
      navigate(step, { state: { promotionId: res.data?._id }, replace: true });

    }
    setLoading(false);
  }
  const basicSubmitHandler = async (basicData: any, step: string) => {
    // const priceId = basicData?.serviceId?.map((id: any) => id._id);
    setLoading(true);
    const formData = new FormData();
    formData.append("businessId", businessData?._id)

    if (step !== "off-peak-hours" && step !== "assign-branch") {
      const limit = basicData?.LimitUsePerClient.maxUse || "0";
      const limitUser = {
        active: basicData?.LimitNumberOfUses.active,
        maxUser: basicData?.LimitNumberOfUses?.maxUser
      }

      formData.append("LimitUsePerClient", limit)
      // formData.append("discountCode", JSON.stringify(discount))
      formData.append("LimitNumberOfUses", JSON.stringify(limitUser));

      formData.append("promotionType", 'Regular')
      formData.append("promotionName", basicData?.promotionName)
      formData.append("unit", basicData?.unit)
      formData.append("tagline", basicData?.tagline)
      formData.append("frequency", basicData?.frequency)
      formData.append("branchArr", JSON.stringify(basicData?.branchArr));

      formData.append("dateFrom", basicData?.dateFrom)
      formData.append("dateTo", basicData?.dateTo)
      formData.append("startTimeFrom", basicData?.startTimeFrom)
      formData.append("endTimeTo", basicData?.endTimeTo)
      formData.append("isPublish", basicData?.isPublish)

      formData.append("selldateFrom", basicData?.selldateFrom)
      formData.append("selldateTo", basicData?.selldateTo)
      formData.append("sellstartTimeFrom", basicData?.sellstartTimeFrom)
      formData.append("sellendTimeTo", basicData?.sellendTimeTo)
      formData.append("promotionIdentity", "business")

      formData.append("discountCode", basicData?.discountCode)
      if (basicData?.promotionType === "Dynamic Pricing") {
        formData.append("dynamicPromotionStatus", basicData?.dynamicPromotionStatus)
      }
    }

    if (step === "assign-branch") {
      formData.append("offPeakHours", JSON.stringify(basicData))
    }


    if (basicData?.serviceCategory) {
      formData.append("serviceCategory", JSON.stringify(basicData?.serviceCategory))
    } else {
      formData.append("serviceCategory", JSON.stringify([]))
    }

    if (basicData?.priceId) {
      formData.append("priceId", JSON.stringify(basicData?.priceId));
    } else {
      formData.append("priceId", JSON.stringify([]));
    }
    if (basicData?.bundleId) {
      formData.append("bundleId", JSON.stringify(basicData?.bundleId));
    } else {
      formData.append("bundleId", JSON.stringify([]));
    }

    if (step === "blackout") {
      formData.append("promotionProvidesCategory", basicData?.promotionProvidesCategory);
      formData.append("forFirstTime", JSON.stringify(basicData?.forFirstTime === "newUser"))
    }

    // if (basicData?.type) {
    //   formData.append("type", basicData?.type)
    // }

    const path = location?.state ? `updatePromotion/${location?.state?.promotionId}` : `createPromotion`
    const method = location?.state ? "PATCH" : "POST";
    const res = await httpRequest(path, method, formData, "formdata");
    if (res?.status) {

      if (step === "back") {
        setShowSuccessModal(true)
        setMessage(res?.message)
        setTimeout(() => {
          navigate(-1)
        }, 2000)
      } else {
        setActiveTab(step);
        navigate(step, { state: { promotionId: res.data?._id }, replace: true });
      }
    }
    setLoading(false);
  }

  const handleSubmit = async (data: any, step: string) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("businessId", businessData?._id)
    formData.append("details", data?.details)
    formData.append("termsAndCondition", data?.term)
    formData.append("redemptionInstruction", data?.redemption)
    formData.append("cancellationPolicy", data?.cancellation)
    formData.append("promotionIdentity", "business")

    for (let i = 0; i < data?.image.length; i++) {
      formData.append('image', data?.image[i]);
    }

    if (data?.existImage) {
      formData.append('oldImages', JSON.stringify(data?.existImage));
    }
    const res = await httpRequest(`updatePromotion/${promotionId ? promotionId : location?.state?.promotionId}`, "PATCH", formData, "formdata");
    if (res?.status) {
      if (step === "back") {
        setShowSuccessModal(true)
        setMessage(res?.message)
        setTimeout(() => {
          navigate(-1)
        }, 2000)
      } else {
        setActiveTab(step);
        navigate(step, { state: { promotionId: res.data?._id }, replace: true });
      }
    }
    setLoading(false);
  }

  const handleTabSelect = (tab: any) => {
    if (editData) {
      setActiveTab(tab);
      navigate(`${tab}`, { state: { promotionId: location?.state?.promotionId }, replace: true });
    }
  };

  return (
    <>
      <SuccessPopup
        showSuccessModal={showSuccessModal}
        handleSuccessModalClose={handleSuccessModalClose}
        message={message}
      />
      <section className={`${st.pageWrapper} ${st.maxWidth_pageWrapper}`}>
        <div className={`${st.newRedesign_main}`}>
        <BreadCrumbs main={{menu:'Promotion', link:'/business/promotion'}} current={{menu:'View Promotion Details', link:'disabled'}}/>
          <Row>
            <Col md={10}>
              <div className={`${st.head_paraBox}`}>
                <h2>
                  {location.state && location?.state?.type !== "copy" ? `Business Managed Promotion -  ${editData?.promotionName}` : " Add Business managed promotion"}
                </h2>
                <p>Add your promotions to your Wellnezza business listing. Create, manage, and track campaigns with discounts, special offers, or time-limited deals directly on the platform</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={`${st.branch_locatedBox_bg} `}>
                <Row>
                  <Col md={5}>
                    <div className={`${st.reFormBox} mb-4`}>
                      <label htmlFor="">Name</label>
                      <span className={`${st.sub_label}`}>Give a catchy name for your promotion</span>
                      <input type="text" className='form-control' disabled={activeTab !== 'info'} placeholder='Enter Name'
                        {...register("name", { required: true })}
                      />
                      {errors?.name?.type === "required" && (
                        <p className={"errorMessage"}>This field is required</p>
                      )}
                    </div>
                  </Col>
                  {/* <Col md={6}>
                    <div className={`${st.reFormBox}`}>
                      <label htmlFor="">Type of Promotion</label>
                      <select
                        className="form-select"
                        {...register("promotionType", { required: true })}
                        disabled={editData?.promotionName || activeTab !== 'info'}
                      >
                        <option value="">Select</option>
                        {["Regular"]?.map((item: any, i: any) => {
                          return (
                            <option value={item} key={i}>{item}</option>
                          )
                        })}
                      </select>
                      {errors?.promotionType?.type === "required" && (
                        <p className={"errorMessage"}>This field is required</p>
                      )}
                    </div>
                  </Col> */}
                </Row>
                <Row style={{gap:'68px'}}>
                  <Col md={5}>
                    <div className={`${st.reFormBox}`}>
                      <label htmlFor="">Promo Code</label>
                      <span className={`${st.sub_label}`}>Promo Code Create Promo code for which can be offered to your business partners</span>
                      <Col md={12}>
                        <input type="text" className='form-control' disabled={activeTab !== 'info'} placeholder='Enter Promo Code' {...register("discountCode")}
                        />
                      </Col>
                      {errors?.discountCode?.type === "required" && (
                        <p className={"errorMessage"}>This field is required</p>
                      )}
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className={`${st.reFormBox}`}>
                      <label htmlFor="">Promotion tag line</label>
                      <span className={`${st.sub_label}`}>Tagline will be display on promotion profile image as sticker</span>
                      <Col md={8}>
                        <input type="text" className='form-control' disabled={activeTab !== 'info'} placeholder='Enter Promotion tag line' {...register("tagline", {
                          required: true,
                          maxLength: {
                            value: 15,
                            message: "Tagline cannot exceed 15 characters"
                          }
                        })}
                        />
                      </Col>
                      {errors?.tagline?.type === "required" && (
                        <p className={"errorMessage"}>This field is required</p>
                      )}
                      {errors?.tagline?.type === "maxLength" && (
                        <p className={"errorMessage"}>Tagline cannot exceed 15 characters</p>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>


        <div className={`${st.tabbingBox} ${st.tabbingBox}`}>
          <Tab.Container id="left-tabs-example"
            activeKey={activeTab}
            onSelect={handleTabSelect}>
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="info">
                  Details
                </Nav.Link>
              </Nav.Item>
              <span className={`${st.linespan}`}></span>
              <Nav.Item>
                <Nav.Link eventKey="blackout">
                  Black Out Period
                </Nav.Link>
              </Nav.Item>
              <span className={`${st.linespan}`}></span>
              <Nav.Item>
                <Nav.Link eventKey="services">
                  Services
                </Nav.Link>
              </Nav.Item>
              <span className={`${st.linespan}`}></span>

              {/* <Nav.Item>
                <Nav.Link eventKey="off-peak-hours">
                  <img src={clock} alt="OffPeakHours" className={`${st.defalut}`} />
                  <img src={clockA} alt="OffPeakHours" className={`${st.active}`} />
                  Off Peak Hours
                </Nav.Link>
              </Nav.Item> */}
              {/* <Nav.Item>
                <Nav.Link eventKey="setting">
                  <img
                    src={promotionSetting}
                    alt="promotion-icon"
                    className={`${st.defalut}`}
                  />
                  <img
                    src={promotionSettingA}
                    alt="promotion-icon"
                    className={`${st.active}`}
                  />
                  Promotion Setting
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link eventKey="assign-branch">
                  Assign Branch
                </Nav.Link>
              </Nav.Item>
              <span className={`${st.linespan}`}></span>
              <Nav.Item>
                <Nav.Link eventKey="additional">
                  Wellnezza Promotion page
                </Nav.Link>
              </Nav.Item>


            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey={activeTab}>
                <Routes>
                  <Route path={activeTab}
                    element={<PromotionRoutes dynamicName={dynamicName} setEditData={setEditData} handleStepsFormData={handleStepsFormData} loading={loading} formObj={formObj} />} />
                </Routes>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>

    </>
  );
};

export default AddPromotion;
