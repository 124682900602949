import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import st from '../../../../assets/stylesheet/style.module.scss';
import cx from '../index.module.scss'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { httpRequest } from '../../../../Apis/commonApis';
import LoadingBtn from '../../Buttons/LoadingBtn';

export default function AssignBranch({ editBundleData, loading, handleStepsFormData }: any) {
    const [branchData, setBranchData] = useState<any>([]);
    const [selectedBranchId, setSelectedBranchId] = useState<any>([]);
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    );
    const navigate = useNavigate()

    const [showSuccessModal, setShowSuccessModal] = useState<any>(false);
    const handleSuccessModalClose = () => setShowSuccessModal(false);

    useEffect(() => {
        if (editBundleData?.branchArr && editBundleData?.branchArr?.length !== 0) {
            setSelectedBranchId(editBundleData?.branchArr)
        }
    }, [editBundleData])

    const getBranches = async () => {
        let res = await httpRequest(`getBranch?businessId=${businessData._id}`, "get", null, 'json');
        if (res.status) {
            setBranchData(res.data);
        }
    }

    useEffect(() => {
        if (businessData) {
            getBranches();
        }
    }, [businessData]);



    const handleBranch = (e: any) => {
        if (!e.target.checked) {
            const filterBranch = selectedBranchId?.filter((item: any) => item !== e.target.value);
            setSelectedBranchId(filterBranch)
        } else {
            setSelectedBranchId([...selectedBranchId, e.target.value])
        }
    }

    function arraysEqual(arr1: any, arr2: any) {
        arr1?.sort()
        arr2?.sort()
        if (arr1?.length !== arr2?.length) {
            return false;
        }
        for (let i = 0; i < arr1?.length; i++) {
            if (arr1?.[i] !== arr2?.[i]) {
                return false;
            }
        }
        return true;
    }

    const handleSave = (e: any) => {
        e.preventDefault();
        handleStepsFormData({ branchArr: JSON.stringify(selectedBranchId) }, 'completed');
    }

    const skipStep = (e: any) => {
        e.preventDefault();
        navigate(-1)
    }
    return (
        <>
            <Row>
                <Col md={10}>
                    <div className={`${st.reFormBox}`}>
                        <label className='mb-2'>Service Available For</label>
                        <div>
                            <div className={`${st.edit_publish_checkBox} ${st.checkBox_FlexColumn}`}>
                                {branchData?.map((item: any) => {
                                    return (
                                        <label className={`d-inline-flex ${st.checkbox}`} key={item?._id}>
                                            <input type="checkbox" onChange={handleBranch} value={item?._id} checked={selectedBranchId?.some((branchId: any) => branchId === item?._id)} />
                                            <span className={`${st.checkmark}`}></span>
                                            <span className={`${st.labelName} ms-1`}>{item?.branchName}</span>
                                        </label>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className={`${cx.offcanvas_footer}`}>
                        <Row>
                            <Col md={12}>
                                <div className={`${st.formBox} ${cx.formSign}`}>
                                    <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-3`}>
                                        <button className={`btn ${st.btn3} ${cx.btnGray}`} type="submit" onClick={handleSave} disabled={loading}>
                                            {loading ? <LoadingBtn /> : 'Save'}
                                        </button>
                                        <button className={`btn ${st.btn3} ${cx.btnGray} ${cx.btnGrayLight}`} type="submit" onClick={(e: any) => {
                                            e.preventDefault();
                                            handleStepsFormData(null, 'info');
                                        }}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </>
    )
}
