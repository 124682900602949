import React from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Modal, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { MdClose } from "react-icons/md";
import MultiSelect from "../MultiSelect/MultiSelect";

const AssignPage = (props: any) => {
  let { showAssignModal, handleAssignModalClose, page } = props;
  // const coupons = [1,2,3,4,5,6,7];
  return (
    <>
      <Modal
        centered
        show={showAssignModal}
        backdrop="static"
        keyboard={false}
        onHide={handleAssignModalClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={m.modalCts}
      >
        <Modal.Header>
          <Modal.Title>Assign {page}</Modal.Title>
          <button className={`${m.closeIcon}`} onClick={handleAssignModalClose}>
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <div className={`${st.formBox}`}>
                <label className="form-label">{page} Name</label>
                <MultiSelect />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={`btn ${m.cancelBtn}`}
            onClick={handleAssignModalClose}
          >
            Close
          </button>
          <button type="button" className={`btn ${m.actionBtn}`}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssignPage;
