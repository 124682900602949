import React, { useEffect, useState } from 'react'
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Tab, Nav, Row, Dropdown, Offcanvas } from 'react-bootstrap';
import { editButton, deleteIcon, copyClipBoard, upArrow, iconLogoGrey } from '../../../assets/images';
import cx from "./index.module.scss";
import { AiFillCamera } from 'react-icons/ai';
import { RxCross2 } from 'react-icons/rx';
import HoverEyeContent from '../HoverEyeContent/HoverEyeContent';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, Value } from 'react-phone-number-input'
import { FaPlus } from 'react-icons/fa';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { NavLink, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import BundleRoute from './BundleCompoenent/BundleRoute';
import { httpRequest } from '../../../Apis/commonApis';
import { useSelector } from 'react-redux';
import { getDynamicURIName } from '../../../Utils/Funcs';

export default function BundleBodyInside(props: any) {
    let { handleBundleCanvasClose } = props;
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    );
    const location = useLocation();
    const dynamicName = getDynamicURIName(location.pathname);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<any>(false);
    const [bundleName, setBundleName] = useState<string>('')
    const [errorTrig, setErrorTrig] = useState<boolean>(false);
    const [editBundleData, setEditBundleData] = useState<any>(null);

    const [activeTab, setActiveTab] = useState(dynamicName);

    const handleStepsFormData = async (allData: any, step: any) => {
        if (!allData) {
            navigate(-1);
            handleBundleCanvasClose()
            return
        }
        const formData = new FormData();
        formData.append("businessId", businessData?._id)
        if (step === "pricing") {
            if(!bundleName){
                setErrorTrig(true)
                setTimeout(() =>{
                    setErrorTrig(false)
                },5000)
                return
            }
            
            for (const key in allData) {
                formData.append(key, allData[key]);
            }
            formData.append('bundleName', bundleName);
        }

        if (step === "assign-branch") {
            formData.append("priceId", allData?.priceId)
            formData.append("discount", allData?.discount)
            formData.append("finalPrice", allData?.finalPrice)
            formData.append("branchArr", allData?.branchArr)
        }
        
        if(step === 'completed'){
            formData.append("branchArr", allData?.branchArr)
        }
        const path = location?.state?.bundleId ? `updateBundle/${location?.state?.bundleId}` : `createBundle`;
        const method = location?.state?.bundleId ? "PATCH" : "POST";

        setLoading(true);
        const res = await httpRequest(path, method, formData, "formdata",);
        if (res.status) {
            // if (step === "completed") {
            if (step === "assign-branch") {
                handleBundleCanvasClose()
                return
            }
            navigate(step, { state: { bundleId: res.data?._id }, replace: true });
            setActiveTab(step);
        }
        setLoading(false)
        // navigate(step, { state: { bundleId: srvId }, replace: true });
    };

    const handleTabSelect = (tab: any) => {
        if (editBundleData) {
            setActiveTab(tab);
            navigate(`${tab}`, { state: { bundleId: location?.state?.bundleId }, replace: true });
        }
    };
    return (
        <>
            <Offcanvas.Header closeButton >
                <div className={`${st.offHeader_main}`}>
                    <h3>{editBundleData?._id ? editBundleData?.bundleName : "Add Bundle"}</h3>
                    <Col md={10} className='pe-1'>
                        <div className={`${st.reFormBox} mb-0`}>
                            <input type="text" placeholder="Enter Bundle Name" disabled={activeTab !== 'info'} className="form-control" value={bundleName} onChange={(e:any) => setBundleName(e.target.value)}/>
                            {!bundleName && errorTrig && <p className='errorMessage mt-2'>this field is required</p>}
                        </div>
                    </Col>
                </div>

            </Offcanvas.Header>
            <Offcanvas.Body className='pt-0' key={activeTab}>
                <div>
                    <Col md={12}>
                        <div >
                            <div className={`${st.tabbingBox}  mt-0`}>
                                <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={handleTabSelect}>
                                    <div className={`${st.tabbing_pillBox}`}>
                                        <Nav variant="pills" className="mb-0">
                                            <Nav.Item>
                                                <Nav.Link eventKey="info">
                                                    Basic Info
                                                </Nav.Link>
                                            </Nav.Item>
                                            <span className={`${st.linespan}`}></span>
                                            <Nav.Item>
                                                <Nav.Link eventKey="pricing">
                                                    Pricing and Duration
                                                </Nav.Link>
                                            </Nav.Item>
                                            {/* <span className={`${st.linespan}`}></span> */}
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="assign-branch">
                                                    Assign Branches
                                                </Nav.Link>
                                            </Nav.Item> */}
                                        </Nav>
                                    </div>
                                    <Tab.Content>
                                        <Tab.Pane eventKey={activeTab}>
                                            <Routes>
                                                <Route path={activeTab}
                                                    element={<BundleRoute dynamicName={dynamicName} setEditBundleData={setEditBundleData} editBundleData={editBundleData} handleStepsFormData={handleStepsFormData} loading={loading} setBundleName={setBundleName}/>} />
                                            </Routes>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                    </Col>

                </div>
            </Offcanvas.Body>
        </>
    )
}
