import React from 'react';
import cx from './index.module.scss';
import { teamPerson1 } from '../../../assets/images';

export default function TopTeamItem({ team }: any) {
    const role = team?.Role?.flatMap((item:any, i:number) => item?.role?.reduce((acc:any, val:any) => {
        if(team?.Role?.[i + 1]?.role?.some((r:any) => r?.teamTitle === val?.teamTitle)){
            return acc
        }else{
            return [...acc, val]
        }
    } , [])?.map((r:any) => r?.teamTitle))?.reduce((acc:any, val:any) => {
        if(acc?.some((r:any) => r === val)){
            return acc
        }else{
            return [...acc, val]
        }
    } , [])
    return (
        <tr>
            <td>
                <div className={`${cx.teamimgBox}`}>
                    <div className={`${cx.avatar}`}>
                        {team?.image ? (
                            <img src={team?.image} alt="person" />
                        ) : (
                            <div role='img' className={cx.fakeLogo}>
                                <span>{team?.firstName?.[0]}</span>
                            </div>
                        )}
                    </div>
                    <div className={`${cx.detail}`}>
                        <h5>{`${team?.firstName} ${team?.lastName || ''} ${team?.nickName ? `(${team?.nickName})` : ''}`}</h5>
                        <span>{role ? role?.join(' , ') : ''}</span>
                    </div>
                </div>
            </td>
            <td>{team?.timesOfUsages || ''}</td>
            <td>{team?.timesOfUsages || ''}</td>
        </tr>
    )
}
