import React from "react";
import { NavLink } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneNumber = () => {
  return (
    <>
      <PhoneInput
        country={"SG"}
        inputProps={{
          name: "phone",
          required: true,
          autoFocus: false,
        }}
        defaultErrorMessage="It doesn't works, why?"
      />
    </>
  );
};

export default PhoneNumber;
