import React, { useEffect, useState } from "react";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { getDynamicURIName } from "../../../Utils/Funcs";
import { useDispatch, useSelector } from "react-redux";
import StepperRow from "../../../components/Business/StepperBox/StepperRow";
import PublishRoute from "./PublishRoutes";
import { helloHand } from "../../../assets/images";
import { httpRequest } from "../../../Apis/commonApis";
import { getBusinessData, updateCounter } from "../../../redux_toolkit/reducer/shareDataReducer";
import BranchInfo from "./BranchInfo";
import ServiceInfo from "./ServiceInfo";
import BundleInfo from "./BundleInfo";
import PromotionInfo from "./PromotionInfo";
import TeamInfo from "./TeamInfo";
import BusinessInfo from "./BusinessInfo";

const Publish = () => {
  const [activeTab, setActiveTab] = useState('business');

  const handleTabSelect = (tab: any) => {
    setActiveTab(tab);
    // navigate(`${tab}`, { replace: true });
  };

  let { businessData, counter } = useSelector((state: any) => state.shareDataReducer);
  const { mainModuleRoles } = useSelector((state: any) => state.BranchReducer);
  const dispatch = useDispatch()
  const [promotionList, setPromotionList] = useState<any>([])
  const [branchData, setBranchData] = useState<any>([]);
  const [bundleList, setBundleList] = useState<any>([]);
  const [serviceList, setServiceList] = useState([]);
  const [priceList, setPriceList] = useState<any>([]);
  const [teamList, setTeamList] = useState<any>([])

  useEffect(() => {
    // window.scrollTo(0, 0);
    if (businessData) {
      getBranches();
      getService()
      getBundle();
      getPrices();
      getPromotion()
      getTeamMember()
    }
  }, [businessData]);

  const getTeamMember = async () => {
    const res: any = await httpRequest(`getAllTeamMembers?businessId=${businessData._id}`, "get", null, "json");
    if (res?.status) {
      setTeamList(res?.data)
    }
  };

  const getBranches = async () => {
    let res = await httpRequest(`getBranch?businessId=${businessData._id}`, "get", null, 'json');
    if (res.status === true) {
      setBranchData(res.data);
    }
  }
  const getService = async () => {
    const res = await httpRequest(`getBusinessService?businessId=${businessData._id}`, "get", null, "json");
    if (res.status) {
      setServiceList(res?.data);
    }
  };
  const getPrices = async () => {
    const res = await httpRequest(`getBusinessPrice?businessId=${businessData._id}`, "get", null, "json");
    if (res.status) {
      setPriceList(res?.data);
    }
  };

  const getPromotion = async () => {
    if (businessData) {
      let res = await httpRequest(`getPromotion?businessId=${businessData?._id}`, "get", null, "json");
      if (res.status) {
        setPromotionList(res?.data);
      }
    }
  };

  const getBundle = async () => {
    const res = await httpRequest(`getBundle?businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      setBundleList(res?.data);
    }
  };

  const modifiedServices = serviceList?.map((service: any) => {
    const priceData = priceList?.filter((price: any) => price?.BusinessService?._id === service?._id);
    return { ...service, prices: priceData }
  })

  useEffect(() => {
    if (businessData?.stepCompleted >= 10) {
      dispatch(updateCounter({ ...counter, branch: branchData?.length, service: serviceList?.length, team: teamList?.length, bundles: bundleList?.length, promotion: promotionList?.length }))
    }
  }, [branchData, serviceList, teamList, bundleList, promotionList, businessData?.stepCompleted, dispatch])
  
  const canPublish = mainModuleRoles?.Publish?.Edit;
  const isTeamPublishable = teamList?.some((team: any) => team?.isPublish)
  const isServicePublishable = modifiedServices?.some((service: any) => service?.prices?.some((price: any) => price?.isPublish));
  const isBranchPublishable = branchData?.some((item: any) => item?.isPublish)
  const ispublishable = isBranchPublishable && isServicePublishable && isTeamPublishable && canPublish;
  return (
    <>
      
      <section className={`${st.pageWrapper} ${st.maxWidth_pageWrapper}`}>
        <StepperRow type='publish' ispublishable={ispublishable}/>
        <div className={`${st.newRedesign_main}`}>
          <Row className="align-items-center">
            <Col md={12}>
              <div className={`${st.head_paraBox} mt-4 mb-4 pb-2 pt-1`}>
                <h4>
                  {/* <img src={helloHand} alt="hello" />  */}
                  Click on tabs below to manage each business details being published on Wellnezza</h4>
              </div>
            </Col>
          </Row>
          {/* {canPublish && ispublishable && <div className='text-end mb-3 pb-1'>
            <button className={`${st.addGrey} btn`} style={{ minWidth: '250px' }} onClick={() => handlePublish()}>{businessData?.isPublished ? "Unpublish " : "Publish on wellnezza"}
            </button>
          </div>} */}

          <div className={`${st.tabbingBox} mt-0`}>
            <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={handleTabSelect}>
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link eventKey="business">
                    Business Page Info
                  </Nav.Link>
                </Nav.Item>
                <span className={`${st.linespan}`}></span>
                <Nav.Item>
                  <Nav.Link eventKey="branch">
                    Branch and location
                  </Nav.Link>
                </Nav.Item>
                <span className={`${st.linespan}`}></span>
                <Nav.Item>
                  <Nav.Link eventKey="services">
                    Services
                  </Nav.Link>
                </Nav.Item>
                <span className={`${st.linespan}`}></span>
                <Nav.Item>
                  <Nav.Link eventKey="team">
                    Team Members
                  </Nav.Link>
                </Nav.Item>
                <span className={`${st.linespan}`}></span>
                <Nav.Item>
                  <Nav.Link eventKey="bundles">
                    Bundles
                  </Nav.Link>
                </Nav.Item>
                <span className={`${st.linespan}`}></span>
                <Nav.Item>
                  <Nav.Link eventKey="promotion">
                    Promotion
                  </Nav.Link>
                </Nav.Item>

              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey='business'>
                  <BusinessInfo businessData={businessData} branchData={branchData}/>
                </Tab.Pane>
                <Tab.Pane eventKey='branch'>
                  <BranchInfo branchData={branchData} getBranches={getBranches} setActiveTab={setActiveTab} />
                </Tab.Pane>
                <Tab.Pane eventKey='services'>
                  <ServiceInfo serviceList={modifiedServices} getService={getService} getPrices={getPrices} setActiveTab={setActiveTab} />
                </Tab.Pane>
                <Tab.Pane eventKey='team'>
                  <TeamInfo getTeamMember={getTeamMember} teamList={teamList} setActiveTab={setActiveTab} />
                </Tab.Pane>
                <Tab.Pane eventKey='bundles'>
                  <BundleInfo bundleList={bundleList} getBundle={getBundle} setActiveTab={setActiveTab} />
                </Tab.Pane>
                <Tab.Pane eventKey='promotion'>
                  <PromotionInfo promotionList={promotionList} getPromotion={getPromotion} setActiveTab={setActiveTab} />
                </Tab.Pane>
                {/* <Tab.Pane eventKey={activeTab}>

                  <Routes>
                    <Route path={activeTab} element={<PublishRoute
                      dynamicName={dynamicName}
                      branchData={branchData}
                      getBranches={getBranches}
                      serviceList={modifiedServices}
                      getService={getService}
                      getPrices={getPrices}
                      priceList={priceList}
                      bundleList={bundleList}
                      getBundle={getBundle}
                      promotionList={promotionList}
                      getPromotion={getPromotion}
                      teamList={teamList}
                      getTeamMember={getTeamMember}
                    />} />
                  </Routes>
                </Tab.Pane> */}
              </Tab.Content>
            </Tab.Container>

          </div>
        </div>

      </section>
    </>
  );
};

export default Publish;
