import React from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Modal, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { MdClose } from "react-icons/md";
import PhoneNumber from "../Phonenumber/Phonenumber";

const EditClient = (props: any) => {
  let { showEditClient, handleEditClientClose } = props;
  // const coupons = [1,2,3,4,5,6,7];
  return (
    <>
      <Modal
        centered
        show={showEditClient}
        backdrop="static"
        keyboard={false}
        onHide={handleEditClientClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={m.modalCts}
      >
        <Modal.Header>
          <Modal.Title>Arnold Shakold </Modal.Title>
          <button className={`${m.closeIcon}`} onClick={handleEditClientClose}>
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <div className={`${st.formBox}`}>
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Arnold Shakold"
                />
              </div>
            </Col>
            <Col md={12}>
              <div className={`${st.formBox}`}>
                <label className="form-label">DOB</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="date"
                />
              </div>
            </Col>
            <Col md={12}>
              <div className={`${st.formBox}`}>
                <label className="form-label">Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="bewanewa@gmail.com
                  "
                />
              </div>
            </Col>
            <Col md={12}>
              <div className={`${st.formBox}`}>
                <label className="form-label">Phone</label>
                <div className={`${st.phoneBox}`}>
                <PhoneNumber />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className={`${st.formBox}`}>
                <label className="form-label">Address</label>
                <div className={`${st.phoneBox}`}>
                 <textarea className="form-control" style={{ minHeight:'100px' }}>Ukraine, Lviv region,Zolochiv, 70800</textarea>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={`btn ${m.cancelBtn}`}
            onClick={handleEditClientClose}
          >
            Close
          </button>
          <button type="button" className={`btn ${m.actionBtn}`}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditClient;
