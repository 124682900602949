import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { useForm } from "react-hook-form";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import {
  basicInfo,
  basicInfoA,
  editButton,
} from "../../../assets/images";
import MultiSelect from "../../../components/Business/MultiSelect/MultiSelect";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { httpRequest } from "../../../Apis/commonApis";
import SuccessPopup from "../../../components/Business/Modals/SuccessPopup";
import { useSelector } from "react-redux";
import ChangePasswordModal from "../../../components/Business/Modals/ChangePasswordModal";
import ChangeEmailModal from "../../../components/Business/Modals/ChangeEmailModal";
import ErrorPopup from "../../../components/Business/Modals/ErrorPopup";
import OtpInput from 'react-otp-input';
import PhoneInput from "react-phone-input-2";
import { MdVerified } from "react-icons/md";
import BreadCrumbs from "../../../components/Business/BreadCrumbs/BreadCrumbs";

const Setting = (props: any) => {
  const navigate = useNavigate();
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const handlePasswordModalClose = () => setShowPasswordModal(false);

  const [showEmailModal, setShowEmailModal] = useState(false);
  const handleEmailModalClose = () => setShowEmailModal(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleSuccessModalClose = () => setShowSuccessModal(false);
  const [confirm, setConfirm] = useState(false);
  const [userlist, setUserlist] = useState<any>();
  const [active, setActive] = useState(false);
  const [mobileDis, setMobileDis] = useState(false)
  const [phoneError, setPhoneError] = useState('');
  const [mobileotp, setMobileNoOtp] = useState('');
  const [emailOtp, setEmailOtp] = useState('')
  const [showErrorModal, setShowErrorModal] = useState('');
  const handleAlertModalClose = () => setShowErrorModal('');
  const [mobileNo, setMobileno] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [countryData, setCountryData] = useState<any>(null);
  const [showVerifyInput, setshowVerifyInput] = useState(false)
  const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);
  // const [sucessMessage, setSucessMessage] = useState("")
  const userData: any = localStorage.getItem('user_data');
  let userId = JSON.parse(userData)
 
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm();
  useEffect(() => {
    if (confirm) {
      setShowEmailModal(true)
      setConfirm(false)
    }
  }, [confirm])
  useEffect(() => {
    getUserDetils()
  }, [])
  const getUserDetils = async () => {
    let res: any = await httpRequest(`getSetting?userId=${userId?._id}`, "get", null, "json");
    if (res.status) {
      setUserlist(res?.data)
      setValue("firstName", res?.data?.firstName);
      setValue("lastName", res?.data?.lastName);
      setValue("mobile", res?.data?.mobile);
    }
  };

  const updateprofile = async (postdata: any) => {
    let data: any = { };
    if (+mobileNo !== +userlist?.mobile) {
      if(mobileNo.length<8){
        setPhoneError("invalid number")
        return;
      }
      data.userId = userId?._id
      data.newMobile = mobileNo
    }else if(+mobileNo === +userlist?.mobile){
      setPhoneError("same mobile no")
      return;
    }else if (postdata.mobile !== userlist?.mobile){
      data.userId = userId?._id
      data.mobile = postdata.mobile;
    }
     else if (postdata.newEmail !== userlist?.email) {
      data.newEmail = postdata.newEmail;
    } 
    else {
        data.userId = userId?._id
        data.firstName = postdata.firstName;
        data.lastName = postdata.lastName;
    }
    const res = await httpRequest('editSetting', 'post', data, 'json');
    if (res.status) {
      getUserDetils()
      setActive(false)
      handleAlertModalShow(`Success, ${res.message}`);

    }
  }
  const sendLink = async (postdata:any)=>{
    let data: any = {};
     if (postdata.newEmail !== userlist?.email) {
      data.newEmail = postdata.newEmail;
    } 
     const res = await httpRequest('editSetting', 'post', data, 'json');
     if (res.status) {
       getUserDetils()
       handleAlertModalShow(`Success, ${res.message}`);
     }
  }
  const resendOtp = async () => {
    let data = {
      mobile: `${userlist?.dialCode}${userlist?.mobile}`,
    };
    const res = await httpRequest(`resendOTP/${userId?._id}`, "post", data, "withoutToken");
    if (res.status) {
      // handleAlertModalShow(`Success, Otp send successfully`);
    }
  };
  const emailAndMobileVerify = async (type: any) => {
    let data = {
      enteredOTP: type == "mobile" ? mobileotp : emailOtp,
      otpType: type,
      userType: userId?.userType,
      newMobile: mobileNo,
    };
    const res = await httpRequest(`verifyEmailAndMobileOTP/${userId?._id}`, "post", data, "json");
    if (res.status) {
      if (type == "mobile") {
        setMobileError("")
        getUserDetils()
      } else {
        getUserDetils()
        setEmailError("")
        // setshowVerifyInput(false)
        setMobileDis(false)
        // window.location.reload()
      }

    } else {
      if (type == "mobile") {
        setMobileError(res?.message)
      } else {
        setEmailError(res?.message)
      }
    }
  }
  const handleOnChange = (value: any, selectedCountry: any) => {
    setMobileno(value);
    setCountryData(selectedCountry);
    if (phoneError !== '') {
      setPhoneError('');
    }
  };
  const changeMobileNo = () => {
    setMobileDis(true)
  }

  return (
    <>
      {showPasswordModal && <ChangePasswordModal showPasswordModal={showPasswordModal} handlePasswordModalClose={handlePasswordModalClose} />}
      {showEmailModal && <ChangeEmailModal showEmailModal={showEmailModal} handleEmailModalClose={handleEmailModalClose} getUserDetils={getUserDetils} userlist={userlist} resendOtp={resendOtp} />}
      <SuccessPopup
        resendOtp={resendOtp}
        changeButton={"yes"}
        setConfirm={setConfirm}
        showSuccessModal={showSuccessModal}
        handleSuccessModalClose={handleSuccessModalClose}
        message={
          "Do you want to change Email"
        }
        type={"Error"}
      />
      <section className={`${st.pageWrapper} `}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            {/* <h5>
              Setting
            </h5> */}
            <BreadCrumbs main={{menu:'Setting', link:'disabled'}} current={null}/>
          </div>
          <div className={`${st.rightInfo}`}></div>
        </div>

        <div className={`${st.tabbingBox} ${st.tabbingBox} ${cx.setting}`}>
          {active == true ? "" : (
            <div className={cx.editButton} onClick={() => {
              setActive(true)
            }}>
              <img src={editButton} alt="edit" />
            </div>
          )}

          <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="tabNew1">
                  <img src={basicInfo} alt="info" className={`${st.defalut}`} />
                  <img src={basicInfoA} alt="info" className={`${st.active}`} />
                  Basic Info
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="tabNew1">
                <form >
                  <Row >
                    <Col md={4}>
                      <div className={`${st.formBox}`}>
                        <label className="form-label">
                          First Name
                        </label>
                        <input
                          type="text"
                          placeholder="First Name"
                          className="form-control"
                          defaultValue={userlist?.firstName}
                          disabled={!active}
                          {...register("firstName", {
                            required: true,
                            pattern: /^[a-zA-Z ]+$/,
                            min: 1,
                          })}
                        />

                        {errors?.firstName?.type === "required" && (
                          <p className={"errorMessage"}>
                            This field is required
                          </p>
                        )}
                        {errors?.firstName?.type === "pattern" && (
                          <p className={"errorMessage"}> Alphabets only</p>
                        )}
                        {errors?.firstName?.type === "min" && (
                          <p className={"errorMessage"}>
                            {" "}
                            minimum one character is necessary .
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className={`${st.formBox}`}>
                        <label className="form-label">
                          Last Name
                        </label>
                        <input
                          type="text"
                          placeholder="Last Name"
                          className="form-control"
                          defaultValue={userlist?.lastName}
                          disabled={!active}
                          {...register("lastName", {
                            required: true,
                            pattern: /^[a-zA-Z ]+$/,
                            min: 1,
                          })}
                        />
                        {errors?.lastName?.type === "required" && (
                          <p className={"errorMessage"}>
                            This field is required
                          </p>
                        )}
                        {errors?.lastName?.type === "pattern" && (
                          <p className={"errorMessage"}> Alphabets only</p>
                        )}
                        {errors?.lastName?.type === "min" && (
                          <p className={"errorMessage"}>
                            {" "}
                            minimum one character is necessary .
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col md={4}></Col>
                    <Col md={4}>
                      <div className={`${st.formBox}`}>
                        <label className="form-label">
                          Mobile Number
                        </label>

                        <div className={`${!mobileDis ? st.phoneBox : ""}`}>
                          <PhoneInput
                            country='SG'
                            onChange={handleOnChange}
                            disabled={!mobileDis}
                            inputProps={{
                              name: "phone",
                              required: true,
                              autoFocus: false,
                            }}
                            value={mobileDis !== true ? `${userlist?.mobile}` || "" : ""}
                            defaultErrorMessage="It doesn't work, why?"
                          />
                        </div>
                        <p className={"errorMessage"}>
                          {phoneError}
                        </p>
                      </div>
                    </Col>
                    <Col md={4}>
                      {mobileDis !== true ? (
                        <button className={`${cx.btn} singleBtn`} onClick={(e: any) => {
                          e.preventDefault();
                          changeMobileNo()
                        }}> Edit</button>) : (
                          <button
                          className={`${cx.btn} singleBtn`}
                          onClick={handleSubmit(updateprofile)}
                          // disabled={mobileNo?.length<8}
                        >
                          Send Otp
                        </button>
                      )}
                    </Col>
                    <Col md={4}></Col>
                    {/* {showVerifyInput === true ? (
                      <> */}
                        {userlist?.otp_sent == true ? (
                          <><Col md={4} className="mb-3">
                            <p className={cx.otpHeading}>Mobile Otp</p>
                            <div className={cx.otpScreen}>
                              <OtpInput
                                value={mobileotp}
                                onChange={setMobileNoOtp}
                                numInputs={4}
                                renderSeparator={<span> </span>}
                                inputType="number"

                                renderInput={(props) => <input {...props} disabled={userlist?.mobile_OTP_verify === true} />} />
                            </div>
                            <p className={"errorMessage"}> {mobileError}</p>
                          </Col><Col md={4}>
                              <button className={`${cx.btn} singleBtn`} onClick={(e: any) => { e.preventDefault(); emailAndMobileVerify("mobile"); }}>{userlist?.mobile_OTP_verify === true ? 'Verified' : "Verify"}</button></Col><Col md={4}></Col>
                            
                              <Col md={4}>
                                <p className={cx.otpHeading}>Email Otp</p>
                                <div className={cx.otpScreen}>
                                  <OtpInput
                                    value={emailOtp}
                                    onChange={setEmailOtp}
                                    numInputs={4}
                                    renderSeparator={<span> </span>}
                                    inputType="number"
                                    renderInput={(props) => <input {...props} />} />
                                </div>
                                <p className={"errorMessage"}> {emailError}</p>
                              </Col><Col md={4}>
                                  <button className={`${cx.btn} singleBtn`} onClick={(e: any) => { e.preventDefault(); emailAndMobileVerify("email"); }}>Verify</button></Col><Col md={4}></Col></>) : ""}
                                  {/* </>) : ""} */}
                    <Col md={4}>
                      <div className={`${st.formBox}`}>
                        <label className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          placeholder="Email"
                          className="form-control"
                          defaultValue={userlist?.email}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <button className={`${cx.btn} singleBtn`} onClick={(e: any) => { e.preventDefault(); setShowSuccessModal(true) }}>Edit</button>
                    </Col>
                    <Col md={4}></Col>
                    {userlist?.reset?.mobileVerified === true && userlist?.reset?.newEmailVerified == false && userlist?.reset?.oldEmailVerified == false ? (
                      <><Col md={4}>
                        <div className={`${st.formBox}`}>
                          <label className="form-label">
                            Enter new email
                          </label>
                          <input
                            type="email"
                            placeholder=" Enter new email "
                            className="form-control"
                            {...register("newEmail", {
                              required: true,
                              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            })}
                          />
                          {errors?.newEmail?.type === "pattern" && (
                            <p className={"errorMessage"}>Invalid email address</p>
                          )}
                          {errors?.newEmail?.type === "required" && (
                            <p className={"errorMessage"}>This field is required</p>
                          )}
                        </div>
                      </Col>
                      <Col md={4}>
                      <button className={`${cx.btn} singleBtn`} onClick={(e:any) => { e.preventDefault(); handleSubmit(sendLink)(); }}>Send link</button>
                        </Col><Col md={4}></Col></>
                    ) : ""}
                    <Col md={4}>
                      <div className={`${st.formBox}`}>
                        <label className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          defaultValue={"******"}
                          disabled
                          placeholder="password"
                          className="form-control"
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <button className={`${cx.btn} singleBtn`} onClick={(e: any) => { e.preventDefault(); setShowPasswordModal(true) }}>Change</button>
                    </Col>

                    <Col md={12}>
                      <div className={`${st.formBox}`}>
                        <div className={`${st.btnsGroup}`}>
                          <button
                            className={`btn ${st.btn1}`}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(-1);
                            }}
                          >
                            Back
                          </button>
                          <button
                            className={`btn ${st.btn4}`}
                            type="submit"
                            onClick={handleSubmit(updateprofile)}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </form>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

      </section>
      <ErrorPopup
        handlePasswordModalClose={handlePasswordModalClose}
        showErrorModal={showErrorModal}
        handleErrorModalClose={handleAlertModalClose}
      />
    </>
  );
};

export default Setting;
