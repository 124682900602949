import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import moment from 'moment';
import cx from './index.module.scss';
import st from "../../../assets/stylesheet/style.module.scss";
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { menuList } from '../../../assets/images';
import AppointmentModal from '../../../components/Business/Modals/AppointmentModal';
import { toTitleCase } from '../../../Utils/Funcs';
import { useSelector } from 'react-redux';
import { MdOutlineChevronLeft, MdOutlineChevronRight } from 'react-icons/md';
import { FadeLoader } from 'react-spinners';
import { useLocation, useNavigate } from 'react-router-dom';
import { convertTimeToMinute, convertTimetoTotalMinutes, convertToHoursMinutes, formatSlashDate, mergeTimeRanges } from '../../../constants/Constants';
import { httpRequest } from '../../../Apis/commonApis';
import { GoDotFill } from "react-icons/go";
import ErrorPopup from '../../../components/Business/Modals/ErrorPopup';
import { TbReload } from 'react-icons/tb';
import HelpButon from '../../../components/Business/HelpButon.tsx/HelpButton';
import MyCustomEvents from './MyCustomEvents';
import MyCustomResourceHeader from './MyCustomResourceHeader';
import { breakTimeRangesToHourIntervals, convertToTime, divideTimeRangeToHourIntervals, generateTimeArray, getDates, getDayName, getWeekStartAndEndDates, groupNotAvailableHours, handleEventsResources, isDateToday, pushTimeIfInRange, removeConflictingSlots, splitIntoSlots } from './CalendarCommonMethod';

moment.locale('ko', {
    week: {
        dow: 1,
        doy: 1,
    },
});
const localizer = momentLocalizer(moment);


const dayStart = new Date(2023, 10, 12, 0, 0)
const dayEnd = new Date(2023, 10, 12, 24, 0);

const MyCalendar = (props: any) => {
    const { getBooking, bookingData, selectedDate, listData, roleObj, handleTeamBooking, handleCreateSlotEvent, handleToolbarChange, branchTeamList, selectedViewDateTeam, loading, handleDateSelector, branchId } = props;
    const { currentBranchData } = useSelector((state: any) => state.BranchReducer);

    const [resources, setResources] = useState<any[]>([]);
    const [eventData, setEventData] = useState<any[]>([]);

    const [showErrorModal, setShowErrorModal] = useState("");
    const handleAlertModalClose = () => setShowErrorModal("");
    const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);

    const location = useLocation();
    const dateParam = location.state && location.state.date;

    useEffect(() => {
        if (currentBranchData) {
            handleEventsResources(currentBranchData, selectedDate, selectedViewDateTeam, setResources, setEventData, dayStart, dayEnd, roleObj, bookingData, getBooking, branchId, handleTeamBooking)
        }
    }, [bookingData, selectedDate, currentBranchData]);

    const handleSelectSlot = (eventVal: any) => {
        if (!roleObj?.canCreateAllBooking && !roleObj?.canCreateOwnBooking) {
            return
        }

        if (roleObj?.canCreateOwnBooking && !roleObj?.canCreateAllBooking) {
            if (roleObj?.userData?._id !== eventVal?.resourceId) {
                return
            }
        }
        handleCreateSlotEvent(eventVal, bookingData);
    };

    const customSlotPropGetter = (date: Date) => {
        const currentTime = moment();
        const cellTime = moment(date);
        // Check if cell time is before current time
        if (cellTime.isBefore(currentTime)) {
            return {
                className: cx.grayColor, // Add custom class to grey out
            };
        }
        return {
            className: cx.specialDay,
        }
    }

    const CalendarToolbar = (actions: any) => {
        const { onView, label, views, view, onNavigate } = actions;
        const navigate = useNavigate();
        let caleDate = formatSlashDate(actions?.date)
        const [selectedTeam, setSelectedTeam] = useState(selectedViewDateTeam?.team);
        const [selectView, setSelectView] = useState(selectedViewDateTeam?.view);
        const [calendarDate, setCalendarDate] = useState(caleDate);

        useEffect(() => {
            setSelectedTeam(selectedViewDateTeam?.team)
        }, [selectedViewDateTeam])

        useEffect(() => {
            setCalendarDate(caleDate);
        }, [caleDate]);

        function handleNavigate(type: string) {
            onNavigate(type);
            handleToolbarChange('navigate', { to: type, date: actions.date, view: view });
        }

        function handleViewChange(type: string) {
            if (type === "month") {
                navigate("/business/calendar")
                return
            }

            onView(type);
            setSelectView(type);
            if (type === 'week' && !selectedTeam) {
                setSelectedTeam(branchTeamList[0]?.id);
            }
            handleToolbarChange('changeView', { to: type, date: actions.date });
        }

        function handleTeamChange(value: string) {
            if (value === '' && selectView === 'week') {
                setSelectView('day');
                onView('day');
            }
            setSelectedTeam(value);
            handleToolbarChange('changeTeam', value);
        }
        function handleReload() {
            window.location.reload()
        }

        const handleDateNavigate = (dateVal: any, type: any = null) => {
            let date: any;

            if (type === "today") {
                date = new Date()
            } else {
                date = new Date(dateVal)
            }

            const todayFormated = formatSlashDate(date);
            const parsedDate = new Date(todayFormated);

            onNavigate('DATE', parsedDate);
            handleToolbarChange('navigateDate', { date: dateVal, view: view });
            handleDateSelector(dateVal)
        };

        useEffect(() => {
            if (dateParam) {
                const inputDate = new Date(location.state.date);
                const formattedDate = moment(inputDate).format('YYYY-MM-DD');
                handleDateNavigate(formattedDate)
            }
        }, [dateParam])

        return (
            <>
                <div className={`${cx.calendartoolbox}`}>
                    <div className={`${cx.firstBox} ${cx.calendar}`}>
                        {/* <div className={`${st.formBox} ${cx.availabilityBox}`}>
                            <select className="form-select">
                                <option value="Upcoming">All Bookings</option>
                            </select>
                        </div> */}
                        <ul className={cx.view} onClick={() => handleToolbarChange('listview', null)}>
                            <li>
                                <img src={menuList} alt="view" />
                            </li>
                        </ul>
                        <div className={`${cx.dayBox} ${cx.firstBox} justify-content-end`}>
                            <div className={`${st.formBox} ${cx.availabilityBox}`}>
                                <select style={{ paddingLeft: '6px' }} className={`form-select`} value={selectView}
                                    onChange={(e) => handleViewChange(e.target.value)}>
                                    {views.map((view: any) => (
                                        <option key={view} value={view}>{`${toTitleCase(view)} View`}</option>
                                    ))}
                                </select>
                            </div>
                            {/* <button
                            className={`singleBtn`}
                            onClick={() => handleToolbarChange('createNew', null)}
                        >
                            <img src={plusWhite} alt="add" className={cx.addIcon} /> Create
                        </button> */}
                        </div>
                        <div className={`${st.formBox} ${cx.availabilityBox}`}>
                            <select className={`form-select`} value={roleObj?.userData && !roleObj?.canViewAllBooking ? roleObj?.userData?._id : selectedTeam} disabled={roleObj?.userData && !roleObj?.canViewAllBooking ? branchTeamList?.length < 2 : branchTeamList?.length === 0} onChange={(e) => handleTeamChange(e.target.value)}>
                                <option value="" >{branchTeamList?.length === 0 && !loading ? "team unavailable" : "All Team"}</option>
                                {branchTeamList?.map((item: any) => {
                                    return (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    )
                                })}
                            </select>
                        </div>

                    </div>
                    <div className={`${cx.right} ${cx.calendarBox}`}>
                        <button className={`${cx.todayButton} ${cx.circle} ${isDateToday(calendarDate) ? cx.active : cx.inActive}`} onClick={() => handleDateNavigate(formatSlashDate(new Date()), "today")}>Today</button>

                        <button onClick={() => handleNavigate("PREV")}><MdOutlineChevronLeft className={cx.left} /></button>
                        <div className={cx.buttonBox}>
                            <button className={`${cx.todayButton} ${isDateToday(calendarDate) ? '' : cx.inActive}`}>{getDayName(calendarDate)}</button>
                            {view === 'day' ? <input type="date" className={`${cx.dateSelector} ${!isDateToday(calendarDate) ? cx.active : ""} form-control`}
                                value={calendarDate}
                                onChange={(e) => handleDateNavigate(e.target.value)} /> :
                                <span>{label}</span>
                            }
                        </div>
                        <button onClick={() => handleNavigate("NEXT")}><MdOutlineChevronRight className={cx.left} /></button>
                    </div>
                    <div className="d-flex justify-content-end gap-3">
                        <div className={`${cx.reloadBooking_btn}`}>
                            <button className="btn" onClick={() => handleReload()}>
                                <TbReload />
                            </button>
                        </div>
                        <div className="text-end">
                            <HelpButon number={2} type={"help"} className={"btn"} />
                        </div>
                    </div>
                    {/* <div className={`${cx.add_reportingMain_sec}`}>
                        <div className={`${st.formBox} ${cx.add_reporting_box}`}>
                            <label className={'form-label'}>Day's Booking</label>
                            <div className={`form-control`}>8 Compelete/5 Pending </div>
                        </div>
                        <div className={`${st.formBox} ${cx.add_reporting_box}`}>
                            <label className={'form-label'}>Hours Available</label>
                            <div className={`form-control`}>20/25 </div>
                        </div>
                        <div className={`${st.formBox} ${cx.add_reporting_box}`}>
                            <label className={'form-label'}>Today Booking Hours</label>
                            <div className={`form-control`}>25/30</div>
                        </div>
                    </div> */}


                </div>

                <div className={`${cx.calendy_todayBookHrs_main}`}>
                    <ul>
                        <li><GoDotFill /> <span className={`${cx.left}`}>Upcoming Booking:</span> <span className={`${cx.right}`}>20</span></li>
                        <li><GoDotFill /> <span className={`${cx.left}`}>Completed Booking:</span> <span className={`${cx.right}`}>20</span></li>
                        <li><GoDotFill /> <span className={`${cx.left}`}>Time Available:</span> <span className={`${cx.right}`}>20 hours 10 mins</span></li>
                        <li><GoDotFill /> <span className={`${cx.left}`}>Total:</span> <span className={`${cx.right}`}>21 hours 10 mins</span></li>
                        <li><GoDotFill /> <span className={`${cx.left}`}>Completed:</span> <span className={`${cx.right}`}>20 hours 10 mins</span></li>
                    </ul>
                </div>
            </>
        )
    };

    const onEventDrop = async (data: any) => {
        if (data?.event?.title === "emptyBoxes" || data?.event?.title === "teamCount") {
            return
        }
        const { start, end, event, resourceId } = data;
        const booking = listData?.find((item: any) => item?._id === event?.booking?._id)

        const date = new Date(start);
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so add 1
        let day = date.getDate().toString().padStart(2, '0');


        if (roleObj?.singleTeamChangeCheck) {
            const changeCondition = !event?.service?.every((item: any) => item?.TeamMemberId?.length > 0 ? item?.TeamMemberId?.[0]?._id === roleObj?.userData?._id : true)
            if (changeCondition) {
                return
            }
        }

        const currentBookingDAteInFormat = new Date(`${booking?.salectDate}T${data?.event?.service?.[0]?.startTime}:00`)

        const currentHour = currentBookingDAteInFormat?.getHours()
        const currentMinutes = currentBookingDAteInFormat?.getMinutes();

        const newHours = date?.getHours()
        const newMinutes = date?.getMinutes()

        const timeDifference = (currentHour * 60 + currentMinutes) - (newHours * 60 + newMinutes);
        const timeDiffereceMinutes = timeDifference

        // if(timeDiffereceMinutes ===0 ){
        //     return
        // }

        let formattedDate = `${year}-${month}-${day}`;

        const existStartTime = convertTimeToMinute(booking?.salectTime);
        const updatedMinutes = existStartTime - timeDiffereceMinutes;
        const formattedTime = convertToTime(updatedMinutes)

        let salectDateInFormat = `${formattedDate}T${formattedTime}:00`;

        if (+new Date(salectDateInFormat) < +new Date()) {
            handleAlertModalShow("Error, appointment can't be rescheduled on past time")
            return
        }

        const bookingServices = booking?.services?.sort((a: any, b: any) => {
            return a.startTime.localeCompare(b.startTime);
        })?.map((item: any) => {
            return {
                ...item,
                TeamMemberId: data?.event?.service?.some((book: any) => book?._id === item?._id) ? resourceId !== 1 ? [resourceId] : [] : item?.TeamMemberId?.length > 0 ? [item?.TeamMemberId?.[0]?._id] : []
            }
        });

        const services = bookingServices?.map((item: any, index: number) => {
            const existStartTime = convertTimeToMinute(item?.startTime);
            const updatedMinutes = existStartTime - timeDiffereceMinutes;
            const formattedTime = convertToTime(updatedMinutes)

            return {
                ...item,
                serviceId: item?.serviceId?._id,
                TeamMemberId: item?.TeamMemberId,
                bundleId: item?.bundleId?._id || null,
                promotionId: item?.promotionId?._id || null,
                bundlePromotionId: item?.bundlePromotionId || null,
                bundlePromotionalPrice: item?.bundlePromotionalPrice || "",
                _id: item?._id,
                startTime: formattedTime,
            }
        })

        const serviceId = services?.map((item: any) => item?.serviceDetails?._id);
        const totalDuration: any = bookingServices?.reduce((acc: any, item: any) => acc + Number.parseInt(item?.serviceId?.duration), 0);;
        let queryArr: any = bookingServices?.map((item: any, i: any) => {

            return (
                {
                    serviceId: item?.serviceDetails?._id,
                    duration: Number.parseInt(item?.serviceId?.duration),
                    teamMemberId: item?.TeamMemberId?.[0] || null,
                    teamTitleId: item?.serviceId?.teamTitleId,
                }
            )
        })

        queryArr?.sort((a: any, b: any) => {
            if (a.serviceId === b.serviceId) {
                if (a.teamMemberId === null && b.teamMemberId !== null) {
                    return 1;  // a comes after b
                } else if (a.teamMemberId !== null && b.teamMemberId === null) {
                    return -1; // a comes before b
                } else {
                    return 0; // no change in order
                }
            }
        });



        const teamId: any = []
        bookingServices?.forEach((item: any) => {
            if (item?.TeamMemberId?.length > 0) {
                teamId.push(item?.TeamMemberId?.[0])
            }
        });

        let params = `businessId=${booking?.Business?._id}&bookingId=${booking?._id}&branchId=${booking?.BranchLocation?._id}&startDate=${formattedDate}&endDate=${formattedDate}&serviceArray=${JSON.stringify(queryArr)}&serviceId=${JSON.stringify(serviceId)}&teamMemberId=${JSON.stringify(teamId)}&serviceDuration=${Number.parseInt(totalDuration)}`

        const response: any = await httpRequest(`getAvailableReschedulingSlots?${params}`, "GET", null, "json");

        if (response?.status) {
            const bookingSlots = response?.data?.[0]?.result;
            if (bookingSlots?.length > 0) {
                const fromArray = bookingSlots?.map((item: any) => item?.from);
                if (fromArray.includes(formattedTime)) {
                    let finalData = {
                        ...booking,
                        salectDateInFormat,
                        salectDate: formattedDate,
                        salectTime: formattedTime,
                        services,
                    }



                    const res = await httpRequest(`updateBooking/${booking?._id}`, "PATCH", finalData, "json");
                    if (res.status) {
                        getBooking && getBooking()
                    }
                } else {
                    handleAlertModalShow("Error, team member is not available for this service at this time!")
                    return
                }

            } else {
                handleAlertModalShow("Error, team member is not available for this service at this time!")
                return
            }

            // let finalData = {
            //     ...booking,
            //     salectDateInFormat,
            //     salectDate: formattedDate,
            //     salectTime: formattedTime,
            //     services,
            // }

            // const res = await httpRequest(`updateBooking/${booking?._id}`, "PATCH", finalData, "json");
            // if (res.status) {
            //     getBooking && getBooking()

            // }
        }


    };

    const eventPropGetter = (event: any, start: any, end: any, isSelected: any) => {
        if (event.resourceId === "0" || event.eventType === "emptyBoxes") {
            return {
                className: 'custom-event', // Use your custom class here
            }
        } else {
            return {
                className: ""
            }
        }
    }

    return (
        <React.Fragment key={branchId}>
            <ErrorPopup
                showErrorModal={showErrorModal}
                handleErrorModalClose={handleAlertModalClose}
            />
            <div>
                <div className={`${cx.DnDCalendar} ${loading ? cx.filring : null}`}>
                    <FadeLoader loading={loading} margin={2} width={5} color="#36d7b7" style={{ position: "absolute", top: "55%", left: "50%", zIndex: '1000' }} />
                    <DndProvider backend={TouchBackend}
                        options={{
                            enableMouseEvents: true,  // Allows compatibility with both mouse and touch
                            ignoreContextMenu: true, // Ignores long press context menu on mobile
                            delayTouchStart: 100,    // Optional: Fine-tune the delay
                        }}>
                        <DnDCalendar
                            defaultView={selectedViewDateTeam?.view}
                            key={currentBranchData?._id}
                            events={eventData}
                            localizer={localizer}
                            resources={resources}
                            style={{ height: 'calc(100dvh - 90px)' }}
                            components={{
                                resourceHeader: MyCustomResourceHeader,
                                event: MyCustomEvents,
                                toolbar: CalendarToolbar,
                            }}
                            onEventDrop={onEventDrop}
                            resizable={false}
                            onSelectSlot={handleSelectSlot}
                            eventPropGetter={eventPropGetter}
                            selectable
                            step={15}
                            timeslots={4}
                            slotPropGetter={customSlotPropGetter}
                            views={['day', 'week', "month"]}
                            min={dayStart} // Set the minimum time
                            // max={dayEnd} // set the maximum time
                            max={new Date(dayStart.getFullYear(), dayStart.getMonth(), dayStart.getDate(), 23, 59)} // Explicitly set to 23:59
                            popup
                        />
                    </DndProvider>
                </div>
            </div>
        </React.Fragment>
    )
}
const DnDCalendar = withDragAndDrop(Calendar)
export default MyCalendar

