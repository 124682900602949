import React, { useEffect, useState } from 'react';
import { Col, Tab, Nav, Row, Dropdown } from 'react-bootstrap';
import { editButton, deleteIcon, iconLogoGrey } from '../../../assets/images';
import cx from "./index.module.scss";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { getDynamicURIName } from '../../../Utils/Funcs';
import ServiceRoutes from './ServiceComponents/ServiceRoutes';
import Offcanvas from 'react-bootstrap/Offcanvas';
import st from "../../../assets/stylesheet/style.module.scss";
import { useForm } from 'react-hook-form';
import { httpRequest } from '../../../Apis/commonApis';
import { useSelector } from 'react-redux';

export default function ServiceInside({ editServiceData, handleServiceCanvasClose }: any) {
    const location = useLocation();
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    );
    const dynamicName = getDynamicURIName(location.pathname);
    const navigate = useNavigate();
    const [serviceName, setServiceName] = useState('')
    const [activeTab, setActiveTab] = useState(dynamicName);
    const [editData, setEditData] = useState<any>(null);
    const submitForm = useForm();
    const [apiError, setApiError] = useState("");
    const [catData, setCatData] = useState([]);
    const [selectedServiceCat, setSelectedServiceCat] = useState<string>('');
    const [errorTrig, setErrorTrig] = useState<boolean>(false)

    const isExternal = editServiceData?.serviceCategory === null

    const getServiceCatagory = async () => {
        const res = await httpRequest(`getServiceCatagory?businessId=${businessData._id}&type=list`, "get", null, "json");
        if (res.status === true) {
            setCatData(res?.data);
        }
    };

    useEffect(() => {
        if (businessData) {
            getServiceCatagory()
        }
    }, [businessData]);

    const handleStepsFormData = (formdata: any, step: any) => {
        let srvId = editData ? editData?._id : formdata?._id;
        if (step === "amenities") {
            handleServiceCanvasClose()
            return
        }
        navigate(step, { state: { serviceId: srvId }, replace: true });
        setActiveTab(step);
    };

    const handleTabSelect = (tab: any) => {
        if (editData) {
            setActiveTab(tab);
            navigate(`${tab}`, { state: { serviceId: editData?._id }, replace: true });
        }
    };
    
    useEffect(() => {
        if (!editData && editServiceData?.editService) {
            setServiceName(editServiceData?.editService?.serviceName)
        } else {
            setSelectedServiceCat(editData?.serviceCategory?._id)
            setServiceName(editData?.serviceName)
        }
    }, [editData, editServiceData])

    
    return (
        <div>
            <Offcanvas.Header closeButton >
                <div className={`${st.offHeader_main}`}>
                    {!isExternal && <h6>Service Category : {editServiceData?.serviceCategory?.serviceCategoryName} </h6>}
                    <h3>{editServiceData?.isEdit ? `Service: ${serviceName}` : 'Add a New Service'}</h3>
                    <Col md={10} className='pe-1'>
                        <div className={`${st.reFormBox} mb-0`}>
                            <input type="text" disabled={activeTab !== 'info'} placeholder="Enter Your Service Name" className="form-control" value={serviceName} onChange={(e: any) => setServiceName(e.target.value)} />
                            {apiError && (
                                <p className={"errorMessage"}>
                                    {apiError}
                                </p>
                            )}
                        </div>
                    </Col>
                    {isExternal && <Col md={10} className='pe-1 mt-2'>
                        <div className={`${st.reFormBox} mb-0`}>
                            <select className="form-select" disabled={activeTab !== 'info'} value={selectedServiceCat} onChange={(e:any) => setSelectedServiceCat(e.target.value)}>
                                <option value="">Service Category</option>
                                {catData?.map((cat: any) => {
                                    return (
                                        <option key={cat?._id} value={cat?._id}>{cat?.serviceCategoryName}</option>
                                    )
                                })}
                            </select>
                            {errorTrig && !selectedServiceCat && isExternal && (
                                <p className={"errorMessage"}>
                                    this field is required
                                </p>
                            )}
                        </div>
                    </Col>}
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body className='pt-0'
            //  style={{ height: 'calc(100vh - 0px)' }} 
             key={activeTab}>
                <div>
                    <Col md={12}>
                        <div >
                            <div className={`${st.tabbingBox}  mt-0`}>
                                <Tab.Container
                                    id="left-tabs-example"
                                    activeKey={activeTab}
                                    onSelect={handleTabSelect}
                                >
                                    <div className={`${st.tabbing_pillBox}`}>
                                        <Nav variant="pills" className="mb-0">
                                            <Nav.Item>
                                                <Nav.Link eventKey="info">
                                                    Basic Info
                                                </Nav.Link>
                                            </Nav.Item>
                                            <span className={`${st.linespan}`}></span>

                                            <Nav.Item>
                                                <Nav.Link eventKey="pricing">
                                                    Pricing and Duration
                                                </Nav.Link>
                                            </Nav.Item>
                                            <span className={`${st.linespan}`}></span>
                                            <Nav.Item>
                                                <Nav.Link eventKey="assign-branch">
                                                    Assign Branches
                                                </Nav.Link>
                                            </Nav.Item>

                                        </Nav>
                                    </div>
                                    <Tab.Content>
                                        <Tab.Pane eventKey={activeTab} style={{ minWidth: 'auto' }}>
                                            <Routes>
                                                <Route path={activeTab}
                                                    element={<ServiceRoutes dynamicName={dynamicName}
                                                        handleStepsFormData={handleStepsFormData}
                                                        editService={editServiceData?.editService}
                                                        editServiceData={editData}
                                                        setEditServiceData={setEditData}
                                                        submitForm={submitForm}
                                                        isExternal={isExternal}
                                                        serviceName={serviceName}
                                                        setErrorTrig={setErrorTrig}
                                                        setApiError={setApiError}
                                                        selectedServiceCat={selectedServiceCat}
                                                        serviceCategoryId={editServiceData?.serviceCategory?._id}
                                                    />}
                                                />
                                            </Routes>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>

                            </div>
                        </div>
                    </Col>

                </div>
            </Offcanvas.Body>

            {/* <div className={`${cx.offcanvas_footer}`}>
                <Col md={12}>
                    <div className={`${st.formBox} ${cx.formSign}`}>
                        <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-3`}>
                            {activeTab === 'info' ? (
                                <button className={`btn ${st.btn3} ${cx.btnGray}`} type="submit" onClick={handleSubmit(submitHandler)}>
                                    {loading ? <LoadingBtn /> : 'Save'}
                                </button>
                            ) : activeTab === 'pricing' ? (
                                <button className={`btn ${st.btn3} ${cx.btnGray}`} type="submit" onClick={handlePriceSave}>
                                    {loading ? <LoadingBtn /> : 'Save'}
                                </button>
                            ) : (
                                <button className={`btn ${st.btn3} ${cx.btnGray}`} id='submit-service' type="submit">
                                    {loading ? <LoadingBtn /> : 'Save'}
                                </button>
                            )}
                            <button className={`btn ${st.btn3} ${cx.btnGray} ${cx.btnGrayLight}`} onClick={() => {
                                handleServiceCanvasClose()
                                // if (activeTab === 'info') {
                                //     handleServiceCanvasClose()
                                // } else {
                                //     navigate(-1)
                                // }
                            }}
                                type="submit">
                                Cancel
                            </button>
                        </div>
                    </div>
                </Col>
            </div> */}
        </div>
    )
}
