import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Row } from "react-bootstrap";
import { Switch as CustomSwitch } from "../../../components/Website/Forms";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import CustomTimeSelect from "../../../constants/CustomTimeSelect";
import { timeValues, weekValues } from "../../../constants/Constants";
import { disablePastDate } from "../../../Utils/Funcs";
import { FormControlLabel, styled, Switch } from "@mui/material";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import OnOffSwitch from "../../../components/Business/Form/OnOffSwitch";
import HoverEyeContent from "../../../components/Business/HoverEyeContent/HoverEyeContent";
import { FaPlus } from "react-icons/fa";
import { copyClipBoard } from "../../../assets/images";


interface FormValues {
    [key: string]: string;
}
export default function BlackOut(props: any) {
    const { handleStepsFormData, editPromotionData, loading, type = "", editLoading } = props;

    const { register, setValue, control, handleSubmit, watch, formState: { errors }, reset } = useForm<FormValues>();
    const {
        register: regNonOpHrs,
        control: controlNonOpHrs,
        handleSubmit: handleSubmitNonOpHrs,
        watch: watchNonOpHrs,
        setValue: setNonOpHrsValue,
        formState: { errors: NonOpHrsErr },
        reset: resetNonOpHrs,
    } = useForm<FormValues>();

    const [selectedNonOpHrs, setSelectedNonOpHrs] = useState<any>([]);
    const [dayWiseOff, setDayWiseOff] = useState<any>([]);
    const [isSameEveryDay, setIsSameEveryDay] = useState(true);
    const [fullDayCheck, setFullDayCheck] = useState(false);

    useEffect(() => {
        if (editPromotionData) {
            let timeCounts: any = {}
            if (editPromotionData?.blackOutPeriod) {
                for (const entry of editPromotionData?.blackOutPeriod) {
                    const combinationKey = `${entry.from}-${entry.to}`;
                    if (combinationKey in timeCounts) {
                        timeCounts[combinationKey]++;
                    } else {
                        timeCounts[combinationKey] = 1;
                    }
                }
                const uniqueTimeCombinations: any = editPromotionData?.blackOutPeriod?.filter((entry: any) => {
                    const combinationKey = `${entry.from}-${entry.to}`;
                    return timeCounts[combinationKey] === 1;
                });

                reset();
                resetNonOpHrs();
                editPromotionData?.blackOutPeriod?.map((item: any) => {
                    setValue(`${item.dayName}from`, item.from === "Closed" ? "" : item.from);
                    setValue(`${item.dayName}to`, item.to === "Closed" ? "" : item.to);
                })
                setDayWiseOff(editPromotionData?.blackOutPeriod?.map((item: any) => ({ day: item.dayName, value: item.from === "Closed" })))
                // if (uniqueTimeCombinations?.length > 0) {
                //     setIsSameEveryDay(false)
                //     setValue(`from`, "N/A");
                //     setValue(`to`, "N/A");
                // } else {
                //     setIsSameEveryDay(editPromotionData?.sameEveryDay?.isSameEveryDay)
                //     setValue(`from`, editPromotionData?.sameEveryDay?.from);
                //     setValue(`to`, editPromotionData?.sameEveryDay?.to);
                // }
                setSelectedNonOpHrs(editPromotionData?.nonApplicableHours);
            }
        }
    }, [editPromotionData]);


    const handleSwitchChange = (value: any) => {
        let isChecked = value
        let fromVal = isChecked ? watch('from', '') : '';
        let toVal = isChecked ? watch('to', '') : '';
        weekValues.forEach((item: any, index: any) => {
            if (dayWiseOff?.[index] && dayWiseOff?.[index]?.value) {
                return
            }
            setValue(`${item.label}from`, fromVal);
            setValue(`${item.label}to`, toVal);
        });
        setIsSameEveryDay(value);
    };

    useEffect(() => {
        if (dayWiseOff?.length !== 0) {
            dayWiseOff?.map((item: any, index: number) => {
                item?.value && setValue(`${item.day}from`, "");
                item?.value && setValue(`${item.day}to`, "");
            })
        }
    }, [dayWiseOff])

    function handleTimeChange() {
        if (isSameEveryDay) {
            let fromVal = watch('from', '');
            let toVal = watch('to', '');
            weekValues.forEach((item: any, index: number) => {
                if (dayWiseOff?.[index] && dayWiseOff?.[index]?.value) {
                    return
                }
                if (fromVal) setValue(`${item.label}from`, fromVal);
                if (toVal) setValue(`${item.label}to`, toVal);
            });
        }
    }

    const onSubmit: SubmitHandler<FormValues> = (data) => {
        let workingHours: any = [];
        const finalNotWorkingHour = selectedNonOpHrs?.map((item: any) => {
            if (!item?.isFullDay) {
                return { ...item, from: "00:00", to: "23:59" }
            } else {
                return item
            }
        })
        weekValues.forEach((elem, index) => {
            workingHours.push({
                dayName: elem.label,
                from: dayWiseOff?.[index]?.value ? "Closed" : data[`${elem.label}from`],
                to: dayWiseOff?.[index]?.value ? "Closed" : data[`${elem.label}to`],
            })
        });
        let sameEveryDayObj = { isSameEveryDay: isSameEveryDay, from: data.from, to: data.to };
        let allData = {
            blackOutPeriod: JSON.stringify(workingHours),
            sameEveryDay: JSON.stringify(sameEveryDayObj),
            nonApplicableHours: JSON.stringify(finalNotWorkingHour),
        }

        handleStepsFormData(allData, 'step2');
    };


    // -------------- multi start time and end time selection ----------------- //

    const onNonOpHrsSubmit: SubmitHandler<FormValues> = (data) => {
        setSelectedNonOpHrs((prev: any) => [
            ...prev,
            {
                fromDate: data.fromDate,
                toDate: data.toDate,
                isFullDay: fullDayCheck,
                from: !fullDayCheck ? '' : data.from,
                to: !fullDayCheck ? '' : data.to,
            }
        ]);
        setFullDayCheck(false);
        resetNonOpHrs();
        setNonOpHrsValue('fromDate', '');
        setNonOpHrsValue('toDate', '');

        setNonOpHrsValue('from', '');
        setNonOpHrsValue('to', '');
    };




    const nonOpHrsRemoveChangeHandler = (e: any, indexToRemove: any) => {
        e.preventDefault();
        const clonefield = selectedNonOpHrs.slice();
        clonefield.splice(indexToRemove, 1);
        setSelectedNonOpHrs(clonefield);
    };

    function handleCheckBoxSelection(e: any) {
        resetNonOpHrs();
        setFullDayCheck(e.target.checked);
    }    

    return (
        <>
            <Row>
                <Col md={12}>
                    <div className="d-flex gap-5">
                        <p className={`${st.tabbing_para}`}>Indicate the blackout period during which this promotion is unavailable, such as peak hours. You can also set the blackout period for particular weekdays or specific dates.</p>
                        <div className={`${st.formBox} ${st.formSign} w-auto`}>
                            <div className={`${st.btnsGroup} ${st.formSignGroup} mt-0 pb-3 justify-content-end`}>
                                {/* <button
                                className={`btn ${st.btn1} ${st.btnGray} ${st.btnGrayLight}`}
                                onClick={() => handleStepsFormData('prevStep', `${type}info`)}
                            >
                                Back
                            </button> */}
                                {<button type='submit' className={`btn ${st.btn4} ${st.btnGray} mx-0`} disabled={loading} onClick={handleSubmit(onSubmit)}>
                                    {false ? (
                                        <div
                                            className="spinner-border spinner-border-sm text-light"
                                            role="status"
                                        ></div>
                                    ) : (
                                        'Save'
                                    )}
                                </button>}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            <div className={`${st.newRedesign_main}`}>
                <Row>
                    <Col xl={6} lg={7} md={8}>
                        <Col md={12}>
                            <div className={`${st.branchContact_tabBox} ${st.branchContact_tabBox2}`} >
                                <h4 className="mb-0">Black Out Period on specfic hour of weekday</h4>
                                <p>Promotion not applicable during following hours on each day</p>

                                <ul className={`${st.branchOperating_tab_InputMian}`}>
                                    <li>
                                        <div className={`${st.firstBox} ${st.copyAllDays}`}>
                                            <label className={`d-inline-flex ${st.checkbox}`}>
                                                <input type="checkbox"

                                                    key={"sameeveryday"} />
                                                <span className={`${st.checkmark}`}></span>
                                                <span className={`${st.labelName} ms-0`}>Copy to <br /> all days</span>
                                            </label>
                                        </div>
                                        {<div className='d-flex gap-2'>
                                            <div className={`${st.secondBox}`}>
                                                <div className={`${st.inoutArea}`} >
                                                    <label>From</label>
                                                    <select className="form-select" {...register('from')}>
                                                        <option value="">-Select-</option>
                                                        {timeValues?.map((item: any) => {
                                                            return (
                                                                <option value={item?.value} key={item?.value}>{item?.label}</option>
                                                            )
                                                        })}
                                                        {errors?.from?.type === "required" && (
                                                            <p className="errorMessage">required*</p>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className={`${st.secondBox}`}>
                                                <div className={`${st.inoutArea}`} >
                                                    <label>To</label>
                                                    <select className="form-select"  {...register('to')}>
                                                        <option value="">-Select-</option>
                                                        {timeValues.filter((item: any) => item.value >= watch('from', ''))?.map((item: any) => {
                                                            return (
                                                                <option value={item?.value} key={item?.value}>{item?.label}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    {errors?.to?.type === "required" && (
                                                        <p className="errorMessage">required*</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>}
                                        <div className={`${st.lastCopyClip}`}>
                                            <button onClick={() => handleSwitchChange(true)}>
                                                <img src={copyClipBoard} alt="copy" />
                                            </button>
                                        </div>
                                    </li>
                                    {weekValues.map((item: any, index: number) => {
                                        return (
                                            <li>
                                                <div className={`${st.firstBox}`}>
                                                    <h6 style={{ lineHeight: '33px' }}>{item?.label}</h6>
                                                    {/* <label className={`d-inline-flex ${st.checkbox}`}>
                                                        <input type="checkbox" checked={dayWiseOff?.[index]?.value}
                                                            onChange={(e: any) => {
                                                                let updateDayWiseOff: any = [...dayWiseOff];
                                                                updateDayWiseOff[index] = {
                                                                    day: item?.label,
                                                                    value: e.target.checked
                                                                }
                                                                setDayWiseOff(updateDayWiseOff)
                                                            }} />
                                                        <span className={`${st.checkmark}`}></span>
                                                        <span className={`${st.labelName} ms-1`} >Closed</span>
                                                    </label> */}
                                                </div>
                                                {!dayWiseOff?.[index]?.value && <div className='d-flex gap-2'>
                                                    <div className={`${st.secondBox}`}>
                                                        <div className={`${st.inoutArea}`}>
                                                            <label>From</label>
                                                            <Controller
                                                                name={`${item.label}from`}
                                                                control={control}
                                                                rules={{ required: false }}
                                                                render={({ field }) => (
                                                                    <select className="form-select" disabled={dayWiseOff?.[index]?.value} value={field.value} onChange={field.onChange}>
                                                                        <option value="">-Select-</option>
                                                                        {timeValues?.map((time: any) => {
                                                                            return (
                                                                                <option value={time?.value} key={time?.value}>{time?.label}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                    // <CustomTimeSelect style={{ fontSize: "13px", padding: "10px 11px", backgroundSize: "11px", minWidth: "84px" }} disabled={dayWiseOff?.[index]?.value} value={field.value} onChange={field.onChange}
                                                                    //     timeValues={timeValues} required={false} />
                                                                )}
                                                            />
                                                            {errors[`${item.label}from`]?.type === "required" && (
                                                                <span className="errorMessage">required*</span>
                                                            )
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className={`${st.secondBox}`}>
                                                        <div className={`${st.inoutArea}`}>
                                                            <label>To</label>
                                                            <Controller
                                                                name={`${item.label}to`}
                                                                control={control}
                                                                rules={{ required: false }}
                                                                render={({ field }) => {
                                                                    const filteredTimeValues = timeValues.filter((time) => time.value >= watch(`${item.label}from`, ''));
                                                                    return (
                                                                        <select className="form-select" disabled={dayWiseOff?.[index]?.value} value={field.value} onChange={field.onChange}>
                                                                            <option value="">-Select-</option>
                                                                            {filteredTimeValues?.map((time: any) => {
                                                                                return (
                                                                                    <option value={time?.value} key={time?.value}>{time?.label}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                        // <CustomTimeSelect style={{ fontSize: "13px", padding: "10px 11px", backgroundSize: "11px" }}  timeValues={filteredTimeValues} required={false} />
                                                                    );
                                                                }}
                                                            />
                                                            {errors[`${item.label}to`]?.type === "required" && (
                                                                <span className="errorMessage">required*</span>
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>}
                                            </li>
                                        )
                                    })
                                    }
                                </ul>
                            </div>
                        </Col>
                    </Col>
                    <Col md={12} className="mt-4">
                        <div className={`${st.branchContact_tabBox} h-auto`}>
                            <Col md={12}>
                                <h4 className="mb-0">Blackout on Specific dates</h4>
                                <p>Promotion not applicable during following period</p>
                                <Row>
                                    <Col md={5}>
                                        <div className={`${st.reFormBox}`}>
                                            <Row className="g-3">
                                                <Col md={6}>
                                                    <label>Date From</label>
                                                    <input type="date" className="form-control" placeholder="DD-MM-YYYY"
                                                        min={disablePastDate()}
                                                        {...regNonOpHrs("fromDate", {
                                                            required: true,
                                                        })}
                                                    />
                                                    {NonOpHrsErr?.fromDate?.type === "required" && (
                                                        <p className="errorMessage">required*</p>
                                                    )}
                                                </Col>
                                                <Col md={6}>
                                                    <label>Date To</label>
                                                    <input type="date" className="form-control" placeholder="DD-MM-YYYY"
                                                        min={watchNonOpHrs('fromDate', disablePastDate())}
                                                        {...regNonOpHrs("toDate", {
                                                            required: false,
                                                        })}
                                                    />
                                                    {NonOpHrsErr?.toDate?.type === "required" && (
                                                        <p className="errorMessage">required*</p>
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col md={7}>
                                        <div className={`${st.reFormBox}`}>
                                            <label>Specific Hours</label>
                                            <Row className="g-3">
                                                <Col md={3} className="text-center">
                                                    <label className={`${st.checkbox} ${cx.profileCheck} d-inline-flex`} style={{ marginTop: '8px', left: '0px' }}>
                                                        <input type="checkbox" checked={fullDayCheck} onChange={handleCheckBoxSelection} />
                                                        <span className={`${st.checkmark}`}></span>
                                                        <span className="ms-1" style={{ fontSize: '13px', marginTop: '1px', opacity: '0' }}>t
                                                        </span>
                                                    </label>
                                                </Col>
                                                {fullDayCheck && <>
                                                    <Col md={3}>
                                                        <Controller
                                                            name="from"
                                                            control={controlNonOpHrs}
                                                            rules={{ required: fullDayCheck }}
                                                            render={({ field }) => (
                                                                <>
                                                                    <div className="position-relative">
                                                                        <label style={{
                                                                            position: 'absolute', top: '-8px',
                                                                            left: '11px',
                                                                            backgroundColor: '#fff',
                                                                            fontSize: '11px',
                                                                            color: '#222',
                                                                            fontWeight: 700,
                                                                            padding: '0px 7px'
                                                                        }}
                                                                        >From</label>
                                                                        <select className="form-select" value={field.value} onChange={field.onChange} required={!fullDayCheck}>
                                                                            <option value="">-Select-</option>
                                                                            {timeValues?.map((time: any) => {
                                                                                return (
                                                                                    <option value={time?.value} key={time?.value}>{time?.label}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                    {NonOpHrsErr?.from?.type === "required" && (
                                                                        <p className="errorMessage">required*</p>
                                                                    )}
                                                                </>

                                                            )}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <Controller
                                                            name="to"
                                                            control={controlNonOpHrs}
                                                            rules={{ required: fullDayCheck }}
                                                            render={({ field }) => (
                                                                <>
                                                                    <div className="position-relative">
                                                                        <label style={{
                                                                            position: 'absolute', top: '-8px',
                                                                            left: '11px',
                                                                            backgroundColor: '#fff',
                                                                            fontSize: '11px',
                                                                            color: '#222',
                                                                            fontWeight: 700,
                                                                            padding: '0px 7px'
                                                                        }}>To</label>
                                                                        <select className="form-select" value={field.value} onChange={field.onChange} required={!fullDayCheck}>
                                                                            <option value="">-Select-</option>
                                                                            {timeValues.filter((item: any) => item.value >= watchNonOpHrs('from', ''))?.map((time: any) => {
                                                                                return (
                                                                                    <option value={time?.value} key={time?.value}>{time?.label}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                    {NonOpHrsErr?.to?.type === "required" && (
                                                                        <p className="errorMessage">required*</p>
                                                                    )}
                                                                </>

                                                            )} />
                                                    </Col>
                                                </>}
                                                <Col md={2}>
                                                    <button className={`${st.addGrey} btn`} onClick={handleSubmitNonOpHrs(onNonOpHrsSubmit)}>
                                                        Save
                                                    </button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>

                                </Row>
                                {selectedNonOpHrs?.map((item: any, i: any) => {
                                    return (
                                        <Row key={i}>
                                            <Col md={5}>
                                                <div className={`${st.reFormBox}`}>
                                                    <label>Date</label>
                                                    <Row className="g-3">
                                                        <Col md={6}>
                                                            <input type="date" disabled
                                                                defaultValue={item.fromDate} className="form-control" placeholder="DD-MM-YYYY" />

                                                        </Col>
                                                        <Col md={6}>
                                                            {<input type="date" className="form-control" placeholder="DD-MM-YYYY"
                                                                disabled
                                                                defaultValue={item.toDate ? item?.toDate : item?.fromDate}
                                                            />}

                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col md={7}>
                                                <div className={`${st.reFormBox}`}>
                                                    <label style={!item?.isFullDay ? {opacity:'0'} : {}}>Specific Hours</label>
                                                    <Row className="g-3">
                                                        {item?.isFullDay && <>
                                                        <Col md={3} className="text-center">
                                                            <label className={`${st.checkbox} ${cx.profileCheck} d-inline-flex`} style={{ marginTop: '8px', left: '0px' }}>
                                                                <input type="checkbox" checked={item.isFullDay === 'true' || item.isFullDay} disabled />
                                                                <span className={`${st.checkmark}`}></span>
                                                                <span className="ms-1" style={{ fontSize: '13px', marginTop: '1px', opacity: '0' }}>t
                                                                </span>
                                                            </label>
                                                        </Col>
                                                            <Col md={3}>
                                                                <div className="position-relative">
                                                                    <label style={{
                                                                        position: 'absolute', top: '-8px',
                                                                        left: '11px',
                                                                        backgroundColor: '#fff',
                                                                        fontSize: '11px',
                                                                        color: '#222',
                                                                        fontWeight: 700,
                                                                        padding: '0px 7px'
                                                                    }}
                                                                    >From</label>
                                                                    <select className="form-select" value={item.from} disabled>
                                                                        <option value="">-Select-</option>
                                                                        {timeValues?.map((time: any) => {
                                                                            return (
                                                                                <option value={time?.value} key={time?.value}>{time?.label}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>

                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="position-relative">
                                                                    <label style={{
                                                                        position: 'absolute', top: '-8px',
                                                                        left: '11px',
                                                                        backgroundColor: '#fff',
                                                                        fontSize: '11px',
                                                                        color: '#222',
                                                                        fontWeight: 700,
                                                                        padding: '0px 7px'
                                                                    }}
                                                                    >To</label>
                                                                    <select className="form-select" value={item?.to} disabled>
                                                                        <option value="">-Select-</option>
                                                                        {timeValues?.map((time: any) => {
                                                                            return (
                                                                                <option value={time?.value} key={time?.value}>{time?.label}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </Col>
                                                        </>}
                                                        <Col md={2}>
                                                            <button style={{ minWidth: '50px' }} className={`${st.addGrey} btn`} onClick={(e) => nonOpHrsRemoveChangeHandler(e, i)}>
                                                                -
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>

                                        </Row>
                                    )
                                })}
                                {/* <Row>
                                    <Col md={12}>
                                        <div className={`${st.addBtn}`}>
                                            <button className={`btn`} > <FaPlus /> Add</button>
                                        </div>
                                    </Col>
                                </Row> */}
                            </Col>
                        </div>
                    </Col >

                </Row >
            </div >


        </>
    )
}
