import React, { useState } from 'react'
import { deleteIcon, editButton } from '../../../assets/images'
import { Accordion } from 'react-bootstrap'
import { Status } from '../../../components/Business';
import st from "../../../assets/stylesheet/style.module.scss";
import { useDispatch, useSelector } from 'react-redux';
import { httpRequest } from '../../../Apis/commonApis';
import { useNavigate } from 'react-router-dom';
import { getBusinessData } from '../../../redux_toolkit/reducer/shareDataReducer';
import { DeletePopup } from '../../../components/Business/Modals';

export default function ServiceCard({ children, service, getService, getPrices,type = 'all', priceList, handleServiceCanvasShow, setEditServiceData }: any) {
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );
  const navigate = useNavigate()
  const [apiMessage, setApiMessage] = useState<any>("")
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setApiMessage("");
    setShow(false);
  }
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const deleteApi = async () => {
    const res = await httpRequest(
      `deleteBusinessService?_id=${service?._id}&businessId=${businessData?._id}`,
      "DELETE",
      null,
      "json"
    );
    if (res.status === true) {
      getService();
      getPrices();

      if (res?.data?.length === 0) {
        dispatch(getBusinessData({ id: `businessId=${businessData?._id}` }));
      }
      handleClose();
    } else {
      setApiMessage(res?.message)
    }
  };

  const canEditService = subModuleRoles?.['Services']?.Services?.Edit;
  const canDeleteService = subModuleRoles?.['Services']?.Services?.Delete;
  const canDeactivate = subModuleRoles?.['Services']?.Services?.deactivate;
  return (
    <>
      <DeletePopup
        show={show}
        handleClose={handleClose}
        deleteApi={deleteApi}
        apiMessage={apiMessage}
      />
      <div>
        <div className={`${st.edit_publishBranchMain}`}>
          <div className={`${st.topBox_withBtn} mb-1`}>
            <h5>{service?.serviceName}</h5>
          </div>

          <div className={`${st.inside_Accordion}`}>
            <Accordion>
              <Accordion.Item eventKey="inside1">
                <div className={`${st.newInside_AccordionFlexBtn}`}>
                  {priceList?.length > 0 && <Accordion.Header>
                    <span className={`${st.inside_head}`}>{priceList?.length} Price variation</span>
                  </Accordion.Header>}
                  {priceList?.length === 0 && <p className={`${st.noData}`}>Please add price first</p>}
                  <ul className={`${st.btnList} align-items-center`}>
                    {canEditService && <li onClick={() => {
                      setEditServiceData({ isEdit: true, serviceCategory: service?.ServiceCategory, editService: service })
                      handleServiceCanvasShow()
                      const route = type === 'publish' ? '/business/publish/edit-service/info' : '/business/services/edit-service/info'
                      navigate(route, { state: { serviceId: service?._id } })
                    }}>
                      <button> <img src={editButton} alt="edit" /></button>
                    </li>}
                    {canDeleteService && type !== 'publish' && <li onClick={handleShow}>
                      <button> <img src={deleteIcon} alt="deleteIcon" /></button>
                    </li>}
                    {canDeactivate && type !== 'publish' && <li className="ms-2">
                      <Status status={service?.isActive}
                        api='updateBusinessService' id={service?._id}
                        apiMethod='json'
                        getLatest={() => {
                          getService()
                          getPrices()
                        }} />
                    </li>}
                  </ul>
                </div>
                {priceList?.length > 0 && <Accordion.Body>
                  <div className={`${st.tableCollapse}`}>
                    {children}
                  </div>
                </Accordion.Body>}
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div >
    </>
  )
}
