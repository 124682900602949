import React, { useEffect, useState, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";
import cx from "./index.module.scss";
import { FaTelegramPlane } from "react-icons/fa";
import { GrAttachment, GrClose } from "react-icons/gr";
import { db, storage } from "../../../Firebase";
import { MdCall, MdKeyboardVoice, MdOutlineEmojiEmotions, MdOutlineMoreVert } from "react-icons/md";
import { getDownloadURL, listAll, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import { IoDocumentTextOutline } from "react-icons/io5";
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import {
    arrayUnion,
    doc,
    Timestamp,
    updateDoc,
    onSnapshot,
    serverTimestamp,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import { BsArrowDownCircle } from "react-icons/bs";
import { pdfImage } from "../../../assets/images";
import FadeLoader from "react-spinners/FadeLoader";
import ChatImgPopUp from "../../../components/Business/Modals/ChatImgPopUp";
import { groupMessagesByDate } from "../../../constants/Constants";
const Chats = (props: any) => {
    const { selectedChat } = props;
    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    let { mainModuleRoles } = useSelector((state: any) => state.BranchReducer);
    let { branchId } = useSelector((state: any) => state.BranchReducer);
    const canEdit = mainModuleRoles?.Chat?.Edit
    const [text, setText] = useState<any>("");
    const [img, setImg] = useState<any[]>([]);
    const [messages, setMessages] = useState([]);
    const [newImg, setNewImg] = useState<any[]>([]);
    const [imgType, setImgType] = useState<any>("");
    const [photoUrl, setPhotourl] = useState(null)
    let [loading, setLoading] = useState(false);
    const [selectedEmoji, setSelectedEmoji] = useState<any>([]);
    const newRef: any = useRef(null);
    const textRef: any = useRef(null);
    const textareaRef = useRef(null);
    const [imgUploadLoading, setImageUploadLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<any>(false);
    const [showChatImgPopUp, setChatImgPopUpShow] = useState(false);
    const [emoji, setShowEmoji] = useState(false);
    const [inputKey, setInputKey] = useState(0);
    const handleChatImgPopUpClose = () => setChatImgPopUpShow(false);
    const handleChatImgPopUpShow = () => setChatImgPopUpShow(true);

    useEffect(() => {
        const textarea: any = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            const newHeight = Math.min(textarea.scrollHeight, 190);
            textarea.style.height = `${newHeight}px`;
        }
    }, [text]);

    useEffect(() => {
        textRef?.current?.scrollIntoView();
    }, [messages]);

    useEffect(() => {
        setMessages([]);
        if (branchId && selectedChat?.bookingData?.clientUid && selectedChat?.chatType !== 'admin' && selectedChat?.chatType !== 'generalUser') {
            try {

                const unSub = onSnapshot(
                    doc(db, "userChat", branchId, 'booking', selectedChat?.bookingId),
                    (doc) => {
                        doc.exists() && setMessages(doc.data().messages);
                    }
                );
                return () => {
                    unSub();
                };
            } catch (err:any) {
                console.log(err.message);

            }
        }
        if (selectedChat?.chatType === 'admin') {
            try {
                const unSub = onSnapshot(
                    doc(db, "superadminuser", selectedChat?._id),
                    (documentSnapshot) => {
                        setMessages(documentSnapshot?.data()?.messages || [])
                    }
                );
                return () => {
                    unSub();
                };
            } catch (err:any) {
                console.log(err.message)
            }
        }

        if (selectedChat?.chatType === 'generalUser') {
            try {
                const unSub = onSnapshot(
                    doc(db, "generalBusinessChat", businessData?._id, 'business', selectedChat?.clientId),
                    (doc) => {
                        if (doc.exists()) {
                            setMessages(doc.data().messages);
                        }
                    },
                    (error) => {
                        console.error("Error with onSnapshot:", error);
                    }
                );

                return () => {
                    unSub();
                };
            } catch (error) {
                console.error("Error initializing onSnapshot:", error);
            }
        }


    }, [selectedChat]);


    const handleSend = async () => {
        try {
            let sendTxt: any = text;
            if (newImg) {
                let newMessageArr: any = [...messages];
                for (let item of newImg) {
                    newMessageArr?.push({
                        date: "",
                        fileSizeKB: 514.83984375,
                        id: 1709703953302,
                        imgName: item?.name,
                        imgformat: "img",
                        photoUrl: "",
                        type: "business",
                        loading: true,
                    })
                    setMessages(newMessageArr)
                    const fileExtension = item?.name.split('.').pop();
                    const imgRef = ref(storage, `files/${v4()}.${fileExtension || 'jpg'}`);
                    setImageUploadLoading(true);
                    await uploadBytes(imgRef, item).then(async (value) => {
                        const url = await getDownloadURL(value.ref);
                        const fileSizeBytes = value.metadata.size;
                        const fileSizeKB = fileSizeBytes / 1024;
                        if (url) {
                            setLoading(false)
                            if (selectedChat?.chatType === 'admin') {
                                let chatDocRef = doc(db, "superadminuser", selectedChat?._id);
                                await updateDoc(chatDocRef, {
                                    messages: arrayUnion({
                                        id: Date.now(),
                                        photoUrl: url,
                                        type: "admin",
                                        imgName: item?.name,
                                        fileSizeKB: fileSizeKB,
                                        imgformat: imgType,
                                        date: Timestamp.now(),
                                    }),
                                    updateMessage: serverTimestamp(),
                                    lastMsg: url,
                                    type: imgType
                                });
                            } else if (selectedChat?.chatType === 'generalUser') {
                                let chatDocRef = doc(db, "generalBusinessChat", businessData?._id, 'business', selectedChat?.clientId);
                                await updateDoc(chatDocRef, {
                                    messages: arrayUnion({
                                        id: Date.now(),
                                        photoUrl: url,
                                        type: "business",
                                        imgName: item?.name,
                                        fileSizeKB: fileSizeKB,
                                        imgformat: imgType,
                                        date: Timestamp.now(),
                                    }),
                                    updateMessage: serverTimestamp(),
                                    lastMsg: url,
                                    type: imgType
                                });
                                let chatDocBusinessRef = doc(db, "generalUserChat", selectedChat?.clientId, 'business', businessData?._id);
                                await updateDoc(chatDocBusinessRef, {
                                    logo: businessData?.profileImage,
                                    messages: arrayUnion({
                                        id: Date.now(),
                                        photoUrl: url,
                                        type: "business",
                                        imgName: item?.name,
                                        fileSizeKB: fileSizeKB,
                                        imgformat: imgType,
                                        date: Timestamp.now(),
                                    }),
                                    updateMessage: serverTimestamp(),
                                    lastMsg: url,
                                    type: 'generalUser'
                                });
                            } else {
                                let chatDocRef = doc(db, "userChat", selectedChat?.bookingData?.clientUid, 'booking', selectedChat?.bookingId)
                                await updateDoc(chatDocRef, {
                                    messages: arrayUnion({
                                        id: Date.now(),
                                        photoUrl: url,
                                        type: "business",
                                        imgName: item?.name,
                                        fileSizeKB: fileSizeKB,
                                        imgformat: imgType,
                                        date: Timestamp.now(),
                                    }),
                                    updateMessage: serverTimestamp(),
                                    lastMsg: url,
                                    type: imgType
                                });
                                let chatBranchRef = doc(db, "userChat", branchId, 'booking', selectedChat?.bookingId)
                                await updateDoc(chatBranchRef, {
                                    messages: arrayUnion({
                                        id: Date.now(),
                                        photoUrl: url,
                                        type: "business",
                                        imgName: item?.name,
                                        fileSizeKB: fileSizeKB,
                                        imgformat: imgType,
                                        date: Timestamp.now(),
                                    }),
                                    updateMessage: serverTimestamp(),
                                    lastMsg: url,
                                    type: imgType
                                });
                            }
                        }
                    });
                    setDisabled(false);
                    setImageUploadLoading(false);
                }
                setImg([])
                setNewImg([])
                setText("")

            }
            if (sendTxt && sendTxt !== ' ') {
                if (text.trim() === "") {
                    return;
                }
                setText("");
                setSelectedEmoji([""])

                if (selectedChat?.chatType === 'admin') {
                    const chatDocRef = doc(db, "superadminuser", selectedChat?._id);
                    await updateDoc(chatDocRef, {
                        messages: arrayUnion({
                            id: Date.now(),
                            text: sendTxt,
                            type: "admin",
                            date: Timestamp.now(),
                        }),
                        updateMessage: serverTimestamp(),
                        lastMsg: sendTxt,
                        type: 'text'
                    });
                } else if (selectedChat?.chatType === "generalUser") {
                    const chatDocRef = doc(db, "generalBusinessChat", businessData?._id, 'business', selectedChat?.clientId);
                    await updateDoc(chatDocRef, {
                        messages: arrayUnion({
                            id: Date.now(),
                            text: sendTxt,
                            type: "business",
                            date: Timestamp.now(),
                        }),
                        updateMessage: serverTimestamp(),
                        lastMsg: sendTxt,
                        type: 'generalUser'
                    });
                    let chatDocBusinessRef = doc(db, "generalUserChat", selectedChat?.clientId, 'business', businessData?._id);
                    await updateDoc(chatDocBusinessRef, {
                        logo: businessData?.profileImage,
                        messages: arrayUnion({
                            id: Date.now(),
                            text: sendTxt,
                            type: "business",
                            date: Timestamp.now(),
                        }),
                        updateMessage: serverTimestamp(),
                        lastMsg: sendTxt,
                        type: 'generalUser'
                    });
                } else {
                    const chatBranchRef = doc(db, "userChat", branchId, 'booking', selectedChat?.bookingId)
                    await updateDoc(chatBranchRef, {
                        messages: arrayUnion({
                            id: Date.now(),
                            text: sendTxt,
                            type: "business",
                            date: Timestamp.now(),
                        }),
                        updateMessage: serverTimestamp(),
                        lastMsg: sendTxt,
                        type: 'text'
                    });
                    const chatDocRef = doc(db, "userChat", selectedChat?.bookingData?.clientUid, 'booking', selectedChat?.bookingId)
                    await updateDoc(chatDocRef, {
                        messages: arrayUnion({
                            id: Date.now(),
                            text: sendTxt,
                            type: "business",
                            date: Timestamp.now(),
                        }),
                        updateMessage: serverTimestamp(),
                        lastMsg: sendTxt,
                        type: 'text'
                    });
                }

            }
            setShowEmoji(false)

            setText('')
        } catch (err) {
            console.log(err)
        }
    };
    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            handleSend();
            setText("")
        }
    };
    const handleFileChange = (e: any, type: any) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const urls = Array.from(files).map((file: any) => ({
                url: URL.createObjectURL(file),
                name: file.name
            }));
            setImg(urls);
            setNewImg(files);
            setImgType(type);

            // Reset the input key to re-render the input
            if (files.length <= 1) {
                setInputKey((prevKey: any) => prevKey + 1);
            }
        }
    };

    const handleRemoveImage = (url: any, e: any) => {
        let indexToRemove = img.indexOf(url);
        if (indexToRemove !== -1) {
            img?.splice(indexToRemove, 1);
            let imgArr: any = [];
            for (let i = 0; i < newImg?.length; i++) {
                if (i === indexToRemove) {
                    imgArr = imgArr
                } else {
                    imgArr.push(newImg[i])
                }
                setImg([...img])
                setNewImg([...imgArr])
            }
        } else {
            console.log("URL not found in the array.");
        }
    };
    const viewImg = (image: any) => {
        handleChatImgPopUpShow();
        setPhotourl(image)
    };
    const emojiPicker = () => {
        if (emoji == true) {
            setShowEmoji(false)
        } else {
            setShowEmoji(true)
        }
    };

    const handleEmojiSelect = (emoji: any) => {
        setSelectedEmoji((selectedEmoji: any) => [...selectedEmoji, emoji.emoji]);
    };
    useEffect(() => {
        if (selectedEmoji) {
            // Use useEffect to handle side effects after selectedEmoji is updated
            let emojiIc = selectedEmoji.join("");
            setText(emojiIc);
        }
    }, [selectedEmoji]);

    const groupedMessages = groupMessagesByDate(messages);
    return (
        <>
            <div className={`${cx.userchatBody}`} ref={newRef}>
                <div>
                    {groupedMessages?.map((chatMessage: any, i: number) => {
                        return (
                            <div key={i} className={`${cx.dateHeading}`}>
                                <p>{chatMessage?.date}</p>
                                {chatMessage?.messages?.map((item: any) => {
                                    const date = new Date(item?.date?.seconds * 1000);
                                    let hours = date.getHours();
                                    const amPM = hours >= 12 ? 'PM' : 'AM';
                                    hours = hours % 12 || 12;
                                    let minutes: any = date.getMinutes();
                                    if (minutes < 10) {
                                        minutes = '0' + minutes;
                                    }

                                    // const amPM = hours >= 12 ? 'PM' : 'AM';
                                    let fileNameWithoutExtension = item?.imgName?.substring(0, 5);
                                    let fileExtension = item?.imgName?.substring(item?.imgName.lastIndexOf('.') + 1);
                                    if (item?.type === "business" || item?.type === "admin") {
                                        return (
                                            <div className={`${cx.chatMessage} ${cx.chatRight}`}>
                                                {item?.loading ? (
                                                    <div className={`${cx.chatMessageBody} ${cx.loader}`}>
                                                        <span className={`${cx.loadingIndicator} spinner-border spinner-border-lg text-success`}
                                                            role="status"></span>
                                                    </div>
                                                ) : item?.imgformat === "img" ? (
                                                    <div className={`${cx.chatMessageBody}`} id={"chatBody"}>
                                                        {item?.photoUrl ? (
                                                            <img src={item?.photoUrl} className={cx.chatImage} alt="img-upload" onClick={() => viewImg(item?.photoUrl)} />
                                                        ) : (
                                                            <p> {item?.text}</p>

                                                        )}
                                                        <time>{hours}:{minutes} {amPM}</time>

                                                    </div>) : (
                                                    <div className={`${cx.chatMessageBody}`} id={"chatBody"}>
                                                        {item?.photoUrl ? (
                                                            <div className={`${cx.pdf_main}`}>
                                                                <div className={`${cx.pdf_icon}`}>
                                                                    <IoDocumentTextOutline />
                                                                </div>
                                                                <div className={`${cx.pdf_details}`}>
                                                                    <div className={`${cx.line}`}>{fileNameWithoutExtension}...{fileExtension}</div>
                                                                    <div className={`${cx.download_withsize}`}>
                                                                        <div className={`${cx.size}`}>{item?.fileSizeKB.toFixed(2)} KB </div>
                                                                        <div style={{ cursor: 'pointer' }}>
                                                                            <a href={item?.photoUrl} download target='_blank' rel="noopener noreferrer">
                                                                                <BsArrowDownCircle />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <p> {item?.text}</p>

                                                        )}
                                                        <time>{hours}:{minutes} {amPM}</time>

                                                    </div>
                                                )}
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className={`${cx.chatMessage} ${cx.chatLeft}`}>
                                                {item?.imgformat === "img" ? (
                                                    <div className={`${cx.chatMessageBody}`} id={"chatBody"}>
                                                        {item?.photoUrl ? (
                                                            // <a href={item?.photoUrl} download target="_blank" rel="noopener noreferrer">
                                                            <img src={item?.photoUrl} className={cx.chatImage} alt="img-upload" onClick={() => viewImg(item?.photoUrl)} />
                                                            // </a>
                                                        ) : (
                                                            <p> {item?.text}</p>
                                                        )}
                                                        <time>{hours}:{minutes} {amPM}</time>
                                                    </div>) : (
                                                    <div className={`${cx.chatMessageBody}`} id={"chatBody"}>
                                                        {item?.photoUrl ? (
                                                            <div className={`${cx.pdf_main}`}>
                                                                <div className={`${cx.pdf_icon}`}>
                                                                    <IoDocumentTextOutline />
                                                                </div>
                                                                <div className={`${cx.pdf_details}`}>
                                                                    <div className={`${cx.line}`}>{fileNameWithoutExtension}...{fileExtension}</div>
                                                                    <div className={`${cx.download_withsize}`}>
                                                                        <div className={`${cx.size}`}>{item?.fileSizeKB.toFixed(2)} KB </div>
                                                                        <div style={{ cursor: 'pointer' }}>
                                                                            <a href={item?.photoUrl} download target='_blank' rel="noopener noreferrer">
                                                                                <BsArrowDownCircle />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <p> {item?.text}</p>
                                                        )}
                                                        <time>{hours}:{minutes} {amPM}</time>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        )
                    })}
                    <div className={`${cx.chatMessage} ${cx.chatRight}`} role='img' ref={textRef} style={{ opacity: 0 }}></div>
                </div>
                <div className={`${cx.chatSendBox}`}>
                    {canEdit && <div className={`${cx.chatSubmit}`}
                    // style={selectedChat?.chatType === 'admin' ? { width: '62%' } : {}}
                    >
                        {selectedChat ? (
                            <div className={`${cx.chatSubmiBody}`}>
                                <div className={`${cx.actionBtns}`}>
                                    {/* <button title="Voice">
                            <MdKeyboardVoice style={{ fontSize: "22px" }} />
                        </button> */}
                                    <button title="Upload Files">
                                        <GrAttachment />
                                        <input type="file" key={inputKey} onChange={(e: any) => handleFileChange(e, "img")} accept="image/*" multiple />
                                    </button>
                                    <button title="Upload Files">
                                        <IoDocumentTextOutline />
                                        <input type="file" key={inputKey} accept=".txt, .pdf, .doc, .docx, .pptx, .xlsx" onChange={(e: any) => handleFileChange(e, "doc")} multiple />
                                    </button>
                                </div>

                                <div className={`${cx.typeForm}`}>
                                    <div className="d-flex flex-wrap" style={{ gap: "8px" }}>
                                        {img?.map((item: any) => {
                                            const parts = item?.name.split(".");
                                            const extension = parts[parts.length - 1];
                                            return (
                                                <>
                                                    <div className={`${cx.sendImageBox} ${imgUploadLoading ? cx.imageUploadLoadingBox : ""}`}>
                                                        {/* {imgUploadLoading && <span className={`${cx.loader} spinner-border spinner-border-lg text-success`}
                                                    role="status"></span>} */}
                                                        <img src={imgType === "img" ? item?.url : pdfImage} alt="Selected" className={cx.sendImage} />
                                                        <button className={`${cx.crossButton} btn btn-outline-secondary`} type="button" onClick={(e: any) => handleRemoveImage(item, e)}>
                                                            <GrClose />
                                                        </button>
                                                        <div className={`${cx.type_nameFile}`}><div className={`${cx.nameFiles}`}>{parts[0]}</div><span>.{extension}</span></div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>

                                    {/* <input
                                type="text"
                                className="form-control"
                                placeholder="Write a message..."
                                value={text}
                                onChange={(e) => {
                                    setText(e.target.value);
                                    setSelectedEmoji([e.target.value])
                                }}
                                onKeyDown={handleKeyPress}

                            /> */}

                                    <div className={cx.chatTextareaWrapper}>
                                        <textarea
                                            rows={1}
                                            ref={textareaRef}
                                            className={`${cx.chatTextarea} form-control`}
                                            placeholder="Write a message..."
                                            value={text}
                                            onChange={(e) => {
                                                setText(e.target.value);
                                            }}
                                            onKeyDown={handleKeyPress}
                                        ></textarea>
                                    </div>
                                </div>

                                <div className={`${cx.submitAction}`}>
                                    {/* <button title="Send" disabled={disabled}    className={`${text ? cx.activeTyping : ""} ` }>
                                <FaTelegramPlane onClick={handleSend} />
                            </button> */}
                                    <button
                                        title="Send"
                                        disabled={disabled}
                                        className={`${text !== "" ? cx.activeTyping : ""}`}
                                    >
                                        <FaTelegramPlane onClick={handleSend} />
                                    </button>


                                    <button title="Send" onClick={emojiPicker}>
                                        <MdOutlineEmojiEmotions />
                                    </button>
                                </div>

                                {emoji == true ? (
                                    <div>
                                        <EmojiPicker style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '380px', transform: 'translate(0%, -100%)' }} emojiStyle={EmojiStyle.APPLE} previewConfig={{ showPreview: false }} onEmojiClick={handleEmojiSelect} autoFocusSearch={true} />
                                    </div>) : ""}
                            </div>) : ""}


                    </div>}
                </div>
                <FadeLoader loading={loading} margin={2} width={5} color="#36d7b7" style={{ position: "absolute", top: "50%", left: "50%" }} />
            </div>


            <ChatImgPopUp photoUrl={photoUrl} show={showChatImgPopUp} handleChatImgPopUpClose={handleChatImgPopUpClose} />
        </>


    );
};

export default Chats;
