import React from "react";
import m from "./Modal.module.scss";
import { NavLink } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";

const NoShowModal = (props: any) => {
  let { showNoShow, handleNoShowClose, data, getBookings, getBooking, handleCustomerClose } = props;
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );
  let { branchId } = useSelector((state: any) => state.BranchReducer);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const body = {
      businessId: businessData?._id,
      branchId: branchId,
      bookingStatus: "No Show",
      clientId: data?.Client?._id,
      clientUid: data?.clientUid
    }
    const res = await httpRequest(`updateBooking/${data?._id}`, 'PATCH', body, "json");
    if (res?.status) {
      getBookings && getBookings()
      getBooking && getBooking()
      handleNoShowClose && handleNoShowClose()
      handleCustomerClose && handleCustomerClose()
    }
  }

  const bookingId = data ? `${data?.bookedBy === "online" ? data?.booking_Id || "" : data?.businessBookingId || ""}` : ""
  const formattedBookingId = bookingId ? bookingId?.includes("-") ? bookingId?.split("-")[1] : bookingId : ""

  return (
    <>
      <Modal
        centered
        show={showNoShow}
        backdrop="static"
        keyboard={false}
        onHide={handleNoShowClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={m.modalCts}
      >
        <Modal.Header className="p-2">
          <Modal.Title>No Show {`(${formattedBookingId})` || ""}</Modal.Title>
          <button
            className={`${m.closeIcon} ${m.v2}`}
            onClick={handleNoShowClose}
          >
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className={`${m.logoutPopup}`}>
            <h3 className={`${m.completedConfirmation}`}>
              Appointment set to no-show
            </h3>
            <div className={`${m.btnsAlignments}`}>
              <NavLink
                className={`btn ${m.cancelBtn}`}
                to="#"
                onClick={handleNoShowClose}
              >
                Cancel
              </NavLink>
              <button
                type="button"
                className={`btn ${m.actionBtn}`}
                onClick={handleSubmit}
              >
                Ok
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NoShowModal;
