import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpRequest } from "../../Apis/commonApis";

const initialState = {
    getHelpDocumentState: 0,
    helpDocuments: [],
    error: null,
};

export const getHelpDocument: any = createAsyncThunk(
    "getHelpDocument",
    async () => {
        const res = await httpRequest(`get_helpDocumentB2B`, "get", null, "json");
        console.log(res ,"llll");  
        return res;
    }
);

const helpDocumentSlice: any = createSlice({
    name: "helpDocument",
    initialState,
    reducers: {},
    extraReducers: {
        [getHelpDocument.pending]: (state: any) => {
            state.getHelpDocumentState = 0;
        },
        [getHelpDocument.fulfilled]: (state: any, { payload }) => {
            state.getHelpDocumentState = 1;
            if (payload?.data) {
                state.helpDocuments = payload.data;
            } else {
                state.error = payload.error || payload.message;
            }
        },
        [getHelpDocument.rejected]: (state: any, { error }) => {
            state.getHelpDocumentState = -1;
            state.error = error.message;
        },
    },
});

export default helpDocumentSlice.reducer;
