import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Tab, Nav, Row } from 'react-bootstrap';
import { editButton, deleteIcon, copyClipBoard, upArrow } from '../../../assets/images';
import cx from "./index.module.scss";
import { AiFillCamera } from 'react-icons/ai';
import { RxCross2 } from 'react-icons/rx';
import HoverEyeContent from '../HoverEyeContent/HoverEyeContent';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, Value } from 'react-phone-number-input'
import { FaPlus } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { httpRequest } from '../../../Apis/commonApis';
import ClientCreationForm from './ClientCreationForm';

function AddCustomerCanvas(props: any) {
    let { show, handleAddCustomerCanvasClose } = props;
    const locationData = useLocation()
    const formObj = useForm();
    const {
        register,
    } = formObj

    return (
        <>
            <div className={`${st.offcanvas_newPages1}`}>
                <Offcanvas show={show} onHide={handleAddCustomerCanvasClose} placement="end" className="newOffcanvas_width">
                    <Offcanvas.Header closeButton>
                        <div className={`${st.offHeader_main}`}>
                            <div className='d-flex gap-4 align-items-center mb-1'>
                                <h3>{locationData?.state?.firstName ? `${locationData?.state?.firstName || ""} ${locationData?.state?.lastName || ""}` : "Add a New Customer"}</h3>
                                <div className={`${st.edit_publish_checkBox} mb-2`}>
                                    <label className={`d-inline-flex ${st.checkbox} me-1`}>
                                        <input type="checkbox" {...register("guestclient", {
                                        })} />
                                        <span className={`${st.checkmark}`}></span>
                                        <span className={`${st.labelName} ms-1`}>Walk-in</span>
                                    </label>
                                </div>
                            </div>
                            <p className={`${st.offcanvas_para} mb-0`}>Enter customer details. For walk-in customers,
                                you can create  one common 'Guest Customer' profile by selecting the Walk-In tab.</p>
                        </div>

                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {show && (
                            <ClientCreationForm {...props} cx={cx} formObj={formObj} />
                        )}
                    </Offcanvas.Body>
                    {/* <div className={`${cx.offcanvas_footer}`}>
                        <Col md={12}>
                            <div className={`${st.formBox} ${cx.formSign}`}>
                                <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-3`}>
                                    <button className={`btn ${st.btn3} ${cx.btnGray}`} type="submit">
                                        Save
                                    </button>
                                    <button className={`btn ${st.btn3} ${cx.btnGray} ${cx.btnGrayLight}`} type="submit">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </div> */}
                </Offcanvas>
            </div>

        </>
    )
}

export default AddCustomerCanvas