import React from 'react';
import cx from './index.module.scss';

function TipsOfTheDay({ item }: any) {
    return (
        <li>
            <div className={`${cx.notification_ImgPara}`}>
                <div className={`${cx.paraBox}`}>
                    <h5 className={cx.paraTitle}>{item?.title || ''}</h5>
                    <p className={`${cx.paraTop}`}>{item?.content}</p>
                </div>
            </div>
        </li>
    )
}

export default TipsOfTheDay