import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Modal, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { httpRequest } from "../../../Apis/commonApis";
import SuccessPopup from "./SuccessPopup";
import HelpButon from "../HelpButon.tsx/HelpButton";
import HoverEyeContent from "../HoverEyeContent/HoverEyeContent";
import { AnyCnameRecord } from "dns";
import { useSelector } from "react-redux";
import LoadingBtn from "../Buttons/LoadingBtn";
import Select from 'react-select';

const ChangeAccessManager = (props: any) => {
    const { showAccessManagerModal, handleAccessManagerModalClose, accessRole, data, getTeamMember } = props;

    const [selectedRoleArr, setSelectedRoleArr] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [roles, setRoles] = useState<any>([])

    function handleRole(value: any, branchId: any) {        
        let updatedRoleArr: any = [...selectedRoleArr];
        const accessManager = value?.map((item:any) => item?.label)
        const accessManagerId = value?.map((item:any) => item?.value)

        updatedRoleArr = updatedRoleArr?.map((item: any) => {
            if (item?.branch === branchId) {
                return {
                    ...item,
                    accessManagerId,
                    accessManager,
                }
            }
            return item
        })
        setSelectedRoleArr(updatedRoleArr)
    }

    async function handleSubmit(e: any) {
        e.preventDefault();
        setLoading(true)
        const formdata = new FormData();
        formdata.append("Role", JSON.stringify(selectedRoleArr))
        const res = await httpRequest(`updateTeamMember/${data?._id}`, 'PATCH', formdata, "formdata");
        if (res?.status) {
            getTeamMember && getTeamMember()
            handleAccessManagerModalClose()
        }
        setLoading(false)
    }

    useEffect(() => {
        if (data) {
            setSelectedRoleArr(data?.Role?.map((item: any) => ({ ...item, branch: item?.branch?._id, role: item?.role?.map((role: any) => role?._id) })))

        }
    }, [data])
    

    function handleAccessManager(data:any, branchId:any){
        setRoles(data);
        handleRole(data ,branchId)
    }

    const branchRoleObj = selectedRoleArr?.find((item: any) => item?.branch === data?.branch?._id)

    useEffect(() =>{
        if(branchRoleObj){
            const rolesArr = Array.isArray(branchRoleObj?.accessManager) ? branchRoleObj?.accessManager?.map((item:any, i:number) => ({label:item, value:branchRoleObj?.accessManagerId?.[i]})) : [{label:branchRoleObj?.accessManager , value:branchRoleObj?.accessManager?.accessManagerId}]
            setRoles(rolesArr)
        }
    },[branchRoleObj])

    const accessRoleValues = accessRole?.map((item: any) => ({ label: item?.role, value: item?._id }));

    return (
        <>
            <Modal
                centered
                show={showAccessManagerModal}
                backdrop="static"
                keyboard={false}
                onHide={handleAccessManagerModalClose}
                aria-labelledby="example-modal-sizes-title-sm"
                className={m.modalCts}
            >
                <Modal.Header>
                    <Modal.Title>
                        Change Role
                    </Modal.Title>
                    <button
                        className={`${m.closeIcon}`}
                        onClick={handleAccessManagerModalClose}
                    >
                        <MdClose />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <div className={`${st.formBox}`}>
                                <label className="form-label">Branch</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="branch"
                                    value={data?.branch?.branchName}
                                    disabled
                                />
                            </div>
                        </Col>

                        <Col md={12}>
                            <div className={`${st.formBox}`}>
                                <label className="form-label">Access Manager</label>
                                <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    options={accessRoleValues}
                                    value={roles}
                                    onChange={(data:any) => handleAccessManager(data, branchRoleObj?.branch)}

                                />
                                {/* <select className="form-select" onChange={(e: any) => handleRole(e.target.value, branchRoleObj?.branch)} value={`${branchRoleObj?.accessManagerId}#${branchRoleObj?.accessManager}`}>
                                    <option value="">--Select--</option>
                                    {accessRole?.map((item: any, i: number) => {
                                        return <option value={`${item?._id}#${item?.role}`} key={i}>{item?.role}</option>
                                    })}
                                </select> */}
                            </div>
                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className={`btn ${m.cancelBtn}`}
                        onClick={(e) => {
                            handleAccessManagerModalClose()
                        }}
                    >
                        Close
                    </button>
                    <button className={`btn ${m.actionBtn}`} disabled={loading} onClick={(e: any) => handleSubmit(e)}>
                        {loading ? <LoadingBtn /> : 'Save'}
                    </button>
                </Modal.Footer>
            </Modal>
            {/* <SuccessPopup
        showSuccessModal={showSuccessModal}
        handleSuccessModalClose={handleSuccessModalClose}
        message={message}
      /> */}
        </>
    );
};

export default ChangeAccessManager;
