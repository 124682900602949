import React, { useState } from 'react';
import st from '../../../assets/stylesheet/style.module.scss';
import { useSelector } from 'react-redux';
import { httpRequest } from "../../../Apis/commonApis";
import ErrorPopup from '../../../components/Business/Modals/ErrorPopup';

export default function SuggestedServiceCard({ service, serviceList, branchData, getService, setFilterCat, catData }: any) {
    const serviceBusinessCat = catData?.find((cat: any) => cat?.superServiceCategoryId === service?.SuperServiceCategory?._id)
    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    const addedService = serviceList?.find((item: any) => item?.serviceName === service?.serviceName)
    const [showErrorModal, setShowErrorModal] = useState("");
    const handleAlertModalClose = () => setShowErrorModal("");
    const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);

    const createService = async () => {
        let basicData: any = {}
        basicData.serviceName = service?.serviceName;
        basicData.serviceCatagoryId = serviceBusinessCat?._id;
        basicData.serviceAvilableFor = ''
        basicData.businessId = businessData?._id;
        basicData.branchId = branchData?.map((item: any) => item?._id);
        basicData.serviceDescription = '';
        basicData.ServiceAftercareDescription = '';
        let apiUrl = 'createBusinessService';
        let apiMethod = 'post';
        const res = await httpRequest(apiUrl, apiMethod, basicData, "json");
        if (res.status) {
            handleAlertModalShow("Success, Service added successfully. Don't forget to add price")
            getService && getService();
            setFilterCat('all')
        }
    }
    const deletedService = async () => {
        const res = await httpRequest(
            `deleteBusinessService?_id=${addedService?._id}&businessId=${businessData?._id}`,
            "DELETE",
            null,
            "json"
        );
        if (res.status) {
            getService && getService()
            setFilterCat('all')
        }
    }
    return (
        <>
            <ErrorPopup
                showErrorModal={showErrorModal}
                handleErrorModalClose={handleAlertModalClose}
            />
            <div className={`${st.edit_publishBranchMain}`}>
                <div className={`${st.topBox_withBtn} d-flex`}>
                    <h5>{service?.serviceName}</h5>
                    <span className={`${st.value}`} style={{ fontSize: '14px', borderRadius: '54px', color: '#fff', fontWeight: '500', textAlign: 'center', minWidth: '80px', padding: '2px 0', maxWidth: '80px', background: `${addedService ? '#cf4956' : '#508AAA'}`, margin: '0', cursor: 'pointer' }} onClick={() => addedService ? deletedService() : createService()}>{addedService ? <span>Remove</span> : <span>Add</span>}</span>
                </div>
            </div>
        </>
    )
}
