import cx from './loader.module.scss';
export default function Loader({ className }: any) {
    return (
        <div className={`${cx.container} ${className ? className : ''}`}>
            <div className={cx.rlLoadingContainer}>
                <div className={`${cx.rlLoadingThumb} ${cx.thumb1}`}></div>
                <div className={`${cx.rlLoadingThumb} ${cx.thumb2}`}></div>
                <div className={`${cx.rlLoadingThumb} ${cx.thumb3}`}></div>
                <div className={`${cx.rlLoadingThumb} ${cx.thumb4}`}></div>
            </div>
        </div>
    )
}